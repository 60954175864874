import { StationResponseDto } from '@pclocs/platform-sdk';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLatestSdkCall } from '../../helpers/sdk-hooks';
import { GET_AVAILABLE_FIRMWARE_LIST } from '../../store/actionTypes';
import { RootState } from '../../store/rootReducer';
import _ from 'lodash';
import { firmwareValidAndGt, MINIMUM_FIRMWARE_VERSION } from '../../helpers/station';

export const useUngroupedStationsList = () => {
  const dispatch = useDispatch();
  const latestFirmwareVersion = useSelector((state: RootState) => {
    return state.dashboard.firmwareList?.[0]?.version;
  });

  const [ungroupedStations, setUngroupedStations] = useState<StationResponseDto[]>();
  const [fetchError, setFetchError] = useState<Error>(undefined);
  const [fetchUngroupedStations] = useLatestSdkCall(
    'StationApi',
    'findUngrouped',
    res => {
      setFetchError(undefined);
      setUngroupedStations(res.data);
    },
    e => setFetchError(e.error)
  );

  const [updatingStationIds, setUpdatingStationIds] = useState<string[]>([]);
  const [resettingStationIds, setResettingStationIds] = useState<string[]>([]);
  const stationStatusPollingTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    dispatch({ type: GET_AVAILABLE_FIRMWARE_LIST });
    fetchUngroupedStations();
    return () => clearInterval(stationStatusPollingTimer.current);
  }, []);

  // clear the station updating list based on incoming results
  useEffect(() => {
    if (!ungroupedStations) {
      return;
    }
    const stationsUpdating = ungroupedStations.filter(v => !!v.reported?.firmwareUpdateInProgress).map(v => v.id);
    const stationsOnLatestFirmware = ungroupedStations
      .filter(v => !firmwareValidAndGt(latestFirmwareVersion, v.reported?.firmwareVersion?.toString()))
      .map(v => v.id);
    setUpdatingStationIds(_.difference(_.uniq([...stationsUpdating, ...updatingStationIds]), stationsOnLatestFirmware));
    setResettingStationIds(resettingStationIds.filter(id => !!ungroupedStations.find(s => s.id === id)));
  }, [ungroupedStations, latestFirmwareVersion]);

  // While we have pending stations let's poll the endpoint for updates
  useEffect(() => {
    if (updatingStationIds.length + resettingStationIds.length === 0) {
      clearInterval(stationStatusPollingTimer.current);
    } else {
      stationStatusPollingTimer.current =
        stationStatusPollingTimer.current ?? setInterval(fetchUngroupedStations, 15000);
    }
  }, [updatingStationIds, resettingStationIds]);

  return {
    ungroupedStations:
      latestFirmwareVersion &&
      ungroupedStations?.map(s => {
        return {
          id: s.id,
          name: s.name ?? s.id,
          mandatoryFirmwareUpdate: firmwareValidAndGt(
            MINIMUM_FIRMWARE_VERSION,
            s.reported?.firmwareVersion?.toString()
          ),
          availableFirmwareUpdate: firmwareValidAndGt(latestFirmwareVersion, s.reported?.firmwareVersion?.toString()),
          isUpdatingFirmware: updatingStationIds.includes(s.id),
          isFactoryResetting: resettingStationIds.includes(s.id)
        };
      }),
    fetchError,
    flagStationUpdating: (id: string) => {
      setUpdatingStationIds(_.uniq([...updatingStationIds, id]));
    },
    flagStationFactoryResetting: (id: string) => {
      setResettingStationIds(_.uniq([...resettingStationIds, id]));
    }
  };
};
