import _ from 'lodash';
import React, { Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as ActionTypes from '../../../store/actionTypes';
import { CommonActionTypes, UpdateAuthorizedAction } from '../../../store/common/types';
import { RootState } from '../../../store/rootReducer';
import { FetchFailedAlert } from '../fetch-failed-alert';
import { ManageAuthorized } from '../ManageAuthorized/manage-authorized';

const mapStateToProps = (state: RootState) => ({
  ..._.pick(state.dashboard, ['bayUsersFetchFailed'])
});
const mapDispatchToProps = (dispatch: Dispatch<CommonActionTypes>) => ({
  fetch: (id: string) => dispatch({ type: ActionTypes.FETCH_AUTHORIZED_IN_BAY, payload: { id } }),
  update: (id: string, authorizedEntities: UpdateAuthorizedAction['payload']['authorizedEntities']) =>
    dispatch({ type: ActionTypes.UPDATE_AUTHORIZED_IN_BAY, payload: { id, authorizedEntities } }),
  remove: (id: string) => dispatch({ type: ActionTypes.REMOVE_ALL_AUTHORIZED_IN_BAY, payload: { id } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const BayUsers = connector((props: Props) => {
  if (props.bayUsersFetchFailed) {
    return <FetchFailedAlert itemType="bay" statusCode={props.bayUsersFetchFailed.statusCode} />;
  }

  return (
    <>
      <p data-test-id="bay-info-display" style={{ marginBottom: 30 }}>
        Adding to this list will authorize users to access the bay.
      </p>
      <ManageAuthorized
        fetchUsers={props.fetch}
        updateAuthorized={{ update: props.update, remove: props.remove }}
        maxItemsPerUpdateForManageUsers={1000}
      />
    </>
  );
});
