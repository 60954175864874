import { all, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import * as ActionTypes from '../actionTypes';
import { createApi } from '../../api/sdk';
import { EventLogsState, FindEventLogsAction, FindEventLogsErrorAction, FindEventLogsSuccessAction } from './types';
import { EventLogsApi, EventLogsQueryDto } from '@pclocs/platform-sdk/dist';
import { RootState } from '../rootReducer';

function* findEventLogs() {
  yield takeLatest(ActionTypes.FIND_EVENT_LOGS, function*(action: FindEventLogsAction) {
    try {
      const eventLogState: EventLogsState = yield select((state: RootState) => state.eventLogs);
      const api = createApi<EventLogsApi>('EventLogsApi');
      const query: EventLogsQueryDto = {
        startDate: eventLogState.startDateFilter,
        endDate: eventLogState.endDateFilter,
        userTypes: eventLogState.userTypeFilters,
        descriptionSearch: eventLogState.searchFilters
      };

      let executionId = eventLogState.executionId;
      if (!executionId) {
        const response: ApiReturnType<typeof api.query> = yield call(api.query, query);
        executionId = response.data.executionId;
      }

      let getDataResponse: ApiReturnType<typeof api.getResults>;
      do {
        getDataResponse = yield call(api.getResults, executionId, action.payload.pageSize, eventLogState.nextToken);
        yield delay(500);
      } while (getDataResponse.data.available === false);

      yield put<FindEventLogsSuccessAction>({
        type: ActionTypes.FIND_EVENT_LOGS_SUCCESS,
        payload: {
          data: { items: getDataResponse.data.items, nextToken: getDataResponse.data.nextToken, executionId }
        }
      });
    } catch (e) {
      console.error(e);
      yield put<FindEventLogsErrorAction>({
        type: ActionTypes.FIND_EVENT_LOGS_ERROR,
        payload: { fetchError: 'Failed to fetch items' }
      });
    }
  });
}

export function* eventLogsSaga() {
  yield all([findEventLogs()]);
}
