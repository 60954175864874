import { DashboardActionTypes, DashboardState } from './types';
import { CommonActionTypes } from '../common/types';
import * as ActionTypes from '../actionTypes';
import _, { Dictionary } from 'lodash';
import { BayResponseDto, CurfewDto, CurfewScheduleDto } from '@pclocs/platform-sdk';

function mapCurfewSchedule(curfew: CurfewDto) {
  return _.mapValues({ mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 }, index =>
    curfew?.schedule ? curfew.schedule.filter((schedule: CurfewScheduleDto) => schedule.dayOfTheWeek === index) : []
  );
}

/**
 * Merges multiple bay reported states into the bays state object.
 */
function mergeBayReported(
  baysState: DashboardState['bays'],
  reportedDeltas: Dictionary<Partial<BayResponseDto['reported']>>
): DashboardState['bays'] {
  if (baysState === undefined) {
    return undefined;
  }
  const validDeltas = _.pickBy(reportedDeltas || {}, (_val, bayId: string) => baysState[bayId] !== undefined);
  return {
    ...baysState,
    ..._.mapValues(validDeltas, (delta: Partial<BayResponseDto['reported']>, bayId: string) => {
      return {
        ...baysState[bayId],
        reported: {
          ...baysState[bayId].reported,
          ...delta
        }
      };
    })
  };
}

/**
 * Merges multiple bay syncErrors states into the bays state object.
 */
function mergeBaySyncErrors(
  baysState: DashboardState['bays'],
  syncErrors: Dictionary<BayResponseDto['syncErrors']>
): DashboardState['bays'] {
  if (baysState === undefined) {
    return undefined;
  }
  const validDeltas = _.pickBy(syncErrors || {}, (_val, bayId: string) => baysState[bayId] !== undefined);
  return {
    ...baysState,
    ..._.mapValues(validDeltas, (delta: BayResponseDto['syncErrors'], bayId: string) => {
      return {
        ...baysState[bayId],
        syncErrors: {
          ...baysState[bayId].syncErrors,
          ...delta
        }
      };
    })
  };
}

/**
 * Merged a config value into derivedConfig and optionally into config. Omit the last parameter if you do not want to set this value.
 * @param state The station or group state portion.
 * @param configKey The config key to set.
 * @param derivedConfigValue The derived config value. This has to be provided.
 * @param configValue The config value. Omit this parameter to not set any value into config. Setting `undefined` will clear the value.
 */
function mergeConfigValue<T extends DashboardState['station'] | DashboardState['group']>(
  state: T,
  configKey: string,
  derivedConfigValue: any,
  configValue?: any
): T {
  if (state === undefined) {
    return state;
  }
  return {
    ...state,
    config:
      arguments.length === 4
        ? {
            ...state.config,
            ...{
              [configKey]: configValue
            }
          }
        : state.config,
    derivedConfig: {
      ...state.derivedConfig,
      ...{
        [configKey]: derivedConfigValue
      }
    }
  };
}

const defaultState: DashboardState = {
  changeAccessModePending: false,
  changeBayOfflineWhenAccessedPending: false,
  accessModeOverridePending: false,
  changeNamePending: false,
  showNetworkModal: false,
  networkSettingsPending: false,
  changeTimezonePending: false,
  showGroupNetworkModal: false,
  restartStationPending: false,
  factoryResetPending: false,
  toggleBayOfflinePending: [],
  unlockBayPending: [],
  showCurfewModal: false,
  updateCurfewPending: false,
  toggleStationLockdownPending: false,
  bayAssignmentPending: false,
  firmwareUpdatePending: false,
  newSubGroupPending: false,
  deleteSubGroupPending: false,
  firmwareUpdateSchedule: {
    version: 'latest',
    windows: []
  },
  toggleGroupAutomaticFirmwareUpdateOverridePending: false,
  toggleGroupAutomaticFirmwareUpdatePending: false,
  showAssignCredentialsModal: false
};

export const dashboard = (
  state: DashboardState = defaultState,
  action: DashboardActionTypes | CommonActionTypes
): DashboardState => {
  switch (action.type) {
    case ActionTypes.DASHBOARD_PAGE_UNLOADED: {
      return {
        ...defaultState
      };
    }
    case ActionTypes.UPDATE_STATION_STATUS: {
      let bays = state.bays;
      if (action.payload.data.bays) {
        bays = mergeBayReported(state.bays, action.payload.data.bays);
      }
      if (action.payload.data.baySyncErrors) {
        bays = mergeBaySyncErrors(state.bays, action.payload.data.baySyncErrors);
      }
      return {
        ...state,
        station:
          state.station && state.station.id === action.payload.id
            ? {
                ...state.station,
                reported: {
                  ...state.station.reported,
                  ...action.payload.data.station?.reported
                },
                derivedConfig: {
                  ...state.station.derivedConfig,
                  ...action.payload.data.station?.derivedConfig
                },
                syncErrors: {
                  ...state.station.syncErrors,
                  ...action.payload.data.station?.syncErrors
                }
              }
            : state.station,
        bays
      };
    }
    case ActionTypes.FETCH_AUTHORIZED_IN_BAY: {
      return {
        ...state,
        bayUsersFetchFailed: undefined
      };
    }
    case ActionTypes.FETCH_AUTHORIZED_IN_BAY_FAILED: {
      return {
        ...state,
        bayUsersFetchFailed: {
          statusCode: action.payload.statusCode
        }
      };
    }
    case ActionTypes.FETCH_GROUP: {
      return {
        ...state,
        ...(action.payload.id !== state.group?.id
          ? {
              group: undefined
            }
          : {}),
        groupFetchFailed: undefined
      };
    }
    case ActionTypes.FETCH_GROUP_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        group: data,
        curfewSchedule: mapCurfewSchedule(data.derivedConfig.curfew as CurfewDto)
      };
    }
    case ActionTypes.FETCH_GROUP_FAILED: {
      return {
        ...state,
        groupFetchFailed: {
          statusCode: action.payload.statusCode
        }
      };
    }
    case ActionTypes.FETCH_GROUP_OVERVIEW_SUCCESS: {
      return {
        ...state,
        groupOverview: action.payload.data
      };
    }
    case ActionTypes.UNLOAD_GROUP_OVERVIEW: {
      return {
        ...state,
        groupOverview: undefined
      };
    }
    case ActionTypes.UNLOAD_GROUP: {
      return {
        ...state,
        group: undefined,
        groupFetchFailed: undefined
      };
    }
    case ActionTypes.CLEAR_STATION: {
      return {
        ...state,
        station: undefined
      };
    }
    case ActionTypes.FETCH_STATION: {
      return {
        ...state,
        ...(action.payload.id !== state.station?.id
          ? {
              changeNamePending: false,
              station: undefined,
              bays: undefined
            }
          : {}),
        stationFetchFailed: undefined
      };
    }
    case ActionTypes.FETCH_STATION_FAILED: {
      return {
        ...state,
        stationFetchFailed: {
          statusCode: action.payload.statusCode
        }
      };
    }
    case ActionTypes.FETCH_STATION_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        station: _.omit(data, ['bays']),
        bays: data.bays,
        curfewSchedule: mapCurfewSchedule(data.derivedConfig.curfew as CurfewDto)
      };
    }
    case ActionTypes.UPDATE_GROUP_NAME:
    case ActionTypes.UPDATE_BAY_NAME:
    case ActionTypes.UPDATE_STATION_NAME: {
      return {
        ...state,
        changeNamePending: true
      };
    }
    case ActionTypes.UPDATE_STATION_NAME_SUCCESS:
    case ActionTypes.UPDATE_STATION_TAGS_SUCCESS: {
      return {
        ...state,
        changeNamePending: false,
        station: state.station
          ? {
              ...state.station,
              name: action.payload.station.name
            }
          : state.station
      };
    }
    case ActionTypes.UPDATE_BAY_NAME_SUCCESS: {
      return {
        ...state,
        changeNamePending: false,
        bays: {
          ...state.bays,
          [action.payload.id]: action.payload.bay
        }
      };
    }
    case ActionTypes.UPDATE_GROUP_NAME_SUCCESS:
    case ActionTypes.UPDATE_GROUP_TAGS_SUCCESS: {
      return {
        ...state,
        changeNamePending: false,
        group: state.group
          ? {
              ...state.group,
              name: action.payload.group.name,
              tags: action.payload.group.tags
            }
          : state.group
      };
    }
    case ActionTypes.UPDATE_GROUP_NAME_ERROR:
    case ActionTypes.UPDATE_BAY_NAME_ERROR:
    case ActionTypes.UPDATE_STATION_NAME_ERROR: {
      return {
        ...state,
        changeNamePending: false
      };
    }
    case ActionTypes.TOGGLE_STATION_ACCESS_MODE_PENDING: {
      return {
        ...state,
        changeAccessModePending: action.payload.value
      };
    }
    case ActionTypes.TOGGLE_STATION_ACCESS_MODE_OVERRIDE_PENDING: {
      return {
        ...state,
        accessModeOverridePending: action.payload.value
      };
    }
    case ActionTypes.CHANGE_STATION_ACCESS_MODE_SUCCESS: {
      return {
        ...state,
        station: mergeConfigValue(state.station, 'managed', action.payload.managed),
        changeAccessModePending: false
      };
    }
    case ActionTypes.TOGGLE_STATION_ACCESS_MODE_OVERRIDE_SUCCESS: {
      return {
        ...state,
        station: mergeConfigValue(
          state.station,
          'managed',
          action.payload.derivedManaged,
          action.payload.configManaged
        ),
        accessModeOverridePending: false
      };
    }
    case ActionTypes.TOGGLE_GROUP_ACCESS_MODE_PENDING:
    case ActionTypes.TOGGLE_GROUP_ACCESS_MODE_OVERRIDE_PENDING: {
      return {
        ...state,
        accessModeOverridePending: action.payload.value
      };
    }
    case ActionTypes.CHANGE_GROUP_ACCESS_MODE_SUCCESS:
    case ActionTypes.TOGGLE_GROUP_ACCESS_MODE_OVERRIDE_SUCCESS: {
      return {
        ...state,
        group:
          action.type === ActionTypes.TOGGLE_GROUP_ACCESS_MODE_OVERRIDE_SUCCESS
            ? mergeConfigValue(state.group, 'managed', action.payload.derivedManaged, action.payload.configManaged)
            : mergeConfigValue(state.group, 'managed', action.payload.managed, action.payload.managed),
        accessModeOverridePending: false
      };
    }
    case ActionTypes.CHANGE_GROUP_BAY_OFFLINE_WHEN_ACCESSED:
    case ActionTypes.TOGGLE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE: {
      return {
        ...state,
        changeBayOfflineWhenAccessedPending: true
      };
    }
    case ActionTypes.CHANGE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS:
    case ActionTypes.TOGGLE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS: {
      return {
        ...state,
        changeBayOfflineWhenAccessedPending: false,
        group: action.payload.group
      };
    }
    case ActionTypes.CHANGE_STATION_BAY_OFFLINE_WHEN_ACCESSED:
    case ActionTypes.TOGGLE_STATION_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE: {
      return {
        ...state,
        changeBayOfflineWhenAccessedPending: true
      };
    }
    case ActionTypes.CHANGE_STATION_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS:
    case ActionTypes.TOGGLE_STATION_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS: {
      return {
        ...state,
        changeBayOfflineWhenAccessedPending: false,
        station: action.payload.station
      };
    }

    case ActionTypes.TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE: {
      return {
        ...state,
        toggleGroupAutomaticFirmwareUpdatePending: true
      };
    }

    case ActionTypes.TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_SUCCESS: {
      return {
        ...state,
        group: action.payload.group,
        toggleGroupAutomaticFirmwareUpdatePending: false
      };
    }

    case ActionTypes.TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE: {
      return {
        ...state,
        toggleGroupAutomaticFirmwareUpdateOverridePending: true,
        toggleGroupAutomaticFirmwareUpdatePending: true
      };
    }

    case ActionTypes.TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE_SUCCESS: {
      return {
        ...state,
        group: action.payload.group,
        toggleGroupAutomaticFirmwareUpdateOverridePending: false,
        toggleGroupAutomaticFirmwareUpdatePending: false
      };
    }

    /**
     * TODO: FIX THESE UP!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
     */

    case ActionTypes.CHANGE_BAY_BAY_OFFLINE_WHEN_ACCESSED:
    case ActionTypes.TOGGLE_BAY_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE: {
      return {
        ...state,
        changeBayOfflineWhenAccessedPending: true
      };
    }
    case ActionTypes.CHANGE_BAY_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS:
    case ActionTypes.TOGGLE_BAY_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS: {
      return {
        ...state,
        changeBayOfflineWhenAccessedPending: false,
        bays: {
          ...state.bays,
          [action.payload.bay.id]: action.payload.bay
        }
      };
    }

    case ActionTypes.CHANGE_GROUP_TIMEZONE:
    case ActionTypes.TOGGLE_GROUP_TIMEZONE_OVERRIDE: {
      return {
        ...state,
        changeTimezonePending: true
      };
    }
    case ActionTypes.CHANGE_GROUP_TIMEZONE_SUCCESS:
    case ActionTypes.TOGGLE_GROUP_TIMEZONE_OVERRIDE_SUCCESS: {
      return {
        ...state,
        changeTimezonePending: false,
        group: action.payload.group
      };
    }
    case ActionTypes.TOGGLE_NETWORK_MODAL: {
      return {
        ...state,
        showNetworkModal: action.payload.value
      };
    }
    case ActionTypes.UPDATE_NETWORK_SETTINGS:
    case ActionTypes.UPDATE_GROUP_NETWORK_SETTINGS: {
      return {
        ...state,
        networkSettingsPending: true
      };
    }
    case ActionTypes.UPDATE_NETWORK_SETTINGS_SUCCESS: {
      return {
        ...state,
        station: mergeConfigValue(
          state.station,
          'networkSettings',
          action.payload.derivedNetworkSettings,
          action.payload.configNetworkSettings
        ),
        networkSettingsPending: false,
        showNetworkModal: false
      };
    }
    case ActionTypes.UPDATE_GROUP_NETWORK_SETTINGS_SUCCESS: {
      return {
        ...state,
        group: mergeConfigValue(
          state.group,
          'networkSettings',
          action.payload.derivedNetworkSettings,
          action.payload.configNetworkSettings
        ),
        networkSettingsPending: false,
        showGroupNetworkModal: false
      };
    }
    case ActionTypes.UPDATE_NETWORK_SETTINGS_ERROR: {
      return {
        ...state,
        networkSettingsPending: false
      };
    }
    case ActionTypes.TOGGLE_GROUP_NETWORK_MODAL: {
      return {
        ...state,
        showGroupNetworkModal: action.payload.value
      };
    }
    case ActionTypes.RESTART_STATION: {
      return {
        ...state,
        restartStationPending: true
      };
    }
    case ActionTypes.RESTART_STATION_SUCCESS:
    case ActionTypes.RESTART_STATION_ERROR: {
      return {
        ...state,
        restartStationPending: false
      };
    }
    case ActionTypes.FACTORY_RESET_STATION: {
      return {
        ...state,
        factoryResetPending: true
      };
    }
    case ActionTypes.FACTORY_RESET_STATION_SUCCESS: {
      return {
        ...state,
        factoryResetPending: false
      };
    }
    case ActionTypes.TOGGLE_BAY_OFFLINE: {
      return {
        ...state,
        toggleBayOfflinePending: [...state.toggleBayOfflinePending, action.payload.id]
      };
    }
    case ActionTypes.TOGGLE_BAY_OFFLINE_SUCCESS: {
      return {
        ...state,
        toggleBayOfflinePending: state.toggleBayOfflinePending.filter(v => v !== action.payload.id)
      };
    }
    case ActionTypes.UNLOCK_BAY: {
      return {
        ...state,
        unlockBayPending: [...state.unlockBayPending, action.payload.id]
      };
    }
    case ActionTypes.UNLOCK_BAY_SUCCESS: {
      return {
        ...state,
        unlockBayPending: state.unlockBayPending.filter(v => v !== action.payload.id)
      };
    }
    case ActionTypes.ADD_CURFEW_SLIDER: {
      if (state.curfewSchedule === undefined) return state;

      return {
        ...state,
        curfewSchedule: {
          ...state.curfewSchedule,
          [action.payload.dayOfTheWeek]: [...state.curfewSchedule[action.payload.dayOfTheWeek], action.payload.schedule]
        }
      };
    }
    case ActionTypes.UPDATE_CURFEW_SLIDER: {
      state.curfewSchedule &&
        state.curfewSchedule[action.payload.dayOfTheWeek].splice(action.payload.index, 1, action.payload.schedule);
      return {
        ...state
      };
    }
    case ActionTypes.REMOVE_CURFEW_SLIDER: {
      state.curfewSchedule && state.curfewSchedule[action.payload.dayOfTheWeek].splice(action.payload.index, 1);
      return {
        ...state,
        curfewSchedule: _.cloneDeep(state.curfewSchedule)
      };
    }
    case ActionTypes.LOAD_CURFEW_MODAL: {
      return {
        ...state,
        showCurfewModal: true,
        initialCurfewSchedule: action.payload.curfewSchedule
      };
    }
    case ActionTypes.UNLOAD_CURFEW_MODAL: {
      return {
        ...state,
        showCurfewModal: false,
        curfewSchedule: state.initialCurfewSchedule
      };
    }
    case ActionTypes.UPDATE_GROUP_CURFEW:
    case ActionTypes.UPDATE_CURFEW: {
      return {
        ...state,
        updateCurfewPending: true
      };
    }
    case ActionTypes.UPDATE_CURFEW_SUCCESS: {
      return {
        ...state,
        station: mergeConfigValue(state.station, 'curfew', action.payload.derivedCurfew, action.payload.curfew),
        updateCurfewPending: false,
        showCurfewModal: false
      };
    }
    case ActionTypes.UPDATE_GROUP_CURFEW_SUCCESS: {
      return {
        ...state,
        group: mergeConfigValue(state.group, 'curfew', action.payload.derivedCurfew, action.payload.curfew),
        updateCurfewPending: false,
        showCurfewModal: false
      };
    }
    case ActionTypes.REMOVE_BAY_ASSIGNMENT:
    case ActionTypes.SET_BAY_ASSIGNMENT: {
      return {
        ...state,
        bayAssignmentPending: true
      };
    }
    case ActionTypes.REMOVE_BAY_ASSIGNMENT_SUCCESS:
    case ActionTypes.SET_BAY_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        bayAssignmentPending: false,
        showAssignCredentialsModal: false,
        bays: mergeBayReported(state.bays, {
          [action.payload.bayId]: { accessDate: undefined }
        })
      };
    }
    case ActionTypes.REMOVE_BAY_ASSIGNMENT_ERROR:
    case ActionTypes.SET_BAY_ASSIGNMENT_ERROR: {
      return {
        ...state,
        bayAssignmentPending: false,
        showAssignCredentialsModal: false
      };
    }
    case ActionTypes.TOGGLE_ASSIGN_CREDENTIALS_MODAL: {
      return {
        ...state,
        showAssignCredentialsModal: action.payload.showModal
      };
    }
    case ActionTypes.TOGGLE_STATION_LOCKDOWN: {
      return {
        ...state,
        toggleStationLockdownPending: true
      };
    }
    case ActionTypes.TOGGLE_STATION_LOCKDOWN_SUCCESS:
    case ActionTypes.TOGGLE_STATION_LOCKDOWN_ERROR: {
      return {
        ...state,
        toggleStationLockdownPending: false
      };
    }
    case ActionTypes.GET_AVAILABLE_FIRMWARE_LIST_SUCCESS: {
      return {
        ...state,
        firmwareList: action.payload.firmwareList
      };
    }
    case ActionTypes.UPDATE_FIRMWARE: {
      return {
        ...state,
        firmwareUpdatePending: true
      };
    }
    case ActionTypes.UPDATE_FIRMWARE_ERROR:
    case ActionTypes.UPDATE_FIRMWARE_SUCCESS: {
      return {
        ...state,
        firmwareUpdatePending: false
      };
    }
    case ActionTypes.CREATE_SUB_GROUP: {
      return {
        ...state,
        newSubGroupPending: true
      };
    }
    case ActionTypes.CREATE_SUB_GROUP_SUCCESS:
    case ActionTypes.CREATE_SUB_GROUP_ERROR: {
      return {
        ...state,
        newSubGroupPending: false
      };
    }
    case ActionTypes.DELETE_SUB_GROUP: {
      return {
        ...state,
        deleteSubGroupPending: true
      };
    }
    case ActionTypes.DELETE_SUB_GROUP_SUCCESS: {
      return {
        ...state,
        deleteSubGroupPending: false
      };
    }
    case ActionTypes.DELETE_SUB_GROUP_ERROR: {
      return {
        ...state,
        deleteSubGroupPending: false
      };
    }
    default:
      return state;
  }
};
