import { take, put, call, delay } from 'redux-saga/effects';
import * as ActionTypes from '../actionTypes';
import { SetDegradationStatusAction } from './types';
import { DegradationStatusApi } from '@pclocs/platform-sdk';
import { createApi } from '../../api/sdk';

const DEGRADATION_STATUS_POLL_INTERVAL_IN_MS = 300000; // Added 5 minutes delay for each poll

export function* checkDegradationStatus() {
  yield take(ActionTypes.CHECK_DEGRADATION_STATUS);

  while (true) {
    try {
      const aaf = createApi<DegradationStatusApi>('DegradationStatusApi');
      const response: ApiReturnType<typeof aaf.getStatus> = yield call(aaf.getStatus);

      yield put<SetDegradationStatusAction>({
        type: ActionTypes.SET_DEGRADATION_STATUS,
        payload: {
          status: response.data.status
        }
      });
    } catch (e) {
      console.error(e);
    }

    yield delay(DEGRADATION_STATUS_POLL_INTERVAL_IN_MS);
  }
}

export function* degradationStatusSaga() {
  yield checkDegradationStatus();
}
