import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import * as ActionTypes from '../store/actionTypes';
import { RootState } from '../store/rootReducer';
import { BackgroundJob, NotificationMessageOptions, TriggeredBackgroundJob } from '../store/common/types';
import { ErrorMessage, LoadMessage, SuccessMessage } from './job-status-notification-helper';
import { usePrevious } from './use-previous-hook';
import { BackgroundJobCompleteStatusResponseDto } from '@pclocs/platform-sdk';
import _ from 'lodash';

export const useJobStatusNotificationHook = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => ({
    currentUser: state.auth.currentUser,
    backgroundJobs: state.common.backgroundJobs,
    triggeredBackgroundJobs: state.common.triggeredBackgroundJobs
  }));

  const removeCurrentJob = (jobId: string) => {
    dispatch({ type: ActionTypes.REMOVE_TRIGGERED_BACKGROUND_JOB, payload: { jobId } });
  };

  const showMessage = ({ type, ...props }: NotificationMessageOptions) => message[type](props);

  const hideMessage = (config: NotificationMessageOptions) => showMessage({ ...config, duration: 0.5 });

  const showLoadMessage = (job: TriggeredBackgroundJob) => {
    showMessage({
      key: job.id,
      type: 'loading' as const,
      content: <LoadMessage jobKey={job.jobName} />,
      duration: 0
    });
  };

  const showErrorMessage = (jobItem: Pick<BackgroundJob, 'id' | 'jobName'>) => {
    const config = {
      key: jobItem.id,
      type: 'error' as const,
      content: <ErrorMessage jobKey={jobItem.jobName} onClose={() => hideMessage(config)} />,
      duration: 0,
      onClose: () => removeCurrentJob(jobItem.id)
    };
    showMessage(config);
  };

  const showSuccessMessage = (jobItem: BackgroundJobCompleteStatusResponseDto) => {
    const config = {
      key: jobItem.id,
      type: 'success' as const,
      content: (
        <SuccessMessage
          jobKey={jobItem.jobName}
          downloadUrl={jobItem.downloadUrl}
          result={jobItem.result}
          onClose={() => hideMessage(config)}
        />
      ),
      duration: 0,
      onClose: () => removeCurrentJob(jobItem.id)
    };
    showMessage(config);
  };

  useEffect(() => {
    if (state.currentUser) {
      dispatch({ type: ActionTypes.GET_TRIGGERED_BACKGROUND_JOBS });

      return () => {
        message.destroy();
        dispatch({ type: ActionTypes.UPDATE_TRIGGERED_BACKGROUND_JOBS, payload: { jobs: [] } });
      };
    }
  }, [state.currentUser]);

  const subTriggeredJobs = state.currentUser ? state.triggeredBackgroundJobs.length > 0 : false;
  const prevSubTriggeredJobs = usePrevious(subTriggeredJobs);

  useEffect(() => {
    if (prevSubTriggeredJobs === true && subTriggeredJobs === false) {
      dispatch({ type: ActionTypes.UNWATCH_BACKGROUND_JOBS });
    } else if (subTriggeredJobs === true) {
      dispatch({ type: ActionTypes.WATCH_BACKGROUND_JOBS });
    }
  }, [subTriggeredJobs]);

  useEffect(() => {
    if (state.backgroundJobs && state.triggeredBackgroundJobs.length > 0) {
      const jobs = _.intersectionBy(state.backgroundJobs, state.triggeredBackgroundJobs, 'id');
      for (const job of jobs) {
        if ('dateCompleted' in job) {
          job.success ? showSuccessMessage(job) : showErrorMessage(job);
        } else {
          showLoadMessage(job);
        }
      }
    }
  }, [state.backgroundJobs, state.triggeredBackgroundJobs]);
};
