import { FeaturesDtoFeaturesEnum as FeatureId } from '@pclocs/platform-sdk';
import { useSelector } from 'react-redux';
import { AccountState } from '../store/account/reducer';

export { FeaturesDtoFeaturesEnum as FeatureId } from '@pclocs/platform-sdk';

export function useFeatureAvailability(): [(feature: FeatureId) => boolean, boolean, FeatureId[] | null] {
  const { isLoading, availableFeatures } = useSelector((state: { account: AccountState }) => ({
    isLoading: state.account.isAvailableFeaturesPending,
    availableFeatures: state.account.availableFeatures
  }));

  return [(feature: FeatureId) => !!availableFeatures?.includes(feature), isLoading, availableFeatures];
}
