import React from 'react';
import _ from 'lodash';

const JOB_LIST = [
  { key: 'StationUsersImportJob', label: 'User import job' },
  { key: 'StationUsersUpdateJob', label: 'User update job' },
  { key: 'StationUsersExportJob', label: 'User export job' },
  { key: 'CustomerReportsExportJob', label: 'Report export job' },
  { key: 'DeleteIdentityProviderJob', label: 'Delete SSO provider job' }
] as const;

const getJobDisplayName = (jobKey: string) => JOB_LIST.find(v => v.key === jobKey)?.label ?? 'Job';

export const LoadMessage = ({ jobKey }: { jobKey: string }) => (
  <div>{getJobDisplayName(jobKey)} is in progress, we will notify you when it is ready.</div>
);

export const ErrorMessage = ({ jobKey, onClose }: { jobKey: string; onClose: () => void }) => (
  <div>
    {getJobDisplayName(jobKey)} failed due to a server error, please try again. <a onClick={onClose}>close</a>
  </div>
);

export const SuccessMessage = ({
  jobKey,
  downloadUrl,
  result,
  onClose
}: {
  jobKey: string;
  downloadUrl?: string;
  result?: unknown;
  onClose: () => void;
}) => {
  const results: string[] = _.isObject(result) && 'results' in result ? _.get(result, 'results') : undefined;
  return (
    <div>
      {getJobDisplayName(jobKey)} is completed.
      {!!downloadUrl?.length ? (
        <span>
          &nbsp;Click&nbsp;
          <a href={downloadUrl} target="_blank" rel="noopener noreferrer" onClick={onClose}>
            here
          </a>
          &nbsp;to download your CSV.
        </span>
      ) : (
        <>
          &nbsp;<a onClick={onClose}>close</a>
        </>
      )}
      {!!results?.length && (
        <ul style={{ listStyleType: 'none', paddingLeft: 0, marginBottom: 0 }}>
          {results.map((val: string, key: number) => (
            <li key={key} style={{ textAlign: 'left' }}>
              {val}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
