import { Alert } from 'antd';
import React from 'react';
import _ from 'lodash';
import { pbkdf2 } from 'crypto';
import * as ipaddr from 'ipaddr.js';
import moment, { Moment } from 'moment';
import { PickPredicate } from '../typescript-utils';

export const getErrorAlertMessage = (messageItems: string[]) => {
  return (
    <Alert
      className="test"
      message={messageItems.map((value, i) => (
        <p key={i}>{value}</p>
      ))}
      type="error"
      showIcon
      style={{ marginBottom: 16 }}
    />
  );
};

export const formatMessage = (msg: string) => {
  const words: string[] = msg.split(' ');

  if (words[0].includes('.')) {
    const propertyPath: string[] = words[0].split('.');
    const propertyName: string = propertyPath[propertyPath.length - 1].toUpperCase();
    words.splice(0, 1, propertyName);

    return words.join(' ');
  } else {
    return msg;
  }
};

export const isValidIpAddress = (ip: string, type?: 'ipv4' | 'ipv6'): boolean => {
  switch (type) {
    case 'ipv4':
      return ipaddr.IPv4.isValid(ip);
    case 'ipv6':
      return ipaddr.IPv6.isValid(ip);
    default:
      return ipaddr.isValid(ip);
  }
};

export const fromCidrIp = (addr: string): { ip: string; netmask?: string } => {
  const [ip, cidr] = addr.split('/');
  if (_.isEmpty(cidr)) {
    return { ip };
  }
  const intCidr = parseInt(cidr, 10);
  const netmask: string = ipaddr.IPv4.isValid(ip)
    ? ipaddr.IPv4.subnetMaskFromPrefixLength(intCidr).toString()
    : ipaddr.IPv6.subnetMaskFromPrefixLength(intCidr).toString();
  return { ip, netmask };
};

export const toCidrIp = (addr: { ip: string; netmask: string }) => {
  if (_.isEmpty(addr.netmask)) {
    return addr.ip;
  } else if (addr.netmask.match(/^\d+$/)) {
    return `${addr.ip}/${addr.netmask}`;
  } else {
    const cidrSuffix: number | null = ipaddr.parse(addr.netmask).prefixLengthFromSubnetMask();
    return cidrSuffix ? `${addr.ip}/${cidrSuffix}` : addr.ip;
  }
};

export const encryptPsk = async (ssid: string, psk: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    pbkdf2(psk, ssid, 4096, 32, 'sha1', (err, buffer) => {
      if (err) {
        reject(err);
      } else {
        resolve(buffer.toString('hex'));
      }
    });
  });
};

export const isValidPrivateKeyContent = (content: string): boolean => {
  return content.match(/-{5}BEGIN (\w+ )?PRIVATE KEY-{5}(.*?)-{5}END (\w+ )?PRIVATE KEY-{5}/gs) !== null;
};

export const isValidCertificateContent = (content: string): boolean => {
  return content.match(/-{5}BEGIN CERTIFICATE-{5}(.*?)-{5}END CERTIFICATE-{5}/gs) !== null;
};

export const isDateAfterToday = (date: Moment | undefined, granularity?: any) => {
  return date ? date.isAfter(moment(), granularity) : false;
};

export const closeDatesSelector = (status: any, confirm: any) => {
  if (!status) {
    confirm();
  }
};

// deepOmitBy is required for issue where empty props are causing a mismatch in settings and occurs in firmware version v2.0.4 and earlier.
// The issue causing the mismatch in the settings has been fixed in firmware version > v2.0.4, see changes in PSP-356
export const deepOmitBy: any = (value: any, iteratee: any) => {
  const callbackFnc = (v: any) => deepOmitBy(v, iteratee);
  if (_.isObject(value)) {
    if (_.isArray(value)) {
      return _.map(value, callbackFnc);
    } else {
      return _(value)
        .omitBy(iteratee)
        .mapValues(callbackFnc)
        .value();
    }
  } else {
    return value;
  }
};

export function searchItems<S extends object, K extends keyof PickPredicate<S, string | number>>(
  items: S[],
  searchPropertiesOrFn: K[] | ((item: S) => string),
  query: string
): S[] {
  const escapedQuery = query
    .toLocaleLowerCase()
    .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    .split(' ');
  const regex = new RegExp(`.*${escapedQuery.join('.*')}.*`, 'i');
  return items.filter(item => {
    const value =
      typeof searchPropertiesOrFn === 'function'
        ? searchPropertiesOrFn(item)
        : _(item)
            .pick(searchPropertiesOrFn)
            .values()
            .join(' ');
    return regex.test(value.toLocaleLowerCase());
  });
}
