import * as sdk from '@pclocs/platform-sdk';
import { BaseAPI } from '@pclocs/platform-sdk/dist/base';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as ActionTypes from '../store/actionTypes';
import { PickPredicate } from '../typescript-utils';
import { RootState } from '../store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid4 } from 'uuid';
import { AxiosError, AxiosResponse, AxiosPromise } from 'axios';
import _ from 'lodash';
import { SdkCallAction } from '../store/common/types';
import { usePageableReducer } from './pageable-reducer';
import { ValidationExceptionDto } from '@pclocs/platform-sdk';

type SdkType = typeof sdk;

type SdkClasses = PickPredicate<SdkType, new () => BaseAPI>;

export type SdkApiName = keyof SdkClasses;

export type SdkApiFunctionNames<Api extends SdkApiName> = keyof PickPredicate<
  SdkType[Api]['prototype'],
  (...args: any[]) => AxiosPromise<unknown>
>;

type SdkApiFunctionDef<
  Api extends SdkApiName,
  FnName extends SdkApiFunctionNames<Api>
> = SdkType[Api]['prototype'][FnName];

export type SdkApiFunctionParameters<Api extends SdkApiName, FnName extends SdkApiFunctionNames<Api>> = Parameters<
  SdkApiFunctionDef<Api, FnName>
>;

type SdkApiFunctionReturn<Api extends SdkApiName, FnName extends SdkApiFunctionNames<Api>> = ReturnType<
  SdkApiFunctionDef<Api, FnName>
> extends AxiosPromise<infer U>
  ? AxiosResponse<U>
  : never;

export type UseSdkCallSuccess<Api extends SdkApiName, FnName extends SdkApiFunctionNames<Api>> = {
  status: number;
  data: SdkApiFunctionReturn<Api, FnName>['data'];
};

export type UseSdkCallError =
  | {
      status: 400;
      data: ValidationExceptionDto;
      error: AxiosError;
    }
  | {
      status: number;
      data: unknown;
      error: AxiosError;
    }
  | {
      error: Error;
    };

const useSdkCall = <Api extends SdkApiName, FnName extends SdkApiFunctionNames<Api>>(
  strategy: 'takeLatest' | 'takeEvery',
  api: Api,
  apiFn: FnName,
  success: (v: UseSdkCallSuccess<Api, FnName>) => void,
  error: (e: UseSdkCallError) => void
): [(...parameters: SdkApiFunctionParameters<Api, FnName>) => void, boolean] => {
  const dispatch = useDispatch();
  const ref = useRef(uuid4());
  const { apiResponse } = useSelector((state: RootState) => ({
    apiResponse: state.common.apiResponse?.requestId === ref.current ? state.common.apiResponse : undefined
  }));

  const requestCounter = useRef<number>(1);
  const loadingCounter = useRef<number>(0);
  const [loadingState, setLoadingState] = useState<boolean>(false);

  useEffect(() => {
    if (apiResponse) {
      loadingCounter.current = loadingCounter.current - 1;
      if (loadingCounter.current === 0) {
        setLoadingState(false);
      }
      if ('error' in apiResponse) {
        error(_.omit(apiResponse, ['requestId']));
      } else if ('data' in apiResponse) {
        success({ status: apiResponse.status, data: apiResponse.data });
      }
    }
  }, [apiResponse]);

  const makeSdkCall = useCallback(
    (...parameters: SdkApiFunctionParameters<Api, FnName>) => {
      if (strategy === 'takeLatest') {
        ref.current = `${ref.current.split('_')[0]}_${requestCounter.current++}`;
        loadingCounter.current = 1;
      } else {
        loadingCounter.current = loadingCounter.current + 1;
      }
      const action: SdkCallAction = {
        type: ActionTypes.SDK_CALL_ACTION,
        payload: {
          requestId: ref.current,
          api,
          function: apiFn as string,
          parameters
        }
      };
      setLoadingState(true);
      dispatch(action);
    },
    [ref, loadingCounter, requestCounter]
  );

  return [makeSdkCall, loadingState];
};

export const useLatestSdkCall = <Api extends SdkApiName, FnName extends SdkApiFunctionNames<Api>>(
  api: Api,
  apiFn: FnName,
  success: (v: UseSdkCallSuccess<Api, FnName>) => void,
  error: (e: UseSdkCallError) => void
): [(...parameters: SdkApiFunctionParameters<Api, FnName>) => void, boolean] => {
  return useSdkCall('takeLatest', api, apiFn, success, error);
};

export const useEverySdkCall = <Api extends SdkApiName, FnName extends SdkApiFunctionNames<Api>>(
  api: Api,
  apiFn: FnName,
  success: (v: UseSdkCallSuccess<Api, FnName>) => void,
  error: (e: UseSdkCallError) => void
): [(...parameters: SdkApiFunctionParameters<Api, FnName>) => void, boolean] => {
  return useSdkCall('takeEvery', api, apiFn, success, error);
};

export const useSdkCallOnChanges = <Api extends SdkApiName, FnName extends SdkApiFunctionNames<Api>>(
  api: Api,
  apiFn: FnName,
  ...parameters: SdkApiFunctionParameters<Api, FnName>
): [boolean, UseSdkCallSuccess<Api, FnName>['data'] | undefined, UseSdkCallError | undefined] => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<UseSdkCallSuccess<Api, FnName>['data']>();
  const [error, setError] = useState<UseSdkCallError>();

  const [sdkCall] = useLatestSdkCall<Api, FnName>(
    api,
    apiFn,
    v => {
      setData(v.data);
      setLoading(false);
    },
    e => {
      setError(e);
      setLoading(false);
    }
  );

  useEffect(() => {
    setLoading(true);
    setData(undefined);
    setError(undefined);
    sdkCall(...parameters);
  }, parameters ?? []);

  return [loading, data, error];
};

export const usePageableSdkCall = <
  Api extends SdkApiName,
  FnName extends SdkApiFunctionNames<Api>,
  ApiItemType extends UseSdkCallSuccess<Api, FnName>['data'] extends { items: (infer U)[] } ? U : never,
  Filter extends Record<string, unknown>
>(
  api: Api,
  apiFn: FnName,
  initialFilter: Filter,
  requestArgs: (
    fn: (...parameters: SdkApiFunctionParameters<Api, FnName>) => void,
    filters: Filter,
    limit: number,
    after?: string
  ) => void,
  responseArgs: (res: UseSdkCallSuccess<Api, FnName>['data']) => { items: ApiItemType[]; after?: string }
) => {
  const pageableSlice = usePageableReducer<ApiItemType, Filter>(initialFilter);
  const [sdkCallFn] = useLatestSdkCall<Api, FnName>(
    api,
    apiFn,
    res => {
      const convertedResponse = responseArgs(res.data);
      pageableSlice.setResponse(convertedResponse);
    },
    () => pageableSlice.setResponse({ error: 'Failed to fetch items.' })
  );
  useEffect(() => {
    if (pageableSlice.requestParameters) {
      const { filters, limit, after } = pageableSlice.requestParameters;
      requestArgs(sdkCallFn, filters, limit, after);
    }
  }, [pageableSlice.requestParameters]);

  return _.omit(pageableSlice, ['setResponse', 'requestParameters']);
};

export const isValidationExceptionError = (
  e: UseSdkCallError
): e is UseSdkCallError & { data: ValidationExceptionDto } => {
  return (
    'data' in e &&
    Array.isArray((e.data as ValidationExceptionDto)?.message) &&
    typeof (e.data as ValidationExceptionDto)?.error === 'string' &&
    (e.data as ValidationExceptionDto)?.statusCode === 400
  );
};
