import { GoogleWorkspaceConfigCreateDto, GoogleWorkspaceTestResponseDto } from '@pclocs/platform-sdk';
import { List } from 'antd';
import React from 'react';
import { If } from '../../Util';
import { CheckItem, MissingAttributesItem } from './common-test-results-components';

type GoogleMappingAttributes = GoogleWorkspaceTestResponseDto['optionalMappingAttributes'];

export const GoogleTestResults = ({
  results,
  attributes
}: {
  results: GoogleWorkspaceTestResponseDto;
  attributes: GoogleWorkspaceConfigCreateDto['attributes'];
}) => {
  const falseyKeys = (obj: GoogleMappingAttributes) =>
    Object.entries(obj)
      .filter(([_name, isPresent]) => !isPresent)
      .map(([name]) => attributes[name as keyof GoogleWorkspaceConfigCreateDto['attributes']]);

  const missingOptionalAttributes = falseyKeys(results.optionalMappingAttributes);

  return (
    <List bordered={true} style={{ marginTop: 20 }}>
      <CheckItem isSuccess={results.auth} title="Authentication" />
      <CheckItem
        canFail={results.auth}
        isSuccess={results.usersFound}
        title="Users"
        warningDescription="No users found. User attributes could not be validated"
      />
      <If condition={results.usersFound && missingOptionalAttributes.length}>
        <MissingAttributesItem type="Optional" attributes={missingOptionalAttributes} />
      </If>
      <CheckItem
        canFail={results.auth}
        isSuccess={results.groupsFound}
        title="Groups"
        warningDescription="No groups found."
      />
    </List>
  );
};
