import React, { Dispatch } from 'react';
import { Button, Modal, Skeleton, Alert, Upload, Spin, Result } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { LinkOutlined, UploadOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import * as ActionTypes from '../../store/actionTypes';
import { CsvImportState, StationUserActionTypes } from '../../store/users/types';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import * as RfidHelper from '../../helpers/rfid-converter-helper';

type StationUserUpdateCsvModalProps = {
  loadExportModal: () => void;
} & FormComponentProps;

const mapStateToProps = (state: RootState) => ({
  ...state.stationUser
});

const mapDispatchToProps = (dispatch: Dispatch<StationUserActionTypes>) => ({
  unloadModal: () => dispatch({ type: ActionTypes.STATION_USER_UNLOAD_UPDATE_CSV_MODAL }),
  pushCsvFileToS3: (file: File) => dispatch({ type: ActionTypes.PUSH_CSV_FILE_TO_S3, payload: { file } }),
  triggerCsvImport: (fileReference: string) =>
    dispatch({ type: ActionTypes.TRIGGER_API_CSV_UPDATE, payload: { fileReference } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & StationUserUpdateCsvModalProps;

export const StationUserUpdateCsvModal = connector(
  LegacyForm.create<Props>({ name: 'StationUserUpdateCsv' })((props: Props) => {
    const handleCsvUpload = (e: any) => {
      e.preventDefault();
      props.fileReference && props.triggerCsvImport(props.fileReference);
    };

    const pending =
      props.csvImportState === CsvImportState.UPLOADING || props.csvImportState === CsvImportState.UPDATING;

    return (
      <Modal
        title="Update Users"
        visible={props.showStationUserUpdateCsvModal}
        maskClosable={!pending}
        onCancel={props.unloadModal}
        data-test-id="station-user-update-csv-modal"
        width={420}
        footer={[
          <Button
            key="back"
            onClick={props.unloadModal}
            style={{ display: pending ? 'none' : 'initial' }}
            data-test-id="station-user-btn-update-csv-cancel">
            {props.csvImportState === CsvImportState.DONE ? 'OK' : 'Cancel'}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCsvUpload}
            disabled={pending}
            style={{ display: !!props.fileReference ? 'initial' : 'none' }}
            loading={props.csvImportState === CsvImportState.UPDATING}
            data-test-id="station-user-btn-update-csv-upload">
            Update
          </Button>
        ]}>
        {props.stationUserId && !props.stationUser ? (
          <Skeleton active />
        ) : props.csvImportState === CsvImportState.DONE ? (
          <Result
            status="info"
            title="Update in progress"
            subTitle="We've started to update your user list. This process can take a while so we'll send you an email once it's complete."
          />
        ) : (
          <>
            <Alert
              message="For better performance it is recommended to only upload user rows which contain changes."
              type="info"
              style={{ marginBottom: 16 }}
              showIcon
            />

            <div>
              To update users in bulk, first{' '}
              <a
                onClick={() => {
                  props.unloadModal();
                  props.loadExportModal();
                }}>
                export users
              </a>
              , then modify the CSV file and upload here.
            </div>

            <hr />

            <div style={{ marginBottom: 16 }}>
              <Spin spinning={props.csvImportState === CsvImportState.UPLOADING}>
                <Upload
                  key="preview"
                  accept=".csv"
                  fileList={undefined}
                  showUploadList={false}
                  customRequest={options => props.pushCsvFileToS3(options.file as File)}
                  className="csv-upload-wrapper"
                  style={{ float: 'left' }}>
                  <Button style={{ marginRight: '15px' }}>
                    <UploadOutlined /> Choose File
                  </Button>
                </Upload>
              </Spin>
            </div>

            {props.csvImportState !== CsvImportState.UPLOADING && props.csvFile?.name && (
              <div style={{ display: 'block', marginBottom: 16 }}>
                <LinkOutlined />
                &nbsp;<span title={props.csvFile.name}>{props.csvFile.name}</span>
              </div>
            )}

            <div>
              <span>RFID format:&nbsp;{RfidHelper.rfidFormatTooltip}&nbsp;&nbsp;</span>
              <RfidHelper.FormatSelector onChange={() => ({})} />
            </div>

            {props.errorMessage && <Alert message={props.errorMessage} type="error" style={{ marginTop: 16 }} />}
          </>
        )}
      </Modal>
    );
  })
);
