import React, { Dispatch, useEffect } from 'react';
import { Col, Menu } from 'antd';
import { DashboardOutlined, TeamOutlined } from '@ant-design/icons';
import { Route, Switch, useParams, Link, useRouteMatch, Redirect } from 'react-router-dom';
import { BayUsers } from './users';
import * as _ from 'lodash';
import * as ActionTypes from '../../../store/actionTypes';
import { RootState } from '../../../store/rootReducer';
import { DashboardActionTypes } from '../../../store/dashboard/types';
import { connect, ConnectedProps } from 'react-redux';
import { BayOverview } from './overview';
import { StationUserActionTypes } from '../../../store/users/types';
import { essentialsTierOrAbove } from '../../../helpers/subscription-helper';
import { subscriptionMessage } from '../../../constants/validations';
import { FetchFailedAlert } from '../fetch-failed-alert';
import { WebsocketStationRequestDtoTypeEnum } from '@pclocs/platform-sdk';
import { websocketActions, WebsocketActionTypes } from '../../../store/websocket/reducer';

const mapStateToProps = (state: RootState, ownProps: any) => {
  const bayId: string = ownProps.match.params.bayId;
  return {
    stationFetchFailed: state.dashboard.stationFetchFailed,
    stationName: state.dashboard.station?.name,
    station: state.dashboard.station,
    bay: _.find(state.dashboard?.bays || {}, ['id', bayId]),
    subscriptionInfo: state.account.subscriptionInfo
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<DashboardActionTypes | StationUserActionTypes | WebsocketActionTypes>
) => ({
  fetchStation: (id: string) => {
    dispatch({ type: ActionTypes.FETCH_STATION, payload: { id } });
    dispatch(websocketActions.subscribe({ id, type: WebsocketStationRequestDtoTypeEnum.Station }));
  },
  clearStation: (id?: string) => {
    dispatch({ type: ActionTypes.CLEAR_STATION });
    id && dispatch(websocketActions.unsubscribe({ id, type: WebsocketStationRequestDtoTypeEnum.Station }));
  },
  bayPageLoaded: () => dispatch({ type: ActionTypes.CLEAR_STATION_USER }),
  bayPageUnloaded: () => dispatch({ type: ActionTypes.CLEAR_STATION_USER })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const Bay = connector((props: Props) => {
  const { stationId, bayId } = useParams<{ stationId: string; bayId: string }>();
  const { url, path } = useRouteMatch();
  const routeTab = useRouteMatch<any>(`${url}/:tab?`)?.params?.tab || 'overview';
  const correctSubscription: boolean = essentialsTierOrAbove(props.subscriptionInfo);
  const isHeadless = !!props.station?.derivedConfig?.isHeadless;

  useEffect(() => {
    props.bayPageLoaded();
    return () => {
      props.bayPageUnloaded();
    };
  }, []);

  // on component load lets start the socket to fetch the station status
  // which will also load all bay data
  useEffect(() => {
    props.clearStation();
    if (stationId === undefined) {
      return;
    }
    props.fetchStation(stationId);

    return () => {
      props.clearStation(stationId);
    };
  }, [stationId]);

  const getBayNumber = (bayId?: string): string => _.last(_.split(bayId || '', '-')) || '';

  return (
    <Col className="dashboard-section" span={12}>
      <div className="dashboard-section-title">
        {props.stationName || stationId} / {props.bay?.name || `Bay ${getBayNumber(bayId)}`}
      </div>
      <div className="dashboard-section-inner">
        <Menu selectedKeys={routeTab} mode="horizontal">
          <Menu.Item key="overview">
            <Link to={`${url}/overview`}>
              <DashboardOutlined /> Overview
            </Link>
          </Menu.Item>
          {!isHeadless && (
            <Menu.Item
              key="users"
              disabled={!correctSubscription}
              title={!correctSubscription ? subscriptionMessage : undefined}>
              <Link to={`${url}/users`}>
                <TeamOutlined /> Users
              </Link>
            </Menu.Item>
          )}
        </Menu>
        {props.stationFetchFailed ? (
          <FetchFailedAlert itemType="station" statusCode={props.stationFetchFailed.statusCode} />
        ) : (
          <Switch>
            <Route path={`${path}/overview`} component={BayOverview} />
            {!isHeadless && <Route path={`${path}/users`} component={BayUsers} />}
            <Redirect from="/" to={`${url}/overview`} />
          </Switch>
        )}
      </div>
    </Col>
  );
});
