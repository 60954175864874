import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoRefreshToken, CognitoUserSession } from 'amazon-cognito-identity-js';

export const USER_POOL_DOMAIN = `${process.env.USER_POOL_DOMAIN_PREFIX}.auth.us-west-2.amazoncognito.com`;
export const OAUTH_REDIRECT_SIGN_IN_URI = `${window.location.origin}/login/auth`;

Auth.configure({
  Auth: {
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
    oauth: {
      domain: USER_POOL_DOMAIN,
      scope: ['openid'],
      redirectSignIn: OAUTH_REDIRECT_SIGN_IN_URI,
      redirectSignOut: `${window.location.origin}/login`,
      responseType: 'code'
    }
  }
});

export function signIn(username: string, password: string): ReturnType<typeof Auth.signIn> {
  return Auth.signIn(username, password);
}

export function setupMfaTotp(user: CognitoUser): ReturnType<typeof Auth.setupTOTP> {
  return Auth.setupTOTP(user);
}

export function verifyMfaTotpToken(user: CognitoUser, totpToken: string): ReturnType<typeof Auth.verifyTotpToken> {
  return Auth.verifyTotpToken(user, totpToken);
}

export function confirmMfaTotpToken(user: CognitoUser, totpToken: string): ReturnType<typeof Auth.confirmSignIn> {
  return Auth.confirmSignIn(user, totpToken, 'SOFTWARE_TOKEN_MFA');
}

export function setPreferredMFA(
  user: CognitoUser,
  mfaMethod: 'TOTP' | 'NOMFA'
): ReturnType<typeof Auth.setPreferredMFA> {
  return Auth.setPreferredMFA(user, mfaMethod);
}

export function currentUserPoolUser(): ReturnType<typeof Auth.currentUserPoolUser> {
  return Auth.currentUserPoolUser();
}

export function signOut(): ReturnType<typeof Auth.signOut> {
  return Auth.signOut();
}

export function forgotPassword(username: string): ReturnType<typeof Auth.forgotPassword> {
  return Auth.forgotPassword(username);
}

export function resetPassword(
  username: string,
  code: string,
  password: string
): ReturnType<typeof Auth.forgotPasswordSubmit> {
  return Auth.forgotPasswordSubmit(username, code, password);
}

export function getCurrentSession(): Promise<CognitoUserSession> {
  return Auth.currentSession();
}

export function getCurrentAuthenticatedUser(): Promise<CognitoUser> {
  return Auth.currentAuthenticatedUser({
    bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  });
}

export async function changePassword(oldPassword: string, newPassword: string): Promise<any> {
  const user = await Auth.currentAuthenticatedUser();
  return await Auth.changePassword(user, oldPassword, newPassword);
}

export async function completeNewPassword(user: CognitoUser, password: string): Promise<any> {
  return await Auth.completeNewPassword(user, password, {});
}

export async function refreshToken(token: CognitoRefreshToken): Promise<void> {
  const authUser = await getCurrentAuthenticatedUser();
  await new Promise((resolve, reject) => {
    authUser.refreshSession(token, (err: unknown) => (err ? reject(err) : resolve(undefined)));
  });
}
