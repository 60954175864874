export const REDIRECT = 'REDIRECT';
export const SHOW_UNAUTHORIZED_MODAL = 'SHOW_UNAUTHORIZED_MODAL';
export const SHOW_NOTIFICATION_MESSAGE = 'SHOW_NOTIFICATION_MESSAGE';
export const SHOW_NOTIFICATION_MODAL = 'SHOW_NOTIFICATION_MODAL';
export const DISMISS_NOTIFICATION_MODAL = 'DISMISS_NOTIFICATION_MODAL';
export const VALIDATE_LOGIN = 'VALIDATE_LOGIN';
export const DASHBOARD_PAGE_LOADED = 'DASHBOARD_PAGE_LOADED';
export const DASHBOARD_PAGE_UNLOADED = 'DASHBOARD_PAGE_UNLOADED';
export const INTEGRATIONS_PAGE_LOADED = 'INTEGRATIONS_PAGE_LOADED';
export const INTEGRATIONS_PAGE_UNLOADED = 'INTEGRATIONS_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SET_CONTEXT = 'SET_CONTEXT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';
export const ACCOUNT_CREATED_PAGE_LOADED = 'ACCOUNT_CREATED_PAGE_LOADED';
export const ACCOUNT_CREATED_PAGE_UNLOADED = 'ACCOUNT_CREATED_PAGE_UNLOADED';
export const VERIFY_EMAIL_FORM = 'VERIFY_EMAIL_FORM';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';
export const LOGIN_PAGE_LOADED = 'LOGIN_PAGE_LOADED';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_PAGE_LOADED = 'FORGOT_PASSWORD_PAGE_LOADED';
export const FORGOT_PASSWORD_PAGE_UNLOADED = 'FORGOT_PASSWORD_PAGE_UNLOADED';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_PAGE_LOADED = 'RESET_PASSWORD_PAGE_LOADED';
export const RESET_PASSWORD_PAGE_UNLOADED = 'RESET_PASSWORD_PAGE_UNLOADED';
export const SSO_SIGN_IN = 'SSO_SIGN_IN';
export const SSO_SIGN_IN_ERROR = 'SSO_SIGN_IN_ERROR';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const USERS_PAGE_LOADED = 'USERS_PAGE_LOADED';
export const USERS_PAGE_UNLOADED = 'USERS_PAGE_UNLOADED';
export const ACCOUNT_PAGE_LOADED = 'ACCOUNT_PAGE_LOADED';
export const ACCOUNT_PAGE_UNLOADED = 'ACCOUNT_PAGE_UNLOADED';
export const REGISTER_PAGE_LOADED = 'REGISTER_PAGE_LOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const PROFILE_LOADED = 'PROFILE_LOADED';
export const TOGGLE_PROFILE_FORM = 'TOGGLE_PROFILE_FORM';
export const CLEAR_UNIQUE_PIN = 'CLEAR_UNIQUE_PIN';
/**
 * @deprecated Consider using the "websocket.streamMessage" state instead.
 */
export const WEBSOCKET_INCOMING_MESSAGE = 'WEBSOCKET_INCOMING_MESSAGE';
export const UPDATE_STATION_STATUS = 'UPDATE_STATION_STATUS';
export const FETCH_STATION = 'FETCH_STATION';
export const CLEAR_STATION = 'CLEAR_STATION';
export const FETCH_STATION_SUCCESS = 'FETCH_STATION_SUCCESS';
export const FETCH_STATION_FAILED = 'FETCH_STATION_FAILED';
export const FETCH_STATION_BAYS = 'FETCH_STATION_BAYS';
export const FETCH_STATION_BAYS_SUCCESS = 'FETCH_STATION_BAYS_SUCCESS';
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const COMPLETE_ADMIN_ONBOARD = 'COMPLETE_ADMIN_ONBOARD';
export const ADMIN_ONBOARD_UPDATE_INFO = 'ADMIN_ONBOARD_UPDATE_INFO';
export const GET_USER_CONTEXT = 'GET_USER_CONTEXT';
export const CONFIRM_MFA_TOTP_TOKEN_SUCCESS = 'CONFIRM_MFA_TOTP_TOKEN_SUCCESS';
export const REQUEST_SOFTWARE_TOKEN_MFA = 'REQUEST_SOFTWARE_TOKEN_MFA';
export const GET_MFA_SECRET_CODE = 'GET_MFA_SECRET_CODE';
export const GET_MFA_SECRET_CODE_ERROR = 'GET_MFA_SECRET_CODE_ERROR';
export const SETUP_SOFTWARE_TOKEN_MFA = 'SETUP_SOFTWARE_TOKEN_MFA';
export const VERIFY_MFA_TOTP_TOKEN = 'VERIFY_MFA_TOTP_TOKEN';
export const VERIFY_MFA_TOTP_TOKEN_PROFILE_ERROR = 'VERIFY_MFA_TOTP_TOKEN_PROFILE_ERROR';
export const VERIFY_MFA_TOTP_TOKEN_PROFILE_CLEANUP = 'VERIFY_MFA_TOTP_TOKEN_PROFILE_CLEANUP';
export const CONFIRM_MFA_TOTP_TOKEN = 'CONFIRM_MFA_TOTP_TOKEN';
export const GET_USER_CONTEXT_NO_MFA_CHECK = 'GET_USER_CONTEXT_NO_MFA_CHECK';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR';
export const REMOVE_MFA_FOR_ADMIN = 'REMOVE_MFA_FOR_ADMIN';
export const REMOVE_MFA_FOR_ADMIN_SUCCESS = 'REMOVE_MFA_FOR_ADMIN_SUCCESS';
export const REMOVE_MFA_FOR_ADMIN_ERROR = 'REMOVE_MFA_FOR_ADMIN_ERROR';
export const FIND_STATION_USERS = 'FIND_STATION_USERS';
export const FIND_STATION_USERS_SUCCESS = 'FIND_STATION_USERS_SUCCESS';
export const FIND_STATION_USERS_ERROR = 'FIND_STATION_USERS_ERROR';
export const CLEAR_STATION_USERS = 'CLEAR_STATION_USERS';
export const FILTER_STATION_USERS_BY_PROPERTY = 'FILTER_STATION_USERS_BY_PROPERTY';
export const INTERNAL_STATION_USER_LOAD_MODAL = 'STATION_USER_LOAD_MODAL';
export const INTERNAL_STATION_USER_UNLOAD_MODAL = 'STATION_USER_UNLOAD_MODAL';
export const FIND_STATION_USER = 'FIND_STATION_USER';
export const GET_STATION_USER = 'GET_STATION_USER';
export const CLEAR_STATION_USER = 'CLEAR_STATION_USER';
export const GET_STATION_USER_SUCCESS = 'GET_STATION_USER_SUCCESS';
export const GET_STATION_USER_ERROR = 'GET_STATION_USER_ERROR';
export const UPDATE_STATION_NAME = 'UPDATE_STATION_NAME';
export const UPDATE_STATION_NAME_SUCCESS = 'UPDATE_STATION_NAME_SUCCESS';
export const UPDATE_STATION_NAME_ERROR = 'UPDATE_STATION_NAME_ERROR';
export const UPDATE_STATION_TAGS = 'UPDATE_STATION_TAGS';
export const UPDATE_STATION_TAGS_SUCCESS = 'UPDATE_STATION_TAGS_SUCCESS';
export const UPDATE_BAY_NAME = 'UPDATE_BAY_NAME';
export const UPDATE_BAY_NAME_SUCCESS = 'UPDATE_BAY_NAME_SUCCESS';
export const UPDATE_BAY_NAME_ERROR = 'UPDATE_BAY_NAME_ERROR';
export const UPDATE_BAY_TAGS = 'UPDATE_BAY_TAGS';
export const UPDATE_BAY_TAGS_SUCCESS = 'UPDATE_BAY_TAGS_SUCCESS';
export const DELETE_STATION_USER = 'DELETE_STATION_USER';
export const DELETE_STATION_USER_SUCCESS = 'DELETE_STATION_USER_SUCCESS';
export const DELETE_STATION_USER_ERROR = 'DELETE_STATION_USER_ERROR';
export const STATION_USER_UNLOAD_DELETE_MODAL = 'STATION_USER_UNLOAD_DELETE_MODAL';
export const SET_STAGED_STATION_USERS = 'SET_STAGED_STATION_USERS';
export const UPDATE_STATION_USER = 'UPDATE_STATION_USER';
export const UPDATE_STATION_USER_SUCCESS = 'UPDATE_STATION_USER_SUCCESS';
export const UPDATE_STATION_USER_ERROR = 'UPDATE_STATION_USER_ERROR';
export const CREATE_STATION_USER = 'CREATE_STATION_USER';
export const CREATE_STATION_USER_SUCCESS = 'CREATE_STATION_USER_SUCCESS';
export const CREATE_STATION_USER_ERROR = 'CREATE_STATION_USER_ERROR';
export const CLEAR_UNIQUE_PIN_STATION_USER = 'CLEAR_UNIQUE_PIN_STATION_USER';
export const GET_UNIQUE_PIN_STATION_USER = 'GET_UNIQUE_PIN_STATION_USER';
export const GET_UNIQUE_PIN_STATION_USER_SUCCESS = 'GET_UNIQUE_PIN_STATION_USER_SUCCESS';
export const GET_UNIQUE_PIN_STATION_USER_ERROR = 'GET_UNIQUE_PIN_STATION_USER_ERROR';
export const UNLOAD_MANAGE_USERS_TABLE = 'UNLOAD_MANAGE_USERS_TABLE';
export const PUSH_CSV_FILE_TO_S3 = 'PUSH_CSV_FILE_TO_S3';
export const PUSH_CSV_FILE_TO_S3_SUCCESS = 'PUSH_CSV_FILE_TO_S3_SUCCESS';
export const PUSH_CSV_FILE_TO_S3_ERROR = 'PUSH_CSV_FILE_TO_S3_ERROR';
export const TRIGGER_API_CSV_IMPORT = 'TRIGGER_API_CSV_IMPORT';
export const TRIGGER_API_CSV_IMPORT_SUCCESS = 'TRIGGER_API_CSV_IMPORT_SUCCESS';
export const TRIGGER_API_CSV_UPDATE = 'TRIGGER_API_CSV_UPDATE';
export const TRIGGER_API_CSV_UPDATE_SUCCESS = 'TRIGGER_API_CSV_UPDATE_SUCCESS';
export const STATION_USER_LOAD_CSV_MODAL = 'STATION_USER_LOAD_CSV_MODAL';
export const STATION_USER_UNLOAD_CSV_MODAL = 'STATION_USER_UNLOAD_CSV_MODAL';
export const STATION_USER_LOAD_UPDATE_CSV_MODAL = 'STATION_USER_LOAD_UPDATE_CSV_MODAL';
export const STATION_USER_UNLOAD_UPDATE_CSV_MODAL = 'STATION_USER_UNLOAD_UPDATE_CSV_MODAL';
export const STATION_USER_FIND_BAY_ASSIGNMENTS = 'STATION_USER_FIND_BAY_ASSIGNMENTS';
export const STATION_USER_FIND_BAY_ASSIGNMENTS_SUCCESS = 'STATION_USER_FIND_BAY_ASSIGNMENTS_SUCCESS';
export const TRIGGER_API_USER_EXPORT = 'TRIGGER_API_USER_EXPORT';
export const TRIGGER_API_USER_EXPORT_SUCCESS = 'TRIGGER_API_USER_EXPORT_SUCCESS';
export const STATION_USER_LOAD_EXPORT_MODAL = 'STATION_USER_LOAD_EXPORT_MODAL';
export const STATION_USER_UNLOAD_EXPORT_MODAL = 'STATION_USER_UNLOAD_EXPORT_MODAL';
export const CHANGE_STATION_ACCESS_MODE = 'CHANGE_STATION_ACCESS_MODE';
export const CHANGE_STATION_ACCESS_MODE_SUCCESS = 'CHANGE_STATION_ACCESS_MODE_SUCCESS';
export const TOGGLE_STATION_ACCESS_MODE_PENDING = 'TOGGLE_STATION_ACCESS_MODE_PENDING';
export const TOGGLE_STATION_ACCESS_MODE_OVERRIDE = 'TOGGLE_STATION_ACCESS_MODE_OVERRIDE';
export const TOGGLE_STATION_ACCESS_MODE_OVERRIDE_SUCCESS = 'TOGGLE_STATION_ACCESS_MODE_OVERRIDE_SUCCESS';
export const TOGGLE_STATION_ACCESS_MODE_OVERRIDE_PENDING = 'TOGGLE_STATION_ACCESS_MODE_OVERRIDE_PENDING';

// Authorisation start (table list + modal)
export const FETCH_AUTH_USERS_IN_MANAGE_TABLE = 'FETCH_AUTH_USERS_IN_MANAGE_TABLE';
export const FETCH_AUTH_USERS_IN_MANAGE_TABLE_SUCCESS = 'FETCH_AUTH_USERS_IN_MANAGE_TABLE_SUCCESS';
export const FETCH_AUTH_USERS_IN_MANAGE_TABLE_ERROR = 'FETCH_AUTH_USERS_IN_MANAGE_TABLE_ERROR';

export const FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE = 'FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE';
export const FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE_SUCCESS = 'FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE_SUCCESS';
export const FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE_ERROR = 'FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE_ERROR';

export const FETCH_USER_GROUPS_IN_MANAGE_MODAL = 'FETCH_USER_GROUPS_IN_MANAGE_MODAL';
// Authorisation end

export const FETCH_AUTHORIZED_IN_STATION = 'FETCH_AUTHORIZED_IN_STATION';
export const FETCH_AUTHORIZED_IN_STATION_SUCCESS = 'FETCH_AUTHORIZED_IN_STATION_SUCCESS';
export const UPDATE_AUTHORIZED_IN_STATION = 'UPDATE_AUTHORIZED_IN_STATION';
export const UPDATE_AUTHORIZED_IN_STATION_SUCCESS = 'UPDATE_AUTHORIZED_IN_STATION_SUCCESS';
export const UPDATE_MANAGE_AUTHORIZED_ERROR = 'UPDATE_MANAGE_AUTHORIZED_ERROR';
export const REMOVE_ALL_AUTHORIZED_IN_STATION = 'REMOVE_ALL_AUTHORIZED_IN_STATION';
export const FETCH_AUTHORIZED_IN_BAY = 'FETCH_AUTHORIZED_IN_BAY';
export const FETCH_AUTHORIZED_IN_BAY_SUCCESS = 'FETCH_AUTHORIZED_IN_BAY_SUCCESS';
export const FETCH_AUTHORIZED_IN_BAY_FAILED = 'FETCH_AUTHORIZED_IN_BAY_FAILED';
export const UPDATE_AUTHORIZED_IN_BAY = 'UPDATE_AUTHORIZED_IN_BAY';
export const UPDATE_AUTHORIZED_IN_BAY_SUCCESS = 'UPDATE_AUTHORIZED_IN_BAY_SUCCESS';
export const REMOVE_ALL_AUTHORIZED_IN_BAY = 'REMOVE_ALL_AUTHORIZED_IN_BAY';
export const FETCH_AUTHORIZED_IN_GROUP = 'FETCH_AUTHORIZED_IN_GROUP';
export const FETCH_AUTHORIZED_IN_GROUP_SUCCESS = 'FETCH_AUTHORIZED_IN_GROUP_SUCCESS';
export const UPDATE_AUTHORIZED_IN_GROUP = 'UPDATE_AUTHORIZED_IN_GROUP';
export const UPDATE_AUTHORIZED_IN_GROUP_SUCCESS = 'UPDATE_AUTHORIZED_IN_GROUP_SUCCESS';
export const REMOVE_ALL_AUTHORIZED_IN_GROUP = 'REMOVE_ALL_AUTHORIZED_IN_GROUP';
export const FETCH_GROUP = 'FETCH_GROUP';
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_FAILED = 'FETCH_GROUP_FAILED';
export const FETCH_GROUP_OVERVIEW = 'FETCH_GROUP_OVERVIEW';
export const FETCH_GROUP_OVERVIEW_SUCCESS = 'FETCH_GROUP_OVERVIEW_SUCCESS';
export const UNLOAD_GROUP = 'UNLOAD_GROUP';
export const UNLOAD_GROUP_OVERVIEW = 'UNLOAD_GROUP_OVERVIEW';
export const UPDATE_GROUP_NAME = 'UPDATE_GROUP_NAME';
export const UPDATE_GROUP_NAME_SUCCESS = 'UPDATE_GROUP_NAME_SUCCESS';
export const UPDATE_GROUP_NAME_ERROR = 'UPDATE_GROUP_NAME_ERROR';
export const UPDATE_GROUP_TAGS = 'UPDATE_GROUP_TAGS';
export const UPDATE_GROUP_TAGS_SUCCESS = 'UPDATE_GROUP_TAGS_SUCCESS';
export const CHANGE_GROUP_ACCESS_MODE = 'CHANGE_GROUP_ACCESS_MODE';
export const CHANGE_GROUP_ACCESS_MODE_SUCCESS = 'CHANGE_GROUP_ACCESS_MODE_SUCCESS';
export const TOGGLE_GROUP_ACCESS_MODE_PENDING = 'TOGGLE_GROUP_ACCESS_MODE_PENDING';
export const TOGGLE_GROUP_ACCESS_MODE_OVERRIDE = 'TOGGLE_GROUP_ACCESS_MODE_OVERRIDE';
export const TOGGLE_GROUP_ACCESS_MODE_OVERRIDE_SUCCESS = 'TOGGLE_GROUP_ACCESS_MODE_OVERRIDE_SUCCESS';
export const TOGGLE_GROUP_ACCESS_MODE_OVERRIDE_PENDING = 'TOGGLE_GROUP_ACCESS_MODE_OVERRIDE_PENDING';
export const CHANGE_GROUP_BAY_OFFLINE_WHEN_ACCESSED = 'CHANGE_GROUP_BAY_OFFLINE_WHEN_ACCESSED';
export const CHANGE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS = 'CHANGE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS';
export const TOGGLE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE = 'TOGGLE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE';
export const TOGGLE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS =
  'TOGGLE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS';
export const CHANGE_STATION_BAY_OFFLINE_WHEN_ACCESSED = 'CHANGE_STATION_BAY_OFFLINE_WHEN_ACCESSED';
export const CHANGE_STATION_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS = 'CHANGE_STATION_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS';
export const TOGGLE_STATION_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE = 'TOGGLE_STATION_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE';
export const TOGGLE_STATION_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS =
  'TOGGLE_STATION_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS';
export const CHANGE_BAY_BAY_OFFLINE_WHEN_ACCESSED = 'CHANGE_BAY_BAY_OFFLINE_WHEN_ACCESSED';
export const CHANGE_BAY_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS = 'CHANGE_BAY_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS';
export const TOGGLE_BAY_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE = 'TOGGLE_BAY_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE';
export const TOGGLE_BAY_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS =
  'TOGGLE_BAY_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS';
export const CHANGE_GROUP_TIMEZONE = 'CHANGE_GROUP_TIMEZONE';
export const CHANGE_GROUP_TIMEZONE_SUCCESS = 'CHANGE_GROUP_TIMEZONE_SUCCESS';
export const TOGGLE_GROUP_TIMEZONE_OVERRIDE = 'TOGGLE_GROUP_TIMEZONE_OVERRIDE';
export const TOGGLE_GROUP_TIMEZONE_OVERRIDE_SUCCESS = 'TOGGLE_GROUP_TIMEZONE_OVERRIDE_SUCCESS';
export const TOGGLE_NETWORK_MODAL = 'TOGGLE_NETWORK_MODAL';
export const UPDATE_NETWORK_SETTINGS = 'UPDATE_NETWORK_SETTINGS';
export const UPDATE_NETWORK_SETTINGS_SUCCESS = 'UPDATE_NETWORK_SETTINGS_SUCCESS';
export const UPDATE_NETWORK_SETTINGS_ERROR = 'UPDATE_NETWORK_SETTINGS_ERROR';
export const TOGGLE_NETWORK_OVERRIDE = 'TOGGLE_NETWORK_OVERRIDE';
export const TOGGLE_NETWORK_OVERRIDE_SUCCESS = 'TOGGLE_NETWORK_OVERRIDE_SUCCESS';
export const TOGGLE_GROUP_NETWORK_OVERRIDE = 'TOGGLE_GROUP_NETWORK_OVERRIDE';
export const TOGGLE_GROUP_NETWORK_OVERRIDE_SUCCESS = 'TOGGLE_GROUP_NETWORK_OVERRIDE_SUCCESS';
export const TOGGLE_GROUP_NETWORK_MODAL = 'TOGGLE_GROUP_NETWORK_MODAL';
export const UPDATE_GROUP_NETWORK_SETTINGS = 'UPDATE_GROUP_NETWORK_SETTINGS';
export const UPDATE_GROUP_NETWORK_SETTINGS_SUCCESS = 'UPDATE_GROUP_NETWORK_SETTINGS_SUCCESS';
export const RESTART_STATION = 'RESTART_STATION';
export const RESTART_STATION_SUCCESS = 'RESTART_STATION_SUCCESS';
export const RESTART_STATION_ERROR = 'RESTART_STATION_ERROR';
export const TOGGLE_BAY_OFFLINE = 'TOGGLE_BAY_OFFLINE';
export const TOGGLE_BAY_OFFLINE_SUCCESS = 'TOGGLE_BAY_OFFLINE_SUCCESS';
export const UNLOCK_BAY = 'UNLOCK_BAY';
export const UNLOCK_BAY_SUCCESS = 'UNLOCK_BAY_SUCCESS';
export const LOAD_CURFEW_MODAL = 'LOAD_CURFEW_MODAL';
export const UNLOAD_CURFEW_MODAL = 'UNLOAD_CURFEW_MODAL';
export const ADD_CURFEW_SLIDER = 'ADD_CURFEW_SLIDER';
export const UPDATE_CURFEW_SLIDER = 'UPDATE_CURFEW_SLIDER';
export const REMOVE_CURFEW_SLIDER = 'REMOVE_CURFEW_SLIDER';
export const UPDATE_CURFEW = 'UPDATE_CURFEW';
export const UPDATE_GROUP_CURFEW = 'UPDATE_GROUP_CURFEW';
export const UPDATE_CURFEW_SUCCESS = 'UPDATE_CURFEW_SUCCESS';
export const UPDATE_GROUP_CURFEW_SUCCESS = 'UPDATE_GROUP_CURFEW_SUCCESS';
export const UPDATE_CURFEW_ERROR = 'UPDATE_CURFEW_ERROR';
export const FACTORY_RESET_STATION = 'FACTORY_RESET_STATION';
export const FACTORY_RESET_STATION_SUCCESS = 'FACTORY_RESET_STATION_SUCCESS';
export const REMOVE_BAY_ASSIGNMENT = 'REMOVE_BAY_ASSIGNMENT';
export const REMOVE_BAY_ASSIGNMENT_SUCCESS = 'REMOVE_BAY_ASSIGNMENT_SUCCESS';
export const REMOVE_BAY_ASSIGNMENT_ERROR = 'REMOVE_BAY_ASSIGNMENT_ERROR';
export const SET_BAY_ASSIGNMENT = 'SET_BAY_ASSIGNMENT';
export const SET_BAY_ASSIGNMENT_SUCCESS = 'SET_BAY_ASSIGNMENT_SUCCESS';
export const SET_BAY_ASSIGNMENT_ERROR = 'SET_BAY_ASSIGNMENT_ERROR';
export const TOGGLE_ASSIGN_CREDENTIALS_MODAL = 'TOGGLE_ASSIGN_CREDENTIALS_MODAL';
export const TOGGLE_STATION_LOCKDOWN = 'TOGGLE_STATION_LOCKDOWN';
export const TOGGLE_STATION_LOCKDOWN_SUCCESS = 'TOGGLE_STATION_LOCKDOWN_SUCCESS';
export const TOGGLE_STATION_LOCKDOWN_ERROR = 'TOGGLE_STATION_LOCKDOWN_ERROR';
export const UPDATE_FIRMWARE = 'UPDATE_FIRMWARE';
export const UPDATE_FIRMWARE_SUCCESS = 'UPDATE_FIRMWARE_SUCCESS';
export const UPDATE_FIRMWARE_ERROR = 'UPDATE_FIRMWARE_ERROR';
export const GET_AVAILABLE_FIRMWARE_LIST = 'GET_AVAILABLE_FIRMWARE_LIST';
export const GET_AVAILABLE_FIRMWARE_LIST_SUCCESS = 'GET_AVAILABLE_FIRMWARE_LIST_SUCCESS';
export const EVENT_LOGS_PAGE_LOADED = 'EVENT_LOGS_PAGE_LOADED';
export const EVENT_LOGS_PAGE_UNLOADED = 'EVENT_LOGS_PAGE_UNLOADED';
export const FIND_EVENT_LOGS = 'FIND_EVENT_LOGS';
export const FIND_EVENT_LOGS_SUCCESS = 'FIND_EVENT_LOGS_SUCCESS';
export const FIND_EVENT_LOGS_ERROR = 'FIND_EVENT_LOGS_ERROR';
export const FILTER_EVENT_LOGS_BY_PROPERTY = 'FILTER_EVENT_LOGS_BY_PROPERTY';
export const UI_KIT_PAGE_LOADED = 'UI_KIT_PAGE_LOADED';
export const UI_KIT_PAGE_UNLOADED = 'UI_KIT_PAGE_UNLOADED';
export const FETCH_APP_CLIENTS = 'FETCH_APP_CLIENTS';
export const FETCH_APP_CLIENTS_SUCCESS = 'FETCH_APP_CLIENTS_SUCCESS';
export const TOGGLE_NEW_APP_CLIENT_FORM = 'TOGGLE_NEW_APP_CLIENT_FORM';
export const CREATE_APP_CLIENT = 'CREATE_APP_CLIENT';
export const UPDATE_APP_CLIENT = 'UPDATE_APP_CLIENT';
export const DELETE_APP_CLIENT = 'DELETE_APP_CLIENT';
export const APP_CLIENT_ERROR = 'APP_CLIENT_ERROR';
export const FETCH_WEBHOOKS = 'FETCH_WEBHOOKS';
export const FETCH_WEBHOOKS_SUCCESS = 'FETCH_WEBHOOKS_SUCCESS';
export const FETCH_WEBHOOK_SUCCESS = 'FETCH_WEBHOOK_SUCCESS';
export const DELETE_WEBHOOK = 'DELETE_WEBHOOK';
export const WEBHOOK_ERROR = 'WEBHOOK_ERROR';
export const UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';
export const UPDATE_PROFILE_INFO_SUCCESS = 'UPDATE_PROFILE_INFO_SUCCESS';
export const SET_ACCOUNT_CONTEXT = 'SET_ACCOUNT_CONTEXT';
export const SET_COGNITO_MFA_CONTEXT = 'SET_COGNITO_MFA_CONTEXT';
export const SET_DEFAULT_ACCOUNT_CONTEXT = 'SET_DEFAULT_ACCOUNT_CONTEXT';
export const TOGGLE_SELECT_ACCOUNT_MODAL = 'TOGGLE_SELECT_ACCOUNT_MODAL';
export const CREATE_SUB_GROUP = 'CREATE_SUB_GROUP';
export const CREATE_SUB_GROUP_SUCCESS = 'CREATE_SUB_GROUP_SUCCESS';
export const CREATE_SUB_GROUP_ERROR = 'CREATE_SUB_GROUP_ERROR';
export const DELETE_SUB_GROUP = 'DELETE_SUB_GROUP';
export const DELETE_SUB_GROUP_SUCCESS = 'DELETE_SUB_GROUP_SUCCESS';
export const DELETE_SUB_GROUP_ERROR = 'DELETE_SUB_GROUP_ERROR';
export const FETCH_GLOBAL_TAGS = 'FETCH_GLOBAL_TAGS';
export const FETCH_GLOBAL_TAGS_SUCCESS = 'FETCH_GLOBAL_TAGS_SUCCESS';

export const TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE = 'TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE';
export const TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_SUCCESS = 'TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_SUCCESS';
export const TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_PENDING = 'TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_PENDING';
export const TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE_SUCCESS =
  'TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE_SUCCESS';
export const TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE = 'TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE';
export const TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE_PENDING =
  'TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE_PENDING';

export const USER_GROUPS_PAGE_LOADED = 'USER_GROUPS_PAGE_LOADED';
export const USER_GROUPS_PAGE_UNLOADED = 'USER_GROUPS_PAGE_UNLOADED';

export const WATCH_BACKGROUND_JOBS = 'WATCH_BACKGROUND_JOBS';
export const UNWATCH_BACKGROUND_JOBS = 'UNWATCH_BACKGROUND_JOBS';
export const UPDATE_BACKGROUND_JOBS = 'UPDATE_BACKGROUND_JOBS';
export const GET_TRIGGERED_BACKGROUND_JOBS = 'GET_TRIGGERED_BACKGROUND_JOBS';
export const ADD_TRIGGERED_BACKGROUND_JOB = 'ADD_TRIGGERED_BACKGROUND_JOB';
export const REMOVE_TRIGGERED_BACKGROUND_JOB = 'REMOVE_TRIGGERED_BACKGROUND_JOB';
export const UPDATE_TRIGGERED_BACKGROUND_JOBS = 'UPDATE_TRIGGERED_BACKGROUND_JOBS';

export const REPORTS_PAGE_LOADED = 'REPORTS_PAGE_LOADED';
export const REPORTS_PAGE_UNLOADED = 'REPORTS_PAGE_UNLOADED';
export const FILTER_REPORTS_BY_PROPERTY = 'FILTER_REPORTS_BY_PROPERTY';
export const GENERATE_REPORT = 'GENERATE_REPORT';
export const FETCH_NEXT_REPORT_PAGE = 'FETCH_NEXT_REPORT_PAGE';
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export const GENERATE_REPORT_ERROR = 'GENERATE_REPORT_ERROR';
export const EXPORT_REPORT = 'EXPORT_REPORT';
export const EXPORT_REPORT_SUCCESS = 'EXPORT_REPORT_SUCCESS';
export const EXPORT_REPORT_ERROR = 'EXPORT_REPORT_ERROR';
export const HIDE_EXPORT_REPORT_MODAL = 'HIDE_EXPORT_REPORT_MODAL';

export const SDK_CALL_ACTION = 'SDK_CALL_ACTION';
export const SDK_CALL_ACTION_SUCCESS = 'SDK_CALL_ACTION_SUCCESS';
export const SDK_CALL_ACTION_ERROR = 'SDK_CALL_ACTION_ERROR';

export const FETCH_GROUP_HIERARCHY = 'FETCH_GROUP_HIERARCHY';
export const SET_GROUP_HIERARCHY = 'SET_GROUP_HIERARCHY';

export const SET_DEGRADATION_STATUS = 'SET_DEGRADATION_STATUS';
export const CHECK_DEGRADATION_STATUS = 'CHECK_DEGRADATION_STATUS';
