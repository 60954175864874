import React, { Dispatch, useEffect, useState } from 'react';
import { Button, Modal, Skeleton, Switch, Popconfirm, Alert, Upload, Spin, Result } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { LinkOutlined, UploadOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import * as ActionTypes from '../../store/actionTypes';
import { CsvImportState, StationUserActionTypes } from '../../store/users/types';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import * as RfidHelper from '../../helpers/rfid-converter-helper';

const sampleCsvFileContents = [
  'name,pin,rfid,externalRefId,tags,groups',
  'Amy Bloggs,1111',
  'John Doe,,00AABBCC12,ref1,"tag 1,tag 2","Group 1,Group 2,Group 3"',
  'Jane Doe,2222,,ref2,tag 1,Group 1',
  'Billy Bob,3333,,ref3'
].join('\n');

const mapStateToProps = (state: RootState) => ({
  ...state.stationUser
});

const mapDispatchToProps = (dispatch: Dispatch<StationUserActionTypes>) => ({
  unloadModal: () => dispatch({ type: ActionTypes.STATION_USER_UNLOAD_CSV_MODAL }),
  pushCsvFileToS3: (file: File) => dispatch({ type: ActionTypes.PUSH_CSV_FILE_TO_S3, payload: { file } }),
  triggerCsvImport: (fileReference: string, deleteAllUsers: boolean) =>
    dispatch({ type: ActionTypes.TRIGGER_API_CSV_IMPORT, payload: { fileReference, deleteAllUsers } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & FormComponentProps;

export const StationUserCsvModal = connector(
  LegacyForm.create({ name: 'StationUserCsv' })((props: Props) => {
    const [deleteUsersOnImport, setDeleteUsersOnImport] = useState(false);

    useEffect(() => {
      props.showStationUserCsvModal && setDeleteUsersOnImport(false);
    }, [props.showStationUserCsvModal]);

    const handleCsvUpload = (e: any) => {
      e.preventDefault();
      props.fileReference && props.triggerCsvImport(props.fileReference, deleteUsersOnImport);
    };

    const pending =
      props.csvImportState === CsvImportState.UPLOADING || props.csvImportState === CsvImportState.IMPORTING;

    return (
      <Modal
        title="Import Users"
        visible={props.showStationUserCsvModal}
        maskClosable={!pending}
        onCancel={props.unloadModal}
        width={420}
        footer={[
          <Button key="back" onClick={props.unloadModal} style={{ display: pending ? 'none' : 'initial' }}>
            {props.csvImportState === CsvImportState.DONE ? 'OK' : 'Cancel'}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCsvUpload}
            disabled={pending}
            style={{ display: !!props.fileReference ? 'initial' : 'none' }}
            loading={props.csvImportState === CsvImportState.IMPORTING}>
            {deleteUsersOnImport ? 'Delete and Import' : 'Import'}
          </Button>
        ]}>
        {props.stationUserId && !props.stationUser ? (
          <Skeleton active />
        ) : props.csvImportState === CsvImportState.DONE ? (
          <Result
            status="info"
            title="Import in progress"
            subTitle="We've started to import your user list. This process can take a while so we'll send you an email once it's complete."
          />
        ) : (
          <>
            <div>
              Download this&nbsp;
              <a href={`data:text/csv,${encodeURIComponent(sampleCsvFileContents)}`} download="upload.csv">
                CSV template
              </a>
              &nbsp;to populate with your list of users and then upload the file.
            </div>

            <hr />

            <div style={{ marginBottom: 16 }}>
              <Spin spinning={props.csvImportState === CsvImportState.UPLOADING}>
                <Upload
                  key="preview"
                  accept=".csv"
                  fileList={undefined}
                  showUploadList={false}
                  customRequest={options => props.pushCsvFileToS3(options.file as File)}
                  className="csv-upload-wrapper">
                  <Button style={{ marginRight: '15px' }}>
                    <UploadOutlined /> Choose File
                  </Button>
                </Upload>
              </Spin>
            </div>

            {props.csvImportState !== CsvImportState.UPLOADING && props.csvFile?.name && (
              <span style={{ display: 'block', marginBottom: 16 }}>
                <LinkOutlined />
                &nbsp;<span title={props.csvFile.name}>{props.csvFile.name}</span>
              </span>
            )}

            <div style={{ marginBottom: 16 }}>
              <span>RFID format:&nbsp;{RfidHelper.rfidFormatTooltip}&nbsp;&nbsp;</span>
              <RfidHelper.FormatSelector onChange={() => ({})} />
            </div>

            <div>
              <Popconfirm
                placement="right"
                title="Are you sure you want to delete all existing users before import?"
                disabled={deleteUsersOnImport}
                cancelText="No"
                okText="Yes"
                onConfirm={() => setDeleteUsersOnImport(!deleteUsersOnImport)}>
                <Switch
                  checked={deleteUsersOnImport}
                  onChange={() => deleteUsersOnImport && setDeleteUsersOnImport(false)}
                  style={{ marginRight: '10px' }}
                />
              </Popconfirm>
              <span>Delete all existing users before import</span>
            </div>

            {props.errorMessage && <Alert message={props.errorMessage} type="error" style={{ marginTop: 16 }} />}
          </>
        )}
      </Modal>
    );
  })
);
