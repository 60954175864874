type NoInfer<T> = [T][T extends unknown ? 0 : never];

/**
 * Asserts that the given argument is of a given type.
 */
export const assertType = <T = never>(v: NoInfer<T>) => v;

/**
 * Returns a type-checking function that asserts that the given argument
 * is of a given type, but returns the type of the argument rather than the
 * type checked.
 *
 * For example, to return a literal but check it matches a partial
 *
 * interface MyInterface = { str: string; n: number }
 * const obj: { n: number } = checkType<Partial<MyInterface>()({ n: 1 }); // allowed
 * const obj: { n: number } = checkType<Partial<MyInterface>()({ n: 'string' }); // not allowed
 */
export function checkType<T = never>() {
  return <P extends NoInfer<T>>(x: P) => x;
}

/**
 * Picks values based on a predicate.
 */
export type PickPredicate<Base, Condition> = Pick<
  Base,
  {
    [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
  }[keyof Base]
>;

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

// This function can be used to have typescript ensure that a type has been fully discriminated.
// For example, in a default case of a switch statement to ensure that the switch is exhaustive.
//
// If the argument has been fully discriminated, its type will resolve to 'never' and the function
// call will be allowed. However, if the argument has not been fully discriminated it will have a
// specific type that is not compatible with never and will be shown in the error message
export function exhaustiveDiscrimination<TArg, TReturn>(x: never, fn: (x: TArg) => TReturn): TReturn {
  return fn(x as TArg);
}
