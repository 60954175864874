import { all, spawn, call, delay } from 'redux-saga/effects';
import { commonSaga } from './common/sagas';
import { accountSaga } from './account/sagas';
import { authSaga } from './auth/sagas';
import { registerSaga } from './register/sagas';
import { dashboardSaga } from './dashboard/sagas';
import { stationUserSaga } from './users/sagas';
import { eventLogsSaga } from './event-logs/sagas';
import { reportsSaga } from './reports/sagas';
import { integrationsSaga } from './integrations/sagas';
import { websocketSaga } from './websocket/sagas';
import { degradationStatusSaga } from './degradation-status/sagas';

export const makeSagaRestartable = (saga: any) => {
  return function*() {
    yield spawn(function*() {
      while (true) {
        try {
          yield call(saga);
          console.error(
            'Unexpected saga termination. Saga passed to makeSagaRestartable() should live for the whole application lifetime.',
            saga
          );
        } catch (e) {
          console.error('Saga error, the saga will be restarted.', e);
        }
        yield delay(1000); // Delay before restarting...
      }
    });
  };
};

export function* rootSaga() {
  const rootSagas = [
    commonSaga,
    accountSaga,
    authSaga,
    degradationStatusSaga,
    registerSaga,
    dashboardSaga,
    stationUserSaga,
    eventLogsSaga,
    reportsSaga,
    integrationsSaga,
    websocketSaga
  ].map(makeSagaRestartable);
  yield all(rootSagas.map(saga => call(saga)));
}
