import React from 'react';
import { Modal, Form, Button, Space } from 'antd';

type Props<T> = {
  submitting: {
    form: boolean;
    delete: boolean;
    test: boolean;
  };
  currentId: string | undefined;
  readonly: boolean;
  validateFields: () => Promise<T>;
  delete: (id: string) => void;
  testConnection: (values: T) => void;
};

export const FooterButtons = <T extends unknown>(props: Props<T>) => {
  const { submitting, currentId, readonly, validateFields } = props;

  const isSubmitting = Object.values(submitting).some(v => !!v);

  return (
    <Form.Item wrapperCol={{ sm: { offset: 6, span: 18 }, xxl: { span: 12 } }} style={{ textAlign: 'right' }}>
      <Space>
        {currentId && (
          <Button
            type="default"
            loading={submitting.delete}
            disabled={isSubmitting}
            onClick={e => {
              e.preventDefault();
              Modal.confirm({
                title: 'Warning',
                content: `Are you sure you want to remove the configuration?`,
                onOk: () => {
                  props.delete(currentId);
                }
              });
            }}>
            Remove Configuration
          </Button>
        )}
        <Button
          type="default"
          loading={submitting.test}
          disabled={isSubmitting || readonly}
          onClick={() =>
            validateFields()
              .then(values => {
                props.testConnection(values);
              })
              .catch(() => {
                // not interested in the error here
              })
          }>
          Test Connection
        </Button>
        <Button type="primary" htmlType="submit" loading={submitting.form} disabled={isSubmitting || readonly}>
          Save
        </Button>
      </Space>
    </Form.Item>
  );
};
