import React from 'react';
import { BayResponseDto } from '@pclocs/platform-sdk';
import { BayStateClass } from '../../helpers/bay-helper';

interface Props {
  status?: BayResponseDto['reported'];
  size?: number;
  style?: React.CSSProperties;
}

export const BayStatusIndicator = (props: Props) => {
  return (
    <span
      style={{
        width: props.size || 14,
        height: props.size || 14,
        borderRadius: 2,
        display: 'inline-block',
        ...(props.style || {})
      }}
      className={BayStateClass[props.status?.bayState as number]}></span>
  );
};
