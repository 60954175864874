import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Skeleton, Tooltip, Button, Switch } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import { RootState } from '../../../../store/rootReducer';
import { DashboardActionTypes } from '../../../../store/dashboard/types';
import * as ActionTypes from '../../../../store/actionTypes';
import _ from 'lodash';
import { AccessMode } from '../../access-mode';
import { BayOfflineWhenAccessed } from '../../BayOfflineWhenAccessed/bay-offline-when-accessed';
import { Timezone } from './timezone';
import { Network } from '../../Network';
import {
  FeaturesDtoFeaturesEnum,
  FirmwareUpdateScheduleDto,
  GroupResponseDto,
  NetworkSettingsDto
} from '@pclocs/platform-sdk';
import { Curfew } from '../../Curfew';
import { essentialsTierOrAbove } from '../../../../helpers/subscription-helper';
import { NotificationButton } from '../../../Buttons/Notification';
import { subscriptionMessage } from '../../../../constants/validations';
import { AutomaticFirmwareUpdate } from '../../AutomaticFirmwareUpdate/automatic-firmware-update';
import { FetchFailedAlert } from '../../fetch-failed-alert';
import { findGroupNode, useGroupHierarchy } from '../../../../helpers/group-hierarchy';
import { useEverySdkCall } from '../../../../helpers/sdk-hooks';
import { useFeatureAvailability } from '../../../../helpers/feature-availability-hooks';
import { CommonActionTypes } from '../../../../store/common/types';

const mapStateToProps = (state: RootState) => ({
  ..._.pick(state.dashboard, [
    'group',
    'changeAccessModePending',
    'accessModeOverridePending',
    'changeBayOfflineWhenAccessedPending',
    'changeTimezonePending',
    'showGroupNetworkModal',
    'networkSettingsPending',
    'newSubGroupPending',
    'deleteSubGroupPending',
    'firmwareUpdateSchedule',
    'toggleGroupAutomaticFirmwareUpdateOverridePending',
    'toggleGroupAutomaticFirmwareUpdatePending',
    'groupFetchFailed'
  ]),
  subscriptionInfo: state.account.subscriptionInfo
});

const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes | CommonActionTypes>) => ({
  fetchGroup: (id: string) => dispatch({ type: ActionTypes.FETCH_GROUP, payload: { id } }),
  fetchGroupSuccess: (data: GroupResponseDto) =>
    dispatch({ type: ActionTypes.FETCH_GROUP_SUCCESS, payload: { id: data.id, data } }),
  changeAccessMode: (id: string, managed: boolean) =>
    dispatch({ type: ActionTypes.CHANGE_GROUP_ACCESS_MODE, payload: { id, managed } }),
  changeBayOfflineWhenAccessed: (id: string, value: boolean) =>
    dispatch({ type: ActionTypes.CHANGE_GROUP_BAY_OFFLINE_WHEN_ACCESSED, payload: { id, value } }),
  toggleAccessModeOverride: (id: string, value: boolean | null) =>
    dispatch({ type: ActionTypes.TOGGLE_GROUP_ACCESS_MODE_OVERRIDE, payload: { id, value } }),
  toggleBayOfflineWhenAccessedOverride: (id: string, value: boolean | null) =>
    dispatch({ type: ActionTypes.TOGGLE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE, payload: { id, value } }),
  changeTimezone: (id: string, value: string) =>
    dispatch({ type: ActionTypes.CHANGE_GROUP_TIMEZONE, payload: { id, value } }),
  toggleTimezoneOverride: (id: string, value: string | null) =>
    dispatch({ type: ActionTypes.TOGGLE_GROUP_TIMEZONE_OVERRIDE, payload: { id, value } }),
  toggleNetworkModal: (value: boolean) =>
    dispatch({ type: ActionTypes.TOGGLE_GROUP_NETWORK_MODAL, payload: { value } }),
  updateNetworkSettings: (id: string, networkSettings: NetworkSettingsDto | null) =>
    dispatch({
      type: ActionTypes.UPDATE_GROUP_NETWORK_SETTINGS,
      payload: {
        id,
        networkSettings
      }
    }),
  createSubGroup: (id: string) =>
    dispatch({
      type: ActionTypes.CREATE_SUB_GROUP,
      payload: { id }
    }),
  deleteSubGroup: (id: string) =>
    dispatch({
      type: ActionTypes.DELETE_SUB_GROUP,
      payload: { id }
    }),
  setAutomaticFirmwareUpdateSchedule: (id: string, firmwareUpdateSchedule: FirmwareUpdateScheduleDto) => {
    return dispatch({
      type: ActionTypes.TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE,
      payload: { id, firmwareUpdateSchedule: firmwareUpdateSchedule }
    });
  },
  toggleGroupAutomaticFirmwareUpdateOverride: (
    id: string,
    firmwareUpdateSchedule: FirmwareUpdateScheduleDto | null
  ) => {
    return dispatch({
      type: ActionTypes.TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE,
      payload: { id, firmwareUpdateSchedule }
    });
  },
  showErrorNotificationModal: (errorMessage: string) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MODAL,
      payload: { type: 'error', title: 'Error', content: errorMessage }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const GroupSettings = connector((props: Props) => {
  const { groupId } = useParams<{ groupId: string }>();
  const correctSubscription: boolean = essentialsTierOrAbove(props.subscriptionInfo);
  const groupHierarchy = useGroupHierarchy();
  const hasSubGroups = Object.keys(findGroupNode(groupHierarchy, groupId)?.groups ?? {}).length > 0;

  useEffect(() => {
    if (groupId === undefined) {
      return;
    }
    props.fetchGroup(groupId);
  }, [groupId]);

  if (props.groupFetchFailed) {
    return <FetchFailedAlert itemType="group" statusCode={props.groupFetchFailed.statusCode} />;
  }

  const [hasFeature] = useFeatureAvailability();
  const hasLimitUserReservationsFeature = hasFeature(FeaturesDtoFeaturesEnum.LIMITUSERRESERVATIONS);

  const [setLimitUserReservations, setLimitUserReservationsLoading] = useEverySdkCall(
    'GroupApi',
    'updateConfig',
    res => props.fetchGroupSuccess(res.data),
    () => props.showErrorNotificationModal('Failed to set option to limit user reservations.')
  );

  return !props.group?.derivedConfig || !props.subscriptionInfo ? (
    <Skeleton active paragraph={{ rows: 2, width: '80%' }} />
  ) : (
    <>
      <AccessMode
        type="group"
        entity={props.group}
        changeAccessModePending={props.changeAccessModePending}
        accessModeOverridePending={props.accessModeOverridePending}
        changeAccessMode={props.changeAccessMode}
        toggleAccessModeOverride={props.toggleAccessModeOverride}
        subscriptionInfo={props.subscriptionInfo}
      />
      <br />
      <BayOfflineWhenAccessed
        derivedValue={props.group.derivedConfig.takeBayOfflineWhenAccessed as boolean}
        isOverridden={props.group.isRoot ? true : props.group.config?.takeBayOfflineWhenAccessed !== undefined}
        showOverrideButton={!props.group.isRoot}
        changeBayOfflineWhenAccessedPending={props.changeBayOfflineWhenAccessedPending}
        changeBayOfflineWhenAccessed={props.changeBayOfflineWhenAccessed.bind(null, props.group.id)}
        toggleBayOfflineWhenAccessedOverride={props.toggleBayOfflineWhenAccessedOverride.bind(null, props.group.id)}
        subscriptionInfo={props.subscriptionInfo}
      />
      {hasLimitUserReservationsFeature && props.group?.isRoot && (
        <>
          <br />
          <div>
            <Switch
              checked={props.group.config.limitUserReservations}
              loading={setLimitUserReservationsLoading}
              onChange={isChecked => setLimitUserReservations(props.group.id, { limitUserReservations: isChecked })}
            />{' '}
            Limit users to one reservation at a time
          </div>
        </>
      )}

      <br />
      <Curfew
        type="group"
        curfew={props.group.derivedConfig.curfew}
        isOverridden={props.group.isRoot ? true : props.group.config?.curfew !== undefined}
      />
      <br />
      {!correctSubscription && (
        <Tooltip title={subscriptionMessage} placement="right">
          <Button id="create-sub-group-btn" icon={<FolderOutlined />} style={{ marginBottom: 20 }} disabled>
            New sub-node
          </Button>
        </Tooltip>
      )}
      {correctSubscription && (
        <NotificationButton
          id="create-sub-group-btn"
          icon={<FolderOutlined />}
          text="New sub-node"
          promptContent="New sub-node will be created in this node, continue?"
          pending={props.newSubGroupPending}
          onOk={props.createSubGroup.bind(null, props.group.id)}
        />
      )}
      <br />
      <Timezone
        group={props.group}
        changeTimezonePending={props.changeTimezonePending}
        changeTimezone={props.changeTimezone.bind(null, props.group.id)}
        toggleTimezoneOverride={props.toggleTimezoneOverride.bind(null, props.group.id)}
        subscriptionInfo={props.subscriptionInfo}
      />
      <h4 style={{ marginTop: 30 }}>Danger Zone</h4>
      <p>Proceed carefully, these actions can be destructive!</p>
      <Network
        type="group"
        disabled={!correctSubscription && !props.group?.isRoot}
        entity={props.group}
        toggleNetworkModal={props.toggleNetworkModal}
        showNetworkModal={props.showGroupNetworkModal}
        updateNetworkSettings={props.updateNetworkSettings.bind(null, props.group.id)}
        networkSettingsPending={props.networkSettingsPending}
      />
      {!props.group?.isRoot &&
        (hasSubGroups || props.group.stations?.length > 0 ? (
          <>
            <Tooltip title={"Can't delete this node as it has stations or child nodes."} placement="right">
              <Button id="delete-group-btn" icon={<FolderOutlined />} style={{ marginTop: 20 }} disabled>
                Delete node
              </Button>
            </Tooltip>
            <br />
          </>
        ) : (
          <NotificationButton
            id="delete-group-btn"
            btnStyle={{ marginTop: 20 }}
            btnClass="btn-danger"
            icon={<FolderOutlined />}
            text="Delete node"
            promptContent="Are you sure you want to delete this node?"
            pending={props.deleteSubGroupPending}
            onOk={props.deleteSubGroup.bind(null, props.group.id)}
          />
        ))}
      <br />
      {props.group.isRoot && ( // Only display on root group i.e. account level for now, leaving in override/inherit so we can add it in later
        <AutomaticFirmwareUpdate
          derivedValue={props.group.derivedConfig.firmwareUpdateSchedule}
          isOverridden={props.group.isRoot ? true : props.group.config?.firmwareUpdateSchedule !== undefined}
          showOverrideButton={!props.group.isRoot}
          toggleAutomaticFirmwareUpdateOverridePending={props.toggleGroupAutomaticFirmwareUpdateOverridePending}
          toggleAutomaticFirmwareUpdatePending={props.toggleGroupAutomaticFirmwareUpdatePending}
          setAutomaticFirmwareUpdateSchedule={props.setAutomaticFirmwareUpdateSchedule.bind(null, props.group.id)}
          toggleAutomaticFirmwareUpdateOverride={props.toggleGroupAutomaticFirmwareUpdateOverride.bind(
            null,
            props.group.id
          )}
          subscriptionInfo={props.subscriptionInfo}
        />
      )}
    </>
  );
});
