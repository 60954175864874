import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Popconfirm, Skeleton, Switch, Tabs } from 'antd';
import { DeleteOutlined, PhoneFilled } from '@ant-design/icons';
import { PortalAdmin } from './PortalAdmin';
import { AdminRoles } from './AdminRoles';
import { RootState } from '../../store/rootReducer';
import { accountActions, AccountActionTypes } from '../../store/account/reducer';
import _ from 'lodash';
import { AccountBasicResponseDto, AccountBasicResponseDtoStatusEnum } from '@pclocs/platform-sdk';
import { useEverySdkCall } from '../../helpers/sdk-hooks';
import { ShowNotificationMessageAction } from '../../store/common/types';
import * as ActionTypes from '../../store/actionTypes';
import { AuthActionTypes } from '../../store/auth/types';
import { RfidFormatSetting } from './rfid-format-setting';
import { AdminClaimEnum, useHasClaim } from '../../helpers/claims';
import { CollapsibleFormSection } from '../../helpers/collapsible-form-section';
import { NotificationButton } from '../Buttons/Notification';

const mapStateToProps = (state: RootState) => ({
  pendingCurrentUser: state.auth.pendingCurrentUser,
  ..._.pick(state.account, ['accountInfo', 'subscriptionInfo'])
});

const mapDispatchToProps = (
  dispatch: Dispatch<AccountActionTypes | ShowNotificationMessageAction | AuthActionTypes>
) => ({
  showNotification: (payload: ShowNotificationMessageAction['payload']) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload
    }),
  onLoad: () => dispatch(accountActions.accountPageLoaded()),
  getAccountInfo: () => dispatch(accountActions.getAccountInfo()),
  getSubscriptionInfo: () => dispatch(accountActions.getSubscriptionInfo()),
  onUnload: () => dispatch(accountActions.accountPageUnloaded()),
  refreshCurrentUserAccounts: () =>
    dispatch({
      type: ActionTypes.GET_CURRENT_USER
    }),
  logout: () => dispatch({ type: ActionTypes.LOGOUT, payload: { redirect: true } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const Account = connector((props: Props) => {
  const [accountInfo, setAccountInfo] = useState<AccountBasicResponseDto>(props.accountInfo);
  const isAccountInfoPending = accountInfo === undefined;

  useEffect(() => {
    props.onLoad();
    props.getAccountInfo();
    props.getSubscriptionInfo();

    return () => {
      props.onUnload();
    };
  }, []);

  useEffect(() => {
    if (props.accountInfo) {
      setAccountInfo(props.accountInfo);
    }
  }, [props.accountInfo]);

  const [updateAccount, updateAccountPending] = useEverySdkCall(
    'AccountApi',
    'updateOne',
    v => {
      if (v.data.status === AccountBasicResponseDtoStatusEnum.SUSPENDED) {
        props.logout();
      } else {
        setAccountInfo({ ...v.data, id: v.data.id.replace('account-', '') });
        props.showNotification({ type: 'success', content: 'Configuration saved.' });
        props.refreshCurrentUserAccounts();
      }
    },
    () => props.showNotification({ type: 'error', content: 'Could not save the configuration.' })
  );

  const [canEditAccountSettings] = useHasClaim({ type: AdminClaimEnum.AccountSettings });

  return (
    <>
      <h4>Account</h4>
      <div className="section-container">
        <div className="content-section" style={{ height: 'calc(100vh - 200px)' }}>
          <div className="content-section-inner">
            {isAccountInfoPending ? (
              <div>
                <Skeleton active paragraph={{ rows: 2 }} />
              </div>
            ) : (
              <>
                <div>
                  <h5>{accountInfo.name}</h5>
                  <div>
                    <strong>Account Code:</strong> {accountInfo?.accountCode}
                  </div>
                  <div>
                    <PhoneFilled /> {accountInfo?.phone}
                  </div>
                  <div>
                    <strong>Tier:</strong>{' '}
                    {props.subscriptionInfo?.tier === 'none'
                      ? 'No subscription found'
                      : `Cloud ${_.startCase(props.subscriptionInfo?.tier)}`}
                  </div>
                  <div>
                    <strong>Subscription units used:</strong> {props.subscriptionInfo?.usedQuantity}/
                    {props.subscriptionInfo?.subscriptionQuantity}
                  </div>
                </div>

                <hr />
                <Tabs animated={false} tabBarGutter={30}>
                  {canEditAccountSettings && (
                    <Tabs.TabPane tab="Settings" key="1">
                      <div style={{ marginTop: 10, marginBottom: 25 }} id="2fa-section">
                        <h5>Two-Factor Authentication (2FA)</h5>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Popconfirm
                            placement="bottom"
                            title="Are you sure you want to change the 2FA requirement for this account?"
                            cancelText="No"
                            okText="Yes"
                            onConfirm={() => {
                              updateAccount({ mfaRequired: !accountInfo.mfaRequired });
                            }}>
                            <Switch
                              loading={updateAccountPending || props.pendingCurrentUser}
                              checked={accountInfo.mfaRequired}
                            />
                          </Popconfirm>
                          &nbsp;Require admins to log in with two-factor authentication (2FA)
                        </div>
                      </div>
                      <hr />
                      <div id="rfidformat-section" style={{ marginTop: 25 }}>
                        <h5>RFID Format</h5>
                        <RfidFormatSetting
                          loading={updateAccountPending}
                          value={accountInfo.rfidFormat}
                          onChange={v => updateAccount({ rfidFormat: v })}
                        />
                      </div>
                      <>
                        <hr />
                        <div id="delete-account-section" style={{ marginTop: 25 }}>
                          <h5>Danger Zone</h5>
                          <CollapsibleFormSection title="Proceed carefully, these actions can be destructive!">
                            <NotificationButton
                              id="delete-account-notification-btn"
                              btnClass="btn-danger"
                              icon={<DeleteOutlined />}
                              text="Delete Account"
                              promptContent="If you click 'Yes', you will be automatically logged out and all admins will not be able to log in. Are you sure you want to proceed?"
                              pending={updateAccountPending}
                              onOk={() => {
                                updateAccount({ status: AccountBasicResponseDtoStatusEnum.SUSPENDED });
                              }}
                              disabled={updateAccountPending}
                            />
                            <small>
                              This action will initiate the process to delete the account in 7 days. All admins, roles,
                              users, user groups, event logs, integration configurations will be deleted. All stations
                              will be factory reset and removed from the account, and the station tree nodes will be
                              deleted. All owners will receive a confirmation email that contains a link to cancel this
                              process.
                            </small>
                          </CollapsibleFormSection>
                        </div>
                      </>
                    </Tabs.TabPane>
                  )}
                  <Tabs.TabPane tab="Admins" key="2">
                    <PortalAdmin />
                  </Tabs.TabPane>
                  {canEditAccountSettings && (
                    <Tabs.TabPane tab="Roles" key="3">
                      <AdminRoles />
                    </Tabs.TabPane>
                  )}
                </Tabs>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
});
