import React, { Dispatch, useEffect } from 'react';
import { CommonActionTypes } from '../../store/common/types';
import { connect, ConnectedProps } from 'react-redux';
import * as ActionTypes from '../../store/actionTypes';
import { Tabs } from 'antd';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AppClients } from './AppClients';
import { history } from '../../store/store';
import { Webhooks } from './Webhooks';
import { WebhooksForm } from './Webhooks/form';
import { ExternalUsers } from './ExternalUsers';
import { IdentityProviders } from './IdentityProviders';
import { IdentityProvidersForm } from './IdentityProviders/form';
import { AdminClaimEnum } from '../../helpers/claims';
import { IfHasClaim } from '../IfHasClaim';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<CommonActionTypes>) => ({
  onLoad: () => dispatch({ type: ActionTypes.INTEGRATIONS_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ActionTypes.INTEGRATIONS_PAGE_UNLOADED })
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const Integrations = connector((props: Props) => {
  useEffect(() => {
    props.onLoad();
    return () => {
      props.onUnload();
    };
  }, []);

  const { path } = useRouteMatch();
  const baseUrl = useRouteMatch().url;
  const linkTo = (target: string) => {
    history.push(`${baseUrl}/${target}`);
  };

  return (
    <div className="integrations-section">
      <h4>Integrations</h4>
      <div className="section-container">
        <IfHasClaim requiredClaim={{ type: AdminClaimEnum.AccountSettings }} entityName="page">
          <Tabs onTabClick={linkTo} activeKey={history.location.pathname.split('/')[4]} animated={false}>
            <Tabs.TabPane tab="App Clients" key="app-clients" data-test-id="app-clients-tab" />
            <Tabs.TabPane tab="Webhooks" key="webhooks" data-test-id="webhooks-tab" />
            <Tabs.TabPane tab="External Users" key="external-users" data-test-id="external-users-tab" />
            <Tabs.TabPane tab="Single Sign-On (SSO)" key="identity-providers" data-test-id="identity-providers-tab" />
          </Tabs>
          <Switch>
            <Route path={`${path}/app-clients`} component={AppClients} />
            <Route path={`${path}/webhooks/new`} component={WebhooksForm} />
            <Route path={`${path}/webhooks/:webhookId`} component={WebhooksForm} />
            <Route path={`${path}/webhooks`} component={Webhooks} />
            <Route path={`${path}/external-users`} component={ExternalUsers} />
            <Route path={`${path}/identity-providers/new`} component={IdentityProvidersForm} />
            <Route path={`${path}/identity-providers/:providerName`} component={IdentityProvidersForm} />
            <Route path={`${path}/identity-providers`} component={IdentityProviders} />
            <Redirect from="/" to={`${baseUrl}/app-clients`} />
          </Switch>
        </IfHasClaim>
      </div>
    </div>
  );
});
