import { takeEvery, takeLatest, put, all, call } from 'redux-saga/effects';
import { AdminApi, AdminRoleApi, AccountApi, SubscriptionsApi, CommonApi } from '@pclocs/platform-sdk';
import { createApi, joinErrorMessages } from '../../api/sdk';
import { ShowNotificationModalAction, ShowNotificationMessageAction } from '../common/types';
import * as ActionTypes from '../actionTypes';
import { accountActions } from './reducer';
import { cancelAccountSuspension } from '../../api/account';

function* findPortalAdmins() {
  yield takeEvery(accountActions.findPortalAdmins.type, function*() {
    try {
      const aaf = createApi<AdminApi>('AdminApi');
      const response: ApiReturnType<typeof aaf.findMany> = yield call(aaf.findMany);
      yield put(accountActions.findPortalAdminsSuccess({ data: response.data }));
    } catch (e) {
      console.error(e);
      yield put(accountActions.findPortalAdminsError(e.response.data.message));
    }
  });
}

function* deletePortalAdmin() {
  yield takeEvery(accountActions.deletePortalAdmin.type, function*(
    action: ReturnType<typeof accountActions.deletePortalAdmin>
  ) {
    const user = action.payload.user;
    const adminNameOrEmail: string = `${user.name || user.email}`.trim();
    try {
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'loading', key: user.id, content: `Deleting admin ${adminNameOrEmail}.` }
      });
      const aaf = createApi<AdminApi>('AdminApi');
      const response: ApiReturnType<typeof aaf.deleteOne> = yield call(aaf.deleteOne, user.id);
      if (response.status !== 204) {
        throw Error('Failed to delete the admin.');
      }
      yield put(accountActions.deletePortalAdminSuccess());
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', key: user.id, content: `Admin ${adminNameOrEmail} deleted.` }
      });
      yield put(accountActions.findPortalAdmins());
    } catch (e) {
      console.error(e);
      yield put(accountActions.deletePortalAdminError());
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'error', key: user.id, content: `Failed to delete ${adminNameOrEmail}.` }
      });
    }
  });
}

function* findPortalAdmin() {
  yield takeEvery(accountActions.findPortalAdmin.type, function*(
    action: ReturnType<typeof accountActions.findPortalAdmin>
  ) {
    try {
      const aaf = createApi<AdminApi>('AdminApi');
      const response: ApiReturnType<typeof aaf.findOne> = yield call(aaf.findOne, action.payload.adminUserId);
      yield put(accountActions.findPortalAdminSuccess({ data: response.data }));
    } catch (err) {
      console.error(err.response);
      yield put(accountActions.portalAdminUnloadModal());
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: err.response.data.message }
      });
    }
  });
}

function* createPortalAdmin() {
  yield takeEvery(accountActions.createPortalAdmin.type, function*(
    action: ReturnType<typeof accountActions.createPortalAdmin>
  ) {
    try {
      const aaf = createApi<AdminApi>('AdminApi');
      yield call(aaf.createOne, action.payload.data);
      yield put(accountActions.findPortalAdmins());
      yield put(accountActions.portalAdminUnloadModal());
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: 'Admin created' }
      });
    } catch (err) {
      console.error(err.response);
      yield put(accountActions.createPortalAdminError({ messages: joinErrorMessages(err.response.data) }));
    }
  });
}

function* updatePortalAdmin() {
  yield takeEvery(accountActions.updatePortalAdmin.type, function*(
    action: ReturnType<typeof accountActions.updatePortalAdmin>
  ) {
    try {
      const aaf = createApi<AdminApi>('AdminApi');
      yield call(aaf.updateOne, action.payload.adminUserId, action.payload.data);
      yield put(accountActions.findPortalAdmins());
      yield put(accountActions.portalAdminUnloadModal());
    } catch (err) {
      console.error(err.response);
      yield put(accountActions.updatePortalAdminError({ messages: joinErrorMessages(err.response.data) }));
    }
  });
}

function* getUniquePin() {
  yield takeEvery(accountActions.getUniquePin.type, function*() {
    try {
      const aaf = createApi<AdminApi>('AdminApi');
      const response: ApiReturnType<typeof aaf.getUniquePin> = yield call(aaf.getUniquePin);
      yield put(accountActions.getUniquePinSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(accountActions.getUniquePinError({ message: e.response.data.message }));
    }
  });
}

function* getAccountInfo() {
  yield takeLatest(accountActions.getAccountInfo.type, function*() {
    try {
      const api = createApi<AccountApi>('AccountApi');
      const response: ApiReturnType<typeof api.getDefault> = yield call(api.getDefault);
      yield put(accountActions.getAccountInfoSuccess({ data: response.data }));
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: e.response.data.message }
      });
    }
  });
}

function* getSubscriptionInfo() {
  yield takeLatest(accountActions.getSubscriptionInfo.type, function*() {
    try {
      const api = createApi<SubscriptionsApi>('SubscriptionsApi');
      const response: ApiReturnType<typeof api.find> = yield call(api.find);
      yield put(accountActions.getSubscriptionInfoSuccess({ data: response.data }));
    } catch (e) {
      console.error(e);
      yield put(accountActions.getSubscriptionInfoError());
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: e.response.data.message }
      });
    }
  });
}

function* getAvailableFeatures() {
  yield takeLatest(accountActions.getFeatures.type, function*() {
    try {
      const api = createApi<CommonApi>('CommonApi');
      const response: ApiReturnType<typeof api.getFeatures> = yield call(api.getFeatures);
      yield put(accountActions.getFeaturesSuccess({ data: response.data }));
    } catch (e) {
      console.error(e);
      yield put(accountActions.getFeaturesError());
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: 'An error occurred retrieving account features' }
      });
    }
  });
}

function* findAdminRoles() {
  yield takeLatest(accountActions.findAdminRoles.type, function*() {
    try {
      const api = createApi<AdminRoleApi>('AdminRoleApi');
      const response: ApiReturnType<typeof api.findMany> = yield call(api.findMany);
      yield put(accountActions.findAdminRolesSuccess({ data: response.data }));
    } catch (e) {
      console.error(e);
      yield put(accountActions.findAdminRolesError());
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: 'An error occurred retrieving roles' }
      });
    }
  });
}

function* cancelAccountSuspensionRequest() {
  yield takeEvery(accountActions.cancelAccountSuspension.type, function*(
    action: ReturnType<typeof accountActions.cancelAccountSuspension>
  ) {
    try {
      yield call(cancelAccountSuspension, action.payload.accountId, { token: action.payload.token });
      yield put(accountActions.cancelAccountSuspensionSuccess());
    } catch (err) {
      console.error(err);
      yield put(accountActions.cancelAccountSuspensionError({ messages: joinErrorMessages(err.response.data) }));
    }
  });
}

function* getAdminClaims() {
  yield takeLatest(accountActions.getAdminClaims.type, function*(
    action: ReturnType<typeof accountActions.getAdminClaims>
  ) {
    try {
      const adminApi = createApi<AdminApi>('AdminApi');
      const response: ApiReturnType<typeof adminApi.getClaims> = yield call(adminApi.getClaims, action.payload);
      yield put(accountActions.getAdminClaimsSuccess(response));
    } catch (e) {
      console.error(e);
      yield put(accountActions.getAdminClaimsError());
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: 'An error occurred retrieving permissions' }
      });
    }
  });
}

export function* accountSaga() {
  yield all([
    findPortalAdmins(),
    findPortalAdmin(),
    createPortalAdmin(),
    updatePortalAdmin(),
    deletePortalAdmin(),
    getUniquePin(),
    getAccountInfo(),
    getSubscriptionInfo(),
    getAvailableFeatures(),
    findAdminRoles(),
    cancelAccountSuspensionRequest(),
    getAdminClaims()
  ]);
}
