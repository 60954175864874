import React from 'react';
import { Input, Form } from 'antd';
import { AttributesMap, ATTRIBUTES_GOOGLE_WORKSPACE } from './attributes-map';

type GoogleWorkspaceDirectoryConfigProps = {
  readonly?: boolean;
  isNewRecord?: boolean;
};

export const GoogleWorkspaceDirectoryConfig: React.FunctionComponent<GoogleWorkspaceDirectoryConfigProps> = props => {
  const { readonly, isNewRecord } = props;
  return (
    <>
      <Form.Item
        label="Identifier"
        name="origin"
        rules={[
          {
            pattern: /^[A-Z0-9\-\.]*$/gi,
            message: 'The identifier must only contain alphanumeric characters, dashes or dots.'
          },
          { max: 40, message: 'The identifier must not be more than 40 characters' },
          { required: true, message: 'An identifier for this external user directory is required.' }
        ]}>
        <Input disabled={!isNewRecord || readonly} placeholder="A name to identify this user directory" />
      </Form.Item>
      <Form.Item
        label="Customer ID"
        name="customerId"
        rules={[{ required: true, message: 'A Customer ID is required.' }]}>
        <Input type="text" disabled={readonly} />
      </Form.Item>
      <Form.Item
        label="Admin Email"
        name="clientSubject"
        rules={[
          { type: 'email', message: 'Not a valid email address!' },
          { required: true, message: 'An Admin Email address is required.' }
        ]}>
        <Input type="email" disabled={readonly} />
      </Form.Item>
      <AttributesMap readonly={readonly} attributes={ATTRIBUTES_GOOGLE_WORKSPACE} />
    </>
  );
};
