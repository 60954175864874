import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { getChangelogTag, setChangelogTag } from '../../helpers/local-storage-helper';

const docsOrigin =
  process.env.PCLOCS_ENV === 'development' || process.env.NODE_ENV === 'test'
    ? 'https://docs.dev.pclocs.io'
    : `${window.location.protocol}//docs.${window.location.hostname}`;

type Props = {
  userId: string;
  visible: boolean;
  showChangelogs: (visible: boolean) => void;
};

export const Changelogs = (props: Props) => {
  const [changelogsIFrameActive, setChangelogsIFrameActive] = useState<boolean>();
  const currentTag = useRef(getChangelogTag(props.userId));
  const isLatestTagChecked = useRef(false);
  const checkLatestTagRef = useRef(null);
  const changelogsRef = useRef(null);
  const [docsUrl, setDocsUrl] = useState<string>('');

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.origin !== docsOrigin || !event.data) {
        return;
      }

      if (isLatestTagChecked.current) {
        setChangelogsIFrameActive(true);
      } else {
        isLatestTagChecked.current = true;
        if (event.data > currentTag.current) {
          currentTag.current = event.data;
          props.showChangelogs(true);
        }
      }
    }
    window.addEventListener('message', handleMessage);

    const docsUrlNoCache = `${docsOrigin}?timestamp=${new Date().getTime()}`;
    setDocsUrl(docsUrlNoCache);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      <iframe
        ref={checkLatestTagRef}
        src={docsUrl}
        onLoad={() => checkLatestTagRef.current.contentWindow.postMessage(currentTag.current, docsUrl)}
        hidden
      />
      <Modal
        title="What's new"
        className="changelogs-modal"
        visible={props.visible}
        onCancel={() => {
          props.showChangelogs(false);
          setChangelogTag(props.userId, currentTag.current);
        }}
        maskClosable
        width={650}
        footer={[
          <Button key="view-all-updates" type="primary">
            <a href={`${docsOrigin}/changelogs`} target="_blank" rel="noopener noreferrer">
              View all updates
            </a>
          </Button>
        ]}>
        <iframe
          ref={changelogsRef}
          src={docsUrl}
          width="100%"
          height="400px"
          onLoad={() => changelogsRef.current.contentWindow.postMessage(getChangelogTag(props.userId), docsUrl)}
          hidden={!changelogsIFrameActive}
        />
      </Modal>
    </>
  );
};
