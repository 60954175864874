import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../actionTypes';
import {
  ExportReportAction,
  ExportReportErrorAction,
  ExportReportSuccessAction,
  GenerateReportAction,
  GenerateReportErrorAction,
  GenerateReportSuccessAction,
  HideExportReportModalAction
} from './types';
import { ShowNotificationModalAction, AddTriggeredBackgroundJob } from '../common/types';
import { createApi } from '../../api/sdk';
import { AdminUserContextDto, ReportsApi } from '@pclocs/platform-sdk/dist';
import _ from 'lodash';

function* generateReport() {
  yield takeLatest(ActionTypes.GENERATE_REPORT, function*(action: GenerateReportAction) {
    try {
      const api = createApi<ReportsApi>('ReportsApi');
      let response: ApiReturnType<unknown>;

      switch (action.payload.reportKeyFilter) {
        case 'current-status':
          response = yield call(
            api.queryCurrentStatusReport,
            _.startsWith(action.payload.groupOrStationFilter, 'G-') ? action.payload.groupOrStationFilter : undefined,
            _.startsWith(action.payload.groupOrStationFilter, 'S-') ? action.payload.groupOrStationFilter : undefined,
            undefined,
            undefined,
            action.payload.pageSize || 200
          );
          break;
        case 'current-user-reservations':
          response = yield call(
            api.queryCurrentUserBayReservationsReport,
            _.startsWith(action.payload.groupOrStationFilter, 'G-') ? action.payload.groupOrStationFilter : undefined,
            _.startsWith(action.payload.groupOrStationFilter, 'S-') ? action.payload.groupOrStationFilter : undefined,
            undefined,
            undefined,
            action.payload.pageSize || 200
          );
          break;
        case 'current-station-connectivity':
          response = yield call(
            api.queryCurrentStationConnectivityReport,
            _.startsWith(action.payload.groupOrStationFilter, 'G-') ? action.payload.groupOrStationFilter : undefined
          );
          break;
        case 'station-use':
          response = yield call(
            api.queryStationUseReport,
            action.payload.startTimeFilter,
            action.payload.endTimeFilter,
            _.startsWith(action.payload.groupOrStationFilter, 'G-') ? action.payload.groupOrStationFilter : undefined,
            _.startsWith(action.payload.groupOrStationFilter, 'S-') ? action.payload.groupOrStationFilter : undefined,
            undefined,
            undefined,
            action.payload.pageSize || 200
          );
          break;
        case 'station-user-use':
          response = yield call(
            api.queryStationUserUseReport,
            action.payload.startTimeFilter,
            action.payload.endTimeFilter,
            _.startsWith(action.payload.groupOrStationFilter, 'G-') ? action.payload.groupOrStationFilter : undefined,
            _.startsWith(action.payload.groupOrStationFilter, 'S-') ? action.payload.groupOrStationFilter : undefined,
            undefined,
            undefined,
            action.payload.pageSize || 200
          );
          break;
        case 'popular-usage-times':
          response = yield call(
            api.queryPopularUsageTimesReport,
            action.payload.startTimeFilter,
            action.payload.endTimeFilter,
            _.startsWith(action.payload.groupOrStationFilter, 'G-') ? action.payload.groupOrStationFilter : undefined,
            _.startsWith(action.payload.groupOrStationFilter, 'S-') ? action.payload.groupOrStationFilter : undefined,
            undefined,
            undefined,
            action.payload.pageSize || 200
          );
          break;
        default:
          throw Error('Report type not found!');
      }

      if (response.data.results?.length === 0) {
        yield put<ShowNotificationModalAction>({
          type: ActionTypes.SHOW_NOTIFICATION_MODAL,
          payload: { type: 'info', content: 'No report data found' }
        });
      }

      yield put<GenerateReportSuccessAction>({
        type: ActionTypes.GENERATE_REPORT_SUCCESS,
        payload: {
          data: response.data.results,
          nextPage: response.data.nextToken,
          executionId: response.data.executionId
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Could not generate report data'
        }
      });
      yield put<GenerateReportErrorAction>({
        type: ActionTypes.GENERATE_REPORT_ERROR,
        payload: { fetchError: 'Failed to fetch report data' }
      });
    }
  });
}

function* fetchNextReportPage() {
  yield takeLatest(ActionTypes.FETCH_NEXT_REPORT_PAGE, function*(action: GenerateReportAction) {
    try {
      const api = createApi<ReportsApi>('ReportsApi');
      let response: ApiReturnType<unknown>;

      switch (action.payload.reportKeyFilter) {
        case 'current-status':
          response = yield call(
            api.queryCurrentStatusReport,
            _.startsWith(action.payload.groupOrStationFilter, 'G-') ? action.payload.groupOrStationFilter : undefined,
            _.startsWith(action.payload.groupOrStationFilter, 'S-') ? action.payload.groupOrStationFilter : undefined,
            undefined,
            action.payload.nextPage,
            action.payload.pageSize || 200
          );
          break;
        case 'current-user-reservations':
          response = yield call(
            api.queryCurrentUserBayReservationsReport,
            _.startsWith(action.payload.groupOrStationFilter, 'G-') ? action.payload.groupOrStationFilter : undefined,
            _.startsWith(action.payload.groupOrStationFilter, 'S-') ? action.payload.groupOrStationFilter : undefined,
            undefined,
            action.payload.nextPage,
            action.payload.pageSize || 200
          );
          break;
        case 'station-use':
          response = yield call(
            api.queryStationUseReport,
            action.payload.startTimeFilter,
            action.payload.endTimeFilter,
            _.startsWith(action.payload.groupOrStationFilter, 'G-') ? action.payload.groupOrStationFilter : undefined,
            _.startsWith(action.payload.groupOrStationFilter, 'S-') ? action.payload.groupOrStationFilter : undefined,
            undefined,
            action.payload.nextPage,
            action.payload.pageSize || 200,
            action.payload.executionId
          );
          break;
        case 'station-user-use':
          response = yield call(
            api.queryStationUserUseReport,
            action.payload.startTimeFilter,
            action.payload.endTimeFilter,
            _.startsWith(action.payload.groupOrStationFilter, 'G-') ? action.payload.groupOrStationFilter : undefined,
            _.startsWith(action.payload.groupOrStationFilter, 'S-') ? action.payload.groupOrStationFilter : undefined,
            undefined,
            action.payload.nextPage,
            action.payload.pageSize || 200,
            action.payload.executionId
          );
          break;
        default:
          throw Error('Report type not found!');
      }

      yield put<GenerateReportSuccessAction>({
        type: ActionTypes.GENERATE_REPORT_SUCCESS,
        payload: {
          data: response.data.results,
          nextPage: response.data.nextToken,
          executionId: response.data.executionId
        }
      });
    } catch (e) {
      console.error(e);
      yield put<GenerateReportErrorAction>({
        type: ActionTypes.GENERATE_REPORT_ERROR,
        payload: { fetchError: 'Failed to fetch report data' }
      });
    }
  });
}

function* exportReport() {
  yield takeLatest(ActionTypes.EXPORT_REPORT, function*(action: ExportReportAction) {
    try {
      const api = createApi<ReportsApi>('ReportsApi');
      const { reportKeyFilter, groupOrStationFilter, startTimeFilter, endTimeFilter } = action.payload;
      const baseParams = {
        groupId: _.startsWith(groupOrStationFilter, 'G-') ? groupOrStationFilter : undefined,
        stationId: _.startsWith(groupOrStationFilter, 'S-') ? groupOrStationFilter : undefined
      };
      const dateRangeParams = {
        startTime: startTimeFilter,
        endTime: endTimeFilter,
        ...baseParams
      };
      let response: ApiReturnType<unknown>;

      switch (reportKeyFilter) {
        case 'current-status':
          response = yield call(api.exportCurrentStatusReport, baseParams);
          break;
        case 'current-user-reservations':
          response = yield call(api.exportCurrentUserReservationsReport, baseParams);
          break;
        case 'current-station-connectivity':
          response = yield call(api.exportCurrentStationConnectivityReport, { groupId: baseParams.groupId });
          break;
        case 'station-use':
          response = yield call(api.exportStationUseReport, dateRangeParams);
          break;
        case 'station-user-use':
          response = yield call(api.exportStationUserUseReport, dateRangeParams);
          break;
        default:
          throw Error('Report type not found!');
      }

      const currentUser: AdminUserContextDto = yield select(state => state.auth.currentUser);
      if (currentUser?.email) {
        yield put<ExportReportSuccessAction>({ type: ActionTypes.EXPORT_REPORT_SUCCESS });
      } else {
        yield put<AddTriggeredBackgroundJob>({
          type: ActionTypes.ADD_TRIGGERED_BACKGROUND_JOB,
          payload: {
            job: {
              id: response.data.backgroundJobStatusId,
              jobName: 'CustomerReportsExportJob'
            }
          }
        });
        yield put<HideExportReportModalAction>({ type: ActionTypes.HIDE_EXPORT_REPORT_MODAL });
      }
    } catch (e) {
      console.error(e);
      const exportError = 'Failed to export report data';
      yield put<ExportReportErrorAction>({
        type: ActionTypes.EXPORT_REPORT_ERROR,
        payload: { exportError }
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: exportError }
      });
    }
  });
}

export function* reportsSaga() {
  yield all([generateReport(), fetchNextReportPage(), exportReport()]);
}
