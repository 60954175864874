import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { dashboard } from './dashboard/reducer';
import { integrations } from './integrations/reducer';
import { auth } from './auth/reducer';
import { register } from './register/reducer';
import { common } from './common/reducer';
import { accountReducer as account } from './account/reducer';
import { stationUser } from './users/reducer';
import { eventLogs } from './event-logs/reducer';
import { reports } from './reports/reducer';
import { websocketReducer as websocket } from './websocket/reducer';
import { degradationStatus } from './degradation-status/reducer';

export const reducer = combineReducers({
  common,
  dashboard,
  integrations,
  auth,
  register,
  account,
  stationUser,
  eventLogs,
  reports,
  websocket,
  degradationStatus,
  router: routerReducer
});

export type RootState = ReturnType<typeof reducer>;
