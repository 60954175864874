import React from 'react';
import { Link } from 'react-router-dom';
import { ReportColumn } from '../../constants/reports';
import _ from 'lodash';

interface ReportItem<T extends Record<string, string>> {
  accountId: string;
  columns: ReportColumn[] | undefined;
  item: T;
}

interface ReportLink<T extends Record<string, string>> {
  accountId: string;
  column: ReportColumn;
  item: T;
}

const stationIdFromBayId = (id: string) =>
  id
    .split('_')
    .slice(0, -1)
    .join('_');

const parseBayNumber = (id: string) => parseInt(_.get(id.match(/\d+$/g), '0', undefined), 10);

function ReportItemLink<T extends Record<string, string>>({ accountId, column, item }: ReportLink<T>): JSX.Element {
  const link = (subUrl: string) => (
    <Link to={`/auth/${accountId}/${subUrl}`} target="_blank" rel="noopener noreferrer">
      {item[column.dataKey]}
    </Link>
  );
  const linkId = _.get(column, 'linkId');

  switch (linkId) {
    case 'groupId':
      return link(`dashboard/groups/${item[linkId]}/overview`);
    case 'stationId':
      return link(`dashboard/stations/${item[linkId]}/overview`);
    case 'bayId':
      return link(`dashboard/stations/${stationIdFromBayId(item[linkId])}/bays/${item[linkId]}/overview`);
    default:
      if (typeof item[column.dataKey] === 'string') {
        if (column.dataKey === 'bayId') {
          return <>{parseBayNumber(item.bayId) || ''}</>;
        } else if (column.dataKey === 'userName') {
          return <>{item.userName}</>;
        }
        return <>{_.capitalize(item[column.dataKey].replace(/_/g, ' '))}</>;
      }
      return <>{item[column.dataKey]?.toString() ?? ''}</>;
  }
}

export function ReportListItem<T extends Record<string, string>>({
  accountId,
  columns,
  item
}: ReportItem<T>): JSX.Element {
  return (
    <div className="report-list-row list-row">
      {columns?.map((val, key) => (
        <div
          key={key}
          className={`report-detail${val.align ? ` alignment-${val.align}` : ''}`}
          style={{ width: val.width }}>
          <span title={item[val.dataKey]}>
            <ReportItemLink accountId={accountId} column={val} item={item} />
          </span>
        </div>
      ))}
    </div>
  );
}
