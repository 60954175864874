import { useEffect, useState } from 'react';
import { unauthPath } from './index';
import { refreshToken } from '../../api/amplify';
import { exhaustiveDiscrimination } from '../../typescript-utils';
import { AuthTokens, getAuthTokens, getTokenExpiryInMs } from '../../helpers/auth-token-helper';
import { clearGlassUserToken } from '../../helpers/local-storage-helper';

export const useSessionExpiryHook = (): [boolean, () => void] => {
  const [expired, setExpired] = useState<boolean>(false);
  const [checkPaused, setCheckPaused] = useState<boolean>(false);

  const isAuthPath = !unauthPath();

  useEffect(() => {
    setCheckPaused(false);
  }, [isAuthPath]);

  const runCheckInterval = isAuthPath && !expired && !checkPaused;

  useEffect(() => {
    if (!runCheckInterval) {
      return;
    }

    const sessionInterval = setInterval(async () => {
      try {
        const currentTime = Date.now();
        const authTokens = await getAuthTokens();
        if (!authTokens) {
          throw Error('No active session.');
        }

        switch (authTokens.type) {
          case 'glass':
            if (currentTime >= getTokenExpiryInMs(authTokens.idToken)) {
              setExpired(true);
              clearGlassUserToken();
            }
            break;
          case 'cognito':
            const MS_BEFORE_TOKENS_EXPIRE = 10000;
            const idTokenExpiry = getTokenExpiryInMs(authTokens.idToken) - MS_BEFORE_TOKENS_EXPIRE;
            const accessTokenExpiry = getTokenExpiryInMs(authTokens.accessToken) - MS_BEFORE_TOKENS_EXPIRE;
            if (currentTime >= idTokenExpiry || currentTime >= accessTokenExpiry) {
              await refreshToken(authTokens.cognitoRefreshToken);
            }
            setExpired(false);
            break;
          default:
            exhaustiveDiscrimination(authTokens, (authType: AuthTokens) => {
              throw Error(`Unsupported auth token type ${authType.type}`);
            });
        }
      } catch {
        setExpired(true);
      }
    }, 1000);

    return () => {
      clearInterval(sessionInterval);
    };
  }, [runCheckInterval]);

  return [expired, () => setCheckPaused(true)];
};
