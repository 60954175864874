import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Button, Spin } from 'antd';
import { RootState } from '../../store/rootReducer';
import * as ActionTypes from '../../store/actionTypes';
import { accountActions, AccountActionTypes } from '../../store/account/reducer';
import { CommonActionTypes } from '../../store/common/types';
import { Logo } from '../Logo';
import { usePrevious } from '../../helpers/use-previous-hook';
import { getErrorAlertMessage } from '../../helpers/form-helper';

const mapStateToProps = (state: RootState) => ({
  isCancelAccountSuspensionLoading: state.account.isCancelAccountSuspensionLoading,
  cancelAccountSuspensionMessages: state.account.cancelAccountSuspensionMessages
});
const mapDispatchToProps = (dispatch: Dispatch<AccountActionTypes | CommonActionTypes>) => ({
  redirectToLogin: () => dispatch({ type: ActionTypes.REDIRECT, redirectTo: '/login' }),
  cancelAccountSuspensionRequest: (accountId: string, token: string) =>
    dispatch({ type: accountActions.cancelAccountSuspension.type, payload: { accountId, token } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const CancelSuspension = connector((props: Props) => {
  const [accountId, setAccountId] = useState<string>();
  const [token, setToken] = useState<string>();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const prevLoading = usePrevious(props.isCancelAccountSuspensionLoading);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const payloadAccountId = params.get('accountId');
    const payloadToken = params.get('token');

    if (payloadAccountId && payloadToken) {
      setAccountId(payloadAccountId);
      setToken(payloadToken);
    } else {
      props.redirectToLogin();
    }
  }, []);

  useEffect(() => {
    if (prevLoading && !props.isCancelAccountSuspensionLoading && !props.cancelAccountSuspensionMessages) {
      setShowSuccess(true);
    }
  }, [props.isCancelAccountSuspensionLoading, props.cancelAccountSuspensionMessages]);

  const handleSubmit = () => props.cancelAccountSuspensionRequest(accountId, token);

  return (
    <div className="cancel-suspension-section">
      <Link to="/login">
        <Logo />
      </Link>
      {showSuccess ? (
        <>
          <span className="success-title">Success!</span>
          <span className="success-description">
            Your account is no longer suspended and has been successfully restored.
          </span>
          <Button type="primary" className="login-button" style={{ marginTop: 15 }}>
            <Link to="/login">Go to login page</Link>
          </Button>
        </>
      ) : (
        <>
          <Alert
            message="Account suspension cancellation"
            description="Cancelling the suspension will restore your account to its previous state. Click the button 
            below to confirm your action."
            type="info"
            showIcon
            style={{ marginBottom: 24 }}
          />

          {props.cancelAccountSuspensionMessages && getErrorAlertMessage(props.cancelAccountSuspensionMessages)}

          <Spin spinning={props.isCancelAccountSuspensionLoading}>
            <Button block type="primary" htmlType="submit" onClick={handleSubmit}>
              Cancel suspension
            </Button>
          </Spin>
        </>
      )}
    </div>
  );
});
