import { Alert } from 'antd';
import React from 'react';

const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();

export const FetchFailedAlert = ({ statusCode, itemType }: { statusCode: number; itemType: string }) => {
  const message =
    statusCode === 404
      ? `${capitalizeFirstLetter(itemType)} not found`
      : `Unable to load ${itemType.toLocaleLowerCase()}`;
  return <Alert type="error" message={message} />;
};
