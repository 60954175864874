import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Table, Button, Alert, Popconfirm } from 'antd';
import { DeleteFilled, PartitionOutlined, PlusCircleFilled } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { history } from '../../../store/store';
import * as ActionTypes from '../../../store/actionTypes';
import { RootState } from '../../../store/rootReducer';
import { subscriptionMessage } from '../../../constants/validations';
import { GenericIdentityProviderResponseDto } from '@pclocs/platform-sdk';
import { FeatureId, useFeatureAvailability } from '../../../helpers/feature-availability-hooks';
import { useLatestSdkCall } from '../../../helpers/sdk-hooks';
import {
  CommonActionTypes,
  ShowNotificationMessageAction,
  ShowNotificationModalAction
} from '../../../store/common/types';
import { useWatchBackgroundJobs } from '../../../helpers/watch-background-job-helper';
import { CopyToClipboard } from '../../CopyToClipboard';

const mapStateToProps = (state: RootState) => ({
  accountId: state.auth.accountContext,
  subscriptionInfo: state.account.subscriptionInfo
});

const mapDispatchToProps = (
  dispatch: Dispatch<ShowNotificationMessageAction | ShowNotificationModalAction | CommonActionTypes>
) => ({
  showSuccessNotification: (content: string): void =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload: { type: 'success', content }
    }),
  addTriggeredJob: (id: string, jobName: string) =>
    dispatch({
      type: ActionTypes.ADD_TRIGGERED_BACKGROUND_JOB,
      payload: { job: { id, jobName } }
    }),
  showErrorNotification: (title: string, content: string): void =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MODAL,
      payload: { type: 'error', title, content }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const IdentityProviders = connector((props: Props) => {
  const [hasFeature] = useFeatureAvailability();
  const hasSsoFeature = hasFeature(FeatureId.SSOIDENTITYPROVIDERS);
  const [identityProviders, setIdentityProviders] = useState<GenericIdentityProviderResponseDto[]>([]);
  const [providerToTest, setProviderToTest] = useState<string>();
  const identityProviderPath = `/auth/${props.accountId}/integrations/identity-providers`;

  const [fetchIdentityProviders, fetchingIdentityProviders] = useLatestSdkCall(
    'IdentityProviderApi',
    'getIdentityProviders',
    ({ data }) => setIdentityProviders(data),
    () => props.showErrorNotification('Error', 'Could not get SSO providers.')
  );

  const [deleteIdentityProvider, deleteIdentityProviderLoading] = useLatestSdkCall(
    'IdentityProviderApi',
    'deleteIdentityProvider',
    ({ data }) => {
      props.addTriggeredJob(data.backgroundJobStatusId, 'DeleteIdentityProviderJob');
    },
    () => props.showErrorNotification('Error', `Could not delete SSO provider.`)
  );

  const [testProviderConnection, testingProviderConnection] = useLatestSdkCall(
    'IdentityProviderApi',
    'testProviderConnection',
    ({ data }) => {
      if (data.connected) {
        props.showSuccessNotification(`SSO provider '${providerToTest}' is connected.`);
      } else {
        props.showErrorNotification(
          'Connection failed',
          `Please check your configuration for '${providerToTest}' and try again.`
        );
      }
    },
    () => props.showErrorNotification('Error', `Could not test connection for '${providerToTest}'.`)
  );

  const editIdentityProvider = (providerName: string) => {
    history.push(`${identityProviderPath}/${providerName}`);
  };

  const backgroundJobs = useWatchBackgroundJobs();
  const isDeletingIdentityProvider =
    deleteIdentityProviderLoading ||
    !!backgroundJobs.find(job => job.jobName === 'DeleteIdentityProviderJob' && !('dateCompleted' in job));

  useEffect(() => {
    fetchIdentityProviders();
  }, [isDeletingIdentityProvider]);

  const tableColumns: ColumnProps<GenericIdentityProviderResponseDto>[] = [
    {
      key: 'providerName',
      title: 'Name',
      width: '25%',
      ellipsis: true,
      render: (identityProvider: GenericIdentityProviderResponseDto) => (
        <div
          className="link"
          onClick={() => !isDeletingIdentityProvider && editIdentityProvider(identityProvider.providerName)}>
          {identityProvider.providerName}
        </div>
      )
    },
    {
      key: 'SSOUrl',
      title: 'SSO URL',
      width: '50%',
      ellipsis: true,
      render: (identityProvider: GenericIdentityProviderResponseDto) => {
        const SSO_URL = `${window.location.origin}/login/sso-sign-in?provider=${identityProvider.providerName}`;

        return (
          <div className="url">
            <pre style={{ marginBottom: 0 }}>
              <CopyToClipboard data={SSO_URL} title="Copy SSO URL to clipboard" />
              &nbsp;{SSO_URL}
            </pre>
          </div>
        );
      }
    },
    {
      key: 'providerType',
      title: 'Type',
      width: '25%',
      ellipsis: true,
      render: (identityProvider: GenericIdentityProviderResponseDto) => (
        <div
          className="link"
          onClick={() => !isDeletingIdentityProvider && editIdentityProvider(identityProvider.providerName)}>
          {identityProvider.providerType}
        </div>
      )
    },
    {
      align: 'right',
      width: 170,
      fixed: 'right',
      render: (identityProvider: GenericIdentityProviderResponseDto) => (
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
          <Popconfirm
            onConfirm={() => {
              setProviderToTest(identityProvider.providerName);
              testProviderConnection(identityProvider.providerName);
            }}
            disabled={!hasSsoFeature || isDeletingIdentityProvider}
            placement="left"
            title="Test SSO provider connection?"
            okText="Yes"
            cancelText="No">
            <Button
              size="small"
              style={{ marginRight: 10 }}
              className={
                !hasSsoFeature || isDeletingIdentityProvider
                  ? 'btn-default'
                  : 'btn-primary test-provider-connection-btn'
              }
              disabled={!hasSsoFeature || isDeletingIdentityProvider}>
              <PartitionOutlined />
              Test
            </Button>
          </Popconfirm>
          <Popconfirm
            disabled={isDeletingIdentityProvider}
            onConfirm={() => deleteIdentityProvider(identityProvider.providerName)}
            placement="left"
            title="Delete SSO provider?"
            okText="Yes"
            cancelText="No">
            <Button
              size="small"
              className={isDeletingIdentityProvider ? 'btn-default' : 'btn-danger delete-provider-btn'}
              disabled={isDeletingIdentityProvider}>
              <DeleteFilled />
              Delete
            </Button>
          </Popconfirm>
        </div>
      )
    }
  ];

  const pending = fetchingIdentityProviders || testingProviderConnection;

  return (
    <div className="content-section">
      <div className="content-section-inner">
        {!hasSsoFeature && <Alert style={{ marginBottom: 16 }} type="error" message={subscriptionMessage} />}
        {isDeletingIdentityProvider && (
          <Alert
            showIcon
            message="Adding or editing SSO providers is temporarily disabled"
            description="SSO provider deletion is in progress. Once completed, adding or editing SSO providers will become available again."
            type="info"
            style={{ marginBottom: 16 }}
          />
        )}

        <Button
          onClick={() => history.push(`${identityProviderPath}/new`)}
          className="btn-primary add-provider-btn"
          style={{ marginBottom: 16 }}
          disabled={pending || !hasSsoFeature || isDeletingIdentityProvider}>
          <PlusCircleFilled />
          New SSO Provider
        </Button>

        <Table
          className="no-hover"
          pagination={false}
          rowKey={record => record.providerName}
          showHeader={true}
          dataSource={identityProviders}
          columns={tableColumns}
          loading={pending}
          size="small"
        />
      </div>
    </div>
  );
});
