import { Alert, Col, Modal, Row, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSdkCallOnChanges } from '../../helpers/sdk-hooks';

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});
const connector = connect(mapStateToProps, mapDispatchToProps);

interface Props {
  userId: string;
  onClose: () => void;
}

export const ViewStationUserModal = connector((props: Props) => {
  const { accountId } = useParams<{ accountId: string }>();
  const [loading, data, error] = useSdkCallOnChanges('StationUserApi', 'findOne', props.userId);
  const [bayAssignmentsLoading, bayAssignments, bayAssignmentsError] = useSdkCallOnChanges(
    'StationUserInternalApi',
    'findBayAssignments',
    props.userId
  );

  return (
    <Modal title="View User" visible={true} onCancel={props.onClose} footer={false}>
      {(() => {
        if (loading) {
          return <Skeleton active={true} />;
        } else if (error) {
          return <Alert type="error" message="Failed to load user information." />;
        }
        return (
          <>
            <Row gutter={16} style={{ marginBottom: 10 }}>
              <Col span={6} style={{ textAlign: 'right', fontWeight: 600 }}>
                Type:
              </Col>
              <Col span={18}>{data.type === 'internal' ? 'Internal' : 'External'}</Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: 10 }}>
              <Col span={6} style={{ textAlign: 'right', fontWeight: 600 }}>
                Name:
              </Col>
              <Col span={18}>{data.name}</Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: 10 }}>
              <Col span={6} style={{ textAlign: 'right', fontWeight: 600 }}>
                Assignments:
              </Col>
              <Col span={18}>
                {(() => {
                  if (bayAssignmentsLoading) {
                    return <LoadingOutlined spin />;
                  } else if (bayAssignmentsError) {
                    return 'Failed to load assignments';
                  } else if (bayAssignments.length === 0) {
                    return 'No assignments';
                  }
                  return bayAssignments.map(info => (
                    <div key={info.bayId}>
                      <Link
                        to={`/auth/${accountId}/dashboard/stations/${info.stationId}/bays/${info.bayId}/overview`}
                        target="_blank"
                        rel="noopener noreferrer">
                        {info.stationName} - Bay {info.bayNumber}
                      </Link>
                    </div>
                  ));
                })()}
              </Col>
            </Row>
          </>
        );
      })()}
    </Modal>
  );
});
