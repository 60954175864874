import * as ActionTypes from '../actionTypes';
import { CommonActionTypes, CommonState, ItemState, UserState, UserGroupState } from './types';
import _ from 'lodash';
import {
  InternalStationUserResponseDto,
  StationUserGroupResponseDto,
  StationUserResponseDto
} from '@pclocs/platform-sdk';

export const NUM_ITEMS_TO_STAGE: number = 15;

const defaultItemState: UserState & UserGroupState = {
  items: [],
  isLoading: false,
  unknownIds: []
};

const defaultState: CommonState = {
  sideMenuSelectedKey: '',
  unauthorizedModal: null,
  notificationModal: null,
  notificationMessage: null,
  authorizedEntities: undefined,
  isManageUsersLoading: false,
  tableUsers: defaultItemState as UserState,
  tableUserGroups: defaultItemState as UserGroupState,
  backgroundJobWatchers: 0,
  triggeredBackgroundJobs: []
};

export const common = (state: CommonState = defaultState, action: CommonActionTypes): CommonState => {
  switch (action.type) {
    case ActionTypes.REDIRECT:
      return { ...state, redirectTo: action.redirectTo };
    case ActionTypes.DASHBOARD_PAGE_LOADED:
      return {
        ...state,
        sideMenuSelectedKey: 'dashboard'
      };
    case ActionTypes.DASHBOARD_PAGE_UNLOADED:
    case ActionTypes.INTEGRATIONS_PAGE_UNLOADED:
    case ActionTypes.USERS_PAGE_UNLOADED:
    case ActionTypes.EVENT_LOGS_PAGE_UNLOADED:
    case ActionTypes.REPORTS_PAGE_UNLOADED:
    case ActionTypes.UI_KIT_PAGE_UNLOADED:
    case ActionTypes.USER_GROUPS_PAGE_UNLOADED:
      return {
        ...state,
        sideMenuSelectedKey: ''
      };
    case ActionTypes.INTEGRATIONS_PAGE_LOADED:
      return {
        ...state,
        sideMenuSelectedKey: 'integrations'
      };
    case ActionTypes.USERS_PAGE_LOADED:
      return {
        ...state,
        sideMenuSelectedKey: 'users'
      };
    case ActionTypes.SHOW_UNAUTHORIZED_MODAL:
      return {
        ...state,
        unauthorizedModal: action.payload
      };
    case ActionTypes.SHOW_NOTIFICATION_MODAL:
      return {
        ...state,
        notificationModal: action.payload
      };
    case ActionTypes.DISMISS_NOTIFICATION_MODAL:
      return {
        ...state,
        notificationModal: null
      };
    case ActionTypes.SHOW_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload
      };
    case ActionTypes.EVENT_LOGS_PAGE_LOADED:
      return {
        ...state,
        sideMenuSelectedKey: 'event-logs'
      };
    case ActionTypes.REPORTS_PAGE_LOADED:
      return {
        ...state,
        sideMenuSelectedKey: 'reports'
      };
    case ActionTypes.UI_KIT_PAGE_LOADED:
      return {
        ...state,
        sideMenuSelectedKey: 'ui-kit'
      };
    case ActionTypes.FETCH_GLOBAL_TAGS_SUCCESS:
      return {
        ...state,
        globalTags: action.payload.data
      };
    case ActionTypes.USER_GROUPS_PAGE_LOADED:
      return {
        ...state,
        sideMenuSelectedKey: 'user-groups'
      };
    case ActionTypes.UNLOAD_MANAGE_USERS_TABLE:
      return {
        ...state,
        authorizedEntities: undefined,
        tableUsers: defaultItemState,
        tableUserGroups: defaultItemState
      };
    case ActionTypes.FETCH_AUTHORIZED_IN_BAY:
    case ActionTypes.FETCH_AUTHORIZED_IN_STATION:
    case ActionTypes.FETCH_AUTHORIZED_IN_GROUP:
    case ActionTypes.UPDATE_AUTHORIZED_IN_BAY:
    case ActionTypes.UPDATE_AUTHORIZED_IN_STATION:
    case ActionTypes.UPDATE_AUTHORIZED_IN_GROUP:
      return {
        ...state,
        isManageUsersLoading: true
      };
    case ActionTypes.FETCH_AUTHORIZED_IN_BAY_SUCCESS:
    case ActionTypes.FETCH_AUTHORIZED_IN_STATION_SUCCESS:
    case ActionTypes.FETCH_AUTHORIZED_IN_GROUP_SUCCESS:
      return {
        ...state,
        derivedFrom: action.payload.data.derivedFrom,
        authorizedEntities: action.payload.data.authorizedEntities,
        isManageUsersLoading: false
      };
    case ActionTypes.REMOVE_ALL_AUTHORIZED_IN_BAY:
    case ActionTypes.REMOVE_ALL_AUTHORIZED_IN_STATION:
    case ActionTypes.REMOVE_ALL_AUTHORIZED_IN_GROUP:
    case ActionTypes.UPDATE_AUTHORIZED_IN_BAY_SUCCESS:
    case ActionTypes.UPDATE_AUTHORIZED_IN_STATION_SUCCESS:
    case ActionTypes.UPDATE_AUTHORIZED_IN_GROUP_SUCCESS:
      return {
        ...state,
        authorizedEntities: undefined,
        tableUsers: { ...state.tableUsers, ..._.omit(defaultItemState, 'filter') },
        tableUserGroups: { ...state.tableUserGroups, ..._.omit(defaultItemState, 'filter') },
        isManageUsersLoading: false
      };
    case ActionTypes.UPDATE_MANAGE_AUTHORIZED_ERROR:
      return {
        ...state,
        isManageUsersLoading: false
      };

    case ActionTypes.FETCH_AUTH_USERS_IN_MANAGE_TABLE:
      return {
        ...state,
        tableUsers: {
          ...state.tableUsers,
          ...setAuthFetchState(state.tableUsers)
        }
      };
    case ActionTypes.FETCH_AUTH_USERS_IN_MANAGE_TABLE_SUCCESS:
      return {
        ...state,
        tableUsers: {
          ...state.tableUsers,
          ...setItemStateUpdate(
            state.tableUsers,
            action.payload.data as StationUserResponseDto[],
            action.payload.idsNotInResponse
          )
        }
      };
    case ActionTypes.FETCH_AUTH_USERS_IN_MANAGE_TABLE_ERROR:
      return {
        ...state,
        tableUsers: {
          ...state.tableUsers,
          isLoading: false,
          fetchError: action.payload.fetchError
        }
      };
    case ActionTypes.FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE:
      return {
        ...state,
        tableUserGroups: {
          ...state.tableUserGroups,
          ...setAuthFetchState(state.tableUserGroups)
        }
      };
    case ActionTypes.FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE_SUCCESS:
      return {
        ...state,
        tableUserGroups: {
          ...state.tableUserGroups,
          ...setItemStateUpdate(
            state.tableUserGroups,
            action.payload.data as StationUserGroupResponseDto[],
            action.payload.idsNotInResponse
          )
        }
      };
    case ActionTypes.FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE_ERROR:
      return {
        ...state,
        tableUserGroups: {
          ...state.tableUserGroups,
          isLoading: false,
          fetchError: action.payload.fetchError
        }
      };

    case ActionTypes.WATCH_BACKGROUND_JOBS:
      return {
        ...state,
        backgroundJobWatchers: state.backgroundJobWatchers + 1
      };
    case ActionTypes.UNWATCH_BACKGROUND_JOBS:
      return {
        ...state,
        backgroundJobWatchers: Math.max(state.backgroundJobWatchers - 1, 0)
      };
    case ActionTypes.UPDATE_BACKGROUND_JOBS:
      if (action.payload.jobs === undefined) {
        return { ...state, backgroundJobs: undefined };
      } else {
        const updateIds = action.payload.jobs.map(j => j.id);
        return {
          ...state,
          backgroundJobs: [
            ...(state.backgroundJobs || []).filter(j => !updateIds.includes(j.id)),
            ...action.payload.jobs
          ]
        };
      }
    case ActionTypes.ADD_TRIGGERED_BACKGROUND_JOB:
      return {
        ...state,
        triggeredBackgroundJobs: [...state.triggeredBackgroundJobs, action.payload.job]
      };
    case ActionTypes.REMOVE_TRIGGERED_BACKGROUND_JOB:
      return {
        ...state,
        triggeredBackgroundJobs: state.triggeredBackgroundJobs.filter(v => v.id !== action.payload.jobId)
      };
    case ActionTypes.UPDATE_TRIGGERED_BACKGROUND_JOBS:
      return {
        ...state,
        triggeredBackgroundJobs: action.payload.jobs
      };
    case ActionTypes.SDK_CALL_ACTION_SUCCESS:
    case ActionTypes.SDK_CALL_ACTION_ERROR:
      return {
        ...state,
        apiResponse: action.payload
      };
    case ActionTypes.SET_GROUP_HIERARCHY:
      return { ...state, groupHierarchy: action.payload };
    default:
      return state;
  }
};

function setAuthFetchState<T extends UserState | UserGroupState>(state: T): Partial<ItemState> {
  const { items } = state;
  return {
    ...(!items.length && { isLoading: true }),
    fetchError: undefined
  };
}

function setItemStateUpdate<
  X extends ItemState & { items: T[] },
  T extends InternalStationUserResponseDto | StationUserGroupResponseDto
>(state: X, data: T[], idsNotInResponse: string[]) {
  const updatedItems = [...state.items, ...data];
  const updatedUnknownIds = [...state.unknownIds, ...idsNotInResponse];
  return {
    items: _.uniqBy(updatedItems, 'id'),
    unknownIds: updatedUnknownIds,
    isLoading: false
  };
}
