import { ItemState } from '../../../store/common/types';

export function addUnknownItems<TItem extends { id: string }, TItemState extends ItemState & { items: TItem[] }>(
  itemsState: TItemState,
  unknownIdToItem: (id: string) => TItem
): typeof itemsState {
  return {
    ...itemsState,
    items:
      itemsState.isLoading || itemsState.fetchError
        ? []
        : itemsState.items.concat(itemsState.unknownIds.map(unknownIdToItem))
  };
}
