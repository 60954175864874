import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { ColProps } from 'antd/lib/grid';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

export interface Props {
  title: string;
  wrapperCol?: ColProps;
  children: React.ReactNode;
  startShown?: boolean;
}

export const CollapsibleFormSection = (props: Props) => {
  const [expanded, setExpanded] = useState<boolean>(props.startShown ?? false);

  return (
    <>
      <Row>
        <Col {...props.wrapperCol} style={{ marginBottom: 15 }}>
          <span style={{ cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
            {expanded ? <DownOutlined /> : <RightOutlined />}
            &nbsp;{props.title}
          </span>
        </Col>
      </Row>
      <section style={{ display: expanded ? 'block' : 'none' }}>{props.children}</section>
    </>
  );
};
