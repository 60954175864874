import React, { useEffect } from 'react';
import { FolderOpenOutlined, FolderOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_GROUP_HIERARCHY } from '../store/actionTypes';
import { GroupHierarchy } from '../store/common/types';
import { RootState } from '../store/rootReducer';
import _ from 'lodash';
import { AntTreeNodeProps, DataNode } from 'antd/lib/tree';
import { TreeGroupNodeDto } from '@pclocs/platform-sdk';

type HierarchyTree<G> = Record<string, G>;
type AddSubGroups<T> = T & { groups: { [id: string]: AddSubGroups<T> } };

export const useGroupHierarchy = (): GroupHierarchy => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: FETCH_GROUP_HIERARCHY });
  }, []);
  return useSelector((state: RootState) => state.common.groupHierarchy);
};

function groupHierarchyToArrayFn<T extends object>(tree: HierarchyTree<AddSubGroups<T>>): T[] {
  const groupList: T[] = [];
  function explore(subTree: HierarchyTree<AddSubGroups<T>>) {
    for (const group of Object.values(subTree ?? {})) {
      groupList.push(_.omit(group, ['groups']) as T, ...groupHierarchyToArrayFn(group.groups));
    }
  }
  explore(tree);
  return groupList;
}
export const groupHierarchyToArray = _.memoize(groupHierarchyToArrayFn);

export const createTreeNodes = <T extends { name: string; id: string }>(
  tree: HierarchyTree<AddSubGroups<T>>,
  nodeProps?: (n: T) => Partial<AntTreeNodeProps>
): DataNode[] => {
  return Object.values(tree)
    .sort((a, b) => a.name.localeCompare(b.name) || a.id.localeCompare(b.id))
    .map(group => {
      const extraProps = nodeProps?.(group) ?? {};
      const children = (extraProps.children ?? []) as DataNode[];
      const showSwitcherIcon = ({ expanded }: { expanded: boolean }) => {
        const hasSubNodes = Object.keys(group.groups).length + children.length > 0;
        return hasSubNodes && expanded ? <FolderOpenOutlined /> : <FolderOutlined />;
      };
      return {
        title: group.name,
        key: group.id,
        switcherIcon: showSwitcherIcon,
        children: [...createTreeNodes(group.groups, nodeProps), ...children],
        ..._.omit(extraProps, 'children')
      };
    });
};

export function findGroupNode<T extends object>(tree: HierarchyTree<AddSubGroups<T>>, groupId: string): T {
  if (tree === undefined || groupId === undefined) {
    return;
  }
  if (tree[groupId]) {
    return tree[groupId];
  }
  for (const node of Object.values(tree)) {
    const group = findGroupNode(node.groups, groupId);
    if (group) {
      return group;
    }
  }
}

export const filterGroupNode = (node: TreeGroupNodeDto, searchTerm: string): TreeGroupNodeDto => {
  if (node.name.toLocaleLowerCase().match(searchTerm)) {
    return node;
  }
  const filteredGroups = _(node.groups)
    .mapValues(group => filterGroupNode(group, searchTerm))
    .pickBy(v => v !== undefined)
    .value();

  return _.isEmpty(filteredGroups) ? undefined : { ...node, groups: filteredGroups };
};
