import React, { useEffect, useState } from 'react';
import { Button, Modal, Tabs, Alert } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { CheckCircleOutlined, CloseCircleOutlined, WifiOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { OverrideButton } from '../../Buttons/Override';
import { NetworkSettingsDto, NetworkWpaSupplicantConfigDto } from '@pclocs/platform-sdk';
import { InterfaceSettings } from './interface-settings';
import { WpaSupplicantSettings } from './wpa-supplicant-settings';
import _ from 'lodash';
import { toCidrIp, encryptPsk } from '../../../helpers/form-helper';
import { RootState } from '../../../store/rootReducer';

type Props = {
  showNetworkModal: boolean;
  networkSettingsPending: boolean;
  type: 'group' | 'station';
  entity: NonNullable<RootState['dashboard']['station'] | RootState['dashboard']['group']>;
  disabled?: boolean;
  toggleNetworkModal: (show: boolean) => void;
  updateNetworkSettings: (networkSettings: NetworkSettingsDto | null) => void;
} & FormComponentProps;

type NetworkTabLabelProps = {
  reported: NonNullable<RootState['dashboard']['station']>['reported'];
  label: string;
  iface: string;
};
const NetworkTabLabel = (props: NetworkTabLabelProps) => {
  const stationIsConnected = props.reported?.connected || false;
  const networkIsConnected = _.get(props, ['reported', 'networkStatus', props.iface, 'connected'], false);
  return (
    <>
      <span>{props.label}</span>
      {stationIsConnected &&
        (networkIsConnected ? (
          <CheckCircleOutlined className="text-success" style={{ marginLeft: 8 }} />
        ) : (
          <CloseCircleOutlined className="text-danger" style={{ marginLeft: 8 }} />
        ))}
    </>
  );
};

export const Network = LegacyForm.create<Props>({ name: 'network_form' })((props: Props) => {
  const [isOverridden, setIsOverridden] = useState(props.entity.config?.networkSettings !== undefined);

  const fieldsDisabled = props.networkSettingsPending || !isOverridden;
  const derivedNetworkSettings: NetworkSettingsDto = props.entity.derivedConfig.networkSettings;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 }
    }
  };

  const [showAdvancedWifi, setShowAdvancedWifi] = useState(false);
  const [tabsActiveKey, setTabsActiveKey] = useState('wifi');

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.form.validateFields(async (err: Error, values: any) => {
      if (err) {
        return;
      }

      if (isOverridden) {
        values = _.cloneDeep(values);
        for (const ifaceKey in values) {
          const iface: any = values[ifaceKey];
          // address conversion
          if (_.isObject(iface.address?.[0])) {
            iface.address = [toCidrIp(iface.address?.[0] as any)];
          }
          // address cleanup
          if (_.isEmpty(iface.address?.[0])) {
            iface.address = undefined;
          }
          // gateway conversion/cleanup
          if (!iface.gateway?.[0]) {
            iface.gateway = undefined;
          }
          // dns cleanup
          if (iface.dns) {
            iface.dns = iface.dns.filter((v: string) => !_.isEmpty(v));
          }
          // password reapplying
          if (
            iface.wpaSupplicantConfig?.eap === 'PEAP' &&
            _.isEmpty(iface.wpaSupplicantConfig?.password) &&
            derivedNetworkSettings[ifaceKey as keyof NetworkSettingsDto]?.wpaSupplicantConfig?.password
          ) {
            iface.wpaSupplicantConfig.password =
              derivedNetworkSettings?.[ifaceKey as keyof NetworkSettingsDto]?.wpaSupplicantConfig?.password;
          }
          // privateKey reapplying
          if (
            iface.wpaSupplicantConfig?.eap === 'TLS' &&
            _.isEmpty(iface.wpaSupplicantConfig?.privateKey) &&
            derivedNetworkSettings[ifaceKey as keyof NetworkSettingsDto]?.wpaSupplicantConfig?.privateKey
          ) {
            iface.wpaSupplicantConfig.privateKey =
              derivedNetworkSettings?.[ifaceKey as keyof NetworkSettingsDto]?.wpaSupplicantConfig?.privateKey;
          }
          // psk hashing/reapplying
          if (iface.wpaSupplicantConfig?.keyMgmt === 'WPA-PSK') {
            if (!_.isEmpty(iface.wpaSupplicantConfig?.psk)) {
              const wpaConfig = iface.wpaSupplicantConfig as NetworkWpaSupplicantConfigDto;
              wpaConfig.psk = await encryptPsk(wpaConfig.ssid, wpaConfig.psk as string);
            } else if (derivedNetworkSettings[ifaceKey as keyof NetworkSettingsDto]?.wpaSupplicantConfig?.psk) {
              iface.wpaSupplicantConfig.psk =
                derivedNetworkSettings?.[ifaceKey as keyof NetworkSettingsDto]?.wpaSupplicantConfig?.psk;
            }
          }
        }

        if (!values.ethernet) {
          // If we do not view the Ethernet tab then we loose those settings, so reapply from derived config before submitting
          values.ethernet = derivedNetworkSettings.ethernet;
        }

        props.updateNetworkSettings(values);
      } else {
        props.updateNetworkSettings(null);
      }
    });
  };

  const handleCancel = () => {
    props.form.resetFields();
    setTabsActiveKey('wifi');
    props.toggleNetworkModal(false);
  };

  const changeTab = (activeKey: string) => {
    setTabsActiveKey(activeKey);
  };

  useEffect(() => {
    setTabsActiveKey('wifi');
  }, [props.showNetworkModal]);

  useEffect(() => {
    setIsOverridden(props.entity.config?.networkSettings !== undefined);
  }, [!props.showNetworkModal]);

  return (
    <div style={{ marginTop: 20 }}>
      <Button
        className="btn-danger"
        icon={<WifiOutlined />}
        onClick={props.toggleNetworkModal.bind(null, true)}
        disabled={props.disabled}>
        Change network settings
      </Button>
      <Modal
        title="Change Network Settings"
        visible={props.showNetworkModal}
        onCancel={handleCancel}
        data-test-id="network-settings-modal"
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit} loading={props.networkSettingsPending}>
            Save
          </Button>
        ]}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {!('isRoot' in props.entity && props.entity.isRoot) && (
            <OverrideButton
              disabled={props.networkSettingsPending}
              overridden={isOverridden}
              pending={props.networkSettingsPending}
              onClick={(override: boolean) => setIsOverridden(override)}
            />
          )}
        </div>
        <LegacyForm {...formItemLayout}>
          {props.entity &&
            props.type === 'station' &&
            _.get(props.entity, 'syncErrors.networkSettings') != null &&
            !props.networkSettingsPending &&
            _.get(props.entity, 'reported.connected') && (
              <Alert
                message="The settings configured here could not be applied. The station has reverted back to the previous working settings."
                type="warning"
                showIcon
                style={{ marginTop: 10 }}
              />
            )}
          <Tabs defaultActiveKey="wifi" animated={false} activeKey={tabsActiveKey} onChange={changeTab}>
            <Tabs.TabPane
              tab={
                props.type === 'group' ? (
                  'Wifi'
                ) : (
                  <NetworkTabLabel reported={(props.entity as any).reported} iface="wifi" label="Wifi" />
                )
              }
              key="wifi">
              <WpaSupplicantSettings
                interface="wifi"
                form={props.form}
                derivedConfig={derivedNetworkSettings?.wifi?.wpaSupplicantConfig}
                fieldsDisabled={fieldsDisabled}
                showNetworkModal={props.showNetworkModal}
              />

              <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 10 }}>
                <Button type="link" size="small" onClick={() => setShowAdvancedWifi(!showAdvancedWifi)}>
                  {showAdvancedWifi ? 'Hide' : 'Show'} Advanced
                </Button>
              </div>

              <div style={{ display: showAdvancedWifi ? 'block' : 'none' }}>
                <InterfaceSettings
                  type={props.type}
                  interface="wifi"
                  form={props.form}
                  fieldsDisabled={fieldsDisabled}
                  derivedConfig={derivedNetworkSettings?.wifi}
                  showNetworkModal={props.showNetworkModal}
                />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                props.type === 'group' ? (
                  'Ethernet'
                ) : (
                  <NetworkTabLabel reported={(props.entity as any)?.reported} iface="ethernet" label="Ethernet" />
                )
              }
              key="ethernet">
              <InterfaceSettings
                type={props.type}
                interface="ethernet"
                form={props.form}
                fieldsDisabled={fieldsDisabled}
                derivedConfig={derivedNetworkSettings?.ethernet}
                showNetworkModal={props.showNetworkModal}
              />
            </Tabs.TabPane>
          </Tabs>
        </LegacyForm>
      </Modal>
    </div>
  );
});
