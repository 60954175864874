import React, { useEffect, useState } from 'react';
import { Input, Select, Switch } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import '@ant-design/compatible/assets/index.css';
import { NetworkWpaSupplicantConfigDto } from '@pclocs/platform-sdk';
import { CertificateFormField, validateCertificate, validatePrivateKey } from '../../CertificateFormField';

type Props = {
  form: WrappedFormUtils<any>;
  interface: 'wifi';
  fieldsDisabled: boolean;
  /**
   * This acts in a similar way to defaultValue in React input fields.
   * Values from the antd form object is used for the dynamic form.
   */
  derivedConfig?: NetworkWpaSupplicantConfigDto;
  showNetworkModal: boolean;
};

export const WpaSupplicantSettings = (props: Props) => {
  const derivedConfig: NetworkWpaSupplicantConfigDto | undefined = props.derivedConfig;
  const { getFieldDecorator } = props.form;
  const [isSwitchEnabled, setSwitchState] = useState(props.derivedConfig?.enabled || false);

  const formKeyPrefix = `${props.interface}.wpaSupplicantConfig.`;

  useEffect(() => {
    if (props.derivedConfig?.enabled != null) {
      setSwitchState(props.derivedConfig.enabled);
    }
  }, [props.derivedConfig?.enabled]);

  useEffect(() => {
    props.form.setFieldsValue({ [`${formKeyPrefix}enabled`]: isSwitchEnabled });
  }, [isSwitchEnabled]);

  const formValues: NetworkWpaSupplicantConfigDto | undefined = {
    ...derivedConfig,
    ...props.form.getFieldsValue()[props.interface]?.wpaSupplicantConfig
  };

  const clientPrivateKeyFeedback = () => {
    if (derivedConfig?.privateKey && formValues?.privateKey && formValues.privateKey !== derivedConfig.privateKey) {
      return 'Existing private key will be overwritten';
    }
    return undefined;
  };

  return (
    <>
      <LegacyForm.Item label="Enabled">
        {getFieldDecorator(`${formKeyPrefix}enabled`)(
          <Switch checked={isSwitchEnabled} onChange={setSwitchState} disabled={props.fieldsDisabled} />
        )}
      </LegacyForm.Item>
      {isSwitchEnabled && (
        <>
          <LegacyForm.Item label="Security">
            {getFieldDecorator(`${formKeyPrefix}keyMgmt`, {
              initialValue: derivedConfig?.keyMgmt || 'NONE',
              rules: [{ required: true }]
            })(
              <Select disabled={props.fieldsDisabled}>
                <Select.Option value="NONE">Open</Select.Option>
                <Select.Option value="WPA-PSK">WPA/WPA2 Personal</Select.Option>
                <Select.Option value="WPA-EAP">WPA/WPA2 Enterprise</Select.Option>
              </Select>
            )}
          </LegacyForm.Item>
          <LegacyForm.Item label="SSID">
            {getFieldDecorator(`${formKeyPrefix}ssid`, {
              initialValue: derivedConfig?.ssid,
              rules: [
                {
                  required: true,
                  message: 'An SSID is required.'
                }
              ]
            })(<Input disabled={props.fieldsDisabled} />)}
          </LegacyForm.Item>

          {formValues?.keyMgmt === 'WPA-PSK' && (
            <LegacyForm.Item label="PSK">
              {getFieldDecorator(`${formKeyPrefix}psk`, {
                initialValue: undefined,
                rules: [
                  {
                    required: derivedConfig?.psk === undefined,
                    message: 'A PSK is required.'
                  }
                ]
              })(
                <Input
                  disabled={props.fieldsDisabled}
                  placeholder={derivedConfig?.psk === undefined ? '' : 'Use saved key.'}
                />
              )}
            </LegacyForm.Item>
          )}

          {formValues?.keyMgmt === 'WPA-EAP' && (
            <>
              <LegacyForm.Item label="Mode">
                {getFieldDecorator(`${formKeyPrefix}eap`, {
                  initialValue: derivedConfig?.eap,
                  rules: [{ required: true }]
                })(
                  <Select disabled={props.fieldsDisabled}>
                    <Select.Option value="PEAP">Automatic</Select.Option>
                    <Select.Option value="TLS">TLS</Select.Option>
                  </Select>
                )}
              </LegacyForm.Item>

              {formValues?.eap === 'PEAP' && (
                <>
                  <LegacyForm.Item label="Username">
                    {getFieldDecorator(`${formKeyPrefix}identity`, {
                      initialValue: derivedConfig?.identity,
                      rules: [{ required: true, message: 'A username is required.' }]
                    })(<Input disabled={props.fieldsDisabled} />)}
                  </LegacyForm.Item>
                  <LegacyForm.Item label="Password">
                    {getFieldDecorator(`${formKeyPrefix}password`, {
                      initialValue: undefined,
                      rules: [{ required: derivedConfig?.password === undefined, message: 'A password is required.' }]
                    })(
                      <Input
                        type="password"
                        disabled={props.fieldsDisabled}
                        placeholder={derivedConfig?.password === undefined ? '' : 'Use saved password'}
                      />
                    )}
                  </LegacyForm.Item>
                </>
              )}

              {formValues?.eap === 'TLS' && (
                <>
                  <LegacyForm.Item label="Client Cert">
                    {getFieldDecorator(`${formKeyPrefix}clientCert`, {
                      initialValue: derivedConfig?.clientCert,
                      rules: [
                        { required: true, message: 'A client certificate is required.' },
                        {
                          validator: validateCertificate
                        }
                      ]
                    })(<CertificateFormField disabled={props.fieldsDisabled} />)}
                  </LegacyForm.Item>
                  <LegacyForm.Item label="Client Key" extra={clientPrivateKeyFeedback()}>
                    {getFieldDecorator(`${formKeyPrefix}privateKey`, {
                      initialValue: derivedConfig?.privateKey,
                      rules: [
                        { required: true, message: 'A private key is required.' },
                        {
                          validator: (rule: unknown, value: string, callback: (error?: string) => void) => {
                            if (value === derivedConfig?.privateKey) {
                              callback();
                            } else {
                              validatePrivateKey(rule, value, callback);
                            }
                          }
                        }
                      ]
                    })(<CertificateFormField disabled={props.fieldsDisabled} />)}
                  </LegacyForm.Item>
                  <LegacyForm.Item label="CA Cert">
                    {getFieldDecorator(`${formKeyPrefix}caCert`, {
                      initialValue: derivedConfig?.caCert,
                      rules: [
                        {
                          validator: validateCertificate
                        }
                      ]
                    })(<CertificateFormField disabled={props.fieldsDisabled} />)}
                  </LegacyForm.Item>
                  <LegacyForm.Item label="Username">
                    {getFieldDecorator(`${formKeyPrefix}identity`, {
                      initialValue: derivedConfig?.identity
                    })(<Input disabled={props.fieldsDisabled} />)}
                  </LegacyForm.Item>
                </>
              )}
            </>
          ) /* end WPA-EAP block */}
        </>
      )}
    </>
  );
};
