import {
  AdminRoleResponseDto,
  AdminUserResponseDto,
  CustomAdminRoleCreateDto,
  CustomAdminRoleResponseDto,
  CustomAdminRoleUpdateDto
} from '@pclocs/platform-sdk';
import { Alert, Button, Input, Popconfirm, Popover, Tag } from 'antd';
import Icon, { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { useEverySdkCall, useLatestSdkCall } from '../../../helpers/sdk-hooks';
import { AdminRoleModal } from './admin-role-modal';
import * as ActionTypes from '../../../store/actionTypes';
import { CommonActionTypes, ShowNotificationMessageAction } from '../../../store/common/types';
import { DashboardActionTypes } from '../../../store/dashboard/types';
import { accountActions, AccountActionTypes } from '../../../store/account/reducer';
import { RootState } from '../../../store/rootReducer';
import _ from 'lodash';
import { RoleMembersModal } from './role-members-modal';
import { AdminIcon } from '../../../assets/icons/admin';
import { FeatureId, useFeatureAvailability } from '../../../helpers/feature-availability-hooks';
import { subscriptionMessage } from '../../../constants/validations';
import { CopyToClipboard } from '../../CopyToClipboard';
import { InfinityList } from '../../InfinityList';
import { useDebouncedHook } from '../../../helpers/use-debounced-hook';
import { searchItems } from '../../../helpers/form-helper';

const mapStateToProps = (state: RootState) => ({
  ..._.pick(state.account, ['portalAdmins', 'isPortalAdminLoading', 'adminRoles', 'isAdminRolesLoading'])
});
const mapDispatchToProps = (dispatch: Dispatch<AccountActionTypes | CommonActionTypes | DashboardActionTypes>) => ({
  findPortalAdmins: () => dispatch(accountActions.findPortalAdmins()),
  findAdminRoles: () => dispatch(accountActions.findAdminRoles()),
  showNotificationMessage: (payload: ShowNotificationMessageAction['payload']) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload
    })
});

interface AdminRoleWithMembers extends AdminRoleResponseDto {
  roleMembers: AdminUserResponseDto[];
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const AdminRoles = connector((props: Props) => {
  const [modalRole, setModalRole] = useState<CustomAdminRoleResponseDto>();
  const [showRoleModal, setShowRoleModal] = useState<boolean>();

  const [adminModalRole, setAdminModalRole] = useState<AdminRoleResponseDto>();
  const [showAdminModal, setShowAdminModal] = useState<boolean>();

  const [dataSource, setDataSource] = useState<AdminRoleWithMembers[]>([]);

  const [hasFeature] = useFeatureAvailability();
  const hasCustomAdminRolesFeature = hasFeature(FeatureId.CUSTOMADMINROLES);
  const hasSsoAdminsFeature = hasFeature(FeatureId.SSOIDENTITYPROVIDERS);

  const [getAdminRole, getAdminRoleLoading] = useLatestSdkCall(
    'AdminRoleApi',
    'findOne',
    res => setModalRole(res.data),
    () => props.showNotificationMessage({ type: 'error', content: 'Role could not be found.' })
  );

  const [deleteAdminRole, deleteAdminRoleLoading] = useEverySdkCall(
    'AdminRoleApi',
    'deleteOne',
    () => {
      props.showNotificationMessage({ type: 'success', content: 'Role successfully deleted.' });
      props.findAdminRoles();
    },
    () => props.showNotificationMessage({ type: 'error', content: 'Could not delete role.' })
  );

  const [createAdminRole, createAdminRoleLoading] = useEverySdkCall(
    'AdminRoleApi',
    'createOne',
    res => {
      props.showNotificationMessage({
        type: 'success',
        content: `Role ${res.data.name} successfully created.`
      });
      setShowRoleModal(false);
      props.findAdminRoles();
    },
    () => props.showNotificationMessage({ type: 'error', content: 'Could not create role.' })
  );

  const [updateAdminRole, updateAdminRoleLoading] = useEverySdkCall(
    'AdminRoleApi',
    'updateOne',
    () => {
      props.showNotificationMessage({ type: 'success', content: 'Role successfully updated.' });
      setShowRoleModal(false);
      props.findAdminRoles();
    },
    () => props.showNotificationMessage({ type: 'error', content: 'Could not update role.' })
  );

  useEffect(() => {
    props.findAdminRoles();
  }, []);

  useEffect(() => {
    if (props.portalAdmins === undefined) {
      props.findPortalAdmins();
    }
  }, [props.portalAdmins]);

  useEffect(() => {
    if (props.adminRoles && props.portalAdmins) {
      setDataSource(
        props.portalAdmins
          ? props.adminRoles.map(role => {
              return {
                ...role,
                roleMembers: props.portalAdmins.filter(v => v.roles.includes(role.id))
              };
            })
          : []
      );
    }
  }, [props.adminRoles, props.portalAdmins]);

  const [filter, setFilter] = useState<string>('');
  const debouncedFilterValue = useDebouncedHook(filter, 200);
  const filteredDataSource = useMemo(() => {
    return !debouncedFilterValue ? dataSource : searchItems(dataSource, ['name', 'id'], debouncedFilterValue);
  }, [dataSource, debouncedFilterValue]);

  return (
    <div>
      {!hasCustomAdminRolesFeature && <Alert style={{ marginBottom: 16 }} type="error" message={subscriptionMessage} />}
      {showRoleModal && (
        <AdminRoleModal
          adminRole={modalRole}
          adminRoleLoading={getAdminRoleLoading}
          roleSubmitLoading={createAdminRoleLoading || updateAdminRoleLoading}
          foundAdminRoles={props.adminRoles}
          onCancel={() => setShowRoleModal(false)}
          createRole={(roleProps: CustomAdminRoleCreateDto) => createAdminRole(roleProps)}
          updateRole={(roleId: string, roleProps: CustomAdminRoleUpdateDto) => updateAdminRole(roleId, roleProps)}
          hasCustomAdminRolesFeature={hasCustomAdminRolesFeature}
        />
      )}
      {showAdminModal && (
        <RoleMembersModal
          adminRole={adminModalRole}
          roleMembers={props.portalAdmins.filter(v => v.roles.includes(adminModalRole.id))}
          modalLoading={props.isPortalAdminLoading}
          onCancel={() => setShowAdminModal(false)}
        />
      )}

      {hasCustomAdminRolesFeature && (
        <div className="content-split" style={{ marginBottom: 16 }}>
          <Button
            className="btn-primary"
            onClick={() => {
              setModalRole(undefined);
              setShowRoleModal(true);
            }}>
            <PlusCircleFilled />
            New Role
          </Button>
          <Input
            disabled={props.isAdminRolesLoading}
            placeholder="Search"
            onChange={e => setFilter(e.target.value)}
            style={{ width: 220 }}
          />
        </div>
      )}
      <div className="content-section" style={{ height: 'calc(100vh - 500px)' }}>
        <InfinityList
          items={filteredDataSource}
          loading={props.isAdminRolesLoading || deleteAdminRoleLoading || props.isPortalAdminLoading}
          hasMoreItems={false}
          pageSize={filteredDataSource.length}
          loadItems={() => {
            props.findAdminRoles();
          }}
          render={(adminRole: AdminRoleWithMembers) => {
            return (
              <div className="user-list-row list-row" style={{ marginBottom: 16 }}>
                <div className="user-detail admin-role-name" style={{ flex: '1 1' }}>
                  {adminRole.type === 'custom' && (
                    <Button
                      key={adminRole.id}
                      type="link"
                      onClick={() => {
                        getAdminRole(adminRole.id);
                        setShowRoleModal(true);
                      }}>
                      {adminRole.name}
                    </Button>
                  )}
                  {adminRole.type !== 'custom' && (
                    <div className="default" style={{ padding: '0 10px' }}>
                      {adminRole.name}
                    </div>
                  )}
                </div>
                {hasSsoAdminsFeature && (
                  <div
                    className="user-detail admin-role-id"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      flex: '1 1',
                      overflow: 'hidden'
                    }}>
                    <pre
                      style={{ margin: 0, overflowX: 'hidden', marginRight: 5, textOverflow: 'ellipsis' }}
                      title={adminRole.id}>
                      {adminRole.id}
                    </pre>
                    <CopyToClipboard data={adminRole.id} title="Copy role ID to clipboard" />
                  </div>
                )}
                <div
                  className="user-detail admin-role-member"
                  style={{ width: 'fit-content', marginLeft: 'auto', flexBasis: '100px' }}>
                  {adminRole.roleMembers.length > 0 && (
                    <Button
                      type="link"
                      style={{ width: 'fit-content', float: 'right' }}
                      onClick={() => {
                        setAdminModalRole(adminRole);
                        setShowAdminModal(true);
                      }}>
                      {adminRole.roleMembers.length}&nbsp;
                      <Icon style={{ marginLeft: '0px' }} component={() => <AdminIcon width={20} height={16} />} />
                    </Button>
                  )}
                  {adminRole.roleMembers.length === 0 && (
                    <div className="default" style={{ float: 'right', padding: '0 10px' }}>
                      {adminRole.roleMembers.length}&nbsp;
                      <Icon component={() => <AdminIcon width={20} height={16} />} />
                    </div>
                  )}
                </div>
                <div className="user-action" style={{ float: 'right', width: '80px' }}>
                  {adminRole.type === 'canned' ? (
                    <Tag className="ant-tag-primary" style={{ float: 'right' }}>
                      Default
                    </Tag>
                  ) : adminRole.roleMembers.length > 0 ? (
                    <Popover placement="left" title="Roles with remaining admins cannot be deleted." trigger="click">
                      <Button ghost style={{ float: 'right' }} disabled>
                        <DeleteFilled />
                      </Button>
                    </Popover>
                  ) : (
                    <Popconfirm
                      placement="left"
                      title="Delete this role?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => deleteAdminRole(adminRole.id)}>
                      <Button ghost style={{ float: 'right' }}>
                        <DeleteFilled />
                      </Button>
                    </Popconfirm>
                  )}
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
});
