import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Alert, Button, Input, Modal, Select, Skeleton, Switch, Tooltip } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { InfoCircleOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { RootState } from '../../../store/rootReducer';
import {
  AdminRoleResponseDtoPermissionSetEnum,
  AdminRoleResponseDtoTypeEnum,
  AdminUserCreateDto,
  AdminUserUpdateDto,
  FeaturesDtoFeaturesEnum
} from '@pclocs/platform-sdk';
import _ from 'lodash';
import { validatePinOrRfidExists } from '../../../helpers/credentials-helper';
import { getErrorAlertMessage, searchItems } from '../../../helpers/form-helper';
import { AdminUserResponseDto } from '@pclocs/platform-sdk/dist';
import * as RfidHelper from '../../../helpers/rfid-converter-helper';
import { accountActions } from '../../../store/account/reducer';
import { AdminClaimEnum, useHasClaim } from '../../../helpers/claims';
import { useFeatureAvailability } from '../../../helpers/feature-availability-hooks';

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser,
  ..._.pick(state.account, [
    'showPortalAdminModal',
    'adminUserId',
    'adminUser',
    'pending',
    'isUniquePINLoading',
    'uniquePIN',
    'rfid',
    'messages',
    'portalAdmins',
    'adminRoles'
  ])
});
const mapDispatchToProps = {
  unloadModal: () => accountActions.portalAdminUnloadModal(),
  getPortalAdmin: (adminUserId: string) => accountActions.findPortalAdmin({ adminUserId }),
  getUniquePIN: () => accountActions.getUniquePin(),
  createPortalAdmin: (data: AdminUserCreateDto) => accountActions.createPortalAdmin({ data }),
  updatePortalAdmin: (data: AdminUserUpdateDto, adminUserId: string) =>
    accountActions.updatePortalAdmin({ data, adminUserId })
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & FormComponentProps;

export const PortalAdminModal = connector(
  LegacyForm.create<Props>({ name: 'PortalAdmin' })((props: Props) => {
    const checkIfIsStationOnly = (roleId: string) => {
      return (
        props.adminRoles.find(adminRole => adminRole.id === roleId).permissionSet ===
        AdminRoleResponseDtoPermissionSetEnum.StationAdmin
      );
    };

    const isStationOnlyAdmin = props.adminUser ? checkIfIsStationOnly(props.adminUser.roles[0]) : false;
    const [stationOnlyRoleSelected, setStationOnlyRoleSelected] = useState(isStationOnlyAdmin);
    const [isStationAccessEnabled, setStationAccess] = useState(stationOnlyRoleSelected);
    const [showWarningStationAdmin, setWarningStationAdmin] = useState(false);

    const { getFieldDecorator } = props.form;
    const formLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 17,
          offset: 7
        }
      }
    };
    useEffect(() => {
      props.showPortalAdminModal && props.form.resetFields();
      props.showPortalAdminModal && props.adminUserId && props.getPortalAdmin(props.adminUserId);
      !props.adminUserId && !props.isUniquePINLoading && props.showPortalAdminModal && props.getUniquePIN();
    }, [props.showPortalAdminModal]);

    useEffect(() => {
      if (props.showPortalAdminModal) {
        props.form.setFieldsValue({ pin: props.uniquePIN, rfid: props.form.getFieldValue('rfid') });
      }
    }, [props.uniquePIN]);

    useEffect(() => {
      if (!props.adminUser) {
        return;
      }
      if ('stationRfid' in props.adminUser || 'stationPin' in props.adminUser) {
        setStationAccess(!!props.adminUser.stationRfid || !!props.adminUser.stationPin || stationOnlyRoleSelected);
      } else {
        setStationAccess(isStationOnlyAdmin);
      }
      props.form.setFieldsValue({
        ...('stationPin' in props.adminUser && { pin: props.adminUser.stationPin }),
        ...('stationRfid' in props.adminUser && {
          rfid: RfidHelper.hexToInputFieldFormat(props.adminUser.stationRfid ?? '')
        }),
        roleId: props.adminUser.roles[0]
      });
      setStationOnlyRoleSelected(isStationOnlyAdmin);
      setWarningStationAdmin(false);
    }, [props.adminUser]);

    const handleChange = () => {
      props.form.setFieldsValue({
        rfid: RfidHelper.changeFormat(props.form.getFieldValue('rfid'))
      });
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();
      props.form.validateFieldsAndScroll(async (err: Error, values: any) => {
        if (!err) {
          if (props.adminUserId) {
            const updateUser: AdminUserUpdateDto = {
              name: values.name,
              roles: [values.roleId],
              stationPin: isStationAccessEnabled && props.uniquePIN?.length ? props.uniquePIN : null,
              stationRfid: isStationAccessEnabled && values.rfid?.length ? RfidHelper.rfidToHexPad(values.rfid) : null
            };
            props.updatePortalAdmin(updateUser, props.adminUserId);
          } else {
            const createUser: AdminUserCreateDto = {
              name: values.name,
              email: values.email,
              roles: [values.roleId],
              stationPin: isStationAccessEnabled && props.uniquePIN?.length ? props.uniquePIN : undefined,
              stationRfid:
                isStationAccessEnabled && values.rfid?.length ? RfidHelper.rfidToHexPad(values.rfid) : undefined
            };
            props.createPortalAdmin(createUser);
          }
        }
      });
    };

    const [hasWriteClaim] = useHasClaim({ type: AdminClaimEnum.AdminUserWrite });

    const [hasFeature] = useFeatureAvailability();
    const hasCustomAdminRolesFeature = hasFeature(FeaturesDtoFeaturesEnum.CUSTOMADMINROLES);

    const footer = [
      <Button key="back" style={{ display: props.pending === true ? 'none' : 'initial' }} onClick={props.unloadModal}>
        Cancel
      </Button>
    ];

    hasWriteClaim &&
      footer.push(
        <Button key="submit" type="primary" loading={props.pending === true} onClick={handleSubmit}>
          Save
        </Button>
      );

    const checkIfPortalAdminAlreadyExists = (_rule: any, value: any, callback: { (arg0: string): void; (): void }) => {
      const adminAlreadyExists: boolean = !!_.find(props.portalAdmins, (portalAdmin: AdminUserResponseDto) => {
        return value?.localeCompare(portalAdmin.email, undefined, { sensitivity: 'accent' }) === 0;
      });

      if (adminAlreadyExists) {
        callback('This admin already exists!');
      } else {
        callback();
      }
    };

    return (
      <Modal
        title={!props.adminUserId ? 'Create Admin:' : hasWriteClaim ? 'Update Admin' : 'View Admin'}
        visible={props.showPortalAdminModal}
        onCancel={props.unloadModal}
        footer={footer}>
        {props.adminUserId && !props.adminUser ? (
          <Skeleton active />
        ) : (
          <LegacyForm
            {...{
              labelCol: {
                xs: { span: 24 },
                sm: { span: 7 }
              },
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 17 }
              }
            }}>
            <LegacyForm.Item wrapperCol={{ xs: { span: 24 } }} style={{ marginBottom: 16 }}>
              {props.adminUserId && showWarningStationAdmin && (
                <Alert
                  message="Warning: action cannot be undone"
                  description="Changing a user's role to 'Station Admin' means you won't be able to revert that user to another role."
                  type="warning"
                  showIcon
                />
              )}
            </LegacyForm.Item>
            {hasWriteClaim ? (
              <LegacyForm.Item
                colon={false}
                label={
                  <span>
                    Role:&nbsp;
                    <Tooltip title="There can be many owner roles, but the account must have a minimum of 1 owner.">
                      <InfoCircleOutlined className="role-tooltip" />
                    </Tooltip>
                  </span>
                }>
                {getFieldDecorator('roleId', {
                  rules: [{ required: true, message: 'Please select a role!' }]
                })(
                  <Select
                    showSearch
                    placeholder="Select a role"
                    optionFilterProp="children"
                    onChange={(v: string) => {
                      if (
                        props.adminUserId &&
                        props.adminUser.roles[0] != 'adminRole-stationAdmin' &&
                        v == 'adminRole-stationAdmin'
                      ) {
                        setWarningStationAdmin(true);
                      } else {
                        setWarningStationAdmin(false);
                      }
                      const stationOnly = checkIfIsStationOnly(v);
                      if (stationOnly) {
                        setStationOnlyRoleSelected(true);
                        setStationAccess(true);
                      } else {
                        setStationOnlyRoleSelected(false);
                      }
                    }}
                    onBlur={() => props.form.validateFields(['roleId'])}
                    filterOption={(input, option) => searchItems([option.props], ['children'], input).length > 0}
                    dropdownRender={menu => <div className="wrapped-item-select-dropdown">{menu}</div>}
                    disabled={
                      props.currentUser?.id === props.adminUserId ||
                      isStationOnlyAdmin ||
                      (props.adminUser && !('email' in props.adminUser))
                    }>
                    {_.sortBy(props.adminRoles ?? [], ['type', 'name']).map(role => (
                      <Select.Option
                        key={role.id}
                        value={role.id}
                        disabled={!hasCustomAdminRolesFeature && role.type === AdminRoleResponseDtoTypeEnum.Custom}>
                        {role.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </LegacyForm.Item>
            ) : (
              <LegacyForm.Item label="Role">
                <strong>
                  {props.adminRoles?.find(r => r.id === props.adminUser?.roles?.[0])?.name ??
                    props.adminUser?.roles?.[0]}
                </strong>
              </LegacyForm.Item>
            )}

            {stationOnlyRoleSelected && (
              <>
                <LegacyForm.Item label="Name">
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input name!' }],
                    initialValue: props.adminUser?.name
                  })(<Input />)}
                </LegacyForm.Item>
              </>
            )}

            {props.adminUserId && !stationOnlyRoleSelected && (
              <LegacyForm.Item label="Name">
                <strong>{props.adminUser && props.adminUser.name}</strong>
              </LegacyForm.Item>
            )}

            {!stationOnlyRoleSelected && (
              <LegacyForm.Item label="Email">
                {!props.adminUserId ? (
                  getFieldDecorator('email', {
                    rules: [
                      { type: 'email', message: 'Not a valid email address!' },
                      { required: true, message: 'Please input email!' },
                      { validator: checkIfPortalAdminAlreadyExists }
                    ],
                    validateTrigger: 'onBlur'
                  })(<Input />)
                ) : (
                  <strong>{props.adminUser && props.adminUser.email}</strong>
                )}
              </LegacyForm.Item>
            )}

            {hasWriteClaim ? (
              <>
                <LegacyForm.Item {...formLayout}>
                  <Switch
                    checked={isStationAccessEnabled}
                    onChange={setStationAccess}
                    disabled={stationOnlyRoleSelected}
                    style={{ marginRight: 10 }}
                  />
                  Enable station access?
                </LegacyForm.Item>

                <div style={{ display: isStationAccessEnabled ? 'initial' : 'none' }}>
                  <LegacyForm.Item colon={false} label={<span>RFID:&nbsp;{RfidHelper.rfidFormatTooltip}</span>}>
                    {getFieldDecorator('rfid', {
                      rules: isStationAccessEnabled ? [{ validator: validatePinOrRfidExists(props.form) }] : []
                    })(
                      <Input
                        onBlur={() => props.form.validateFields(['rfid', 'pin'])}
                        addonAfter={<RfidHelper.FormatSelector onChange={handleChange} />}
                      />
                    )}
                  </LegacyForm.Item>

                  <LegacyForm.Item label="PIN">
                    {getFieldDecorator('pin', {
                      rules: isStationAccessEnabled ? [{ validator: validatePinOrRfidExists(props.form) }] : []
                    })(<Input hidden />)}

                    <span className="pin-wrapper">
                      {props.isUniquePINLoading && <LoadingOutlined style={{ fontSize: 18, marginRight: 16 }} spin />}
                      {props.uniquePIN}
                    </span>

                    {!props.isUniquePINLoading && (
                      <Button
                        className="btn-primary"
                        style={{ marginLeft: 6, marginRight: 6 }}
                        onClick={props.getUniquePIN}>
                        <ReloadOutlined />
                      </Button>
                    )}
                  </LegacyForm.Item>
                </div>
              </>
            ) : (
              props.currentUser?.id === props.adminUserId && (
                <>
                  {props.rfid && (
                    <LegacyForm.Item label="RFID">
                      <strong>{props.rfid}</strong>
                    </LegacyForm.Item>
                  )}

                  {props.uniquePIN && (
                    <LegacyForm.Item label="PIN">
                      <strong>{props.uniquePIN}</strong>
                    </LegacyForm.Item>
                  )}
                </>
              )
            )}

            {props.messages && getErrorAlertMessage(props.messages)}
          </LegacyForm>
        )}
      </Modal>
    );
  })
);
