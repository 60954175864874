import React, { useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input, Button, Spin, Alert } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { LockOutlined, NumberOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Validations } from '../../constants/validations';
import * as ActionTypes from '../../store/actionTypes';
import { RootState } from '../../store/rootReducer';
import { AuthActionTypes } from '../../store/auth/types';
import { getBrandName } from '../../helpers/brand-helper';

const mapStateToProps = (state: RootState) => ({ ...state.auth });
const mapDispatchToProps = (dispatch: Dispatch<AuthActionTypes>) => ({
  onLoad: () => dispatch({ type: ActionTypes.RESET_PASSWORD_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ActionTypes.RESET_PASSWORD_PAGE_UNLOADED }),
  resetPassword: (email: string, code: string, password: string) =>
    dispatch({ type: ActionTypes.RESET_PASSWORD, payload: { email, code, password } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & FormComponentProps;

export const ResetPassword = connector(
  LegacyForm.create({ name: 'reset_password' })((props: Props) => {
    useEffect(() => {
      props.onLoad();
      props.form.setFieldsValue({ email: props.email });

      return () => {
        props.onUnload();
      };
    }, []);

    const { getFieldDecorator } = props.form;

    const securePassword = (rule: any, value: any, callback: { (arg0: string): void; (): void }) => {
      if (value !== undefined && value.match(Validations.securePassword.rule)) {
        callback();
      } else {
        callback(Validations.securePassword.message);
      }
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();
      props.form.validateFields((err: Error, values: any) => {
        if (!err) {
          props.resetPassword(values.email, values.code, values.password);
        }
      });
    };

    return (
      <div>
        {props.status === 'accountHasBeenCreated' && (
          <>
            <div className="reset-password-message-title">Success!</div>
            <div className="reset-password-message-subtitle">
              Your password has been successfully reset. You can now use it to login.
            </div>
            <Button type="primary" className="login-button" style={{ marginTop: 15 }}>
              <Link to="/login">Go to login page</Link>
            </Button>
          </>
        )}

        {!props.status && (
          <LegacyForm onSubmit={handleSubmit} className="login-form">
            {props.resetPasswordForRestoredAdmin && (
              <Alert
                message={'Password Reset Required'}
                description={`For security reasons, we require you to reset your password for your ${getBrandName()} Cloud account.`}
                type="info"
                showIcon
                style={{ marginBottom: 24 }}
              />
            )}
            <LegacyForm.Item>
              {getFieldDecorator('email', {
                rules: [
                  { type: 'email', message: 'Not a valid email address!' },
                  { required: true, message: 'Please input your email address!' }
                ]
              })(<Input prefix={<UserOutlined />} placeholder="Email" />)}
            </LegacyForm.Item>

            <LegacyForm.Item hasFeedback>
              {getFieldDecorator('password', {
                rules: [{ validator: securePassword }]
              })(
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  autoComplete="new-password"
                  placeholder="New Password"
                />
              )}
            </LegacyForm.Item>

            <LegacyForm.Item extra="Check your email for a recovery code.">
              {getFieldDecorator('code', {
                rules: [{ required: true, message: 'Please input password recovery code!' }]
              })(<Input prefix={<NumberOutlined />} placeholder="Recovery Code" />)}
            </LegacyForm.Item>

            {props.error && typeof props.error === 'string' && (
              <LegacyForm.Item style={{ marginBottom: 16 }}>
                <Alert message={props.error} type="error" showIcon />
              </LegacyForm.Item>
            )}

            <LegacyForm.Item>
              <Spin spinning={props.pending === true}>
                <Button block type="primary" htmlType="submit" className="login-form-button">
                  Reset Password
                </Button>
              </Spin>

              <div className="login-login-link">
                I know my password, <Link to="/login">I want to login.</Link>
              </div>
            </LegacyForm.Item>
          </LegacyForm>
        )}
      </div>
    );
  })
);
