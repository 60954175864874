import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UNWATCH_BACKGROUND_JOBS, WATCH_BACKGROUND_JOBS } from '../store/actionTypes';
import { RootState } from '../store/rootReducer';

export const useWatchBackgroundJobs = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: WATCH_BACKGROUND_JOBS });
    return () => {
      dispatch({ type: UNWATCH_BACKGROUND_JOBS });
    };
  }, []);
  return useSelector((state: RootState) => state.common.backgroundJobs ?? []);
};
