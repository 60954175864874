import React from 'react';
import { Tooltip, Switch } from 'antd';

export const OverrideButton = (props: { overridden: boolean; disabled?: boolean; pending?: boolean; onClick: any }) => {
  const overridden = props.overridden;
  const inheritMessage = 'This setting is overriding settings from a higher level. Click to inherit instead.';
  const overrideMessage = 'This setting is inheriting settings from a higher level. Click to override.';
  const switchElement: JSX.Element = (
    <Switch
      className="override-btn"
      disabled={props.disabled}
      checkedChildren="Overridden"
      unCheckedChildren="Inherited"
      checked={overridden}
      loading={props.pending}
      onClick={props.onClick}
    />
  );

  return !props.disabled ? (
    <Tooltip placement="left" title={overridden ? inheritMessage : overrideMessage} mouseEnterDelay={0.6}>
      {switchElement}
    </Tooltip>
  ) : (
    switchElement
  );
};
