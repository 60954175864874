import {
  AdminRoleResponseDto,
  AdminRoleResponseDtoPermissionSetEnum,
  CustomAdminRoleCreateDto,
  CustomAdminRoleResponseDto,
  CustomAdminRoleUpdateDto
} from '@pclocs/platform-sdk';
import { Button, Input, Modal, Select, Skeleton, TreeSelect } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import '@ant-design/compatible/assets/index.css';
import _ from 'lodash';
import React from 'react';
import { useGroupHierarchy, createTreeNodes } from '../../../helpers/group-hierarchy';

type Props = FormComponentProps & {
  adminRole?: CustomAdminRoleResponseDto;
  adminRoleLoading: boolean;
  roleSubmitLoading: boolean;
  foundAdminRoles: AdminRoleResponseDto[];
  onCancel: () => void;
  createRole: (roleProps: CustomAdminRoleCreateDto) => void;
  updateRole: (roleId: string, roleProps: CustomAdminRoleUpdateDto) => void;
  hasCustomAdminRolesFeature: boolean;
};

export const AdminRoleModal = LegacyForm.create<Props>({ name: 'AdminRole' })((props: Props) => {
  const { getFieldDecorator } = props.form;
  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 7 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 }
    }
  };

  const tree = useGroupHierarchy();

  const submitForm = () => {
    props.form.validateFieldsAndScroll(async (err: Error, values: any) => {
      if (!err) {
        if (props.adminRole) {
          props.updateRole(props.adminRole.id, _.pick(values, ['name', 'permissionSet', 'node']));
        } else {
          props.createRole(_.pick(values, ['name', 'permissionSet', 'node']));
        }
      }
    });
  };

  const footer = [
    <Button key="back" style={{ display: 'initial' }} disabled={props.roleSubmitLoading} onClick={props.onCancel}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      disabled={props.adminRoleLoading}
      loading={props.roleSubmitLoading}
      onClick={submitForm}>
      Save
    </Button>
  ];

  return (
    <Modal title={props.adminRole ? 'Edit Role' : 'New Role'} visible={true} onCancel={props.onCancel} footer={footer}>
      {!props.adminRoleLoading ? (
        <LegacyForm {...formLayout}>
          <LegacyForm.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                { required: true, message: 'Please input a name!' },
                {
                  validator: (_rule, value, callback) => {
                    callback(
                      props.foundAdminRoles.find(
                        role => role.name.toUpperCase() === value.toUpperCase() && role.id !== props.adminRole?.id
                      )
                    );
                  },
                  message: 'Name already in use!'
                }
              ],
              validateTrigger: 'onBlur',
              initialValue: props.adminRole ? props.adminRole.name : ''
            })(
              <Input
                onBlur={() => props.form.validateFields(['name'])}
                disabled={props.roleSubmitLoading || !props.hasCustomAdminRolesFeature}
              />
            )}
          </LegacyForm.Item>

          <LegacyForm.Item label="Permission Set">
            {getFieldDecorator('permissionSet', {
              rules: [{ required: true, message: 'Please select a permission set!' }],
              initialValue: props.adminRole ? props.adminRole.permissionSet : ''
            })(
              <Select
                onBlur={() => props.form.validateFields(['permissionSet'])}
                placeholder="Select a permission set"
                disabled={props.roleSubmitLoading || !props.hasCustomAdminRolesFeature}>
                {Object.entries(AdminRoleResponseDtoPermissionSetEnum).map(permissionSet => {
                  return (
                    <Select.Option key={permissionSet[0]} value={permissionSet[1]}>
                      {permissionSet[0]}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </LegacyForm.Item>

          <LegacyForm.Item label="Node">
            {getFieldDecorator('node', {
              rules: [{ required: true, message: 'Please select a node!' }],
              initialValue: props.adminRole ? props.adminRole.node : ''
            })(
              <TreeSelect
                showSearch
                onBlur={() => props.form.validateFields(['node'])}
                placeholder="Select a Node"
                treeDefaultExpandAll
                disabled={!tree || props.roleSubmitLoading || !props.hasCustomAdminRolesFeature}
                treeNodeFilterProp="title"
                treeData={tree && createTreeNodes(tree, g => ({ value: g.id }))}
              />
            )}
          </LegacyForm.Item>
        </LegacyForm>
      ) : (
        <Skeleton active />
      )}
    </Modal>
  );
});
