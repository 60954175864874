import { Button, Modal, Result } from 'antd';
import React from 'react';

export const ExportReportModal = (props: { visible: boolean; unloadModal: () => void }) => {
  return (
    <Modal
      title="Export Report"
      visible={props.visible}
      onCancel={props.unloadModal}
      data-test-id="export-report-modal"
      destroyOnClose={true}
      footer={[
        <Button key="OK" type="primary" onClick={props.unloadModal} data-test-id="export-report-btn-submit">
          OK
        </Button>
      ]}>
      <Result
        status="info"
        title="Export in progress"
        subTitle="We've started to export the requested report. This process can take a while so we'll send you an email once it's complete."
      />
    </Modal>
  );
};
