import React, { Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as ActionTypes from '../../../store/actionTypes';
import { CommonActionTypes, UpdateAuthorizedAction } from '../../../store/common/types';
import { ManageAuthorized } from '../ManageAuthorized/manage-authorized';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch<CommonActionTypes>) => ({
  fetch: (id: string) => dispatch({ type: ActionTypes.FETCH_AUTHORIZED_IN_STATION, payload: { id } }),
  update: (id: string, authorizedEntities: UpdateAuthorizedAction['payload']['authorizedEntities']) =>
    dispatch({ type: ActionTypes.UPDATE_AUTHORIZED_IN_STATION, payload: { id, authorizedEntities } }),
  remove: (id: string) => dispatch({ type: ActionTypes.REMOVE_ALL_AUTHORIZED_IN_STATION, payload: { id } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const StationUsers = connector((props: Props) => {
  return (
    <>
      <p data-test-id="station-info-display" style={{ marginBottom: 30 }}>
        Adding to this list will authorize users to access any bays in this station, provided there are no users set at
        the bay level.
      </p>
      <ManageAuthorized
        fetchUsers={props.fetch}
        updateAuthorized={{ update: props.update, remove: props.remove }}
        maxItemsPerUpdateForManageUsers={1000}
      />
    </>
  );
});
