import React from 'react';
import { Logo } from '../Logo';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { RegisterForm } from './register-form';

export const Register = () => {
  return (
    <Row className="register">
      <Col style={{ marginBottom: 32, textAlign: 'center' }}>
        <Link to="/login">
          <Logo />
        </Link>
      </Col>

      <RegisterForm />
    </Row>
  );
};
