import React from 'react';
import { Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export const rfidToHexPad = (value: string): string => {
  return (getRfidFormat() === 'decimal' ? parseInt(value, 10).toString(16) : value).padStart(10, '0').toUpperCase();
};

export const changeFormat = (value: string): string => {
  if (value === '' || value == null) {
    return value;
  }

  return getRfidFormat() === 'decimal'
    ? parseInt(value, 16).toString()
    : parseInt(value, 10)
        .toString(16)
        .toUpperCase();
};

export const hexToInputFieldFormat = (value: string): string => {
  if (value === '' || value == null) {
    return value;
  }

  return getRfidFormat() === 'decimal'
    ? parseInt(value, 16).toString()
    : parseInt(value, 16)
        .toString(16)
        .toUpperCase();
};

export const getRfidFormat = (): string => localStorage.getItem('rfidFormat') || 'hex';

export const rfidFormatTooltip: React.ReactNode = (
  <Tooltip
    title="Some RFID scanners read the RFID in different ways. To
  ensure that the number you enter matches the number read by the station,
  you can perform a test read at the station. To do this, log into the Admin
  menu on the station display, select ‘Test read RFID’ and then scan an RFID
  card. The RFID will be shown on the screen and will also be logged to the
  event log, which can be accessed through the ‘Event logs’ section of the
  Cloud Portal.">
    <InfoCircleOutlined className="external-rfid-tooltip" />
  </Tooltip>
);

export const FormatSelector = (props: { onChange: () => void; disabled?: boolean }) => {
  const handleChange = (format: string) => {
    localStorage.setItem('rfidFormat', format);
    props.onChange();
  };

  return (
    <Select
      data-test-id="select-rfid-format"
      defaultValue={getRfidFormat()}
      onChange={handleChange}
      disabled={props.disabled}
      style={{ width: 110 }}>
      <Select.Option value="hex">Hex</Select.Option>
      <Select.Option value="decimal">Decimal</Select.Option>
    </Select>
  );
};
