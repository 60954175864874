import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

type Props = {
  defaultValue: string;
  loading: boolean;
  onSubmit: (value: string) => void;
};

export const EditableLabel = (props: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedValue, setEditedValue] = useState<string>();
  const [hasIssue, setHasIssue] = useState<boolean>(false);

  useEffect(() => {
    setIsEditing(false);
  }, [props.defaultValue]);

  const submitValue = () => {
    if (!editedValue || editedValue.trim().length === 0) {
      setHasIssue(true);
    } else {
      setHasIssue(false);
      if (editedValue.trim() !== props.defaultValue) {
        props.onSubmit(editedValue.trim());
      } else {
        setIsEditing(false);
      }
    }
  };

  const triggerSubmit = () => !props.loading && submitValue();

  return (
    <>
      {isEditing ? (
        <>
          <Input
            className={hasIssue ? 'has-error' : ''}
            size="small"
            value={editedValue}
            disabled={props.loading}
            onChange={e => {
              setHasIssue(false);
              setEditedValue(e.target.value);
            }}
            onPressEnter={triggerSubmit}
            suffix={
              props.loading ? (
                <LoadingOutlined onClick={triggerSubmit} />
              ) : hasIssue ? (
                <CloseOutlined onClick={triggerSubmit} />
              ) : (
                <CheckOutlined onClick={triggerSubmit} />
              )
            }
          />
        </>
      ) : (
        <>
          {props.defaultValue}
          <EditOutlined
            onClick={() => {
              setEditedValue(props.defaultValue);
              setIsEditing(true);
            }}
            style={{ marginLeft: 10 }}
          />
        </>
      )}
    </>
  );
};
