import React, { useState, Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Row, Slider } from 'antd';
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import { DashboardActionTypes, DayOfTheWeek } from '../../../store/dashboard/types';
import * as ActionTypes from '../../../store/actionTypes';
import { CurfewScheduleDto } from '@pclocs/platform-sdk';
import { RootState } from '../../../store/rootReducer';
import * as _ from 'lodash';

const mapStateToProps = (state: RootState) => ({
  ..._.pick(state.dashboard, ['curfewSchedule', 'showCurfewModal'])
});
const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes>) => ({
  addCurfewSlider: (dayOfTheWeek: DayOfTheWeek, schedule: CurfewScheduleDto) =>
    dispatch({ type: ActionTypes.ADD_CURFEW_SLIDER, payload: { schedule, dayOfTheWeek } }),
  updateCurfewSlider: (index: number, dayOfTheWeek: DayOfTheWeek, schedule: CurfewScheduleDto) =>
    dispatch({ type: ActionTypes.UPDATE_CURFEW_SLIDER, payload: { schedule, dayOfTheWeek, index } }),
  removeCurfewSlider: (dayOfTheWeek: DayOfTheWeek, index: number) =>
    dispatch({ type: ActionTypes.REMOVE_CURFEW_SLIDER, payload: { dayOfTheWeek, index } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  dayOfTheWeek: DayOfTheWeek;
  indexOfTheWeek: number;
  start: number;
  end: number;
  add?: boolean;
  delete?: boolean;
  pending: boolean;
  index: number;
};

export const CurfewSlider = connector((props: Props) => {
  const [time, setTime] = useState({ start: props.start, end: props.end === 0 ? 86400 : props.end });

  const secondsToTimeString = (seconds: number) => {
    const hours: number = Math.floor(seconds / 3600);
    const minutes: number = Math.floor((seconds % 3600) / 60);

    return ('0' + hours).slice(-2) + ':' + ('0' + Math.round(minutes)).slice(-2);
  };

  const updateSliderValue = (value: any) => {
    value[1] - value[0] >= 600 && setTime({ start: value[0], end: value[1] });
  };

  const addCurfewSlider = () => {
    !props.pending &&
      props.addCurfewSlider(props.dayOfTheWeek, {
        dayOfTheWeek: props.indexOfTheWeek,
        startTime: '00:00',
        endTime: '00:00'
      });
  };

  const updateCurfewSlider = () => {
    props.updateCurfewSlider(props.index, props.dayOfTheWeek, {
      dayOfTheWeek: props.indexOfTheWeek,
      startTime: secondsToTimeString(time.start),
      endTime: time.end === 86400 ? '00:00' : secondsToTimeString(time.end)
    });
  };

  const removeCurfewSlider = () => {
    !props.pending && props.removeCurfewSlider(props.dayOfTheWeek, props.index);
  };

  useEffect(() => {
    setTime({ start: props.start, end: props.end === 0 ? 86400 : props.end });
  }, [props.showCurfewModal]);

  return !props.delete ? (
    <PlusCircleFilled style={{ lineHeight: '40px', marginLeft: 5 }} onClick={addCurfewSlider} />
  ) : (
    <Row style={{ alignItems: 'center' }}>
      <Col span={18}>
        <Slider
          range
          min={0}
          max={86400}
          step={600}
          value={[time.start, time.end]}
          onChange={updateSliderValue}
          onAfterChange={updateCurfewSlider}
          tooltipVisible={false}
          disabled={props.pending}
        />
      </Col>
      <Col span={4}>
        <div style={{ marginLeft: 20 }}>
          {secondsToTimeString(time.start)} - {time.end === 86400 ? '00:00' : secondsToTimeString(time.end)}
        </div>
      </Col>
      <Col span={2}>
        <DeleteFilled style={{ marginLeft: 5, marginRight: 5 }} onClick={removeCurfewSlider} />
        {props.add && <PlusCircleFilled onClick={addCurfewSlider} />}
      </Col>
    </Row>
  );
});
