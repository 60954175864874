import React from 'react';
import { Divider } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { RootState } from '../../../store/rootReducer';
import _ from 'lodash';
import {
  NetworkSettingsDto,
  NetworkInterfaceSettingsDto,
  NetworkInterfaceSettingsDtoDhcpEnum,
  NetworkWpaSupplicantConfigDtoKeyMgmtEnum
} from '@pclocs/platform-sdk';

type NetworkStatusProps = {
  reported: NonNullable<RootState['dashboard']['station']>['reported'];
};

type NetworkDto = NetworkInterfaceSettingsDto & {
  connected: boolean;
  hwAddr?: string;
  ipv4?: string;
  ipv6?: string;
};

type NetworkStatusDto = {
  wifi?: NetworkDto;
  ethernet?: NetworkDto;
};

const UnavailableNetwork = () => (
  <div className="network-details">
    <Divider orientation="left" style={{ fontSize: '1rem' }}>
      Network status:
    </Divider>
    <div>
      <p style={{ marginBottom: '1rem', fontSize: 'inherit' }}>
        <CloseCircleTwoTone twoToneColor="#e74c3c" style={{ marginRight: 5 }} />
        Unavailable
      </p>
    </div>
    <Divider />
  </div>
);

export const NetworkStatus = (props: NetworkStatusProps) => {
  if (
    !_.isObject(props.reported.networkSettings) ||
    !('wifi' in props.reported.networkSettings || 'ethernet' in props.reported.networkSettings) ||
    !_.isObject(props.reported.networkStatus) ||
    !('wifi' in props.reported.networkStatus || 'ethernet' in props.reported.networkStatus)
  ) {
    return <UnavailableNetwork />;
  }

  const networkSettings: NetworkSettingsDto = props.reported.networkSettings;
  const networkStatus: NetworkStatusDto = props.reported.networkStatus;
  const isWifiConnected: boolean = _.get(networkStatus, 'wifi.connected');
  let network: NetworkDto;
  let dhcp: NetworkInterfaceSettingsDtoDhcpEnum | undefined;

  if (isWifiConnected && networkSettings.wifi && networkStatus.wifi) {
    dhcp = networkSettings.wifi.dhcp;
    network = networkStatus.wifi;
  } else if (networkSettings.ethernet && networkStatus.ethernet) {
    dhcp = networkSettings.ethernet.dhcp;
    network = networkStatus.ethernet;
  } else {
    return <UnavailableNetwork />;
  }

  const ipVersion: NetworkInterfaceSettingsDtoDhcpEnum =
    dhcp === NetworkInterfaceSettingsDtoDhcpEnum.Both || dhcp === NetworkInterfaceSettingsDtoDhcpEnum.None
      ? NetworkInterfaceSettingsDtoDhcpEnum.Ipv4
      : dhcp;
  const { gateway, dns, hwAddr } = network;

  const connectedInterfaceName: string = isWifiConnected ? 'WiFi' : 'Ethernet';
  const wifiSecurityDisplayText: Record<NetworkWpaSupplicantConfigDtoKeyMgmtEnum, string> = {
    [NetworkWpaSupplicantConfigDtoKeyMgmtEnum.NONE]: 'Open',
    [NetworkWpaSupplicantConfigDtoKeyMgmtEnum.WPAPSK]: 'WPA/WPA2 Personal',
    [NetworkWpaSupplicantConfigDtoKeyMgmtEnum.WPAEAP]: 'WPA/WPA2 Enterprise'
  };
  const dhcpDisplayText: Record<NetworkInterfaceSettingsDtoDhcpEnum, string> = {
    [NetworkInterfaceSettingsDtoDhcpEnum.None]: 'None (Static IP)',
    [NetworkInterfaceSettingsDtoDhcpEnum.Both]: 'DHCP',
    [NetworkInterfaceSettingsDtoDhcpEnum.Ipv4]: 'DHCP IPv4',
    [NetworkInterfaceSettingsDtoDhcpEnum.Ipv6]: 'DHCP IPv6'
  };
  // const netmask: string = '!_255.255.255.0'; // todo: do later in separate task, needs a firmware change

  return (
    <div className="network-details">
      <Divider orientation="left" style={{ fontSize: '1rem' }}>
        Network status:
      </Divider>
      <div>
        <p style={{ marginBottom: '1rem', fontSize: 'inherit' }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: 5 }} />
          Station is configured to use <b>{connectedInterfaceName}</b>.
        </p>
        {isWifiConnected && (
          <>
            {networkSettings.wifi?.wpaSupplicantConfig?.keyMgmt && (
              <p>
                Security:{' '}
                {
                  wifiSecurityDisplayText[
                    networkSettings.wifi.wpaSupplicantConfig.keyMgmt as NetworkWpaSupplicantConfigDtoKeyMgmtEnum
                  ]
                }
              </p>
            )}
            <p>SSID: {networkSettings.wifi?.wpaSupplicantConfig?.ssid}</p>
          </>
        )}
        {<p>DHCP: {dhcp && dhcpDisplayText[dhcp]}</p>}
        {<p>IP address: {ipVersion && network[ipVersion]}</p>}
        {/*<p>Netmask: {netmask}</p> todo: do later in separate task, needs a firmware change*/}
        <p>Gateway: {gateway && (Array.isArray(gateway) ? gateway.join(', ') : gateway)}</p>
        <p>DNS: {dns && dns.join(', ')}</p>
        <p>MAC address: {hwAddr}</p>
      </div>
      <Divider />
    </div>
  );
};
