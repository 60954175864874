import _ from 'lodash';
import semverGt from 'semver/functions/gt';
import semverValid from 'semver/functions/valid';

export const MINIMUM_FIRMWARE_VERSION = '2.3.0';

export const firmwareValidAndGt = _.memoize(
  (desiredFirmware: string, stationFirmware: string) => {
    return semverValid(desiredFirmware) && semverValid(stationFirmware)
      ? semverGt(desiredFirmware, stationFirmware)
      : false;
  },
  (s, l) => `${s}|${l}`
);
