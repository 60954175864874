import _ from 'lodash';
import { SubscriptionResponseDto, SubscriptionResponseDtoTierEnum } from '@pclocs/platform-sdk';

export const adminTierOrAbove = (subscriptionInfo?: SubscriptionResponseDto): boolean => {
  return (
    !!subscriptionInfo &&
    subscriptionInfo.subscriptionQuantity >= subscriptionInfo.usedQuantity &&
    _.includes(
      [
        SubscriptionResponseDtoTierEnum.Admin,
        SubscriptionResponseDtoTierEnum.Essentials,
        SubscriptionResponseDtoTierEnum.Advanced
      ],
      subscriptionInfo?.tier
    )
  );
};

export const essentialsTierOrAbove = (subscriptionInfo?: SubscriptionResponseDto): boolean => {
  return (
    !!subscriptionInfo &&
    subscriptionInfo.subscriptionQuantity >= subscriptionInfo.usedQuantity &&
    _.includes(
      [SubscriptionResponseDtoTierEnum.Essentials, SubscriptionResponseDtoTierEnum.Advanced],
      subscriptionInfo?.tier
    )
  );
};

export const advancedTier = (subscriptionInfo?: SubscriptionResponseDto): boolean => {
  return (
    !!subscriptionInfo &&
    subscriptionInfo.subscriptionQuantity >= subscriptionInfo.usedQuantity &&
    subscriptionInfo?.tier === SubscriptionResponseDtoTierEnum.Advanced
  );
};
