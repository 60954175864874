import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Button, Empty, Spin } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

export type InfinityListProps<T extends unknown> = {
  pageSize: number;
  loading: boolean;
  items: T[];
  fetchError?: string;
  hasMoreItems: boolean;
  loadItems: () => void;
  render: (item: T) => JSX.Element;
};

export const InfinityList = <T extends unknown>(props: InfinityListProps<T>) => {
  const ErrorBlock = () => (
    <>
      <span style={{ display: 'block' }} data-test-id="infinity-list-fetch-error">
        {props.fetchError}
      </span>
      <Button icon={<RedoOutlined />} onClick={() => props.loadItems()} data-test-id="infinity-list-reload-items">
        Try again
      </Button>
    </>
  );

  const noItems = !props.loading && !props.fetchError && !props.hasMoreItems && props.items.length === 0;
  const extraItemRender = !!props.loading || !!props.fetchError || noItems;

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <InfiniteLoader
            isItemLoaded={index => props.items.length > index}
            itemCount={Number.MAX_SAFE_INTEGER}
            threshold={Math.floor(props.pageSize / 2)}
            minimumBatchSize={props.pageSize}
            loadMoreItems={() => {
              if (!props.loading && !props.fetchError && props.hasMoreItems) {
                props.loadItems();
              }
              return null;
            }}>
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                className="infinity-list"
                height={height}
                width={width}
                itemCount={props.items.length + (extraItemRender ? 1 : 0)}
                itemSize={35}
                onItemsRendered={onItemsRendered}
                ref={ref}>
                {({ index, style }) => {
                  if (props.items.length === 0) {
                    return (
                      <div
                        className="empty-list"
                        style={{
                          border: 0,
                          height: height - 2,
                          textAlign: 'center',
                          display: 'flex',
                          flexGrow: 1,
                          justifyContent: 'center',
                          flexDirection: 'column'
                        }}>
                        <div style={{ display: 'inline-block' }}>
                          {!!props.loading && <Spin />}
                          {!!props.fetchError && <ErrorBlock />}
                          {noItems && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>
                      </div>
                    );
                  } else if (index === props.items.length && extraItemRender) {
                    return (
                      <div style={{ ...style, height: 'auto', margin: '10px 0', textAlign: 'center' }}>
                        {props.fetchError ? <ErrorBlock /> : <Spin />}
                      </div>
                    );
                  } else {
                    return (
                      <div className="list-item" style={style}>
                        {props.render(props.items[index])}
                      </div>
                    );
                  }
                }}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};
