import { Popconfirm, Select } from 'antd';
import React, { useState } from 'react';
import { AccountBasicResponseDtoRfidFormatEnum as RfidFormat } from '@pclocs/platform-sdk';

export { AccountBasicResponseDtoRfidFormatEnum as RfidFormat } from '@pclocs/platform-sdk';

export const RfidFormatSetting = (props: {
  value: RfidFormat;
  onChange: (v: RfidFormat) => void;
  loading: boolean;
}) => {
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<RfidFormat>(undefined);

  return (
    <>
      <Popconfirm
        placement="bottom"
        title="Are you sure you want to change the RFID format for this account?"
        cancelText="No"
        okText="Yes"
        visible={popupVisible}
        onConfirm={() => {
          setPopupVisible(false);
          props.onChange(selectedValue);
        }}
        onCancel={() => {
          setPopupVisible(false);
        }}>
        <Select
          style={{ width: 350 }}
          optionLabelProp="label"
          onChange={(v: RfidFormat) => setSelectedValue(v)}
          onSelect={(v: RfidFormat) => v !== props.value && setPopupVisible(true)}
          value={props.loading || popupVisible ? selectedValue : props.value}
          loading={props.loading}
          disabled={props.loading}>
          <Select.Option value={RfidFormat.Hex} label="Hex">
            <span>HEX</span>
            <br />
            <small>A hex representation of the value. Eg AB1246</small>
          </Select.Option>
          <Select.Option value={RfidFormat.Decimal} label="Decimal">
            <span>DECIMAL</span>
            <br />
            <small>A numeric representation of the value. Eg 11211334</small>
          </Select.Option>
        </Select>
      </Popconfirm>
      <p>
        <small>The format that your RFIDs are stored in from your external system.</small>
      </p>
    </>
  );
};
