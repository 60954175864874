import React from 'react';
import { Button, Input, Spin } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Link } from 'react-router-dom';
import { getErrorAlertMessage, Props } from '../login-form';
import { DegradationStatusBanner } from '../../DegradationStatusBanner';

export const DefaultLoginForm = (properties: { props: Props }) => {
  const props = properties.props;

  return (
    <>
      <div>
        <LegacyForm.Item>
          {props.form.getFieldDecorator('username', {
            rules: [{ required: true, type: 'email', message: 'Please input a valid email address!' }]
          })(<Input prefix={<UserOutlined />} placeholder="Email" />)}
        </LegacyForm.Item>

        <LegacyForm.Item>
          {props.form.getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your password!' }]
          })(<Input.Password prefix={<LockOutlined />} placeholder="Password" />)}
        </LegacyForm.Item>
      </div>

      {props.error && getErrorAlertMessage(props)}

      <LegacyForm.Item>
        <Spin spinning={props.pending === true}>
          <Button block type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
        </Spin>
        <Link className="login-forgot-password-link" to="/login/forgot-password">
          I forgot my password.
        </Link>
      </LegacyForm.Item>

      <LegacyForm.Item>
        <Button block type="default" className="sso-sign-in-button">
          <Link to="/login/sso-sign-in">Sign in with SSO</Link>
        </Button>
      </LegacyForm.Item>

      <DegradationStatusBanner />
    </>
  );
};
