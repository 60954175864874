import React from 'react';
import { ReportDataType, ReportsPayload } from '../../store/reports/types';
import { Skeleton, Tooltip } from 'antd';
import { InfinityList } from '../InfinityList';
import { ReportListItem } from './report-list-item';
import { ReportColumn } from '../../constants/reports';

type Props = {
  accountId: string;
  nextPage?: string;
  pageSize: number;
  reportData: ReportDataType[];
  columns: ReportColumn[] | undefined;
  isReportsGenerating: boolean;
  isPageLoading: boolean;
  fetchError?: string;
  fetchNextReportPage: (reportProperties: ReportsPayload) => void;
  reportProperties: ReportsPayload;
};

export const TableReport = (props: Props) => {
  const hasMoreItems: boolean = !!props.nextPage?.length && props.pageSize <= props.reportData.length;

  return (
    <Skeleton loading={props.isReportsGenerating} active>
      {props.reportData.length > 0 && (
        <div className="content-section">
          <div className="reports-list-header">
            {props.columns?.map((val, key) => {
              const alignmentClass = val.align ? ` alignment-${val.align}` : '';
              const tooltipClass = val.description ? ` tooltip` : '';
              return (
                <h6
                  key={key}
                  className={`reports-list-header-item${alignmentClass}${tooltipClass}`}
                  style={{ width: val.width }}>
                  {val.description ? <Tooltip title={val.description}>{val.header}</Tooltip> : val.header}
                </h6>
              );
            })}
          </div>
          <div className="content-section-inner reports-list-content">
            <InfinityList
              pageSize={props.pageSize}
              items={props.reportData}
              loading={props.isPageLoading}
              hasMoreItems={hasMoreItems}
              fetchError={props.fetchError}
              loadItems={props.fetchNextReportPage.bind(null, props.reportProperties)}
              render={(report: ReportDataType) => (
                <ReportListItem
                  accountId={props.accountId}
                  columns={props.columns}
                  item={(report as unknown) as Record<string, string>}
                />
              )}
            />
          </div>
        </div>
      )}
    </Skeleton>
  );
};
