import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Input, Tooltip, Upload } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { CloseOutlined, FileOutlined, InfoCircleOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import * as ActionTypes from '../../../store/actionTypes';
import { ShowNotificationMessageAction } from '../../../store/common/types';
import { history } from '../../../store/store';
import { useParams } from 'react-router';
import { getErrorAlertMessage } from '../../../helpers/form-helper';
import { SamlIdentityProviderResponseDto } from '@pclocs/platform-sdk';
import { USER_POOL_DOMAIN } from '../../../api/amplify';
import { joinErrorMessages } from '../../../api/sdk';
import { isValidationExceptionError, useEverySdkCall, UseSdkCallError } from '../../../helpers/sdk-hooks';
import { CollapsibleFormSection } from '../../../helpers/collapsible-form-section';
import { performFileUpload } from '../../../helpers/file-upload';
import { CopyToClipboard } from '../../CopyToClipboard';

const ENTITY_ID = `urn:amazon:cognito:sp:${process.env.USER_POOL_ID}`;
const ACS_URL = `https://${USER_POOL_DOMAIN}/saml2/idpresponse`;

const ATTRIBUTE_MAPPINGS = Object.freeze({
  name: 'Name',
  email: 'Email',
  'custom:external_roles': 'Roles'
});

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch<ShowNotificationMessageAction>) => ({
  showNotification: (type: 'success' | 'error', content: string): void =>
    dispatch({ type: ActionTypes.SHOW_NOTIFICATION_MESSAGE, payload: { type, content } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux &
  FormComponentProps & {
    identityProvider?: SamlIdentityProviderResponseDto;
    fetching: boolean;
    readonly: boolean;
  };

export const SamlIdentityProviderForm = connector(
  LegacyForm.create<Props>({ name: 'SamlIdentityProvider' })((props: Props) => {
    const { accountId, providerName } = useParams<{ accountId: string; providerName: string }>();
    const [metadataFile, setMetadataFile] = useState<{ name: string; ref?: string; uploading?: boolean }>();
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const identityProvidersPath = `/auth/${accountId}/integrations/identity-providers`;

    const handleErrorResponse = (error: UseSdkCallError, notificationError: string) => {
      if (isValidationExceptionError(error)) {
        setErrorMessages(joinErrorMessages(error.data));
      } else {
        props.showNotification('error', notificationError);
      }
    };

    const [createIdentityProvider, creatingIdentityProvider] = useEverySdkCall(
      'IdentityProviderApi',
      'createSamlIdentityProvider',
      () => {
        props.showNotification('success', 'SAML SSO provider has been successfully created.');
        history.push(identityProvidersPath);
      },
      err => handleErrorResponse(err, 'Could not create SAML SSO provider.')
    );

    const [updateIdentityProvider, updatingIdentityProvider] = useEverySdkCall(
      'IdentityProviderApi',
      'updateSamlIdentityProvider',
      () => {
        props.showNotification('success', 'SAML SSO provider has been successfully updated.');
        history.push(identityProvidersPath);
      },
      err => handleErrorResponse(err, 'Could not update SAML SSO provider.')
    );

    const uploadMetadataDocument = async (file: File) => {
      props.form.setFieldsValue({ metadataUrl: '' });
      setMetadataFile({ name: file.name, uploading: true });
      const fileRef: string = await performFileUpload(file, 'text/xml');
      setMetadataFile({ name: file.name, ref: fileRef });
    };

    useEffect(() => {
      props.form.setFieldsValue({
        metadataUrl: props.identityProvider?.metadataUrl,
        attributeMappings: props.identityProvider?.attributeMappings
      });
      props.identityProvider?.hasMetadataFile && setMetadataFile({ name: 'Metadata XML' });
    }, [props.identityProvider]);

    const { getFieldDecorator } = props.form;

    const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      props.form.validateFieldsAndScroll(async (err: Error, values: any) => {
        if (!err) {
          setErrorMessages([]);
          const params = {
            ...values,
            providerTypes: 'SAML',
            metadataFile: metadataFile?.ref ?? null
          };
          if (providerName) {
            updateIdentityProvider(providerName, params);
          } else {
            createIdentityProvider(params);
          }
        }
      });
    };

    const pending = props.fetching || creatingIdentityProvider || updatingIdentityProvider;

    const SSO_URL = `${window.location.origin}/login/sso-sign-in?provider=${providerName}`;

    return (
      <LegacyForm
        className="content-section-inner"
        {...{
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 }
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 }
          }
        }}>
        <LegacyForm.Item label="Provider Name">
          {!props.identityProvider
            ? getFieldDecorator('providerName', {
                rules: [
                  { required: true, message: 'Please input provider name!' },
                  { min: 3, message: 'Provider Name must have at least 3 characters' },
                  { max: 25, message: 'Provider Name must have maximum 25 characters' },
                  {
                    validator: (_rule, value, callback) =>
                      callback(
                        !value || /^[a-zA-Z0-9\-\.]+$/.test(value)
                          ? undefined
                          : 'Provider Name must only contain letters, numbers, hyphens or dots'
                      )
                  }
                ]
              })(<Input disabled={props.readonly} />)
            : props.identityProvider.providerName}
        </LegacyForm.Item>

        <LegacyForm.Item label="Metadata document">
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            {metadataFile ? (
              <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                {metadataFile.uploading ? <LoadingOutlined /> : <FileOutlined />}
                <span style={{ marginLeft: 10 }}>{metadataFile.name}</span>
                {!metadataFile.uploading && (
                  <Button size="small" style={{ marginLeft: 10 }} onClick={() => setMetadataFile(undefined)}>
                    <CloseOutlined />
                  </Button>
                )}
              </div>
            ) : (
              <>
                <Upload
                  key="preview"
                  accept=".xml"
                  customRequest={options => uploadMetadataDocument(options.file as File)}
                  disabled={props.readonly}
                  className="xml-upload-wrapper">
                  <Button>
                    <UploadOutlined /> Choose File
                  </Button>
                </Upload>
                &nbsp;OR&nbsp;
                {getFieldDecorator('metadataUrl', {
                  rules: [
                    { required: !metadataFile, message: 'Please provide metadata document!' },
                    {
                      validator: (_domain, value, callback) =>
                        callback(
                          !value || value.startsWith('https://')
                            ? undefined
                            : 'Metadata endpoint URL must start with protocol https://'
                        )
                    }
                  ]
                })(<Input placeholder="Provide metadata document endpoint URL" disabled={props.readonly} />)}
              </>
            )}
          </div>
        </LegacyForm.Item>

        <CollapsibleFormSection
          title="Attribute Mappings"
          startShown={!!props.identityProvider}
          wrapperCol={{ sm: { offset: 6, span: 18 }, xxl: { span: 12 } }}>
          {Object.entries(ATTRIBUTE_MAPPINGS).map(([field, name]) => (
            <LegacyForm.Item key={field} label={name}>
              {getFieldDecorator(`attributeMappings.${field}`, {
                initialValue:
                  props.identityProvider?.attributeMappings[
                    field as keyof typeof props.identityProvider.attributeMappings
                  ]
              })(<Input disabled={props.readonly} />)}
            </LegacyForm.Item>
          ))}
        </CollapsibleFormSection>

        <LegacyForm.Item label="Entity ID">
          <pre style={{ marginBottom: 0 }}>
            <CopyToClipboard data={ENTITY_ID} title="Copy entity ID to clipboard" />
            &nbsp;{ENTITY_ID}
          </pre>
        </LegacyForm.Item>

        <LegacyForm.Item
          label={
            <span>
              ACS URL&nbsp;
              <Tooltip title="Assertion Consumer Service (ACS) URL is an authorization endpoint to validate the SAML response from your provider">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }>
          <pre style={{ marginBottom: 0 }}>
            <CopyToClipboard data={ACS_URL} title="Copy ACS URL to clipboard" />
            &nbsp;{ACS_URL}
          </pre>
        </LegacyForm.Item>

        {providerName && (
          <LegacyForm.Item label="SSO URL">
            <pre style={{ marginBottom: 0 }}>
              <CopyToClipboard data={SSO_URL} title="Copy SSO URL to clipboard" />
              &nbsp;{SSO_URL}
            </pre>
          </LegacyForm.Item>
        )}

        {errorMessages.length > 0 && getErrorAlertMessage(errorMessages)}

        <LegacyForm.Item
          {...{
            wrapperCol: {
              xs: { span: 22, offset: 1 },
              sm: { span: 10, offset: 6 }
            }
          }}>
          <Button
            key="back"
            className="cancel-btn"
            style={{ display: pending ? 'none' : 'initial' }}
            onClick={() => history.push(identityProvidersPath)}>
            Cancel
          </Button>
          <Button
            key="submit"
            className="submit-btn"
            type="primary"
            loading={pending}
            disabled={props.readonly || metadataFile?.uploading}
            onClick={handleSubmit}>
            Save
          </Button>
        </LegacyForm.Item>
      </LegacyForm>
    );
  })
);
