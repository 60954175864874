import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { CloseOutlined } from '@ant-design/icons';
import _ from 'lodash';

interface Props extends Omit<InputProps, 'onChange'> {
  value?: string;
  onChange?: (v: string | undefined) => void;
  hasPassword: boolean;
}

export const PasswordFormItem = forwardRef((props: Props, ref) => {
  useImperativeHandle(ref, () => ({}), []);

  const [inEditMode, setInEditMode] = useState<boolean>(!props.hasPassword);
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.value) {
      setInputValue(props.value);
    }
  }, [props.value]);

  return (
    <>
      {inEditMode && (
        <Input
          type="password"
          {..._.omit(props, 'hasPassword')}
          value={inputValue}
          onChange={e => {
            const newValue = e.target.value;
            setInputValue(newValue);
            props.onChange?.(newValue === '' ? null : newValue);
          }}
        />
      )}
      {!inEditMode && (
        <>
          <span>&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</span>
          <Button
            size="small"
            style={{ marginLeft: 10 }}
            onClick={() => {
              setInEditMode(true);
              props.onChange?.(null);
            }}>
            <CloseOutlined />
          </Button>
        </>
      )}
    </>
  );
});
