import React from 'react';
import pcLocsLogo from '../../assets/images/pc-locs.png';
import locknchargeLogo from '../../assets/images/lockncharge.png';
import { getBrandName, isBrandPCLocs } from '../../helpers/brand-helper';
import { Tag } from 'antd';
import _ from 'lodash';

export const Logo = (props: { width?: number }) => {
  return (
    <>
      <img
        className="logo"
        src={isBrandPCLocs() ? pcLocsLogo : locknchargeLogo}
        alt={`${getBrandName()} logo`}
        width={props.width ? props.width : ''}
      />
      {process.env.PCLOCS_ENV !== 'production' && (
        <Tag className="ant-tag-info" style={{ height: 'fit-content', marginLeft: 30 }}>
          Environment: {_.startCase(process.env.PCLOCS_ENV)}
        </Tag>
      )}
    </>
  );
};
