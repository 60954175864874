import { Button, Input, Spin } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { SafetyOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { getErrorAlertMessage, Props } from '../Login/login-form';
import React from 'react';

export const MfaForm = (properties: { props: Pick<Props, 'form' | 'error' | 'pendingMfaTotpRegistration'> }) => {
  const props = properties.props;

  return (
    <>
      <LegacyForm.Item>
        {props.form.getFieldDecorator('mfaTotpVerificationToken', {
          rules: [{ required: true, message: 'Please input your One-time password!' }]
        })(<Input prefix={<SafetyOutlined />} type="text" placeholder="One-time password" maxLength={6} />)}
      </LegacyForm.Item>

      {props.error && getErrorAlertMessage(props)}

      <LegacyForm.Item>
        <Spin spinning={props.pendingMfaTotpRegistration === true}>
          <Button block type="primary" htmlType="submit" className="login-form-button" id="mfa-register-btn">
            Register 2FA
          </Button>
        </Spin>
      </LegacyForm.Item>
    </>
  );
};
