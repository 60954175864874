import { CheckDegradationStatusAction, SetDegradationStatusAction } from './types';
import * as ActionTypes from '../actionTypes';

export interface DegradationStatusState {
  status?: string;
}

const defaultState: DegradationStatusState = {
  status: undefined
};

export const degradationStatus = (
  state: DegradationStatusState = defaultState,
  action: SetDegradationStatusAction | CheckDegradationStatusAction
): DegradationStatusState => {
  switch (action.type) {
    case ActionTypes.SET_DEGRADATION_STATUS:
      return {
        ...state,
        status: action.payload.status
      };
    case ActionTypes.CHECK_DEGRADATION_STATUS:
    default:
      return state;
  }
};
