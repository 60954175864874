import {
  AdminClaimWithIdDto,
  AdminClaimWithoutIdDto,
  AdminClaimWithIdDtoTypeEnum,
  AdminClaimWithoutIdDtoTypeEnum
} from '@pclocs/platform-sdk';

export const AdminClaimEnum = AdminClaimWithoutIdDtoTypeEnum;
export const AdminClaimWithIdEnum = AdminClaimWithIdDtoTypeEnum;

export type AdminClaim = AdminClaimWithIdDto | AdminClaimWithoutIdDto;
