import React, { Dispatch, useEffect } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { DatabaseFilled, TabletFilled } from '@ant-design/icons';
import { RootState } from '../../../store/rootReducer';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import { DashboardActionTypes } from '../../../store/dashboard/types';
import * as ActionTypes from '../../../store/actionTypes';
import { useParams } from 'react-router';
import { ChartData, Doughnut, defaults as ChartConfig } from 'react-chartjs-2';
import * as chartjs from 'chart.js';
import { EditableLabel } from '../../EditableLabel';
import { Tags } from '../../Tags';
import { FetchFailedAlert } from '../fetch-failed-alert';

const mapStateToProps = (state: RootState) => ({
  ..._.pick(state.dashboard, ['group', 'groupOverview', 'changeNamePending', 'groupFetchFailed']),
  subscriptionInfo: state.account.subscriptionInfo
});

const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes>) => ({
  fetchGroup: (id: string) => dispatch({ type: ActionTypes.FETCH_GROUP, payload: { id } }),
  fetchGroupOverview: (id: string) => dispatch({ type: ActionTypes.FETCH_GROUP_OVERVIEW, payload: { id } }),
  unloadGroup: () => dispatch({ type: ActionTypes.UNLOAD_GROUP }),
  unloadGroupOverview: () => dispatch({ type: ActionTypes.UNLOAD_GROUP_OVERVIEW }),
  updateGroupName: (id: string, value: string) =>
    dispatch({ type: ActionTypes.UPDATE_GROUP_NAME, payload: { id, value } }),
  updateGroupTags: (id: string, value: string[]) =>
    dispatch({ type: ActionTypes.UPDATE_GROUP_TAGS, payload: { id, value } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const GroupOverview = connector((props: Props) => {
  const { groupId } = useParams<{ groupId: string }>();
  const { group, groupOverview } = props;

  ChartConfig.global.defaultFontSize = 16;

  useEffect(() => {
    if (groupId === undefined) {
      return;
    }

    props.fetchGroupOverview(groupId);
    props.fetchGroup(groupId);

    const pullGroupOverview: any = setInterval(() => {
      props.fetchGroupOverview(groupId);
    }, 60000);

    return () => {
      clearInterval(pullGroupOverview);
      props.unloadGroup();
      props.unloadGroupOverview();
    };
  }, [groupId]);

  const { availableBays, assignedBays, offlineBays } = groupOverview
    ? groupOverview
    : { availableBays: 0, assignedBays: 0, offlineBays: 0 };

  const data: ChartData<chartjs.ChartData> = {
    labels: [`${availableBays} Available`, `${assignedBays} Assigned`, `${offlineBays} Offline`],
    datasets: [
      {
        backgroundColor: ['#39b54a', '#ecf0f1', '#595959'],
        borderColor: ['#39b54a', '#ecf0f1', '#595959'],
        data: [availableBays, assignedBays, offlineBays]
      }
    ]
  };

  const options: chartjs.ChartOptions = { tooltips: { enabled: false } };

  if (props.groupFetchFailed) {
    return <FetchFailedAlert itemType="group" statusCode={props.groupFetchFailed.statusCode} />;
  }

  return !group || !groupOverview || !props.subscriptionInfo ? (
    <Skeleton active />
  ) : (
    <>
      <Row style={{ marginBottom: 15 }}>
        <Col span={12}>
          <EditableLabel
            defaultValue={group.name || group.id}
            loading={!!props.changeNamePending}
            onSubmit={props.updateGroupName.bind(null, group.id)}
          />
        </Col>
      </Row>

      <div style={{ marginBottom: 20 }}>
        <Tags tags={group.tags} updateTags={props.updateGroupTags.bind(null, group.id)} />
      </div>

      <Row style={{ marginBottom: 15 }}>
        <Col span={12}>
          <p style={{ marginBottom: 0, lineHeight: '35px', fontSize: 35 }}>{groupOverview?.stations}</p>
          <p style={{ fontSize: 16 }}>
            <DatabaseFilled /> Stations
          </p>
        </Col>

        <Col span={12}>
          <p style={{ marginBottom: 0, lineHeight: '35px', fontSize: 35 }}>{groupOverview?.bays}</p>
          <p style={{ fontSize: 16 }}>
            <TabletFilled /> Bays
          </p>
        </Col>
      </Row>

      <hr />

      <div>
        <Doughnut data={data} options={options} legend={{ position: 'bottom', labels: { boxWidth: 16 } }} />
      </div>
    </>
  );
});
