import * as ActionTypes from '../actionTypes';
import { RegisterState, RegisterActionTypes } from './types';

const defaultState = {
  pending: false
};

export const register = (state: RegisterState = defaultState, action: RegisterActionTypes): RegisterState => {
  switch (action.type) {
    case ActionTypes.ACCOUNT_CREATED_PAGE_LOADED:
    case ActionTypes.REGISTER_PAGE_LOADED:
      return {
        ...state,
        pending: false
      };
    case ActionTypes.CREATE_ACCOUNT:
      return {
        ...state,
        error: undefined,
        pending: true
      };
    case ActionTypes.VERIFY_EMAIL_ERROR:
      return {
        ...state,
        error: action.payload.error,
        email: action.payload.email,
        pending: false
      };
    case ActionTypes.CREATE_ACCOUNT_ERROR:
      return {
        ...state,
        error: action.payload.error,
        pending: false
      };
    case ActionTypes.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload.account,
        status: 'accountHasBeenCreated',
        pending: false
      };
    case ActionTypes.ACCOUNT_CREATED_PAGE_UNLOADED:
    case ActionTypes.REGISTER_PAGE_UNLOADED:
      return defaultState;
    default:
      return state;
  }
};
