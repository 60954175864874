import React from 'react';
import { Col, Menu } from 'antd';
import { ControlOutlined, DashboardOutlined, TeamOutlined } from '@ant-design/icons';
import { Route, Switch, useParams, Link, useRouteMatch, Redirect } from 'react-router-dom';
import { GroupOverview } from './overview';
import { GroupUsers } from './users';
import { GroupSettings } from './Settings';
import { RootState } from '../../../store/rootReducer';
import { connect, ConnectedProps } from 'react-redux';
import { essentialsTierOrAbove } from '../../../helpers/subscription-helper';
import { subscriptionMessage } from '../../../constants/validations';
import { findGroupNode, useGroupHierarchy } from '../../../helpers/group-hierarchy';

const mapStateToProps = (state: RootState) => ({
  group: state.dashboard.group,
  groupFetchFailed: state.dashboard.groupFetchFailed,
  subscriptionInfo: state.account.subscriptionInfo
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const Group = connector((props: Props) => {
  const { groupId } = useParams<{ groupId: string }>();
  const { url, path } = useRouteMatch();
  const routeTab = useRouteMatch<any>(`${url}/:tab?`)?.params?.tab || 'overview';
  const correctSubscription: boolean = essentialsTierOrAbove(props.subscriptionInfo);
  const groupHierarchy = useGroupHierarchy();
  return (
    <Col className="dashboard-section" span={12}>
      <div className="dashboard-section-title">
        {(props.group?.name ?? findGroupNode(groupHierarchy, groupId)?.name) || <div style={{ height: 24 }} />}
      </div>
      <div className="dashboard-section-inner">
        <Menu selectedKeys={routeTab} mode="horizontal">
          <Menu.Item
            key="overview"
            disabled={!correctSubscription}
            title={!correctSubscription ? subscriptionMessage : undefined}>
            <Link to={`${url}/overview`}>
              <DashboardOutlined /> Overview
            </Link>
          </Menu.Item>
          <Menu.Item
            key="users"
            disabled={!correctSubscription}
            title={!correctSubscription ? subscriptionMessage : undefined}>
            <Link to={`${url}/users`}>
              <TeamOutlined /> Users
            </Link>
          </Menu.Item>
          <Menu.Item key="settings">
            <Link to={`${url}/settings`}>
              <ControlOutlined /> Settings
            </Link>
          </Menu.Item>
        </Menu>
        <Switch>
          {correctSubscription ? (
            <>
              <Route path={`${path}/overview`} component={GroupOverview} />
              <Route path={`${path}/users`} component={GroupUsers} />
              <Route path={`${path}/settings`} component={GroupSettings} />
              <Redirect from="/" to={`${url}/overview`} />
            </>
          ) : (
            <>
              <Route path={`${path}/settings`} component={GroupSettings} />
              <Redirect from="/" to={`${url}/settings`} />
            </>
          )}
        </Switch>
      </div>
    </Col>
  );
});
