import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input, Button, Spin, Alert } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import '@ant-design/compatible/assets/index.css';
import * as ActionTypes from '../../store/actionTypes';
import { RootState } from '../../store/rootReducer';
import { AuthActionTypes } from '../../store/auth/types';
import * as _ from 'lodash';

const mapStateToProps = (state: RootState) => ({ ..._.pick(state.auth, ['error', 'pending']) });
const mapDispatchToProps = (dispatch: Dispatch<AuthActionTypes>) => ({
  signInWithSso: (providerName: string) => dispatch({ type: ActionTypes.SSO_SIGN_IN, payload: { providerName } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & FormComponentProps;

export const SsoSignIn = connector(
  LegacyForm.create({ name: 'sso_signin' })((props: Props) => {
    const { getFieldDecorator } = props.form;

    const provider = new URLSearchParams(window.location.search).get('provider');

    useEffect(() => {
      if (provider) {
        props.form.setFieldsValue({ providerName: provider });
        props.signInWithSso(provider);
      }
    }, [provider]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      props.form.validateFields((err: Error, values: { providerName: string }) => {
        if (!err) {
          props.signInWithSso(values.providerName);
        }
      });
    };

    return (
      <LegacyForm onSubmit={handleSubmit} className="sso-sign-in-form">
        <LegacyForm.Item>
          {getFieldDecorator('providerName', {
            rules: [{ required: true, message: 'Please input your SSO provider name!' }]
          })(<Input placeholder="SSO provider name" />)}
        </LegacyForm.Item>

        {props.error && typeof props.error === 'string' && (
          <LegacyForm.Item style={{ marginBottom: 16 }}>
            <Alert message={props.error} type="error" showIcon />
          </LegacyForm.Item>
        )}

        <LegacyForm.Item>
          <Spin spinning={props.pending === true}>
            <Button block type="primary" htmlType="submit" className="sso-sign-in-form-button">
              Sign in
            </Button>
          </Spin>

          <div className="login-login-link">
            <Link to="/login">Back to login page.</Link>
          </div>
        </LegacyForm.Item>
      </LegacyForm>
    );
  })
);
