import React from 'react';
import { Switch, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { OverrideButton } from '../../Buttons/Override';
import { adminTierOrAbove } from '../../../helpers/subscription-helper';
import { FirmwareUpdateScheduleDto, SubscriptionResponseDto } from '@pclocs/platform-sdk';
import { subscriptionMessage } from '../../../constants/validations';

type Props = {
  derivedValue: FirmwareUpdateScheduleDto;
  isOverridden: boolean;
  showOverrideButton: boolean;
  setAutomaticFirmwareUpdateSchedule: (schedule: FirmwareUpdateScheduleDto) => void;
  toggleAutomaticFirmwareUpdateOverride: (schedule: FirmwareUpdateScheduleDto | null) => void;
  subscriptionInfo?: SubscriptionResponseDto;
  toggleAutomaticFirmwareUpdateOverridePending: boolean;
  toggleAutomaticFirmwareUpdatePending: boolean;
};

export const AutomaticFirmwareUpdate = (props: Props) => {
  const handleOverrideClick = (override: boolean) => {
    props.toggleAutomaticFirmwareUpdateOverride(override ? props.derivedValue : null);
  };
  const incorrectSubscription: boolean = !adminTierOrAbove(props.subscriptionInfo);
  const switchElement: JSX.Element = (
    <div>
      <Switch
        className="automatic-firmware-update-btn"
        disabled={incorrectSubscription || !props.isOverridden || props.toggleAutomaticFirmwareUpdatePending}
        checked={props.derivedValue && props.derivedValue.windows.length > 0}
        loading={props.toggleAutomaticFirmwareUpdatePending}
        onClick={(checked: boolean) => {
          const setMeToStore: FirmwareUpdateScheduleDto = {
            version: 'latest',
            windows: checked
              ? [{ dayOfTheWeek: (null as unknown) as string, startTime: '00:00', endTime: '01:00' }]
              : []
          };
          props.setAutomaticFirmwareUpdateSchedule(setMeToStore);
        }}
      />{' '}
      Allow automatic firmware updates
      <Tooltip title="The firmware update schedule is configured to run everyday from 00:00 to 01:00 local time.">
        <InfoCircleOutlined className="external-ref-id-tooltip" style={{ marginLeft: 5 }} />
      </Tooltip>
      <br />
    </div>
  );
  const overrideBtn: JSX.Element = (
    <OverrideButton
      overridden={props.isOverridden}
      pending={props.toggleAutomaticFirmwareUpdateOverridePending}
      onClick={handleOverrideClick}
      disabled={incorrectSubscription}
    />
  );

  return (
    <div className="content-split">
      {incorrectSubscription ? (
        <Tooltip title={subscriptionMessage} placement="right">
          {switchElement}
        </Tooltip>
      ) : (
        switchElement
      )}
      {props.showOverrideButton &&
        (incorrectSubscription ? (
          <Tooltip title={subscriptionMessage} placement="left">
            <span>{overrideBtn}</span>
          </Tooltip>
        ) : (
          overrideBtn
        ))}
    </div>
  );
};
