import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Tabs, Alert } from 'antd';
import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { RootState } from '../../../store/rootReducer';
import _ from 'lodash';
import { AuthorizedUpdateDto } from '@pclocs/platform-sdk';
import { useFixedSizePageableReducer } from '../../../helpers/pageable-reducer';
import { usePageableSdkCall } from '../../../helpers/sdk-hooks';
import { CompatibleUser, UserInfinityList } from '../../ManageUsersCommon/user-infinity-list';
import { ItemType } from '../../../store/common/types';

type Props = {
  contextId: string;
  authorizedUserIds: string[];
  authorizedUserGroupIds: string[];
  update: (id: string, authorizedEntities: AuthorizedUpdateDto['authorizedEntities']) => void;
  maxItemsPerUpdateForManageUsers: number;
  globalTags: string[] | undefined;
  closeModal: () => void;
};

type FindSomeExtraState = {
  hasFailedOrigins: boolean;
  hasMoreNonPaginatable: boolean;
};

export const ManageAuthorizedModal = (props: Props) => {
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);
  const [allUsersMap, setAllUsersMap] = useState<Record<string, CompatibleUser>>({});
  const [selectedUserGroupsIds, setSelectedUserGroupsIds] = useState<string[]>([]);
  const [allUserGroupsMap, setAllUserGroupsMap] = useState<Record<string, CompatibleUser>>({});
  const [userFindSomeExtraState, setUserFindSomeExtraState] = useState<FindSomeExtraState>({
    hasFailedOrigins: false,
    hasMoreNonPaginatable: false
  });
  const [userGroupFindSomeExtraState, setUserGroupFindSomeExtraState] = useState<FindSomeExtraState>({
    hasFailedOrigins: false,
    hasMoreNonPaginatable: false
  });

  const pageSize: number = 100;

  const { isManageUsersLoading, authorizedEntities } = useSelector((state: RootState) => ({
    isManageUsersLoading: state.common.isManageUsersLoading,
    authorizedEntities: state.common.authorizedEntities
  }));

  const userPager = useFixedSizePageableReducer(
    pageSize,
    usePageableSdkCall(
      'StationUserApi',
      'findSome',
      { name: undefined, tags: undefined },
      (fn, filters, limit, after) => fn(after, limit, filters.name, filters.tags),
      res => {
        setUserFindSomeExtraState({
          hasFailedOrigins: !!res.failedOrigins?.length,
          hasMoreNonPaginatable: !!res.hasMoreNonPaginatable
        });
        return { after: res.after, items: res.items };
      }
    )
  );

  const userGroupPager = useFixedSizePageableReducer(
    pageSize,
    usePageableSdkCall(
      'StationUserGroupApi',
      'findSome',
      { name: undefined },
      (fn, filters, limit, after) => fn(after, limit, filters.name),
      res => {
        setUserGroupFindSomeExtraState({
          hasFailedOrigins: !!res.failedOrigins?.length,
          hasMoreNonPaginatable: !!res.hasMoreNonPaginatable
        });
        return { after: res.after, items: res.items };
      }
    )
  );

  const authorizedLimitExceeded = useMemo(
    () => selectedUsersIds.length + selectedUserGroupsIds.length > props.maxItemsPerUpdateForManageUsers,
    [selectedUsersIds, selectedUserGroupsIds]
  );

  useEffect(() => {
    userPager.loadItems(pageSize);
    userGroupPager.loadItems(pageSize);
  }, []);

  useEffect(() => {
    setAllUsersMap({ ...allUsersMap, ..._.keyBy(userPager.items, 'id') });
  }, [userPager.items]);

  useEffect(() => {
    setAllUserGroupsMap({ ...allUserGroupsMap, ..._.keyBy(userGroupPager.items, 'id') });
  }, [userGroupPager.items]);

  const [authorisedItemType, setAuthorisedItemType] = useState<string>(ItemType.USERS);

  const closeModal = () => {
    props.closeModal();
    setAuthorisedItemType(ItemType.USERS);
  };

  useEffect(() => {
    if (authorizedEntities === undefined && !isManageUsersLoading) {
      closeModal();
    }
  }, [isManageUsersLoading, authorizedEntities]);

  const updateUsers = () => {
    const updatedSelected = {
      stationUsers: _.uniq(props.authorizedUserIds.concat(selectedUsersIds)),
      stationUserGroups: _.uniq(props.authorizedUserGroupIds.concat(selectedUserGroupsIds))
    };
    props.update(props.contextId, { ...updatedSelected, otherClaims: [] });
  };

  const slicedUsers = useMemo(() => selectedUsersIds.slice(0, 3).map(v => allUsersMap[v]), [selectedUsersIds]);
  const slicedUserGroups = useMemo(() => selectedUserGroupsIds.slice(0, 3).map(v => allUserGroupsMap[v]), [
    selectedUserGroupsIds
  ]);

  return (
    <Modal
      title="Authorize users"
      className="authorised-items-modal"
      maskClosable={!isManageUsersLoading}
      visible={true}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal} className="btn-secondary">
          Cancel
        </Button>,
        <Button
          key="submit"
          onClick={updateUsers}
          className="btn-primary"
          loading={isManageUsersLoading}
          disabled={
            isManageUsersLoading ||
            userPager.loading ||
            userGroupPager.loading ||
            (!selectedUsersIds.length && !selectedUserGroupsIds.length) ||
            authorizedLimitExceeded
          }
          data-test-id="authorise-items-submit-btn">
          Authorize selected
        </Button>
      ]}
      destroyOnClose={true}
      width={720}>
      <>
        <Tabs
          size="small"
          className="authorised-items-tab-section"
          animated={false}
          tabBarGutter={30}
          defaultActiveKey={ItemType.USERS}
          activeKey={authorisedItemType}
          onChange={setAuthorisedItemType}>
          <Tabs.TabPane
            key={ItemType.USERS}
            tab={
              <div data-test-id="authorised-users-tab-heading">
                <UserOutlined />
                Users
              </div>
            }>
            <div style={{ height: '50vh', marginBottom: 10 }}>
              <UserInfinityList
                tagsFilterable={true}
                pager={userPager}
                globalTags={props.globalTags ?? []}
                pageSize={pageSize}
                selectable={true}
                selectedIds={selectedUsersIds}
                onSelect={newSelectedIds => setSelectedUsersIds(newSelectedIds)}
                maxSelectableItems={props.maxItemsPerUpdateForManageUsers}
                selectDisabled={authorizedLimitExceeded || isManageUsersLoading}
                unselectDisabled={!selectedUsersIds.length || isManageUsersLoading}
              />
            </div>
            {userFindSomeExtraState.hasFailedOrigins && (
              <Alert type="error" message="Cannot get users from an external directory." showIcon />
            )}
            {userFindSomeExtraState.hasMoreNonPaginatable && (
              <Alert
                showIcon
                type="warning"
                message="External user directory has more results available. Please use filters to refine"
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            key={ItemType.USER_GROUPS}
            tab={
              <div data-test-id="authorised-user-groups-tab-heading">
                <TeamOutlined />
                User groups
              </div>
            }>
            <div style={{ height: '50vh', marginBottom: 10 }}>
              <UserInfinityList
                selectable={true}
                pager={userGroupPager}
                pageSize={pageSize}
                selectedIds={selectedUserGroupsIds}
                onSelect={newSelectedIds => setSelectedUserGroupsIds(newSelectedIds)}
                maxSelectableItems={props.maxItemsPerUpdateForManageUsers}
                selectDisabled={authorizedLimitExceeded || isManageUsersLoading}
                unselectDisabled={!selectedUserGroupsIds.length || isManageUsersLoading}
              />
            </div>
            {userGroupFindSomeExtraState.hasFailedOrigins && (
              <Alert type="error" message="Cannot get groups from an external directory." showIcon />
            )}
            {userGroupFindSomeExtraState.hasMoreNonPaginatable && (
              <Alert
                showIcon
                type="warning"
                message="External user directory has more results available. Please use filters to refine"
              />
            )}
          </Tabs.TabPane>
        </Tabs>
        <div style={{ margin: '15px 0' }}>
          <div className="authorised-items-selected-section">
            <h6>Selected:</h6>
            {!!selectedUsersIds.length && (
              <div className="selected-items">
                <>
                  <UserOutlined />
                  &nbsp;
                  <span>
                    {slicedUsers.map(v => v.name).join(', ')}
                    {selectedUsersIds.length > 3 && <> and {selectedUsersIds.length - 3} others</>}
                  </span>
                </>
              </div>
            )}
            {!!selectedUserGroupsIds.length && (
              <div className="selected-items">
                <>
                  <TeamOutlined />
                  &nbsp;
                  <span>
                    {slicedUserGroups.map(v => v.name).join(', ')}
                    {selectedUserGroupsIds.length > 3 && <> and {selectedUserGroupsIds.length - 3} others</>}
                  </span>
                </>
              </div>
            )}
            {authorizedLimitExceeded && (
              <Alert
                style={{ marginTop: 5, marginBottom: 5 }}
                message={`A maximum of ${props.maxItemsPerUpdateForManageUsers} users and groups can be authorized at a time.`}
                type="error"
              />
            )}
          </div>
        </div>
      </>
    </Modal>
  );
};
