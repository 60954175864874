import * as ActionTypes from '../actionTypes';
import {
  StationUserGroupResponseDto,
  DerivedAuthorizedResponseDto,
  BackgroundJobPendingStatusResponseDto,
  BackgroundJobCompleteStatusResponseDto,
  AuthorizedUpdateDto,
  StationUserResponseDto,
  TreeGroupNodeDto
} from '@pclocs/platform-sdk';
import { AxiosError } from 'axios';
import { ReactNode } from 'react';

interface UnauthorizedModalOptions {
  title: string;
  content: string;
}

interface NotificationModalOptions {
  type: 'error' | 'warning' | 'info';
  title?: string;
  content: string;
}

export interface NotificationMessageOptions {
  type: 'error' | 'info' | 'warning' | 'success' | 'loading';
  content: string | ReactNode;
  key?: string;
  duration?: number;
  onClose?: () => void;
}

export interface ItemRequest {
  ids: string[];
  maxItemsPerUpdateForManageUsers?: number;
}

export enum ItemType {
  USERS = 'users',
  USER_GROUPS = 'userGroups'
}

export type BackgroundJob = BackgroundJobPendingStatusResponseDto | BackgroundJobCompleteStatusResponseDto;

export type TriggeredBackgroundJob = Pick<BackgroundJob, 'id' | 'jobName'>;

export interface ItemState {
  isLoading: boolean;
  unknownIds: string[];
  fetchError?: string;
}

export interface UserState extends ItemState {
  items: StationUserResponseDto[];
}

export interface UserGroupState extends ItemState {
  items: StationUserGroupResponseDto[];
}

export type GroupHierarchy = { [groupId: string]: TreeGroupNodeDto };

export interface CommonState {
  redirectTo?: string | null;
  sideMenuSelectedKey: string | '';
  unauthorizedModal: UnauthorizedModalOptions | null;
  notificationModal: NotificationModalOptions | null;
  notificationMessage: NotificationMessageOptions | null;
  globalTags?: string[];
  derivedFrom?: string | null;
  authorizedEntities?: {
    stationUsers: string[];
    stationUserGroups: string[];
  };
  isManageUsersLoading: boolean;
  tableUsers: UserState;
  tableUserGroups: UserGroupState;
  backgroundJobWatchers: number;
  backgroundJobs?: BackgroundJob[];
  triggeredBackgroundJobs: TriggeredBackgroundJob[];
  apiResponse?: SdkCallSuccessAction['payload'] | SdkCallErrorAction['payload'];
  groupHierarchy?: GroupHierarchy;
}

export interface PageLoadedAction {
  type:
    | typeof ActionTypes.DASHBOARD_PAGE_LOADED
    | typeof ActionTypes.USERS_PAGE_LOADED
    | typeof ActionTypes.USER_GROUPS_PAGE_LOADED
    | typeof ActionTypes.EVENT_LOGS_PAGE_LOADED
    | typeof ActionTypes.REPORTS_PAGE_LOADED
    | typeof ActionTypes.UI_KIT_PAGE_LOADED
    | typeof ActionTypes.INTEGRATIONS_PAGE_LOADED;
}

export interface PageUnloadedAction {
  type:
    | typeof ActionTypes.DASHBOARD_PAGE_UNLOADED
    | typeof ActionTypes.USERS_PAGE_UNLOADED
    | typeof ActionTypes.USER_GROUPS_PAGE_UNLOADED
    | typeof ActionTypes.EVENT_LOGS_PAGE_UNLOADED
    | typeof ActionTypes.REPORTS_PAGE_UNLOADED
    | typeof ActionTypes.UI_KIT_PAGE_UNLOADED
    | typeof ActionTypes.INTEGRATIONS_PAGE_UNLOADED;
}

export interface RedirectAction {
  type: typeof ActionTypes.REDIRECT | typeof ActionTypes.SETUP_SOFTWARE_TOKEN_MFA;
  redirectTo: string | null;
}

export interface FetchGlobalTagsAction {
  type: typeof ActionTypes.FETCH_GLOBAL_TAGS;
}

export interface FetchGlobalTagsSuccessAction {
  type: typeof ActionTypes.FETCH_GLOBAL_TAGS_SUCCESS;
  payload: {
    data: string[];
  };
}

export interface ShowUnauthorizedModalAction {
  type: typeof ActionTypes.SHOW_UNAUTHORIZED_MODAL;
  payload: CommonState['unauthorizedModal'];
}

export interface ShowNotificationModalAction {
  type: typeof ActionTypes.SHOW_NOTIFICATION_MODAL;
  payload: CommonState['notificationModal'];
}

export interface DismissNotificationModalAction {
  type: typeof ActionTypes.DISMISS_NOTIFICATION_MODAL;
}

export interface ShowNotificationMessageAction {
  type: typeof ActionTypes.SHOW_NOTIFICATION_MESSAGE;
  payload: CommonState['notificationMessage'];
}

export interface DashboardStationUsersTabUnloadedAction {
  type: typeof ActionTypes.UNLOAD_MANAGE_USERS_TABLE;
}

export interface FetchAuthorizedUsersAction {
  type:
    | typeof ActionTypes.FETCH_AUTHORIZED_IN_STATION
    | typeof ActionTypes.FETCH_AUTHORIZED_IN_BAY
    | typeof ActionTypes.FETCH_AUTHORIZED_IN_GROUP;
  payload: {
    id: string;
  };
}

export interface FetchUsersInBayFailedAction {
  type: typeof ActionTypes.FETCH_AUTHORIZED_IN_BAY_FAILED;
  payload: {
    id: string;
    statusCode: number;
  };
}

export interface FetchAuthorisedUsersSuccessAction {
  type:
    | typeof ActionTypes.FETCH_AUTHORIZED_IN_STATION_SUCCESS
    | typeof ActionTypes.FETCH_AUTHORIZED_IN_BAY_SUCCESS
    | typeof ActionTypes.FETCH_AUTHORIZED_IN_GROUP_SUCCESS;
  payload: {
    data: DerivedAuthorizedResponseDto;
  };
}

export interface UpdateAuthorizedAction {
  type:
    | typeof ActionTypes.UPDATE_AUTHORIZED_IN_STATION
    | typeof ActionTypes.UPDATE_AUTHORIZED_IN_BAY
    | typeof ActionTypes.UPDATE_AUTHORIZED_IN_GROUP;
  payload: {
    id: string;
    authorizedEntities: AuthorizedUpdateDto['authorizedEntities'];
  };
}

export interface UpdateAuthorisedUsersSuccessAction {
  type:
    | typeof ActionTypes.UPDATE_AUTHORIZED_IN_STATION_SUCCESS
    | typeof ActionTypes.UPDATE_AUTHORIZED_IN_BAY_SUCCESS
    | typeof ActionTypes.UPDATE_AUTHORIZED_IN_GROUP_SUCCESS;
}

export interface UpdateAuthErrorAction {
  type: typeof ActionTypes.UPDATE_MANAGE_AUTHORIZED_ERROR;
}

export interface FetchAuthorisedItemsInManageAction {
  type: typeof ActionTypes.FETCH_AUTH_USERS_IN_MANAGE_TABLE | typeof ActionTypes.FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE;
  payload: {
    ids: string[];
  };
}

export interface FetchAuthorisedItemsInManageSuccessAction {
  type:
    | typeof ActionTypes.FETCH_AUTH_USERS_IN_MANAGE_TABLE_SUCCESS
    | typeof ActionTypes.FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE_SUCCESS;
  payload: {
    data: (StationUserResponseDto | StationUserGroupResponseDto)[];
    idsNotInResponse: string[];
  };
}

export interface FetchAuthorisedItemsInManageErrorAction {
  type:
    | typeof ActionTypes.FETCH_AUTH_USERS_IN_MANAGE_TABLE_ERROR
    | typeof ActionTypes.FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE_ERROR;
  payload: {
    fetchError: string;
  };
}

export interface RemoveAllAuthorisedUsersAction {
  type:
    | typeof ActionTypes.REMOVE_ALL_AUTHORIZED_IN_STATION
    | typeof ActionTypes.REMOVE_ALL_AUTHORIZED_IN_BAY
    | typeof ActionTypes.REMOVE_ALL_AUTHORIZED_IN_GROUP;
  payload: {
    id: string;
  };
}

export interface WatchBackgroundJobs {
  type: typeof ActionTypes.WATCH_BACKGROUND_JOBS | typeof ActionTypes.UNWATCH_BACKGROUND_JOBS;
}

export interface UpdateBackgroundJobs {
  type: typeof ActionTypes.UPDATE_BACKGROUND_JOBS;
  payload: {
    jobs: BackgroundJob[] | undefined;
  };
}

export interface GetTriggeredBackgroundJobs {
  type: typeof ActionTypes.GET_TRIGGERED_BACKGROUND_JOBS;
}

export interface AddTriggeredBackgroundJob {
  type: typeof ActionTypes.ADD_TRIGGERED_BACKGROUND_JOB;
  payload: {
    job: TriggeredBackgroundJob;
  };
}

export interface RemoveTriggeredBackgroundJob {
  type: typeof ActionTypes.REMOVE_TRIGGERED_BACKGROUND_JOB;
  payload: {
    jobId: string;
  };
}

export interface UpdateTriggeredBackgroundJobs {
  type: typeof ActionTypes.UPDATE_TRIGGERED_BACKGROUND_JOBS;
  payload: {
    jobs: TriggeredBackgroundJob[];
  };
}

export interface SdkCallAction {
  type: typeof ActionTypes.SDK_CALL_ACTION;
  payload: {
    requestId: string;
    api: string;
    function: string;
    parameters: unknown[];
  };
}

export interface SdkCallSuccessAction<T = unknown> {
  type: typeof ActionTypes.SDK_CALL_ACTION_SUCCESS;
  payload: {
    requestId: string;
    status: number;
    data: T;
  };
}

export interface SdkCallErrorAction {
  type: typeof ActionTypes.SDK_CALL_ACTION_ERROR;
  payload:
    | {
        requestId: string;
        error: AxiosError;
        status: number;
        data: unknown;
      }
    | {
        requestId: string;
        error: Error;
      };
}

export interface FetchGroupHierarchyAction {
  type: typeof ActionTypes.FETCH_GROUP_HIERARCHY;
  payload?: {
    forceUpdate: true;
  };
}

export interface SetGroupHierarchyAction {
  type: typeof ActionTypes.SET_GROUP_HIERARCHY;
  payload: GroupHierarchy;
}

export type CommonActionTypes =
  | PageLoadedAction
  | PageUnloadedAction
  | FetchGlobalTagsAction
  | FetchGlobalTagsSuccessAction
  | RedirectAction
  | ShowUnauthorizedModalAction
  | ShowNotificationMessageAction
  | ShowNotificationModalAction
  | DismissNotificationModalAction
  | DashboardStationUsersTabUnloadedAction
  | FetchAuthorizedUsersAction
  | FetchAuthorisedUsersSuccessAction
  | FetchUsersInBayFailedAction
  | UpdateAuthorizedAction
  | UpdateAuthorisedUsersSuccessAction
  | UpdateAuthErrorAction
  | FetchAuthorisedItemsInManageAction
  | FetchAuthorisedItemsInManageSuccessAction
  | FetchAuthorisedItemsInManageErrorAction
  | RemoveAllAuthorisedUsersAction
  | WatchBackgroundJobs
  | UpdateBackgroundJobs
  | GetTriggeredBackgroundJobs
  | AddTriggeredBackgroundJob
  | RemoveTriggeredBackgroundJob
  | UpdateTriggeredBackgroundJobs
  | SdkCallAction
  | SdkCallSuccessAction
  | SdkCallErrorAction
  | FetchGroupHierarchyAction
  | SetGroupHierarchyAction;
