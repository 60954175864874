import { AppClientsApi, WebhookApi } from '@pclocs/platform-sdk';
import { message } from 'antd';
import _ from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createApi, joinErrorMessages } from '../../api/sdk';
import * as ActionTypes from '../../store/actionTypes';
import { ShowNotificationMessageAction, ShowNotificationModalAction } from '../common/types';
import * as ActionTypings from './types';

export function* fetchAppClients() {
  yield takeLatest(ActionTypes.FETCH_APP_CLIENTS, function*() {
    try {
      const api = createApi<AppClientsApi>('AppClientsApi');
      const response: ApiReturnType<AppClientsApi['fetchAppClients']> = yield call(api.fetchAppClients);
      yield put<ActionTypings.FetchAppClientsSuccessAction>({
        type: ActionTypes.FETCH_APP_CLIENTS_SUCCESS,
        payload: { appClients: response.data }
      });
    } catch (e) {
      console.error(e);
      yield put<ActionTypings.AppClientErrorAction>({
        type: ActionTypes.APP_CLIENT_ERROR
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: e.response.data.message }
      });
    }
  });
}

export function* createAppClient() {
  yield takeLatest(ActionTypes.CREATE_APP_CLIENT, function*(action: ActionTypings.CreateAppClientAction) {
    try {
      const api = createApi<AppClientsApi>('AppClientsApi');
      yield call(api.createAppClient, action.payload);
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: 'App client successfully created' }
      });
      yield put<ActionTypings.FetchAppClientsAction>({
        type: ActionTypes.FETCH_APP_CLIENTS
      });
    } catch (e) {
      console.error(e);
      message.error('Error creating app client.');
      yield put<ActionTypings.AppClientErrorAction>({
        type: ActionTypes.APP_CLIENT_ERROR
      });
    }
  });
}

export function* updateAppClient() {
  yield takeLatest(ActionTypes.UPDATE_APP_CLIENT, function*(action: ActionTypings.UpdateAppClientAction) {
    try {
      const api = createApi<AppClientsApi>('AppClientsApi');
      yield call(api.updateAppClient, action.payload.clientId, _.omit(action.payload, 'clientId'));
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: 'App client successfully updated' }
      });
      yield put<ActionTypings.FetchAppClientsAction>({
        type: ActionTypes.FETCH_APP_CLIENTS
      });
    } catch (e) {
      console.error(e);
      message.error('Error updating app client.');
      yield put<ActionTypings.AppClientErrorAction>({
        type: ActionTypes.APP_CLIENT_ERROR
      });
    }
  });
}

export function* deleteAppClient() {
  yield takeLatest(ActionTypes.DELETE_APP_CLIENT, function*(action: ActionTypings.DeleteAppClientAction) {
    try {
      const api = createApi<AppClientsApi>('AppClientsApi');
      yield call(api.deleteAppClient, action.payload.clientId);
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: 'App client successfully deleted' }
      });
      yield put<ActionTypings.FetchAppClientsAction>({
        type: ActionTypes.FETCH_APP_CLIENTS
      });
    } catch (e) {
      console.error(e);
      message.error('Error deleting app client.');
      yield put<ActionTypings.AppClientErrorAction>({
        type: ActionTypes.APP_CLIENT_ERROR
      });
    }
  });
}

export function* fetchWebhooks() {
  yield takeLatest(ActionTypes.FETCH_WEBHOOKS, function*() {
    try {
      const api = createApi<WebhookApi>('WebhookApi');
      const response: ApiReturnType<WebhookApi['findMany']> = yield call(api.findMany);
      yield put<ActionTypings.FetchWebhooksSuccessAction>({
        type: ActionTypes.FETCH_WEBHOOKS_SUCCESS,
        payload: { webhooks: response.data }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: e.response.data.message }
      });
    }
  });
}

export function* deleteWebhook() {
  yield takeLatest(ActionTypes.DELETE_WEBHOOK, function*(action: ActionTypings.DeleteWebhookAction) {
    try {
      const api = createApi<WebhookApi>('WebhookApi');
      yield call(api.deleteOne, action.payload.id);
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: 'Webhook successfully deleted' }
      });
      yield put<ActionTypings.FetchWebhooksAction>({
        type: ActionTypes.FETCH_WEBHOOKS
      });
    } catch (e) {
      console.error(e);
      const messages: string[] = joinErrorMessages(e.response.data);
      yield put<ActionTypings.WebhookErrorAction>({
        type: ActionTypes.WEBHOOK_ERROR,
        payload: { messages }
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: e.response.data.message }
      });
    }
  });
}

export function* integrationsSaga() {
  yield all([
    fetchAppClients(),
    createAppClient(),
    updateAppClient(),
    deleteAppClient(),
    fetchWebhooks(),
    deleteWebhook()
  ]);
}
