import jwtDecode from 'jwt-decode';
import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { getCurrentSession } from '../api/amplify';
import { getGlassUserToken } from './local-storage-helper';

interface CognitoJwtPayload {
  'cognito:username': string;
  exp: number;
  iss: string;
  payload?: Record<string, unknown>;
}

export type AuthTokens = {
  idToken: string;
} & (
  | {
      type: 'glass';
    }
  | {
      type: 'cognito';
      accessToken: string;
      cognitoRefreshToken: CognitoRefreshToken;
    }
);

export const getAuthTokens = async (): Promise<AuthTokens | undefined> => {
  const glassUserToken = getGlassUserToken();

  if (glassUserToken) {
    return { type: 'glass', idToken: glassUserToken };
  } else {
    try {
      const currentSession = await getCurrentSession();
      if (!currentSession) {
        return undefined;
      }
      return {
        type: 'cognito',
        idToken: currentSession.getIdToken().getJwtToken(),
        accessToken: currentSession.getAccessToken().getJwtToken(),
        cognitoRefreshToken: currentSession.getRefreshToken()
      };
    } catch {
      return undefined;
    }
  }
};

export const isExternalUser = async (): Promise<boolean> => {
  const authTokens = await getAuthTokens();
  if (authTokens?.type !== 'cognito') {
    return false;
  }
  const payload = parseJwtToken(authTokens.idToken).payload;
  return payload && 'identities' in payload && Array.isArray(payload.identities) && payload.identities.length > 0;
};

export const parseJwtToken = (token: string): CognitoJwtPayload | undefined => {
  const COGNITO_TOKEN_ISSUER = `https://cognito-idp.us-west-2.amazonaws.com/${process.env.USER_POOL_ID}`;
  try {
    const payload = jwtDecode<CognitoJwtPayload>(token);
    return payload.iss === COGNITO_TOKEN_ISSUER ? payload : undefined;
  } catch {
    return undefined;
  }
};

export const getTokenExpiryInMs = (token: string): number => {
  return parseJwtToken(token).exp * 1000;
};

export const getTokenFromHash = (hash: string): string | undefined => {
  return getParams(hash)?.['token'];
};

const getParams = (hash: string): Record<string, string> | undefined => {
  if (!hash.startsWith('#')) {
    return undefined;
  }
  return hash
    .substr(1)
    .split('&')
    .reduce((acc: Record<string, string>, val: string) => {
      const parts = val.split('=');
      acc[parts[0]] = parts[1];
      return acc;
    }, {});
};
