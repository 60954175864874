import { GoogleWorkspaceConfigCreateDto, GoogleWorkspaceConfigResponseDto } from '@pclocs/platform-sdk';
import { Divider, Form, Modal } from 'antd';
import { GoogleWorkspaceDirectoryConfig } from './google-workspace-directory-config';
import { FooterButtons } from './footer-buttons';
import React, { useEffect } from 'react';
import { useEverySdkCall } from '../../../helpers/sdk-hooks';
import { GoogleTestResults } from './google-test-results';
import { ShowNotificationMessageAction } from '../../../store/common/types';

type FormProperties = {
  currentId: string | undefined;
  showNotification: (payload: ShowNotificationMessageAction['payload']) => void;
  setCurrentId: React.Dispatch<React.SetStateAction<string>>;
  initialConfig: GoogleWorkspaceConfigResponseDto | undefined;
  hasExternalDirectoriesFeature: boolean;
};

export const GoogleWorkspaceForm: React.FunctionComponent<FormProperties> = ({
  currentId,
  showNotification,
  setCurrentId,
  hasExternalDirectoriesFeature,
  initialConfig
}) => {
  const [form] = Form.useForm<GoogleWorkspaceConfigCreateDto>();
  useEffect(() => {
    if (!!initialConfig) {
      form.setFieldsValue(initialConfig);
    }
  }, [initialConfig]);

  const [createGoogleDirectoryConfig, createGoogleDirectoryLoading] = useEverySdkCall(
    'ExternalUserDirectoryApi',
    'createGoogleDirectoryConfig',
    v => {
      setCurrentId(v.data.id);
      showNotification({ type: 'success', content: 'Configuration saved.' });
    },
    () => showNotification({ type: 'error', content: 'Could not save the configuration.' })
  );

  const [updateGoogleDirectoryConfig, updateGoogleDirectoryLoading] = useEverySdkCall(
    'ExternalUserDirectoryApi',
    'updateGoogleDirectoryConfig',
    () => showNotification({ type: 'success', content: 'Configuration saved.' }),
    () => showNotification({ type: 'error', content: 'Could not save the configuration.' })
  );

  const [deleteExternalUserProvider, deletingConfiguration] = useEverySdkCall(
    'ExternalUserDirectoryApi',
    'deleteOne',
    () => {
      setCurrentId(undefined);
      form.resetFields();
      showNotification({ type: 'warning', content: 'Removed the configuration.' });
    },
    () => showNotification({ type: 'error', content: 'Could not remove the configuration.' })
  );

  const [testGoogleConnection, isTestingGoogleConnection] = useEverySdkCall(
    'ExternalUserDirectoryApi',
    'testGoogleWorkspaceConfig',
    response => {
      Modal.info({
        title: 'Google Connection Test Results',
        content: <GoogleTestResults results={response.data} attributes={form.getFieldsValue().attributes} />,
        icon: <></>
      });
    },
    () => showNotification({ type: 'error', content: 'Could not test the connection' })
  );

  const submitting = {
    form: updateGoogleDirectoryLoading || createGoogleDirectoryLoading,
    delete: !!deletingConfiguration,
    test: !!isTestingGoogleConnection
  };

  return (
    <Form
      form={form}
      onFinish={values => {
        if (!!currentId) {
          updateGoogleDirectoryConfig(currentId, values);
        } else {
          createGoogleDirectoryConfig(values);
        }
      }}
      labelCol={{ xs: 24, sm: 6 }}
      wrapperCol={{ xs: 24, sm: 18, xxl: 12 }}>
      <GoogleWorkspaceDirectoryConfig isNewRecord={!currentId} readonly={!hasExternalDirectoriesFeature} />

      <Divider orientation="left" />

      <FooterButtons
        submitting={submitting}
        readonly={!hasExternalDirectoriesFeature}
        validateFields={() => form.validateFields()}
        currentId={currentId}
        delete={deleteExternalUserProvider}
        testConnection={testGoogleConnection}
      />
    </Form>
  );
};
