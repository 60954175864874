import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Alert, Divider, Skeleton, Spin, Timeline } from 'antd';
import {
  CloudDownloadOutlined,
  Loading3QuartersOutlined,
  PoweroffOutlined,
  RollbackOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { RootState } from '../../../../store/rootReducer';
import { DashboardActionTypes } from '../../../../store/dashboard/types';
import * as ActionTypes from '../../../../store/actionTypes';
import _ from 'lodash';
import { AccessMode } from '../../access-mode';
import { Network } from '../../Network';
import { NetworkSettingsDto, StationResponseDto } from '@pclocs/platform-sdk';
import { NotificationButton } from '../../../Buttons/Notification';
import { Curfew } from '../../Curfew';
import { essentialsTierOrAbove } from '../../../../helpers/subscription-helper';
import { BayOfflineWhenAccessed } from '../../BayOfflineWhenAccessed/bay-offline-when-accessed';
import { MoveStation } from './move-station';
import { NetworkStatus } from '../../Network/network-status';
import { groupHierarchyToArray, useGroupHierarchy } from '../../../../helpers/group-hierarchy';
import { firmwareValidAndGt } from '../../../../helpers/station';

export type FirmwareUpdateInProgress = null | string;

const mapStateToProps = (state: RootState) => ({
  ..._.pick(state.dashboard, [
    'station',
    'changeAccessModePending',
    'accessModeOverridePending',
    'showNetworkModal',
    'networkSettingsPending',
    'changeBayOfflineWhenAccessedPending',
    'restartStationPending',
    'factoryResetPending',
    'toggleStationLockdownPending',
    'firmwareList',
    'firmwareUpdatePending'
  ]),
  subscriptionInfo: state.account.subscriptionInfo
});
const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes>) => ({
  changeAccessMode: (id: string, managed: boolean) =>
    dispatch({ type: ActionTypes.CHANGE_STATION_ACCESS_MODE, payload: { id, managed } }),
  toggleAccessModeOverride: (id: string, value: boolean | null) => {
    dispatch({ type: ActionTypes.TOGGLE_STATION_ACCESS_MODE_OVERRIDE, payload: { id, value } });
  },
  fetchStation: (id: string) => dispatch({ type: ActionTypes.FETCH_STATION, payload: { id } }),
  toggleNetworkModal: (value: boolean) =>
    dispatch({ type: ActionTypes.TOGGLE_NETWORK_MODAL, payload: { value: value } }),
  updateNetworkSettings: (id: string, networkSettings: NetworkSettingsDto | null) =>
    dispatch({ type: ActionTypes.UPDATE_NETWORK_SETTINGS, payload: { id, networkSettings } }),
  restartStation: (id: string) => dispatch({ type: ActionTypes.RESTART_STATION, payload: { id } }),
  factoryReset: (id: string) => dispatch({ type: ActionTypes.FACTORY_RESET_STATION, payload: { id } }),
  changeBayOfflineWhenAccessed: (id: string, value: boolean) =>
    dispatch({ type: ActionTypes.CHANGE_STATION_BAY_OFFLINE_WHEN_ACCESSED, payload: { id, value } }),
  toggleBayOfflineWhenAccessedOverride: (id: string, value: boolean | null) =>
    dispatch({ type: ActionTypes.TOGGLE_STATION_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE, payload: { id, value } }),
  toggleStationLockdown: (id: string, lockdown: boolean) =>
    dispatch({ type: ActionTypes.TOGGLE_STATION_LOCKDOWN, payload: { id, lockdown } }),
  getAvailableFirmwareList: () => dispatch({ type: ActionTypes.GET_AVAILABLE_FIRMWARE_LIST }),
  updateFirmware: (id: string) => dispatch({ type: ActionTypes.UPDATE_FIRMWARE, payload: { id } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const StationSettings = connector((props: Props) => {
  const station: StationResponseDto | undefined = props.station;
  const stationFirmware = props.station?.reported?.firmwareVersion?.toString();
  const isFirmwareUpdateAvailable = firmwareValidAndGt(props.firmwareList?.[0]?.version, stationFirmware);

  const correctSubscription: boolean = essentialsTierOrAbove(props.subscriptionInfo);

  const groupHierarchy = useGroupHierarchy();
  const isInRootGroup = !!groupHierarchyToArray(groupHierarchy).find(g => g.stations.includes(props.station?.id))
    ?.isRoot;

  useEffect(() => {
    props.getAvailableFirmwareList();
    station?.id && props.fetchStation(station.id);
  }, []);

  const isFirmwareUpdateInProgress: boolean = !!station?.reported?.firmwareUpdateInProgress;
  const isFirmwareUpdateDownloading: boolean = station?.reported.firmwareUpdateInProgress === 'downloading';
  const isFirmwareUpdateApplying: boolean = station?.reported.firmwareUpdateInProgress === 'applying';
  const isFirmwareUpdateRestarting: boolean = station?.reported.firmwareUpdateInProgress === 'restarting';
  const isHeadless = !!station?.derivedConfig?.isHeadless;

  return !station || !station?.derivedConfig || !props.subscriptionInfo ? (
    <Skeleton active paragraph={{ rows: 2, width: '80%' }} />
  ) : (
    <>
      {!correctSubscription && (
        <Alert type="error" message="Some features disabled due to subscription level." style={{ marginBottom: 20 }} />
      )}
      {station && _.get(station, 'syncErrors.networkSettings') != null && station.reported.connected && (
        <Alert
          message="The network settings configured could not be applied. The station has reverted back to the previous working settings."
          type="warning"
          showIcon
          style={{ marginTop: 10 }}
        />
      )}
      {isHeadless && (
        <Alert
          message="This locker is controlled by it's external interface."
          type="info"
          showIcon
          style={{ marginTop: 10 }}
        />
      )}
      {isFirmwareUpdateInProgress && station.reported.connected && (
        <>
          <Alert
            message="Firmware update is in progress, settings are temporarily disabled."
            type="info"
            showIcon
            style={{ marginTop: 10, marginBottom: 15 }}
          />
          <Timeline style={{ marginTop: 20, marginLeft: 20 }}>
            <Timeline.Item
              color={isFirmwareUpdateApplying || isFirmwareUpdateRestarting ? 'green' : 'grey'}
              dot={isFirmwareUpdateDownloading && <Loading3QuartersOutlined spin style={{ fontSize: '16px' }} />}>
              Downloading firmware update
            </Timeline.Item>
            <Timeline.Item
              color={isFirmwareUpdateRestarting ? 'green' : 'grey'}
              dot={isFirmwareUpdateApplying && <Loading3QuartersOutlined spin style={{ fontSize: '16px' }} />}>
              Applying firmware update
            </Timeline.Item>
            <Timeline.Item
              color="grey"
              dot={isFirmwareUpdateRestarting && <Loading3QuartersOutlined spin style={{ fontSize: '16px' }} />}>
              Restarting station
            </Timeline.Item>
          </Timeline>
          <Divider style={{ marginTop: 5, marginBottom: 25 }} />
        </>
      )}
      <NetworkStatus reported={station.reported} />
      {!isHeadless && (
        <>
          <AccessMode
            type="station"
            entity={station}
            changeAccessModePending={props.changeAccessModePending}
            accessModeOverridePending={props.accessModeOverridePending}
            changeAccessMode={props.changeAccessMode}
            toggleAccessModeOverride={props.toggleAccessModeOverride}
            subscriptionInfo={props.subscriptionInfo}
            firmwareUpdateInProgress={station?.reported?.firmwareUpdateInProgress as FirmwareUpdateInProgress}
          />
          <br />
          <BayOfflineWhenAccessed
            derivedValue={station.derivedConfig.takeBayOfflineWhenAccessed as boolean}
            isOverridden={station.config?.takeBayOfflineWhenAccessed !== undefined}
            showOverrideButton={true}
            changeBayOfflineWhenAccessedPending={props.changeBayOfflineWhenAccessedPending}
            changeBayOfflineWhenAccessed={props.changeBayOfflineWhenAccessed.bind(null, station.id)}
            toggleBayOfflineWhenAccessedOverride={props.toggleBayOfflineWhenAccessedOverride.bind(null, station.id)}
            subscriptionInfo={props.subscriptionInfo}
            firmwareUpdateInProgress={station?.reported?.firmwareUpdateInProgress as FirmwareUpdateInProgress}
          />
          <br />
          <br />
          <Curfew
            type="station"
            curfew={station.derivedConfig.curfew as any}
            isOverridden={station.config?.curfew !== undefined}
          />
        </>
      )}
      <MoveStation stationId={station.id} disabled={!!station.reported?.firmwareUpdateInProgress} />
      <NotificationButton
        id="restart-station-notification-btn"
        btnStyle={{ marginTop: 20 }}
        icon={<SyncOutlined />}
        disabled={(!correctSubscription && !isInRootGroup) || isFirmwareUpdateInProgress}
        text="Restart station"
        promptContent="Station will be offline temporarily, proceed?"
        pending={props.restartStationPending}
        onOk={props.restartStation.bind(null, station.id)}
      />
      {!isHeadless && (
        <NotificationButton
          id="lockdown-station-notification-btn"
          icon={<PoweroffOutlined />}
          disabled={(!correctSubscription && !isInRootGroup) || isFirmwareUpdateInProgress}
          btnStyle={{ marginTop: 20 }}
          text={station.reported?.lockdown ? 'Disable station lockdown' : 'Enable station lockdown'}
          promptContent={
            station.reported?.lockdown ? 'Resume station operation?' : 'All bays will be inaccessible, proceed?'
          }
          pending={props.toggleStationLockdownPending}
          onOk={props.toggleStationLockdown.bind(null, station.id, !station.reported?.lockdown)}
        />
      )}
      <h4 style={{ marginTop: 30 }}>Danger Zone</h4>
      <p>Proceed carefully, these actions can be destructive!</p>
      <Network
        type="station"
        entity={station}
        disabled={isFirmwareUpdateInProgress}
        toggleNetworkModal={props.toggleNetworkModal}
        showNetworkModal={props.showNetworkModal}
        updateNetworkSettings={props.updateNetworkSettings.bind(null, station.id)}
        networkSettingsPending={props.networkSettingsPending}
      />
      <NotificationButton
        id="factory-reset-notification-btn"
        btnStyle={{ marginTop: 20 }}
        btnClass="btn-danger"
        icon={<RollbackOutlined />}
        text="Forget Station"
        promptContent="This action will remove the station from the account and perform a factory reset on the station (deleting all settings from the station). This action is irreversible! Are you sure you want to proceed?"
        pending={props.factoryResetPending}
        onOk={props.factoryReset.bind(null, station.id)}
      />

      {!props.firmwareList && (
        <small>
          <br />
          <Spin size="small" />
          &nbsp; &nbsp; Checking for firmware updates
        </small>
      )}

      {props.firmwareList && !isFirmwareUpdateAvailable && (
        <small>
          <br />
          Firmware {stationFirmware}. No updates available.
        </small>
      )}

      {props.firmwareList && isFirmwareUpdateAvailable && (
        <>
          <NotificationButton
            id="update-firmware-version-btn"
            btnStyle={{ marginTop: 20 }}
            disabled={(!correctSubscription && !isInRootGroup) || isFirmwareUpdateInProgress}
            btnClass="btn-danger"
            icon={<CloudDownloadOutlined />}
            text="Update Firmware"
            promptContent="This action will update the current station firmware to the latest version and it cannot be undone!"
            pending={props.firmwareUpdatePending}
            onOk={props.updateFirmware.bind(null, station.id)}
          />
          <small>Firmware {stationFirmware}.</small>
        </>
      )}
    </>
  );
});
