import * as api from '@pclocs/platform-sdk';
import { store } from '../store/store';
import { BaseAPI } from '@pclocs/platform-sdk/dist/base';
import { Configuration, ValidationExceptionDto } from '@pclocs/platform-sdk';
import axios from 'axios';
import { getAuthTokens, parseJwtToken } from '../helpers/auth-token-helper';

axios.interceptors.request.use(
  async config => {
    // Do not modify requests that aren't for our API endpoint
    if (config.url?.indexOf(process.env.API_ENDPOINT as string) !== 0) {
      return config;
    }
    const idToken = (await getAuthTokens())?.idToken;
    config.headers.Authorization = idToken;
    // Adds in a Cognito ID header for local development where we bypass API gateway.
    if (process.env.API_ENV === 'local') {
      const cognitoUsername = parseJwtToken(idToken)?.['cognito:username'];
      if (cognitoUsername) {
        config.headers['X-CognitoId'] = cognitoUsername;
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

const apiKeyResolver = (name: string) => {
  const { currentUser } = store.getState().auth;
  switch (name) {
    case 'X-AccountId':
      if (currentUser === null) {
        throw Error('No user context specified');
      }
      const accountId = store.getState().auth.accountContext;
      if (!accountId) {
        throw Error('No account context specified');
      }
      return accountId;
    case 'Authorization':
      if (currentUser === null) {
        throw Error('No user context specified.');
      }
      // The latest token will be set into the request headers
      return '';
    default:
      throw Error(`Unknown API key ${name} requested.`);
  }
};

export const getConfiguration = (): Configuration => {
  return new Configuration({
    apiKey: apiKeyResolver
  });
};

export const createApi = <T extends BaseAPI>(type: keyof typeof api, configuration?: Configuration): T => {
  // use the factory to generate the actual api...
  return (api as any)[`${type}Factory`](configuration || getConfiguration(), process.env.API_ENDPOINT);
};

export const joinErrorMessages = (error: ValidationExceptionDto): string[] => {
  const message: string[] = [];
  typeof error.message === 'string'
    ? message.push(error.message)
    : Object.values(error.message).forEach(err => Object.values(err.constraints).forEach(msg => message.push(msg)));

  return message;
};
