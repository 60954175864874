import React from 'react';
import { Radio, Modal, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { OverrideButton } from '../Buttons/Override';
import { RadioChangeEvent } from 'antd/lib/radio';
import { GroupResponseDto, StationResponseDto, SubscriptionResponseDto } from '@pclocs/platform-sdk';
import { essentialsTierOrAbove } from '../../helpers/subscription-helper';
import { subscriptionMessage } from '../../constants/validations';
import { FirmwareUpdateInProgress } from './Station/Settings';

const { confirm } = Modal;

type Props = (
  | {
      type: 'station';
      entity: StationResponseDto;
    }
  | {
      type: 'group';
      entity: GroupResponseDto;
    }
) & {
  changeAccessModePending: boolean;
  accessModeOverridePending: boolean;
  changeAccessMode: (id: string, managed: boolean) => void;
  toggleAccessModeOverride: (id: string, value: boolean | null) => void;
  subscriptionInfo?: SubscriptionResponseDto;
  firmwareUpdateInProgress?: FirmwareUpdateInProgress;
};

export const AccessMode = (props: Props) => {
  const isFirmwareUpdateInProgress: boolean = !!props.firmwareUpdateInProgress;
  const managed: boolean = !!props.entity?.derivedConfig.managed;
  const managedIsOverridden: boolean = props.entity?.config?.managed !== undefined;
  const correctSubscription: boolean = essentialsTierOrAbove(props.subscriptionInfo);
  const isPending: boolean =
    props.changeAccessModePending ||
    props.accessModeOverridePending ||
    isFirmwareUpdateInProgress ||
    props.entity?.derivedConfig.managed === undefined;

  const title =
    props.type === 'group'
      ? 'Users set against stations or its bays below this node will lose access'
      : 'Users set against this station or its bays will lose access';

  const handleManagedChange = (event: RadioChangeEvent) => {
    if (!event.target.value) {
      // changing from managed to public, so warn admin
      confirm({
        title: title,
        content: 'Are you sure you want to continue?',
        onOk() {
          props.entity && props.changeAccessMode(props.entity.id, event.target.value);
        }
      });
    } else {
      props.entity && props.changeAccessMode(props.entity.id, event.target.value);
    }
  };

  const handleOverrideClick = (value: boolean) => {
    // send as null value if setting to inherit, send as derived value otherwise
    const configValue: boolean | null = value ? !!props.entity?.derivedConfig.managed : null;
    props.entity && props.toggleAccessModeOverride(props.entity.id, configValue);
  };

  const radioGroup: JSX.Element = (
    <Radio.Group
      id="access-mode-btn"
      buttonStyle="solid"
      onChange={handleManagedChange}
      value={managed}
      disabled={isPending || !managedIsOverridden || (!correctSubscription && !managed)}>
      <Radio.Button value={true}>
        {!managed && props.changeAccessModePending && <LoadingOutlined />} Managed
      </Radio.Button>
      <Radio.Button value={false}>
        {managed && props.changeAccessModePending && <LoadingOutlined />} Public
      </Radio.Button>
    </Radio.Group>
  );

  const overrideButton: JSX.Element = (
    <OverrideButton
      disabled={isPending || (!correctSubscription && !managedIsOverridden)}
      overridden={managedIsOverridden}
      pending={props.accessModeOverridePending}
      onClick={handleOverrideClick}
    />
  );

  return (
    <div className="content-split">
      {!correctSubscription ? (
        <Tooltip title={subscriptionMessage} placement="right">
          {radioGroup}
        </Tooltip>
      ) : (
        radioGroup
      )}
      {!(props.type === 'group' && props.entity.isRoot) &&
        (!correctSubscription ? (
          <Tooltip title={subscriptionMessage} placement="left">
            <span>{overrideButton}</span>
          </Tooltip>
        ) : (
          overrideButton
        ))}
    </div>
  );
};
