import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Input, Tag, Popconfirm } from 'antd';
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import { PortalAdminModal } from './portal-admin-modal';
import { RootState } from '../../../store/rootReducer';
import {
  AdminRoleResponseDtoPermissionSetEnum,
  AdminRoleResponseDtoTypeEnum,
  AdminUserResponseDto
} from '@pclocs/platform-sdk';
import _ from 'lodash';
import { accountActions } from '../../../store/account/reducer';
import { AdminClaimEnum, useHasClaim } from '../../../helpers/claims';
import { InfinityList } from '../../InfinityList';

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser,
  ..._.pick(state.account, ['portalAdmins', 'portalAdminsFilter', 'isPortalAdminLoading', 'adminRoles'])
});

const mapDispatchToProps = {
  deleteUser: (user: AdminUserResponseDto) => accountActions.deletePortalAdmin({ user }),
  findPortalAdmins: () => accountActions.findPortalAdmins(),
  loadModal: (id?: string) => accountActions.portalAdminLoadModal({ adminUserId: id }),
  filter: (value: string) => accountActions.filterPortalAdmins({ value }),
  findAdminRoles: () => accountActions.findAdminRoles()
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const PortalAdmin = connector((props: Props) => {
  useEffect(() => {
    props.findPortalAdmins();
  }, []);

  useEffect(() => {
    if (props.adminRoles === undefined) {
      props.findAdminRoles();
    }
  }, [props.adminRoles]);

  const [hasWriteClaim] = useHasClaim({ type: AdminClaimEnum.AdminUserWrite });

  const dataSource: AdminUserResponseDto[] | undefined =
    props.portalAdmins && props.portalAdminsFilter
      ? props.portalAdmins.filter(user =>
          `${user.name} ${user.email}`.toLowerCase().includes((props.portalAdminsFilter || '').toLocaleLowerCase())
        )
      : props.portalAdmins ?? [];

  const debouncedFilter = _.debounce(props.filter, 400);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFilter(e.target.value);
  };

  return (
    <div>
      <PortalAdminModal />

      <div className="content-split" style={{ marginBottom: 16 }}>
        {hasWriteClaim && (
          <Button disabled={props.isPortalAdminLoading} onClick={() => props.loadModal()} className="btn-primary">
            <PlusCircleFilled />
            Create Admin
          </Button>
        )}
        <Input
          disabled={props.isPortalAdminLoading}
          placeholder="Search"
          onChange={handleSearch}
          style={{ width: 220 }}
        />
      </div>
      <div className="content-section" style={{ height: 'calc(100vh - 500px)' }}>
        <InfinityList
          items={dataSource}
          loading={props.isPortalAdminLoading}
          hasMoreItems={false}
          loadItems={() => {
            props.findPortalAdmins();
          }}
          pageSize={dataSource.length === 0 ? 1 : dataSource.length}
          render={(admin: AdminUserResponseDto) => {
            return (
              <div className="user-list-row list-row" style={{ marginBottom: 16 }}>
                <div className="user-detail">
                  <Button
                    disabled={props.isPortalAdminLoading}
                    title={admin.id}
                    type="link"
                    onClick={() => props.loadModal(admin.id)}>
                    {admin.name}
                    <span>
                      {props.currentUser && props.currentUser.id === admin.id && (
                        <Tag className="ant-tag-primary">You</Tag>
                      )}
                    </span>
                  </Button>
                </div>
                <div className="user-detail" style={{ flex: '0 0 40%' }}>
                  <Button disabled={props.isPortalAdminLoading} type="link" onClick={() => props.loadModal(admin.id)}>
                    {admin.email}
                  </Button>
                </div>
                <div className="user-detail" style={{ flexGrow: 1 }}>
                  {admin.roles.map(roleId => {
                    const role = props.adminRoles?.find(r => r.id === roleId);
                    if (!role) {
                      return <Tag key={roleId}>{roleId}</Tag>;
                    }
                    const isOwner = role.permissionSet === AdminRoleResponseDtoPermissionSetEnum.Owner;
                    const isCanned = role.type === AdminRoleResponseDtoTypeEnum.Canned;
                    return (
                      <Tag
                        key={role.id}
                        className={isOwner && isCanned ? 'ant-tag-primary' : isCanned ? 'ant-tag-info' : ''}>
                        {role.name}
                      </Tag>
                    );
                  })}
                </div>
                <div className="user-action">
                  {hasWriteClaim && (
                    <Popconfirm
                      disabled={(props.currentUser && props.currentUser.id === admin.id) || props.isPortalAdminLoading}
                      placement="left"
                      title="Delete this admin?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => props.deleteUser(admin)}>
                      <Button
                        ghost
                        disabled={
                          (props.currentUser && props.currentUser.id === admin.id) || props.isPortalAdminLoading
                        }>
                        <DeleteFilled />
                      </Button>
                    </Popconfirm>
                  )}
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
});
