import { Form as LegacyForm } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { SubscriptionResponseDto } from '@pclocs/platform-sdk';
import { Input } from 'antd';
import React from 'react';
import { EditableContext } from '.';
import { advancedTier } from '../../../helpers/subscription-helper';

const FormItem = LegacyForm.Item;

interface EditableCellProps<T> {
  editing: boolean;
  dataIndex: keyof T;
  pending: boolean;
  subscriptionInfo?: SubscriptionResponseDto;
  record: T;
  initialValue: (value?: T[EditableCellProps<T>['dataIndex']]) => '';
  placeholder: string;
  extra: string;
  component: typeof Input | typeof Input['Password'];
}

export function EditableCell<T>(props: React.PropsWithChildren<EditableCellProps<T>>) {
  const {
    editing,
    dataIndex,
    pending,
    subscriptionInfo,
    record,
    initialValue,
    placeholder,
    extra,
    component,
    ...restProps
  } = props;

  const InputComponent = component ?? Input;
  const initialValueDefault = initialValue ?? (() => '');

  return (
    <EditableContext.Consumer>
      {(form: any) => {
        const { getFieldDecorator } = form;
        return (
          <td {...restProps}>
            {editing && advancedTier(subscriptionInfo) ? (
              <FormItem style={{ margin: 0 }} extra={extra}>
                {getFieldDecorator(dataIndex, {
                  initialValue: initialValueDefault(record[dataIndex])
                })(<InputComponent placeholder={placeholder} disabled={pending} />)}
              </FormItem>
            ) : (
              restProps.children
            )}
          </td>
        );
      }}
    </EditableContext.Consumer>
  );
}
