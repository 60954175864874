import React from 'react';
import { Input, Select, Form } from 'antd';
import { SecretsInput } from '../../../helpers/secrets-input';
import { CertificateFormField, validateCertificate, validatePrivateKey } from '../../CertificateFormField';
import {
  LdapUserDirectoryConfigUpdateDtoProviderTypeEnum,
  LdapUserDirectoryConfigCreateDtoProviderTypeEnum,
  LdapUserDirectoryConfigResponseDtoProviderTypeEnum
} from '@pclocs/platform-sdk';
import { AttributesMap, ATTRIBUTES_LDAP } from './attributes-map';

type ExternalProviderEnum =
  | LdapUserDirectoryConfigUpdateDtoProviderTypeEnum
  | LdapUserDirectoryConfigCreateDtoProviderTypeEnum
  | LdapUserDirectoryConfigResponseDtoProviderTypeEnum;

export interface LdapUserConfig {
  providerType: ExternalProviderEnum;
  origin: string;
  urls: string[];
  clientKey?: string;
  clientCertificate?: string;
  caCertificate?: string;
  baseDn: string;
  bindDn: string;
  bindPassword: string;
  searchFilter?: string;
}

export type Props = {
  isNewRecord?: boolean;
  readonly?: boolean;
  clientKey?: string;
};

export const LdapUserProviderConfig = (props: Props) => {
  const { isNewRecord, readonly, clientKey } = props;

  return (
    <>
      <Form.Item
        label="Provider Type"
        name="providerType"
        rules={[{ required: true, message: 'A provider type for this external user directory is required.' }]}>
        <Select placeholder="Select a provider type" disabled={!isNewRecord || readonly}>
          {Object.entries(LdapUserDirectoryConfigCreateDtoProviderTypeEnum).map(([key, value]) => (
            <Select.Option key={key} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Identifier"
        name="origin"
        rules={[
          {
            pattern: /^[A-Z0-9\-\.]*$/gi,
            message: 'The identifier must only contain alphanumeric characters, dashes or dots.'
          },
          { max: 40, message: 'The identifier must not be more than 40 characters' },
          { required: true, message: 'An identifier for this external user directory is required.' }
        ]}>
        <Input disabled={!isNewRecord || readonly} placeholder="A name to identify this user directory" />
      </Form.Item>
      <Form.Item
        label="Connection Urls"
        name="urls"
        rules={[
          { required: true, message: 'A connection URL is required.' },
          { type: 'array', max: 5, message: 'A maximum of 5 connection URLs are allowed.' }
        ]}>
        <Select
          disabled={readonly}
          mode="tags"
          placeholder="ldap://serverurl:port"
          tokenSeparators={[';']}
          open={false}
        />
      </Form.Item>
      <Form.Item
        label="Client Key"
        name="clientKey"
        rules={[
          {
            validator: (rule: unknown, value: string, callback: (error?: string) => void) => {
              if (value === clientKey) {
                callback();
              } else {
                validatePrivateKey(rule, value, callback);
              }
            }
          }
        ]}>
        <CertificateFormField disabled={readonly} />
      </Form.Item>
      <Form.Item
        label="Client Certificate"
        name="clientCertificate"
        rules={[
          {
            validator: validateCertificate
          }
        ]}>
        <CertificateFormField disabled={readonly} />
      </Form.Item>
      <Form.Item
        label="CA Certificate"
        name="caCertificate"
        rules={[
          {
            validator: validateCertificate
          }
        ]}>
        <CertificateFormField disabled={readonly} />
      </Form.Item>
      <Form.Item label="Base DN" name="baseDn" rules={[{ required: true, message: 'A base DN is required.' }]}>
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item label="Bind DN" name="bindDn" rules={[{ required: true, message: 'A bind DN is required.' }]}>
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label="Bind Password"
        name="bindPassword"
        rules={[{ required: true, message: 'A bind password is required.' }]}>
        <SecretsInput disabled={readonly} />
      </Form.Item>
      <Form.Item label="LDAP Filter" name="searchFilter">
        <Input disabled={readonly} />
      </Form.Item>
      <AttributesMap readonly={readonly} attributes={ATTRIBUTES_LDAP} />
    </>
  );
};
