import React, { useEffect, Dispatch } from 'react';
import { Menu, Alert, Col } from 'antd';
import { ControlOutlined, DashboardOutlined, TeamOutlined } from '@ant-design/icons';
import { Route, Switch, useParams, Link, useRouteMatch, Redirect } from 'react-router-dom';
import { StationOverview } from './overview';
import { StationSettings } from './Settings';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { DashboardActionTypes } from '../../../store/dashboard/types';
import * as ActionTypes from '../../../store/actionTypes';
import { StationUsers } from './users';
import { essentialsTierOrAbove } from '../../../helpers/subscription-helper';
import { subscriptionMessage } from '../../../constants/validations';
import { FetchFailedAlert } from '../fetch-failed-alert';
import { WebsocketStationRequestDtoTypeEnum } from '@pclocs/platform-sdk';
import { websocketActions, WebsocketActionTypes } from '../../../store/websocket/reducer';

const mapStateToProps = (state: RootState) => ({
  stationFetchFailed: state.dashboard.stationFetchFailed,
  station: state.dashboard.station,
  subscriptionInfo: state.account.subscriptionInfo
});

const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes | WebsocketActionTypes>) => ({
  fetchStation: (id: string) => {
    dispatch({ type: ActionTypes.FETCH_STATION, payload: { id } });
    dispatch(websocketActions.subscribe({ id, type: WebsocketStationRequestDtoTypeEnum.Station }));
  },
  clearStation: (id?: string) => {
    dispatch({ type: ActionTypes.CLEAR_STATION });
    id && dispatch(websocketActions.unsubscribe({ id, type: WebsocketStationRequestDtoTypeEnum.Station }));
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const Station = connector((props: Props) => {
  const { stationId } = useParams<{ stationId: string }>();
  const { url, path } = useRouteMatch();

  const routeTab = useRouteMatch<any>(`${url}/:tab?`)?.params?.tab || 'overview';
  const essentialsTier: boolean = essentialsTierOrAbove(props.subscriptionInfo);
  const isHeadless = !!props.station?.derivedConfig?.isHeadless;

  // on component load lets start the socket to fetch the station status
  useEffect(() => {
    props.clearStation();
    if (stationId === undefined) {
      return;
    }
    props.fetchStation(stationId);

    return () => {
      props.clearStation(stationId);
    };
  }, [stationId]);

  return (
    <Col className="dashboard-section" span={12}>
      <div className="dashboard-section-title">
        {props.station ? `${props.station.name || stationId}` : <div style={{ height: 24 }}></div>}
      </div>
      <div className="dashboard-section-inner">
        {props.station && props.station.reported?.connected !== true && (
          <Alert message="Station cannot contact the cloud." type="warning" showIcon />
        )}

        <Menu selectedKeys={routeTab} mode="horizontal" key={`${stationId}-menu`}>
          <Menu.Item key="overview">
            <Link to={`${url}/overview`}>
              <DashboardOutlined /> Overview
            </Link>
          </Menu.Item>
          {!isHeadless && (
            <Menu.Item key="users" disabled={!essentialsTier} title={!essentialsTier ? subscriptionMessage : undefined}>
              <Link to={`${url}/users`}>
                <TeamOutlined /> Users
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="settings">
            <Link to={`${url}/settings`}>
              <ControlOutlined /> Settings
            </Link>
          </Menu.Item>
        </Menu>

        {props.stationFetchFailed ? (
          <FetchFailedAlert itemType="station" statusCode={props.stationFetchFailed.statusCode} />
        ) : (
          <Switch>
            <Route path={`${path}/overview`} component={StationOverview} />
            {!isHeadless && <Route path={`${path}/users`} component={StationUsers} />}
            <Route path={`${path}/settings`} component={StationSettings} />
            <Redirect from="/" to={`${url}/overview`} />
          </Switch>
        )}
      </div>
    </Col>
  );
});
