import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col, Input, Switch, Checkbox, Button, Skeleton } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import '@ant-design/compatible/assets/index.css';
import { RootState } from '../../../store/rootReducer';
import _ from 'lodash';
import { WebhookDto, WebhookDtoEventTypesEnum } from '@pclocs/platform-sdk';
import * as ActionTypes from '../../../store/actionTypes';
import { history } from '../../../store/store';
import { useParams } from 'react-router';
import { ShowNotificationMessageAction, ShowNotificationModalAction } from '../../../store/common/types';
import { useEverySdkCall, useLatestSdkCall } from '../../../helpers/sdk-hooks';
import { FeatureId, useFeatureAvailability } from '../../../helpers/feature-availability-hooks';

const mapStateToProps = (state: RootState) => ({
  subscriptionInfo: state.account.subscriptionInfo,
  ..._.pick(state.integrations, ['webhooks'])
});
const mapDispatchToProps = (dispatch: Dispatch<ShowNotificationMessageAction | ShowNotificationModalAction>) => ({
  showSuccessNotification: (content: string): void =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload: { type: 'success', content }
    }),
  showErrorNotification: (title: string, content: string): void =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MODAL,
      payload: { type: 'error', title, content }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & FormComponentProps;

export const WebhooksForm = connector(
  LegacyForm.create({ name: 'Webhooks' })((props: Props) => {
    const { accountId, webhookId } = useParams<{ accountId: string; webhookId: string }>();
    const [isWebhookActive, setWebhookActive] = useState(true);
    const [hasFeature] = useFeatureAvailability();
    const featureEnabled = hasFeature(FeatureId.WEBHOOKS);

    const redirect = () => {
      history.push(`/auth/${accountId}/integrations/webhooks`);
    };

    useEffect(() => {
      if (props.subscriptionInfo && !featureEnabled && !webhookId) {
        history.replace(`/auth/${accountId}/dashboard`);
      }
    }, [props.subscriptionInfo]);

    const webhook = props.webhooks?.find(v => v.id === webhookId);

    useEffect(() => {
      props.form.setFieldsValue({
        name: webhook?.name,
        url: webhook?.url,
        description: webhook?.description,
        token: webhook?.token,
        eventTypes: webhook?.eventTypes
      });

      webhook?.active === false ? setWebhookActive(false) : setWebhookActive(true);
    }, [webhook]);

    const [testWebhook, testingWebhook] = useLatestSdkCall(
      'WebhookApi',
      'testWebhook',
      ({ data }) => {
        if (data.success) {
          props.showSuccessNotification('Webhook successfully tested.');
        } else {
          props.showErrorNotification('Test failed', `Error: ${data.error}`);
        }
      },
      () => props.showErrorNotification('Error', 'Could not test webhook.')
    );

    const [createWebhook, creatingWebhook] = useEverySdkCall(
      'WebhookApi',
      'createOne',
      () => {
        props.showSuccessNotification('Webhook successfully created.');
        redirect();
      },
      () => props.showErrorNotification('Error', 'Could not create webhook.')
    );

    const [updateWebhook, updatingWebhook] = useEverySdkCall(
      'WebhookApi',
      'updateOne',
      () => {
        props.showSuccessNotification('Webhook successfully updated.');
        redirect();
      },
      () => props.showErrorNotification('Error', 'Could not update webhook.')
    );

    const loading = creatingWebhook || updatingWebhook;

    const { getFieldDecorator } = props.form;

    const options = [
      { value: WebhookDtoEventTypesEnum.BAYCREDSCACHED, label: 'Bay {bay} reserved on station {station}' },
      { value: WebhookDtoEventTypesEnum.BAYCREDSCLEARED, label: 'Bay {bay} accessed on station {station}' },
      { value: WebhookDtoEventTypesEnum.BAYCLOSED, label: 'Bay {bay} closed on station {station}' },
      { value: WebhookDtoEventTypesEnum.BAYSTUCK, label: 'Bay {bay} stuck on station {station}' },
      { value: WebhookDtoEventTypesEnum.BAYBREACH, label: 'Bay {bay} breached on station {station}' },
      {
        value: WebhookDtoEventTypesEnum.BAYTMPBAN,
        label: 'Bay {bay} was locked out due to incorrect access attempts on station {station}'
      }
    ];

    const tailFormItemLayout = {
      wrapperCol: {
        xs: { span: 22, offset: 1 },
        sm: { span: 10, offset: 6 }
      }
    };

    const handleTest = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      props.form.validateFieldsAndScroll((err: Error, values: any) => {
        if (!err) {
          testWebhook(values);
        }
      });
    };

    const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      props.form.validateFieldsAndScroll((err: Error, values: any) => {
        if (!err) {
          const webhookConfig: WebhookDto = {
            name: values.name,
            url: values.url,
            description: values.description,
            token: values.token,
            active: isWebhookActive,
            eventTypes: values.eventTypes
          };
          webhook ? updateWebhook(webhook.id, webhookConfig) : createWebhook(webhookConfig);
        }
      });
    };

    return webhookId && !webhook ? (
      <Skeleton active paragraph={{ rows: 5 }} />
    ) : (
      <div className="content-section">
        <LegacyForm
          className="content-section-inner"
          {...{
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 }
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 }
            }
          }}>
          <LegacyForm.Item label="Active:">
            {getFieldDecorator('active')(
              <Switch checked={isWebhookActive} onChange={setWebhookActive} disabled={!featureEnabled} />
            )}
          </LegacyForm.Item>

          <LegacyForm.Item label="Name:">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input webhook name!' }]
            })(<Input disabled={!featureEnabled} />)}
          </LegacyForm.Item>

          <LegacyForm.Item label="URL:">
            {getFieldDecorator('url', {
              rules: [{ required: true, message: 'Please input webhook URL!' }]
            })(<Input disabled={!featureEnabled} />)}
          </LegacyForm.Item>

          <LegacyForm.Item label="Description:">
            {getFieldDecorator('description')(<Input disabled={!featureEnabled} />)}
          </LegacyForm.Item>

          <LegacyForm.Item label="Auth Bearer Token:">
            {getFieldDecorator('token')(<Input disabled={!featureEnabled} />)}
          </LegacyForm.Item>

          <LegacyForm.Item label="Event Types:">
            {getFieldDecorator('eventTypes', {
              rules: [{ required: true, message: 'Please select one event!' }]
            })(
              <Checkbox.Group style={{ width: '100%' }}>
                <Row style={{ display: 'block' }}>
                  {options.map((option, i) => (
                    <Col key={i} style={{ marginTop: 8 }}>
                      <Checkbox disabled={!featureEnabled} value={option.value}>
                        {option.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            )}
          </LegacyForm.Item>

          <LegacyForm.Item {...tailFormItemLayout}>
            <Button
              key="back"
              className="my-button"
              style={{ display: loading ? 'none' : 'initial' }}
              onClick={redirect}>
              Cancel
            </Button>
            <Button type="default" loading={testingWebhook} disabled={!featureEnabled} onClick={handleTest}>
              Test
            </Button>
            <Button
              key="submit"
              type="primary"
              id="webhook_save"
              loading={loading}
              onClick={handleSubmit}
              disabled={!featureEnabled}>
              Save
            </Button>
          </LegacyForm.Item>
        </LegacyForm>
      </div>
    );
  })
);
