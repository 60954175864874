import React from 'react';
import { ReportDataType } from '../../store/reports/types';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { PopularUsageTimesRecordDto } from '@pclocs/platform-sdk';
import { Skeleton } from 'antd';
import { ReportKey } from '../../constants/reports';

type Props = {
  reportData: ReportDataType[];
  reportKeyFilter: ReportKey;
  isReportsGenerating: boolean;
};

export const PopularUsageTimesReport = (props: Props) => {
  const reportData = props.reportData
    .map(dto => {
      return {
        ...dto,
        localHourOfDay: moment
          .utc()
          .hour((dto as PopularUsageTimesRecordDto).utcHourOfDay)
          .local()
          .hour()
      };
    })
    .sort((a, b) => (a.localHourOfDay > b.localHourOfDay ? 1 : -1)) as (PopularUsageTimesRecordDto & {
    localHourOfDay: number;
  })[];

  return (
    <Skeleton loading={props.isReportsGenerating} active>
      {props.reportData.length > 0 && (
        <div className="content-section">
          <div className="content-section-inner" style={{ height: '100%' }}>
            <Bar
              data={{
                labels: reportData.map(dto =>
                  moment()
                    .set({
                      h: dto.localHourOfDay,
                      m: 0
                    })
                    .format('HH:mm')
                ),
                datasets: [
                  {
                    label: 'Reserved',
                    data: reportData.map(i => i.bayCredsCached),
                    backgroundColor: 'rgb(255, 99, 132)' // red
                  },
                  {
                    label: 'Accessed',
                    data: reportData.map(i => i.bayCredsCleared),
                    backgroundColor: 'rgb(54, 162, 235)' // blue
                  }
                ]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  xAxes: [
                    {
                      stacked: true,
                      scaleLabel: {
                        display: true,
                        labelString: `Hour of Day (UTC${moment().format('Z')})`,
                        fontStyle: 'bold'
                      }
                    }
                  ],
                  yAxes: [
                    {
                      stacked: true,
                      scaleLabel: {
                        display: true,
                        labelString: 'Usage',
                        fontStyle: 'bold'
                      },
                      ticks: {
                        beginAtZero: true
                      }
                    }
                  ]
                },
                tooltips: {
                  intersect: false,
                  mode: 'index'
                },
                hover: {
                  intersect: false,
                  mode: 'index'
                }
              }}
            />
          </div>
        </div>
      )}
    </Skeleton>
  );
};
