import React, { Dispatch, useEffect, useState } from 'react';
import { Skeleton, Tooltip, Button, Row, Col } from 'antd';
import { CloseCircleOutlined, LockOutlined, PoweroffOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import { RootState } from '../../../store/rootReducer';
import * as ActionTypes from '../../../store/actionTypes';
import { DashboardActionTypes } from '../../../store/dashboard/types';
import { BayStatusIndicator } from '../../BayStatusIndicator';
import { EditableLabel } from '../../EditableLabel';
import { NotificationButton } from '../../Buttons/Notification';
import { BayOfflineWhenAccessed } from '../BayOfflineWhenAccessed/bay-offline-when-accessed';
import { BayState } from '../../../helpers/bay-helper';
import moment from 'moment';
import { essentialsTierOrAbove } from '../../../helpers/subscription-helper';
import { Tags } from '../../Tags';
import { AssignCredentials } from './assign-credentials';
import { FetchFailedAlert } from '../fetch-failed-alert';
import { subscriptionMessage } from '../../../constants/validations';
import { useLatestSdkCall } from '../../../helpers/sdk-hooks';
import { groupHierarchyToArray, useGroupHierarchy } from '../../../helpers/group-hierarchy';

const mapStateToProps = (state: RootState, ownProps: any) => {
  const bayId: string = ownProps.match.params.bayId;
  return {
    station: state.dashboard.station,
    bay: _.find(state.dashboard.bays || {}, ['id', bayId]),
    ..._.pick(state.dashboard, [
      'changeNamePending',
      'toggleBayOfflinePending',
      'unlockBayPending',
      'changeBayOfflineWhenAccessedPending',
      'bayAssignmentPending',
      'showAssignCredentialsModal'
    ]),
    subscriptionInfo: state.account.subscriptionInfo
  };
};

const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes>) => ({
  updateBayName: (id: string, value: string) => dispatch({ type: ActionTypes.UPDATE_BAY_NAME, payload: { id, value } }),
  toggleBayOffline: (id: string, offline: boolean) =>
    dispatch({ type: ActionTypes.TOGGLE_BAY_OFFLINE, payload: { id, offline } }),
  unlockBay: (id: string) => dispatch({ type: ActionTypes.UNLOCK_BAY, payload: { id } }),
  changeBayOfflineWhenAccessed: (id: string, value: boolean) =>
    dispatch({ type: ActionTypes.CHANGE_BAY_BAY_OFFLINE_WHEN_ACCESSED, payload: { id, value } }),
  toggleBayOfflineWhenAccessedOverride: (id: string, value: boolean | null) =>
    dispatch({ type: ActionTypes.TOGGLE_BAY_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE, payload: { id, value } }),
  removeBayAssignment: (bayId: string) => dispatch({ type: ActionTypes.REMOVE_BAY_ASSIGNMENT, payload: { bayId } }),
  updateBayTags: (id: string, value: string[]) =>
    dispatch({ type: ActionTypes.UPDATE_BAY_TAGS, payload: { id, value } }),
  setBayAssignment: (bayId: string, credentials: { pin?: string; rfid?: string }) =>
    dispatch({ type: ActionTypes.SET_BAY_ASSIGNMENT, payload: { bayId, credentials } }),
  toggleAssignCredentialsModal: (showModal: boolean) =>
    dispatch({ type: ActionTypes.TOGGLE_ASSIGN_CREDENTIALS_MODAL, payload: { showModal } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const BayOverview = connector((props: Props) => {
  const { bay } = props;
  const bayStateText: string = BayState[bay?.reported?.bayState as number];
  const isBayOffline = !!bay?.reported?.offline;
  const accessDate: string = bay?.reported?.accessDate
    ? moment(bay?.reported?.accessDate.toString()).format('Do MMMM YYYY, h:mm:ssa')
    : '';

  const groupHierarchy = useGroupHierarchy();
  const stationInRootGroup = !!groupHierarchyToArray(groupHierarchy).find(g => g.stations.includes(props.station?.id))
    ?.isRoot;

  const controlsEnabled = essentialsTierOrAbove(props.subscriptionInfo) || stationInRootGroup;

  const assignedUserId = bay?.reported?.userId?.toString();
  const [assignedUser, setAssignedUser] = useState<{ id?: string; name: string }>();
  const [findAssignedUser, findAssignedUserLoading] = useLatestSdkCall(
    'StationUserApi',
    'findOne',
    v => {
      setAssignedUser(v.data);
    },
    () => setAssignedUser({ name: `(Unknown) ${assignedUserId}` })
  );
  const isHeadless = !!props.station?.derivedConfig?.isHeadless;

  useEffect(() => {
    setAssignedUser(undefined);
    if (assignedUserId) {
      findAssignedUser(assignedUserId);
    }
  }, [assignedUserId]);

  if (props.station && !props.bay) {
    return <FetchFailedAlert itemType="bay" statusCode={404} />;
  }

  return bay === undefined || !props.subscriptionInfo ? (
    <Skeleton active />
  ) : (
    <>
      <AssignCredentials
        visible={props.showAssignCredentialsModal}
        handleSave={props.setBayAssignment}
        toggleModalShown={props.toggleAssignCredentialsModal}
        bayId={bay.id}
        pending={props.bayAssignmentPending}
      />

      <Row style={{ marginBottom: 15 }}>
        <Col span={12}>
          <EditableLabel
            defaultValue={bay.name || bay.id}
            loading={!!props.changeNamePending}
            onSubmit={props.updateBayName.bind(null, bay.id)}
          />
        </Col>
      </Row>

      <div style={{ marginBottom: 20 }}>
        <Tags tags={bay.tags} updateTags={props.updateBayTags.bind(null, bay.id)} />
      </div>

      <div className="content-split">
        <div>
          <BayStatusIndicator status={bay.reported} size={20} style={{ marginRight: 10, verticalAlign: 'text-top' }} />
          <span data-test-id="bay-state-text">{bayStateText}</span>
        </div>
        <div>
          {accessDate}
          {!isHeadless && bay?.reported?.bayState !== 2 && bay?.reported?.bayState !== 3 && (
            <Button
              title={!controlsEnabled ? subscriptionMessage : undefined}
              disabled={!controlsEnabled}
              size="small"
              onClick={props.toggleAssignCredentialsModal.bind(null, true)}>
              Assign temporary credentials
            </Button>
          )}
        </div>
      </div>
      {accessDate && (
        <div className="content-split" style={{ marginTop: 10 }}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {findAssignedUserLoading ? (
              <Skeleton active paragraph={{ rows: 1 }} title={false} />
            ) : (
              <span title={assignedUser?.name}>{assignedUser?.name}</span>
            )}
          </div>
          <NotificationButton
            disabled={!controlsEnabled}
            icon={<CloseCircleOutlined />}
            btnClass="btn-danger"
            size="small"
            text="Remove"
            promptContent="Remove this assignment?"
            pending={props.bayAssignmentPending}
            onOk={() => props.removeBayAssignment(bay.id)}
          />
        </div>
      )}
      {!isHeadless && (
        <>
          <br />
          <br />
          <BayOfflineWhenAccessed
            derivedValue={
              bay.derivedConfig?.takeBayOfflineWhenAccessed !== undefined
                ? bay.derivedConfig?.takeBayOfflineWhenAccessed
                : props.station?.derivedConfig?.takeBayOfflineWhenAccessed
            }
            isOverridden={bay.derivedConfig?.takeBayOfflineWhenAccessed !== undefined}
            showOverrideButton={true}
            changeBayOfflineWhenAccessedPending={props.changeBayOfflineWhenAccessedPending}
            changeBayOfflineWhenAccessed={props.changeBayOfflineWhenAccessed.bind(null, bay.id)}
            toggleBayOfflineWhenAccessedOverride={props.toggleBayOfflineWhenAccessedOverride.bind(null, bay.id)}
            subscriptionInfo={props.subscriptionInfo}
          />
        </>
      )}

      <br />
      {props.station?.reported?.connected !== true ? (
        <Tooltip placement="left" title="Unable to unlock bay while station is offline." mouseEnterDelay={0.6}>
          <Button icon={<LockOutlined />} disabled={true}>
            Unlock bay
          </Button>
        </Tooltip>
      ) : (
        <div title={!controlsEnabled ? subscriptionMessage : undefined}>
          <NotificationButton
            disabled={!controlsEnabled}
            icon={<LockOutlined />}
            text="Unlock bay"
            promptContent="Unlock this bay?"
            pending={props.unlockBayPending.indexOf(bay.id) !== -1}
            onOk={props.unlockBay.bind(null, bay.id)}
          />
        </div>
      )}

      {!isHeadless && (
        <div title={!controlsEnabled ? subscriptionMessage : undefined}>
          <NotificationButton
            disabled={!controlsEnabled}
            icon={<PoweroffOutlined />}
            btnStyle={{ marginTop: 20 }}
            text={isBayOffline ? 'Bring bay online' : 'Take bay offline'}
            promptContent={isBayOffline ? 'Bring this bay online?' : 'Take this bay offline?'}
            pending={props.toggleBayOfflinePending.indexOf(bay.id) !== -1}
            onOk={props.toggleBayOffline.bind(null, bay.id, !isBayOffline)}
          />
        </div>
      )}

      <div style={{ marginTop: 15, marginBottom: 15 }}>
        <strong>ID:</strong> {bay.id}
      </div>
    </>
  );
});
