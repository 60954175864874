import React, { useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input, Button, Spin, Alert } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import * as ActionTypes from '../../store/actionTypes';
import { RootState } from '../../store/rootReducer';
import { AuthActionTypes } from '../../store/auth/types';
import _ from 'lodash';

const mapStateToProps = (state: RootState) => ({ ..._.pick(state.auth, ['email', 'error', 'pending']) });
const mapDispatchToProps = (dispatch: Dispatch<AuthActionTypes>) => ({
  onLoad: () => dispatch({ type: ActionTypes.FORGOT_PASSWORD_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ActionTypes.FORGOT_PASSWORD_PAGE_UNLOADED }),
  forgotPassword: (email: string) => dispatch({ type: ActionTypes.FORGOT_PASSWORD, payload: { email: email } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & FormComponentProps;

export const ForgotPassword = connector(
  LegacyForm.create({ name: 'forgot_password' })((props: Props) => {
    useEffect(() => {
      props.onLoad();
      props.form.setFieldsValue({ email: props.email });

      return () => {
        props.onUnload();
      };
    }, []);

    const { getFieldDecorator } = props.form;

    const handleSubmit = (e: any) => {
      e.preventDefault();
      props.form.validateFields((err: Error, values: any) => {
        if (!err) {
          props.forgotPassword(values.email);
        }
      });
    };

    return (
      <LegacyForm onSubmit={handleSubmit} className="login-form">
        <LegacyForm.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, type: 'email', message: 'Please input a valid email address!' }]
          })(<Input prefix={<UserOutlined />} placeholder="Email" />)}
        </LegacyForm.Item>

        {props.error && typeof props.error === 'string' && (
          <LegacyForm.Item style={{ marginBottom: 16 }}>
            <Alert message={props.error} type="error" showIcon />
          </LegacyForm.Item>
        )}

        <LegacyForm.Item>
          <Spin spinning={props.pending === true}>
            <Button block type="primary" htmlType="submit" className="login-form-button">
              Reset Password
            </Button>
          </Spin>

          <div className="login-login-link">
            I know my password, <Link to="/login">I want to login.</Link>
          </div>
        </LegacyForm.Item>
      </LegacyForm>
    );
  })
);
