import { EventLogsActionTypes, EventLogsState } from './types';
import * as ActionTypes from '../actionTypes';
import moment from 'moment';

const defaultState: EventLogsState = {
  eventLogs: [],
  isEventLogsLoading: true,
  startDateFilter: moment()
    .subtract(6, 'd')
    .startOf('day')
    .toISOString(),
  endDateFilter: moment()
    .endOf('day')
    .toISOString(),
  userTypeFilters: undefined,
  searchFilters: undefined,
  fetchError: undefined,
  nextToken: undefined,
  executionId: undefined
};

export const eventLogs = (state: EventLogsState = defaultState, action: EventLogsActionTypes): EventLogsState => {
  switch (action.type) {
    case ActionTypes.EVENT_LOGS_PAGE_LOADED:
      return {
        ...state,
        ...defaultState,
        eventLogs: [],
        isEventLogsLoading: true
      };
    case ActionTypes.EVENT_LOGS_PAGE_UNLOADED: {
      return {
        ...state,
        ...defaultState
      };
    }
    case ActionTypes.FIND_EVENT_LOGS:
      return {
        ...state,
        fetchError: undefined,
        isEventLogsLoading: true
      };
    case ActionTypes.FIND_EVENT_LOGS_SUCCESS:
      if (state.nextToken) {
        state.eventLogs.push(...action.payload.data.items);
      } else {
        state.eventLogs = action.payload.data.items;
      }
      return {
        ...state,
        isEventLogsLoading: false,
        nextToken: action.payload.data.nextToken,
        executionId: action.payload.data.executionId
      };
    case ActionTypes.FIND_EVENT_LOGS_ERROR:
      return {
        ...state,
        isEventLogsLoading: false,
        fetchError: action.payload.fetchError
      };
    case ActionTypes.FILTER_EVENT_LOGS_BY_PROPERTY:
      return {
        ...state,
        startDateFilter: action.payload.startDateFilter,
        endDateFilter: action.payload.endDateFilter,
        userTypeFilters: action.payload.userTypeFilters,
        searchFilters: action.payload.searchFilters,
        nextToken: undefined,
        executionId: undefined,
        eventLogs: []
      };
    default:
      return state;
  }
};
