import React, { useEffect, Dispatch, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as ActionTypes from '../../store/actionTypes';
import { CommonActionTypes, ShowNotificationMessageAction } from '../../store/common/types';
import { RootState } from '../../store/rootReducer';
import { useParams } from 'react-router-dom';
import { Alert, Button, Skeleton } from 'antd';
import { PlusSquareFilled, MinusSquareFilled } from '@ant-design/icons';
import { essentialsTierOrAbove } from '../../helpers/subscription-helper';
import { useEverySdkCall, usePageableSdkCall, useSdkCallOnChanges } from '../../helpers/sdk-hooks';
import { UserInfinityList } from '../ManageUsersCommon/user-infinity-list';
import { AddRemoveUsersModal } from './add-remove-users-modal';

const mapStateToProps = (state: RootState) => ({
  globalTags: state.common.globalTags,
  accountId: state.auth.accountContext,
  subscriptionInfo: state.account.subscriptionInfo
});

const mapDispatchToProps = (dispatch: Dispatch<CommonActionTypes>) => ({
  fetchGlobalTags: () => dispatch({ type: ActionTypes.FETCH_GLOBAL_TAGS }),
  showNotification: (payload: ShowNotificationMessageAction['payload']) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const UserGroupUsers = connector((props: Props) => {
  const { userGroupId } = useParams<{ userGroupId: string }>();
  const isCorrectSubscription = essentialsTierOrAbove(props.subscriptionInfo);

  useEffect(() => {
    props.fetchGlobalTags();
  }, []);

  const [userGroupLoading, userGroup, userGroupError] = useSdkCallOnChanges(
    'StationUserGroupInternalApi',
    'findOne',
    userGroupId
  );

  const currentUsersPager = usePageableSdkCall(
    'StationUserGroupInternalApi',
    'findUsers',
    {
      name: undefined,
      tags: undefined
    },
    (fn, filters, limit, after) =>
      fn(userGroupId, undefined, after, limit, filters.name || undefined, filters.tags || undefined, [
        'id',
        'name',
        'tags'
      ]),
    res => res
  );

  const reloadItems = () => {
    currentUsersPager.clearItems();
    currentUsersPager.loadItems();
  };

  useEffect(() => {
    if (userGroup) {
      reloadItems();
    }
  }, [userGroup]);

  const [showModal, setShowModal] = useState<'add' | 'remove' | undefined>(undefined);

  const [addUsers, addUsersLoading] = useEverySdkCall(
    'StationUserGroupInternalApi',
    'addUsers',
    () => {
      setShowModal(undefined);
      reloadItems();
      props.showNotification({ type: 'success', content: 'Added users to the group.' });
    },
    () => props.showNotification({ type: 'error', content: 'Could not add users to the group.' })
  );
  const [removeUsers, removeUsersLoading] = useEverySdkCall(
    'StationUserGroupInternalApi',
    'removeUsers',
    () => {
      setShowModal(undefined);
      reloadItems();
      props.showNotification({ type: 'success', content: 'Removed users from the group.' });
    },
    () => props.showNotification({ type: 'error', content: 'Could not remove users from the group.' })
  );
  const userModificationSubmitting = addUsersLoading || removeUsersLoading;

  return (
    <div>
      <h4 className="user-group-details-title">
        {userGroupLoading || !userGroup?.name ? <>&nbsp;</> : `'${userGroup.name}' group`}
      </h4>
      <div className="section-container user-groups-section-inner">
        <div className="content-section" style={{ display: 'flex', flexDirection: 'column' }}>
          {(() => {
            if (!props.subscriptionInfo || userGroupLoading) {
              return <Skeleton active />;
            } else if (!isCorrectSubscription) {
              return <Alert type="error" message="Features disabled due to subscription level." />;
            } else if (userGroupError) {
              return (
                <Alert
                  type="error"
                  message={
                    'status' in userGroupError && userGroupError.status === 404
                      ? 'The user group was not found.'
                      : 'Failed to get the user group.'
                  }
                />
              );
            }
            return (
              <>
                {showModal && (
                  <AddRemoveUsersModal
                    userGroupId={userGroupId}
                    action={showModal}
                    isSubmitting={userModificationSubmitting}
                    onChange={userIds => {
                      if (showModal === 'add') {
                        addUsers(userGroupId, { userIds });
                      } else {
                        removeUsers(userGroupId, { userIds });
                      }
                    }}
                    onClose={() => setShowModal(undefined)}
                  />
                )}

                <div className="user-groups-items-actions">
                  <Button
                    size="small"
                    onClick={() => setShowModal('add')}
                    className="btn-primary"
                    disabled={userModificationSubmitting}>
                    <PlusSquareFilled /> Add users
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setShowModal('remove')}
                    className="btn-danger"
                    disabled={userModificationSubmitting}>
                    <MinusSquareFilled /> Remove users
                  </Button>
                </div>

                <UserInfinityList
                  tagsFilterable={true}
                  pager={currentUsersPager}
                  globalTags={props.globalTags ?? []}
                  pageSize={100}
                />
              </>
            );
          })()}
        </div>
      </div>
    </div>
  );
});
