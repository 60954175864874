import React, { useEffect, Dispatch, useState, useMemo, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col, Tree, Button, Popconfirm, Tooltip, Skeleton, Input } from 'antd';
import {
  CloseOutlined,
  CloudDownloadOutlined,
  LoadingOutlined,
  FolderOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import * as ActionTypes from '../../store/actionTypes';
import { RootState } from '../../store/rootReducer';
import { DashboardActionTypes } from '../../store/dashboard/types';
import { accountActions, AccountActionTypes } from '../../store/account/reducer';
import _ from 'lodash';
import { FeatureId, useFeatureAvailability } from '../../helpers/feature-availability-hooks';
import { useGroupHierarchy, createTreeNodes, filterGroupNode } from '../../helpers/group-hierarchy';
import { useUngroupedStationsList } from './use-ungrouped-stations-list';
import { useEverySdkCall } from '../../helpers/sdk-hooks';
import { CommonActionTypes } from '../../store/common/types';
import { useTrackElementHeight } from '../../helpers/mutation-observer-hooks';
import { useDebouncedHook } from '../../helpers/use-debounced-hook';

export const MAX_SELECTABLE_STATIONS = 10;

const mapStateToProps = (state: RootState) => ({
  subscriptionInfo: state.account.subscriptionInfo,
  isSubscriptionInfoPending: state.account.isSubscriptionInfoPending
});

const mapDispatchToProps = (dispatch: Dispatch<CommonActionTypes | DashboardActionTypes | AccountActionTypes>) => ({
  getSubscriptionInfo: () => dispatch(accountActions.getSubscriptionInfo()),
  updateFirmware: (id: string) => dispatch({ type: ActionTypes.UPDATE_FIRMWARE, payload: { id } }),
  showSuccessMessage: (content: string) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload: { type: 'success', content }
    }),
  showErrorModal: (content: string) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MODAL,
      payload: { type: 'error', title: 'Error', content }
    }),
  showWarningModal: (content: string) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MODAL,
      payload: { type: 'warning', title: 'Warning', content }
    }),
  redirectToDashboard: () => {
    dispatch({ type: ActionTypes.FETCH_GROUP_HIERARCHY, payload: { forceUpdate: true } });
    dispatch({ type: ActionTypes.REDIRECT, redirectTo: 'dashboard' });
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const StationSetup = connector((props: Props) => {
  const [checkedStationIds, setCheckedStationIds] = useState<string[]>([]);
  const [hasFeature] = useFeatureAvailability();
  const [selectedNodeId, setSelectedNodeId] = useState<string>();
  const [searchNodeTerm, setSearchNodeTerm] = useState('');
  const [searchStationTerm, setSearchStationTerm] = useState('');

  const {
    ungroupedStations,
    fetchError,
    flagStationUpdating,
    flagStationFactoryResetting
  } = useUngroupedStationsList();
  useEffect(() => {
    if (ungroupedStations === undefined && fetchError) {
      props.showErrorModal('Could not fetch ungrouped stations.');
    }
  }, [ungroupedStations, fetchError]);

  const availableSubUnits =
    (props.subscriptionInfo?.subscriptionQuantity ?? 0) - (props.subscriptionInfo?.usedQuantity ?? 0);
  const enoughSubscriptionUnits = availableSubUnits >= checkedStationIds.length;
  useEffect(() => {
    props.getSubscriptionInfo();
  }, []);

  const tree = useGroupHierarchy();

  const [addStationsToGroup, addStationsToGroupPending] = useEverySdkCall(
    'StationApi',
    'moveToGroup',
    () => {
      props.showSuccessMessage(
        `Moved ${checkedStationIds.length} station${checkedStationIds.length === 1 ? '' : 's'}.`
      );
      props.redirectToDashboard();
    },
    () => props.showErrorModal('Could not move stations to the node.')
  );

  const [stationAction] = useEverySdkCall(
    'StationApi',
    'action',
    () => props.showSuccessMessage('Factory reset has been initiated on the station.'),
    () => props.showErrorModal('Factory reset could not be initiated.')
  );

  const debouncedSearchStationTerm = useDebouncedHook(searchStationTerm);
  const filteredStations = useMemo(() => {
    const searchStationTermProcessed = debouncedSearchStationTerm.trim().toLocaleLowerCase();
    return ungroupedStations?.filter(station => station.name.toLocaleLowerCase().includes(searchStationTermProcessed));
  }, [ungroupedStations, debouncedSearchStationTerm]);

  const hasManagedStationsFeature = hasFeature(FeatureId.MANAGEDSTATIONS);
  const debouncedSearchNodeTerm = useDebouncedHook(searchNodeTerm);
  const filteredNodes = useMemo(() => {
    if (tree) {
      if (hasManagedStationsFeature) {
        const searchTerm = debouncedSearchNodeTerm.trim().toLocaleLowerCase();
        const filteredTree =
          searchTerm.length > 0
            ? _.mapValues(tree, node => ({ ...node, groups: {}, ...filterGroupNode(node, searchTerm) }))
            : tree;
        return createTreeNodes(filteredTree);
      }
      return createTreeNodes(_.mapValues(tree, g => ({ ...g, groups: {} })));
    }
  }, [tree, hasManagedStationsFeature, debouncedSearchNodeTerm]);

  const ungroupedStationsContainer = useRef<HTMLDivElement>(null);
  const ungroupedStationsContainerHeight = useTrackElementHeight(ungroupedStationsContainer);
  const hierarchyNodesContainer = useRef<HTMLDivElement>(null);
  const nodesContainerHeight = useTrackElementHeight(hierarchyNodesContainer);

  return (
    <div>
      <h4>Set up new stations</h4>
      <Row gutter={32}>
        <Col span={12}>
          <div className="dashboard-section">
            <div className="dashboard-section-title">
              {filteredStations?.length ?? 0} new station{filteredStations?.length !== 1 ? 's' : ''} found.
              <br />
              <small>Select stations and move them into nodes in order to configure them for cloud enabled use.</small>
            </div>
            <div className="dashboard-section-inner hierarchy-tree-section">
              <div className="hierarchy-tree-section-inner">
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchStationTerm(e.target.value);
                  }}
                  value={searchStationTerm}
                  placeholder="Filter by name"
                />
                <div className="hierarchy-tree-container" ref={ungroupedStationsContainer}>
                  {!filteredStations ? (
                    <Skeleton active paragraph={true} />
                  ) : (
                    <Tree
                      checkable
                      selectable={false}
                      defaultExpandAll
                      height={ungroupedStationsContainerHeight}
                      onCheck={v => {
                        const itemsChecked = (v as string[]).length;
                        if (itemsChecked > availableSubUnits) {
                          props.showWarningModal(
                            `There are not enough subscription units to move ${itemsChecked} or more stations. Purchase more units before onboarding.`
                          );
                        } else if (itemsChecked <= MAX_SELECTABLE_STATIONS) {
                          setCheckedStationIds(v as string[]);
                        } else {
                          props.showWarningModal(`You can select a maximum of ${MAX_SELECTABLE_STATIONS} stations`);
                        }
                      }}
                      autoExpandParent={true}
                      blockNode
                      checkedKeys={checkedStationIds}
                      treeData={filteredStations.map(station => {
                        return {
                          disabled: station.mandatoryFirmwareUpdate || station.isFactoryResetting,
                          title: (
                            <div className="content-split">
                              <Tooltip
                                title={station.mandatoryFirmwareUpdate ? 'Station requires an update.' : undefined}>
                                {station.name}
                              </Tooltip>
                              <span style={{ paddingRight: 10 }}>
                                {station.isFactoryResetting || station.isUpdatingFirmware ? (
                                  <Tooltip
                                    title={
                                      station.isFactoryResetting
                                        ? 'Factory reset in progress.'
                                        : 'Firmware update in progress.'
                                    }
                                    placement="topLeft">
                                    <ReloadOutlined spin style={{ padding: '0 3px' }} />
                                  </Tooltip>
                                ) : (
                                  <span onClick={e => e.stopPropagation()}>
                                    {station.availableFirmwareUpdate && (
                                      <Tooltip title="Update firmware." placement="topLeft">
                                        <Button
                                          size="small"
                                          onClick={() => {
                                            flagStationUpdating(station.id);
                                            props.updateFirmware(station.id);
                                          }}
                                          icon={<CloudDownloadOutlined />}
                                        />
                                      </Tooltip>
                                    )}
                                    <Popconfirm
                                      placement="right"
                                      title="Are you sure you want to factory reset this station?"
                                      okText="Yes"
                                      cancelText="No"
                                      onConfirm={() => {
                                        flagStationFactoryResetting(station.id);
                                        stationAction(station.id, { factoryReset: true });
                                      }}>
                                      <Tooltip title="Remove station.">
                                        <Button size="small" style={{ marginLeft: 5 }} icon={<CloseOutlined />} />
                                      </Tooltip>
                                    </Popconfirm>
                                  </span>
                                )}
                              </span>
                            </div>
                          ),
                          key: station.id
                        };
                      })}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
        {
          <Col span={12}>
            <div className="dashboard-section">
              <div className="dashboard-section-title">
                <Row justify="space-between" align="middle">
                  <Col>
                    Node Selection <br />
                    <small>Select a node to move stations into.</small>
                  </Col>
                  <Col>
                    {checkedStationIds.length > 0 &&
                    selectedNodeId &&
                    !addStationsToGroupPending &&
                    enoughSubscriptionUnits ? (
                      <Popconfirm
                        placement="left"
                        title="Are you sure you want to move these stations into the node?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                          addStationsToGroup({ stationIds: checkedStationIds, groupId: selectedNodeId });
                        }}>
                        <Button disabled={false} size="small" className="btn-primary" icon={<FolderOutlined />}>
                          Move {checkedStationIds.length} station{checkedStationIds.length !== 1 ? 's' : ''}
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Button
                        disabled={true}
                        size="small"
                        className="btn-primary"
                        icon={addStationsToGroupPending ? <LoadingOutlined /> : <FolderOutlined />}>
                        Move {checkedStationIds.length} station{checkedStationIds.length !== 1 ? 's' : ''}
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="dashboard-section-inner hierarchy-tree-section">
                <div className="hierarchy-tree-section-inner">
                  <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchNodeTerm(e.target.value);
                    }}
                    value={searchNodeTerm}
                    placeholder="Filter by name"
                  />
                  <div className="hierarchy-tree-container" ref={hierarchyNodesContainer}>
                    {!props.subscriptionInfo || !tree ? (
                      <Skeleton active paragraph={true} />
                    ) : (
                      <Tree
                        showLine
                        defaultExpandAll
                        height={nodesContainerHeight}
                        onSelect={selectedKeys => setSelectedNodeId(selectedKeys[0] as string)}
                        autoExpandParent
                        blockNode
                        selectedKeys={selectedNodeId ? [selectedNodeId] : undefined}
                        treeData={filteredNodes}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        }
      </Row>
    </div>
  );
});
