import { DeleteFilled, EditFilled, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Popconfirm, Table } from 'antd';
import { ColumnProps, ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import moment from 'moment';
import React, { Dispatch, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { subscriptionMessage } from '../../../constants/validations';
import { FeatureId, useFeatureAvailability } from '../../../helpers/feature-availability-hooks';
import * as ActionTypes from '../../../store/actionTypes';
import { AppClient, IntegrationsActionTypes } from '../../../store/integrations/types';
import { RootState } from '../../../store/rootReducer';
import { CopyToClipboard } from '../../CopyToClipboard';
import { EditableCell } from './editable-cell';
import { EditableFormRow } from './editable-row';
import { NewAppClientForm } from './new-app-client-form';

const mapStateToProps = (state: RootState) => {
  return {
    appClients: state.integrations.appClients,
    newAppClientFormShown: state.integrations.newAppClientFormShown,
    pending: state.integrations.pending,
    subscriptionInfo: state.account.subscriptionInfo
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IntegrationsActionTypes>) => ({
  fetchAppClients: () => dispatch({ type: ActionTypes.FETCH_APP_CLIENTS }),
  toggleNewAppClientForm: () => dispatch({ type: ActionTypes.TOGGLE_NEW_APP_CLIENT_FORM }),
  createAppClient: (payload: { clientSecret: string; name: string }) =>
    dispatch({ type: ActionTypes.CREATE_APP_CLIENT, payload }),
  updateAppClient: (payload: { clientId: string; clientSecret: string; name: string }) =>
    dispatch({ type: ActionTypes.UPDATE_APP_CLIENT, payload }),
  deleteAppClient: (clientId: string) => dispatch({ type: ActionTypes.DELETE_APP_CLIENT, payload: { clientId } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const EditableContext = React.createContext({ form: {} });

interface EditableCellProps {
  extra?: string | null;
  placeholder: string;
  initialValue?: (currentValue: string) => string;
  component?: typeof Input | typeof Input['Password'];
}
interface ColumnPropsEditable<T> extends ColumnProps<T>, EditableCellProps {
  editable: true;
}

interface ColumnPropsFixed<T> extends ColumnProps<T> {
  editable?: false;
}

type AppClientColumnProps = ColumnPropsFixed<AppClient> | ColumnPropsEditable<AppClient>;

export const AppClients = connector((props: Props) => {
  const [editingKey, setEditingKey] = useState('');

  const [hasFeature] = useFeatureAvailability();
  const featureEnabled = hasFeature(FeatureId.PUBLICAPI);

  useEffect(() => {
    props.fetchAppClients();
  }, []);

  useEffect(() => {
    setEditingKey('');
  }, [props.appClients]);

  const columns: AppClientColumnProps[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'name',
      width: '50%',
      editable: true,
      placeholder: 'Enter new name',
      initialValue: currentValue => currentValue,
      render: (name: string) => (
        <div>
          &nbsp;
          {name}
        </div>
      )
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      className: 'client-id',
      width: '50%',
      render: (clientId: string) => (
        <div>
          <CopyToClipboard data={clientId} title="Copy client ID to clipboard" />
          &nbsp;
          {clientId}
        </div>
      )
    },
    {
      title: 'Client Secret',
      dataIndex: 'clientSecret',
      className: 'client-secret',
      width: '40%',
      editable: true,
      extra: 'Record this somewhere safe, once saved it can not be retrieved!',
      placeholder: 'Enter new client secret',
      component: Input.Password,
      render: () => {
        return <span>******</span>;
      }
    },
    {
      title: 'Token age',
      dataIndex: 'dateUpdated',
      className: 'dateUpdated',
      width: '50%',
      render: (dateUpdated: string) => {
        const age = moment().diff(dateUpdated, 'days');

        return (
          <div>
            &nbsp;
            {age === 0 ? 'Today' : `${age} days`}
          </div>
        );
      }
    },
    {
      title: '',
      dataIndex: 'operation',
      className: 'text-align-center',
      width: 170,
      fixed: 'right',
      render: (_text: string, record: AppClient) => {
        const editable = isEditing(record);
        return (
          <div>
            {editable ? (
              <>
                <Button
                  ghost
                  size="small"
                  style={{ marginRight: 10 }}
                  onClick={() => setEditingKey('')}
                  disabled={props.pending}
                  className="app-client-cancel-btn">
                  Cancel
                </Button>
                <EditableContext.Consumer>
                  {form => (
                    <Button
                      className="btn-primary app-client-save-btn"
                      size="small"
                      style={{ width: 50 }}
                      onClick={() => saveClient(form, record)}
                      disabled={props.pending}>
                      {props.pending ? <LoadingOutlined /> : 'Save'}
                    </Button>
                  )}
                </EditableContext.Consumer>
              </>
            ) : (
              <>
                <Button
                  size="small"
                  className="btn-primary app-client-edit-btn"
                  onClick={() => setEditingKey(record.clientId)}
                  disabled={!featureEnabled || props.pending}
                  style={{ marginRight: 10 }}>
                  <EditFilled />
                  Edit
                </Button>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => props.deleteAppClient(record.clientId)}
                  className={props.pending ? 'disabled' : 'btn-danger'}
                  disabled={props.pending}>
                  <Button size="small">
                    <DeleteFilled />
                    Delete
                  </Button>
                </Popconfirm>
              </>
            )}
          </div>
        );
      }
    }
  ];

  const isEditing = (record: AppClient) => record.clientId === editingKey;

  const saveClient = (form: any, record: AppClient) => {
    form.validateFields((error: any, row: any) => {
      if (error) {
        return;
      }
      props.updateAppClient({ clientId: record.clientId, clientSecret: row.clientSecret, name: row.name });
    });
  };

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell
    }
  };

  const renderColumns: ColumnType<AppClient>[] = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AppClient) => ({
        record,
        dataIndex: col.dataIndex,
        placeholder: col.placeholder,
        component: col.component,
        initialValue: col.initialValue,
        extra: col.extra,
        title: col.title.toString(),
        key: col.dataIndex,
        editing: isEditing(record),
        pending: props.pending,
        subscriptionInfo: props.subscriptionInfo
      })
    };
  });

  const docspath = `https://docs.${window.location.hostname}`;

  return (
    <div className="content-section">
      {!featureEnabled && <Alert style={{ marginBottom: 16 }} type="error" message={subscriptionMessage} />}
      <div style={{ marginBottom: 16 }}>
        <InfoCircleOutlined /> For more information on how to use the API, please go to{' '}
        <a href={docspath} target="_blank" rel="noopener noreferrer">
          {docspath}
        </a>
      </div>
      <div style={{ marginBottom: 16 }}>
        <NewAppClientForm
          newAppClientFormShown={props.newAppClientFormShown}
          toggleNewAppClientForm={props.toggleNewAppClientForm}
          createAppClient={props.createAppClient}
          pending={props.pending}
          featureEnabled={featureEnabled}
        />
      </div>
      <div className="content-section-inner">
        <Table
          components={components}
          bordered
          dataSource={_.orderBy(props.appClients, ['dateUpdated'], 'desc')}
          columns={renderColumns}
          rowKey="clientId"
          pagination={false}
          scroll={{ y: 420 }}
          size="middle"
          loading={props.pending}
          rowClassName={() => 'app-client-row'}
        />
      </div>
    </div>
  );
});
