import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Alert, Button, Col, Input, Row, Spin } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { EditOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { RootState } from '../../store/rootReducer';
import { Validations } from '../../constants/validations';
import * as ActionTypes from '../../store/actionTypes';
import { AuthActionTypes } from '../../store/auth/types';
import _ from 'lodash';
import { AdminUserContextDtoTypeEnum, AdminUserUpdateDto } from '@pclocs/platform-sdk';
import { CognitoUser } from '@aws-amplify/auth';
import { ProfileMfa } from './profile-mfa';

const mapStateToProps = (state: RootState) => ({
  ..._.pick(state.auth, [
    'pending',
    'pendingProfile',
    'currentUser',
    'changePasswordErrorMessage',
    'changePasswordSuccess',
    'showProfileForm',
    'mfaConfiguredForAdmin',
    'cognitoUser',
    'pendingRemoveMfa',
    'pendingMfaSecretCode',
    'pendingMfaTotpRegistration',
    'mfaSecretCode'
  ])
});
const mapDispatchToProps = (dispatch: Dispatch<AuthActionTypes>) => ({
  onLoad: () => dispatch({ type: ActionTypes.PROFILE_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ActionTypes.PROFILE_PAGE_UNLOADED }),
  getMfaSecretCode: (cognitoUser: CognitoUser) =>
    dispatch({ type: ActionTypes.GET_MFA_SECRET_CODE, payload: { cognitoUser } }),
  changePassword: (oldPassword: string, newPassword: string) =>
    dispatch({ type: ActionTypes.CHANGE_PASSWORD, payload: { oldPassword, newPassword } }),
  toggleProfileForm: (showProfileForm: boolean) =>
    dispatch({ type: ActionTypes.TOGGLE_PROFILE_FORM, payload: { showProfileForm } }),
  updatePortalAdmin: (adminUser: AdminUserUpdateDto, id: string) =>
    dispatch({ type: ActionTypes.UPDATE_PROFILE_INFO, payload: { adminUser, id } }),
  removeMfaForAdmin: () => dispatch({ type: ActionTypes.REMOVE_MFA_FOR_ADMIN }),
  verifyMfaTotp: (cognitoUser: CognitoUser, mfaTotpVerificationToken: string) =>
    dispatch({
      type: ActionTypes.VERIFY_MFA_TOTP_TOKEN,
      payload: { cognitoUser, mfaTotpVerificationToken, origin: 'profile' }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & FormComponentProps;

export const Profile = connector(
  LegacyForm.create({ name: 'profile' })((props: Props) => {
    useEffect(() => {
      props.onLoad();

      return () => {
        props.onUnload();
      };
    }, []);

    useEffect(() => {
      {
        props.showProfileForm === true &&
          props.form.setFieldsValue({
            name: props.currentUser?.name
          });
      }
    }, [props.showProfileForm]);

    const { getFieldDecorator } = props.form;

    const formValues = props.form.getFieldsValue();

    const getSuccessAlertMessage = () => {
      return (
        <LegacyForm.Item label=" ">
          <Alert message="Your password has been changed" type="success" showIcon />
        </LegacyForm.Item>
      );
    };

    const getErrorAlertMessage = (props: Pick<Props, 'changePasswordErrorMessage'>) => {
      return (
        <LegacyForm.Item label=" ">
          <Alert message={props.changePasswordErrorMessage} type="error" showIcon />
        </LegacyForm.Item>
      );
    };

    const securePassword = (rule: any, value: any, callback: { (arg0: string): void; (): void }) => {
      if (value.match(Validations.securePassword.rule)) {
        callback();
      } else {
        callback(Validations.securePassword.message);
      }
    };

    const handleSubmitPasswordChange = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      props.form.validateFields(['oldPassword', 'newPassword'], async (err: any, values: any) => {
        if (!err) {
          props.changePassword(values.oldPassword, values.newPassword);
        }
      });
    };

    const handleSubmitPersonalInfoChange = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      props.form.validateFields(['name'], async (err: any, values: any) => {
        if (!err) {
          const updateUser: AdminUserUpdateDto = {
            name: values.name
          };
          props.currentUser && props.updatePortalAdmin(updateUser, props.currentUser.id);
        }
      });
    };

    const isInternalAdmin = props.currentUser?.type === AdminUserContextDtoTypeEnum.Internal;

    return (
      <div className="profile">
        {props.currentUser === null && (
          <div className="loader ant-modal-mask">
            <Spin spinning={true} size="large" />
          </div>
        )}
        <h4>Profile</h4>
        <div className="section-container">
          <div className="content-section">
            <div className="content-section-inner">
              <Row>
                <Col span={23} offset={1}>
                  <h5>
                    {props.currentUser?.displayText}
                    {isInternalAdmin && (
                      <EditOutlined onClick={() => props.toggleProfileForm(true)} style={{ marginLeft: 10 }} />
                    )}
                  </h5>
                </Col>
                {((isInternalAdmin && !props.showProfileForm) ||
                  props.currentUser?.email !== props.currentUser?.displayText) && (
                  <Col span={23} offset={1}>
                    {props.currentUser?.email}
                  </Col>
                )}
              </Row>
              {isInternalAdmin && (
                <>
                  {props.showProfileForm && (
                    <Row>
                      <Col span={23} offset={1}>
                        <LegacyForm
                          onSubmit={handleSubmitPersonalInfoChange}
                          {...{
                            labelCol: {
                              sm: { span: 24 },
                              md: { span: 1 }
                            },
                            wrapperCol: {
                              sm: { span: 24 },
                              md: { span: 10 }
                            }
                          }}>
                          <LegacyForm.Item label="Name">
                            {getFieldDecorator('name', {
                              rules: [{ required: true, message: 'Please input name!' }]
                            })(<Input />)}
                          </LegacyForm.Item>

                          <LegacyForm.Item label="Email">{props.currentUser?.email}</LegacyForm.Item>

                          <LegacyForm.Item label=" ">
                            <Button
                              type="default"
                              onClick={() => props.toggleProfileForm(false)}
                              style={{ marginRight: 10 }}>
                              Cancel
                            </Button>
                            <Button loading={props.pendingProfile} type="primary" htmlType="submit">
                              Save
                            </Button>
                          </LegacyForm.Item>
                        </LegacyForm>
                      </Col>
                    </Row>
                  )}
                  <hr />
                  <Row>
                    <Col span={23} offset={1}>
                      <h5>Change Password</h5>
                      <LegacyForm
                        onSubmit={handleSubmitPasswordChange}
                        {...{
                          labelCol: {
                            sm: { span: 24 },
                            md: { span: 1 }
                          },
                          wrapperCol: {
                            sm: { span: 24 },
                            md: { span: 10 }
                          }
                        }}>
                        <LegacyForm.Item label="Old Password" hasFeedback>
                          {getFieldDecorator('oldPassword', {
                            validateTrigger: 'onBlur',
                            rules: [{ required: true, message: 'Please input your old password!' }]
                          })(<Input.Password autoComplete="new-password" />)}
                        </LegacyForm.Item>

                        <LegacyForm.Item label="New Password" hasFeedback>
                          {getFieldDecorator('newPassword', {
                            validateTrigger: 'onBlur',
                            rules: [
                              { required: true, message: 'Please input your new password!' },
                              { validator: securePassword }
                            ]
                          })(<Input.Password autoComplete="new-password" />)}
                        </LegacyForm.Item>

                        {props.changePasswordErrorMessage && getErrorAlertMessage(props)}

                        {props.changePasswordSuccess && getSuccessAlertMessage()}

                        <LegacyForm.Item className="submit-button" label=" ">
                          <Button
                            type="default"
                            htmlType="submit"
                            loading={props.pending}
                            disabled={!formValues.oldPassword || !formValues.newPassword}>
                            Change Password
                          </Button>
                        </LegacyForm.Item>
                      </LegacyForm>
                    </Col>
                  </Row>
                  <hr />
                  <ProfileMfa props={props} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  })
);
