import React, { useEffect, Dispatch, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as ActionTypes from '../store/actionTypes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Authenticated } from './Authenticated';
import { Login } from './Login';
import { Register } from './Register';
import { history, store } from '../store/store';
import { push } from 'react-router-redux';
import { RootState } from '../store/rootReducer';
import { CommonActionTypes } from '../store/common/types';
import { NotificationModal } from './NotificationModal';
import { NotificationMessage } from './NotificationMessage';
import { UnauthorizedModal } from './UnauthorizedModal';
import { getBrandName, isBrandPCLocs } from '../helpers/brand-helper';
import { SelectAccount } from './SelectAccount';
import { CancelSuspension } from './CancelSuspension';
import { getAuthTokens, parseJwtToken } from '../helpers/auth-token-helper';
import { getCurrentUserContext } from '../api/account';
import { AuthActionTypes } from '../store/auth/types';
import { LogoLoader } from './LogoLoader';
import Hotjar from '@hotjar/browser';
import { CheckDegradationStatusAction } from '../store/degradation-status/types';
import { DegradationStatusBanner } from './DegradationStatusBanner';

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.currentUser,
  redirectTo: state.common.redirectTo
});

const mapDispatchToProps = (
  dispatch: Dispatch<AuthActionTypes | CommonActionTypes | CheckDegradationStatusAction>
) => ({
  logout: () => dispatch({ type: ActionTypes.LOGOUT, payload: { redirect: true } }),
  onRedirect: () => dispatch({ type: ActionTypes.REDIRECT, redirectTo: null }),
  checkStatus: () => dispatch({ type: ActionTypes.CHECK_DEGRADATION_STATUS })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const App = connector((props: Props) => {
  const [userContextLoading, setUserContextLoading] = useState<boolean>(false);

  const siteId = parseInt(process.env.HOTJAR_SITE_ID);
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion, {
    debug: process.env.NODE_ENV !== 'production'
  });

  // Check for redirects on props. Runs if props.redirectTo changes.
  useEffect(() => {
    if (props.redirectTo) {
      const params = new URLSearchParams(window.location.search);
      const error_description = params.get('error_description');
      store.dispatch(push(props.redirectTo));
      if (error_description) {
        store.dispatch(push(`?error_description=${error_description.split('Error -')[1]}`));
      }
      props.onRedirect();
    }
  }, [props.redirectTo]);

  useEffect(() => {
    props.checkStatus();
    // Set page title to correct brand text
    document.title = `${getBrandName()} Cloud Portal`;
    // Redirect to new docs site for fuyl-networking guide
    if (window.location.pathname == '/docs/fuyl/networking') {
      isBrandPCLocs()
        ? window.location.replace('https://docs.pclocs.io/fuyl-networking')
        : window.location.replace('https://docs.lockncharge.io/fuyl-networking');
    }
  }, []);

  useEffect(() => {
    const checkCurrentSession = async () => {
      const authTokens = await getAuthTokens();
      if (authTokens) {
        const cognitoId = parseJwtToken(authTokens.idToken)?.['cognito:username'];
        const accountId = localStorage.getItem(`defaultAccountId.${cognitoId}`);

        if (accountId) {
          history.replace(`/auth/${accountId}/dashboard`);
        } else {
          try {
            setUserContextLoading(true);
            history.replace('/auth');
            const userContext = await getCurrentUserContext();
            if (userContext?.accounts?.[0]?.id) {
              history.replace(`/auth/${userContext.accounts[0].id}/dashboard`);
            } else {
              props.logout();
            }
          } catch {
            props.logout();
          }
        }
      }
    };

    if (!window.location.pathname.startsWith('/auth') && window.location.pathname !== '/login/auth') {
      checkCurrentSession();
    }
  }, [window.location.pathname]);

  return (
    <>
      <DegradationStatusBanner />
      <NotificationMessage />
      <NotificationModal />
      <UnauthorizedModal />
      <SelectAccount />
      <Switch>
        {userContextLoading && <Route exact path="/auth" component={LogoLoader} />}
        <Route path="/auth/:accountId" component={Authenticated} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/cancel-suspension" component={CancelSuspension} />
        <Redirect from={'/'} to="/login" />
      </Switch>
    </>
  );
});
