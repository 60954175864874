import React, { Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as ActionTypes from '../../store/actionTypes';
import { RootState } from '../../store/rootReducer';
import { Button, Modal } from 'antd';
import { CloseCircleOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { CommonActionTypes } from '../../store/common/types';

const mapStateToProps = (state: RootState) => {
  return {
    notificationModal: state.common.notificationModal
  };
};

const mapDispatchToProps = (dispatch: Dispatch<CommonActionTypes>) => ({
  dismissNotificationModal: () => dispatch({ type: ActionTypes.DISMISS_NOTIFICATION_MODAL })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

// Component with similar look to the ANTD Modal functions in a component.
// We are limiting options and functionality however to maintain consistency.
// Ref: https://github.com/ant-design/ant-design/blob/master/components/modal/index.tsx
export const NotificationModal = connector((props: Props) => {
  const ANT_CONFIRM_MODAL_CLASS = 'ant-modal-confirm';

  if (props.notificationModal === null) {
    return <></>;
  }

  const { type, title, content } = props.notificationModal;

  const ModalIcon = () => {
    switch (type) {
      case 'error':
        return <CloseCircleOutlined />;
      case 'warning':
        return <ExclamationCircleOutlined />;
      case 'info':
        return <InfoCircleOutlined />;
    }
  };

  return (
    <Modal
      visible={true}
      footer={null}
      className={`${ANT_CONFIRM_MODAL_CLASS} ${ANT_CONFIRM_MODAL_CLASS}-${type}`}
      width={416}>
      <div className={`${ANT_CONFIRM_MODAL_CLASS}-body-wrapper`}>
        <div className={`${ANT_CONFIRM_MODAL_CLASS}-body`}>
          <ModalIcon />
          {title && <span className={`${ANT_CONFIRM_MODAL_CLASS}-title`}>{title}</span>}
          <div className={`${ANT_CONFIRM_MODAL_CLASS}-content`}>{content}</div>
        </div>
        <div className={`${ANT_CONFIRM_MODAL_CLASS}-btns`}>
          <Button type="primary" onClick={props.dismissNotificationModal} autoFocus={true}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
});
