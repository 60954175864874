import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Alert, Button, Checkbox, Dropdown, Input, Menu, Popconfirm, Select, Tag, Tooltip } from 'antd';
import {
  DeleteFilled,
  DownOutlined,
  EditOutlined,
  ExportOutlined,
  ImportOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusCircleFilled,
  SearchOutlined,
  UserOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import { InternalStationUserModal } from './internal-station-user-modal';
import * as ActionTypes from '../../store/actionTypes';
import { RootState } from '../../store/rootReducer';
import { StationUserActionTypes } from '../../store/users/types';
import { StationUserResponseDto } from '@pclocs/platform-sdk/dist';
import * as _ from 'lodash';
import { StationUserCsvModal } from './station-user-csv-modal';
import { StationUserExportModal } from './station-user-export-modal';
import { history } from '../../store/store';
import { essentialsTierOrAbove } from '../../helpers/subscription-helper';
import { LegacyInfinityList } from '../InfinityList/legacy';
import { CommonActionTypes } from '../../store/common/types';
import { RefSelectProps, SelectValue } from 'antd/lib/select';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { StationUserDeleteModal } from './station-user-delete-modal';
import { StationUserUpdateCsvModal } from './station-user-update-csv-modal';
import { UsersBulkActionInProgress } from '../Alert/UsersBulkActionInProgress';
import { ViewStationUserModal } from './view-station-user-modal';
import { useWatchBackgroundJobs } from '../../helpers/watch-background-job-helper';

const mapStateToProps = (state: RootState) => ({
  ..._.pick(state.common, ['globalTags']),
  ..._.pick(state.stationUser, [
    'stationUser',
    'stationUsers',
    'stationUsersFilter',
    'stationUsersTagsFilter',
    'isStationUserLoading',
    'showStationUserCsvModal',
    'showStationUserExportModal',
    'showStationUserUpdateCsvModal',
    'showStationUserModal',
    'stationUsersPagingData',
    'isStationUserListLoading',
    'fetchError',
    'createStationUserSuccess',
    'stagedUsers',
    'bayAssignments'
  ]),
  subscriptionInfo: state.account.subscriptionInfo,
  accountId: state.auth.accountContext
});

const mapDispatchToProps = (dispatch: Dispatch<StationUserActionTypes | CommonActionTypes>) => ({
  onLoad: () => dispatch({ type: ActionTypes.USERS_PAGE_LOADED }),
  onUnload: () => {
    dispatch({ type: ActionTypes.USERS_PAGE_UNLOADED });
  },
  loadModal: (id?: string) =>
    dispatch({ type: ActionTypes.INTERNAL_STATION_USER_LOAD_MODAL, payload: { stationUserId: id } }),
  getStationUsers: (
    after: string | undefined,
    pageSize: number,
    filter: string | undefined,
    tagsFilter: string[] | undefined
  ) => {
    return dispatch({ type: ActionTypes.FIND_STATION_USERS, payload: { after, pageSize, filter, tagsFilter } });
  },
  deleteStationUser: (u: StationUserResponseDto) =>
    dispatch({ type: ActionTypes.DELETE_STATION_USER, payload: { id: u.id, name: u.name } }),
  filter: (filter: string, tagsFilter: string[]) => {
    return dispatch({ type: ActionTypes.FILTER_STATION_USERS_BY_PROPERTY, payload: { filter, tagsFilter } });
  },
  loadCsvModal: () => dispatch({ type: ActionTypes.STATION_USER_LOAD_CSV_MODAL }),
  loadExportModal: () => dispatch({ type: ActionTypes.STATION_USER_LOAD_EXPORT_MODAL }),
  loadUpdateCsvModal: () => dispatch({ type: ActionTypes.STATION_USER_LOAD_UPDATE_CSV_MODAL }),
  setStagedUsers: (stagedUsers: { id: string; name: string }[]) =>
    dispatch({ type: ActionTypes.SET_STAGED_STATION_USERS, payload: { stagedUsers } }),
  getGlobalTags: () => dispatch({ type: ActionTypes.FETCH_GLOBAL_TAGS }),
  getStationUserBayAssignments: (id: string) =>
    dispatch({ type: ActionTypes.STATION_USER_FIND_BAY_ASSIGNMENTS, payload: { id } }),
  clearStationUsersList: () => dispatch({ type: ActionTypes.CLEAR_STATION_USERS })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const StationUsers = connector((props: Props) => {
  const pageSize = 200;
  const [propertyFilter, setPropertyFilter] = useState<string>(props.stationUsersFilter);
  const [tagsFilter, setTagsFilter] = useState<string[]>(props.stationUsersTagsFilter);
  const [popConfirmDeleteUser, setPopConfirmDeleteUser] = useState<string>('');
  const [showDeleteManyModal, setShowDeleteManyModal] = useState(false);
  const [viewModalUserId, setViewModalUserId] = useState<string>(undefined);
  let tagsSelect: RefSelectProps | null;

  const reloadList = () => {
    props.clearStationUsersList();
    props.getStationUsers(
      undefined,
      pageSize,
      propertyFilter === '' ? undefined : propertyFilter,
      tagsFilter ? tagsFilter : undefined
    );
  };

  useEffect(() => {
    function handleClickOutsidePopover(event: Event) {
      if (event.target) {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        const nonTriggeringElements =
          _.isString(target.className) &&
          !target.className
            .split(' ')
            .some(
              (s: string) =>
                ['ant-btn-link', 'delete-user', 'confirm-delete'].includes(s) || s.indexOf('popover') !== -1
            );
        if (nonTriggeringElements) {
          setPopConfirmDeleteUser('');
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutsidePopover);

    props.onLoad();
    props.getGlobalTags();
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePopover);
      props.onUnload();
    };
  }, []);

  useEffect(() => {
    if (props.subscriptionInfo && !essentialsTierOrAbove(props.subscriptionInfo)) {
      history.replace(`/auth/${props.accountId}/dashboard`);
    }
  }, [props.subscriptionInfo]);

  useEffect(() => {
    props.showStationUserModal && setPopConfirmDeleteUser('');
  }, [props.showStationUserModal]);

  useEffect(() => {
    if (props.createStationUserSuccess) {
      reloadList();
    }
  }, [props.createStationUserSuccess]);

  const hasMoreItems = !!props.stationUsersPagingData?.after;

  const bulkActions = (
    <Menu>
      <Menu.Item
        key="import"
        onClick={props.loadCsvModal}
        disabled={props.isStationUserListLoading}
        data-test-id="station-user-load-csv-modal">
        <ImportOutlined />
        &nbsp;Import users
      </Menu.Item>
      <Menu.Item
        key="update"
        onClick={props.loadUpdateCsvModal}
        disabled={props.isStationUserListLoading}
        data-test-id="station-user-load-update-csv-modal">
        <EditOutlined />
        &nbsp;Update users
      </Menu.Item>
      <Menu.Item
        key="export"
        onClick={props.loadExportModal}
        disabled={props.isStationUserListLoading}
        data-test-id="station-user-load-export-csv-modal">
        <ExportOutlined />
        &nbsp;Export users
      </Menu.Item>
    </Menu>
  );

  const backgroundJobs = useWatchBackgroundJobs();
  const bulkActionInProgress = !!backgroundJobs.find(
    job =>
      ['StationUsersImportJob', 'StationUsersUpdateJob', 'StationUsersExportJob'].includes(job.jobName) &&
      !('dateCompleted' in job)
  );

  const internalStationUsers = props.stationUsers.filter(v => v.type === 'internal');

  return (
    <div className="users-section">
      <h4>Users</h4>

      <InternalStationUserModal
        readonlyMode={bulkActionInProgress}
        alertComponent={bulkActionInProgress && <UsersBulkActionInProgress />}
      />
      <StationUserCsvModal />
      <StationUserExportModal />
      <StationUserUpdateCsvModal loadExportModal={props.loadExportModal} />
      {showDeleteManyModal && (
        <StationUserDeleteModal
          selectedUsers={props.stagedUsers}
          onDelete={() => {
            setShowDeleteManyModal(false);
            reloadList();
          }}
          onCancel={() => setShowDeleteManyModal(false)}
        />
      )}
      {viewModalUserId && (
        <ViewStationUserModal userId={viewModalUserId} onClose={() => setViewModalUserId(undefined)} />
      )}

      {bulkActionInProgress && <UsersBulkActionInProgress />}

      <div
        className={`section-container ${bulkActionInProgress ? 'section-container-with-banner' : ''}`}
        data-test-id="station-user">
        <div className="controls-section">
          <div className="action-section">
            <Button
              className="btn-primary"
              onClick={() => props.loadModal()}
              disabled={props.isStationUserListLoading || bulkActionInProgress}
              data-test-id="station-user-add-user">
              <PlusCircleFilled />
              Add User
            </Button>

            <Dropdown
              overlay={bulkActions}
              disabled={props.isStationUserListLoading || bulkActionInProgress}
              trigger={['click']}>
              <Button type="primary" data-test-id="station-user-bulk-actions-btn">
                Bulk actions
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>

          <div className="filter-section">
            <Tooltip title="Search by name, external ref or RFID.">
              <InfoCircleOutlined style={{ marginRight: 5 }} />
            </Tooltip>

            <Input
              placeholder="Search"
              onBlur={(e: React.FocusEvent<HTMLInputElement> | { [p: string]: any }) => {
                setPropertyFilter(e.target.value);
                if (e.target.value.length === 0) {
                  props.filter(e.target.value, tagsFilter);
                }
              }}
              onPressEnter={(e: React.KeyboardEvent<HTMLInputElement> | { [p: string]: any }) => {
                if (propertyFilter !== e.target.value) {
                  props.clearStationUsersList();
                }

                setPropertyFilter(e.target.value);
                props.filter(e.target.value, tagsFilter);
              }}
              style={{ width: 220 }}
              disabled={props.isStationUserListLoading}
              data-test-id="station-user-filter-search"
            />

            <Select
              style={{ width: 220, marginLeft: 15 }}
              mode="multiple"
              placeholder="Search by tags"
              onChange={(e: SelectValue) => {
                const value: string[] = e as string[];
                setTagsFilter(value);
                if ((e as string[]).length === 0) {
                  props.filter(propertyFilter, value);
                  tagsSelect.blur();
                }
              }}
              disabled={props.isStationUserListLoading}
              data-test-id="station-user-filter-search-tags"
              filterOption={(input, option) => option?.value?.toLowerCase().includes(input.toLowerCase())}
              ref={(select: RefSelectProps | null) => (tagsSelect = select)}
              notFoundContent={null}>
              {props.globalTags &&
                props.globalTags.map(option => (
                  <Select.Option value={option} key={option}>
                    {option}
                  </Select.Option>
                ))}
            </Select>

            <Button
              className="btn-primary"
              style={{ marginLeft: 15 }}
              onClick={() => {
                props.filter(propertyFilter, tagsFilter);
              }}
              disabled={props.isStationUserListLoading}
              data-test-id="station-user-search">
              <SearchOutlined />
              Go
            </Button>
          </div>
        </div>

        <div
          className="content-section"
          style={{
            width: '100%',
            height: bulkActionInProgress ? 'calc(100vh - 355px)' : 'calc(100vh - 260px)'
          }}>
          <div className="selected-section">
            <Checkbox
              checked={!!props.stagedUsers.length && internalStationUsers.length <= props.stagedUsers.length}
              indeterminate={!!props.stagedUsers.length && props.stagedUsers.length < internalStationUsers.length}
              disabled={props.isStationUserListLoading || !internalStationUsers.length}
              data-test-id="station-user-parent-checkbox"
              onChange={(e: CheckboxChangeEvent) => {
                if (
                  e.target.checked ||
                  (!!props.stagedUsers.length && props.stagedUsers.length < internalStationUsers.length)
                ) {
                  props.setStagedUsers(internalStationUsers.map(v => ({ id: v.id, name: v.name })));
                } else {
                  props.setStagedUsers([]);
                }
              }}>
              Select all users shown
            </Checkbox>
            <Button
              size="small"
              className="btn-danger"
              disabled={
                props.isStationUserListLoading ||
                props.stagedUsers.length === 0 ||
                props.stagedUsers.length > 1000 ||
                bulkActionInProgress
              }
              data-test-id="station-user-load-delete-modal"
              onClick={() => {
                setShowDeleteManyModal(true);
              }}>
              Delete Users ({props.stagedUsers.length})
            </Button>
            <Tooltip title="Maximum 1000 users can be deleted at one time.">
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
            <span style={{ position: 'absolute', right: 0 }}>
              Found {props.stationUsers.length}
              {hasMoreItems ? '+' : ''} results
            </span>
          </div>

          <div className="content-section-inner" style={{ height: '100%' }}>
            <LegacyInfinityList
              refHash={JSON.stringify(`${props.stationUsersFilter}${props.stationUsersTagsFilter?.toString()}`)}
              pageSize={pageSize}
              items={props.stationUsers}
              loading={props.isStationUserListLoading}
              hasMoreItems={hasMoreItems}
              fetchError={props.fetchError}
              loadItems={() => {
                props.getStationUsers(
                  props.stationUsersPagingData?.after,
                  pageSize,
                  props.stationUsersFilter || undefined,
                  props.stationUsersTagsFilter?.length ? props.stationUsersTagsFilter : undefined
                );
              }}
              render={item => {
                const isInternalUser = item.type === 'internal';
                return (
                  <div key={item.id} className="user-list-row list-row" data-test-id={`station-user-${item.id}`}>
                    <div>
                      <Checkbox
                        disabled={!isInternalUser}
                        checked={props.stagedUsers.findIndex(u => u.id === item.id) !== -1}
                        onChange={(e: CheckboxChangeEvent) =>
                          e.target.checked
                            ? props.setStagedUsers([...props.stagedUsers, item])
                            : props.setStagedUsers(props.stagedUsers.filter(u => u.id !== item.id))
                        }
                      />
                    </div>
                    <div className="user-detail">
                      <Button
                        type="link"
                        title={item.name}
                        onClick={() => (isInternalUser ? props.loadModal(item.id) : setViewModalUserId(item.id))}>
                        {isInternalUser ? <UserOutlined /> : <UserSwitchOutlined />}
                        {item.name}
                      </Button>
                    </div>
                    <div className="user-detail user-tags" style={{ flexGrow: 1, marginRight: 0 }}>
                      {item.tags && item.tags.map((tag: string) => <Tag key={tag}>{tag}</Tag>)}
                    </div>
                    <div className="user-action">
                      {isInternalUser && (
                        <Popconfirm
                          placement="left"
                          title={
                            <span className="delete-user-popover popover-title">
                              {props.bayAssignments === undefined ? (
                                <>
                                  Checking if user has any assignments <LoadingOutlined spin />
                                </>
                              ) : (
                                <>
                                  {props.bayAssignments.length ? (
                                    <>
                                      Found {props.bayAssignments.length} assignment
                                      {props.bayAssignments.length === 1 ? '' : 's'}. Proceed to delete user?
                                    </>
                                  ) : (
                                    <>No assignments found. Delete user?</>
                                  )}
                                </>
                              )}
                            </span>
                          }
                          visible={popConfirmDeleteUser === item.id}
                          okText={<span className="confirm-delete">Yes</span>}
                          cancelText="No"
                          okButtonProps={{
                            style: { display: props.bayAssignments === undefined ? 'none' : 'initial' },
                            disabled: props.bayAssignments === undefined,
                            className: 'popover-submit'
                          }}
                          cancelButtonProps={{
                            style: { display: props.bayAssignments === undefined ? 'none' : 'initial' }
                          }}
                          onConfirm={() => {
                            setPopConfirmDeleteUser('');
                            props.deleteStationUser(item);
                          }}>
                          <Button
                            className="delete-user"
                            onClick={() => {
                              setPopConfirmDeleteUser(item.id);
                              props.getStationUserBayAssignments(item.id);
                            }}
                            disabled={
                              popConfirmDeleteUser === item.id || bulkActionInProgress || props.isStationUserListLoading
                            }
                            ghost>
                            <DeleteFilled />
                          </Button>
                        </Popconfirm>
                      )}
                    </div>
                  </div>
                );
              }}
            />
          </div>
          {props.stationUsersPagingData?.hasFailedOrigins && (
            <Alert type="error" message="Cannot get users from an external directory." showIcon />
          )}
          {props.stationUsersPagingData?.hasMoreNonPaginatable && (
            <Alert
              type="warning"
              message="External user directory has more results available. Please use filters to refine."
              showIcon
            />
          )}
        </div>
      </div>
    </div>
  );
});
