import { CommonApi, NetworkSettingsDto } from '@pclocs/platform-sdk';
import _ from 'lodash';
import { createApi } from '../api/sdk';

export const SECRET_REF_PREFIX = '!Secret ';

const regexIsSecretRef = new RegExp(
  `^${SECRET_REF_PREFIX}[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$`,
  'i'
);

export const isSecretRef = (str: string): boolean => typeof str === 'string' && str.match(regexIsSecretRef) !== null;

type ExtractKeysOfValueType<T, K> = { [I in keyof T]: T[I] extends K ? I : never }[keyof T];

async function replaceStrings<
  T extends { [P in K]?: string },
  K extends Extract<ExtractKeysOfValueType<T, string | undefined>, string>
>(obj: T, keys: K[], matcher: (value: string) => boolean, replacer: (value: string) => Promise<string>): Promise<T> {
  const cloned = _.cloneDeep(obj);
  for (const key of keys) {
    const value: string | undefined = cloned[key];
    if (value != null && matcher(value)) {
      cloned[key] = (await replacer(value)) as T[typeof key];
    }
  }
  return cloned;
}

export async function createSecretRef(api: CommonApi, secret: string) {
  const response = await api.createSecret({ secret });
  return `${SECRET_REF_PREFIX}${response.data.id}`;
}

export async function replaceSecretsInNetworkSettings(networkSettings: NetworkSettingsDto) {
  const cloned = _.cloneDeep(networkSettings);
  const api = createApi<CommonApi>('CommonApi');

  if (cloned?.wifi?.wpaSupplicantConfig) {
    cloned.wifi.wpaSupplicantConfig = await replaceStrings(
      cloned.wifi.wpaSupplicantConfig,
      ['password', 'psk', 'privateKey'],
      (secret: string) => !isSecretRef(secret),
      (secret: string) => createSecretRef(api, secret)
    );
  }
  return cloned;
}
