import React, { Dispatch, ReactNode, useEffect, useState } from 'react';
import { Alert, Button, Input, Modal, Skeleton, Tooltip } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import {
  DeleteFilled,
  CaretDownOutlined,
  CaretUpOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import * as ActionTypes from '../../store/actionTypes';
import { StationUserActionTypes, ValidationError } from '../../store/users/types';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { InternalStationUserCreateDto, InternalStationUserUpdateDto } from '@pclocs/platform-sdk/dist';
import { maxLength, minLength, validatePinOrRfidExists, validPinPattern } from '../../helpers/credentials-helper';
import _ from 'lodash';
import { formatMessage } from '../../helpers/form-helper';
import * as RfidHelper from '../../helpers/rfid-converter-helper';
import { Tags } from '../Tags';
import { Link, useParams } from 'react-router-dom';

type StationUserModalProps = {
  readonlyMode: boolean;
  alertComponent: ReactNode;
} & FormComponentProps;

const mapStateToProps = (state: RootState) => ({
  ...state.stationUser
});

const mapDispatchToProps = (dispatch: Dispatch<StationUserActionTypes>) => ({
  unloadModal: () => dispatch({ type: ActionTypes.INTERNAL_STATION_USER_UNLOAD_MODAL }),
  getStationUser: (id: string) => dispatch({ type: ActionTypes.GET_STATION_USER, payload: { stationUserId: id } }),
  getUniquePIN: () => dispatch({ type: ActionTypes.GET_UNIQUE_PIN_STATION_USER }),
  clearUniquePIN: () => dispatch({ type: ActionTypes.CLEAR_UNIQUE_PIN_STATION_USER }),
  updateStationUser: (stationUser: InternalStationUserUpdateDto) =>
    dispatch({ type: ActionTypes.UPDATE_STATION_USER, payload: { data: stationUser } }),
  createStationUser: (stationUser: InternalStationUserCreateDto) =>
    dispatch({ type: ActionTypes.CREATE_STATION_USER, payload: { data: stationUser } }),
  getStationUserBayAssignments: (id: string) =>
    dispatch({ type: ActionTypes.STATION_USER_FIND_BAY_ASSIGNMENTS, payload: { id } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & StationUserModalProps;

export const InternalStationUserModal = connector(
  LegacyForm.create<Props>({ name: 'StationUser' })((props: Props) => {
    const [tags, setTags] = useState<string[] | undefined>([]);
    const { accountId } = useParams<{ accountId: string }>();
    const [bayAssignedExpanded, setBayAssignedExpanded] = useState<boolean>(false);

    useEffect(() => {
      if (!props.showStationUserModal) {
        return;
      }
      props.form.resetFields();
      if (props.stationUserId) {
        props.getStationUser(props.stationUserId);
        props.getStationUserBayAssignments(props.stationUserId);
      }
    }, [props.showStationUserModal, props.stationUserId]);

    useEffect(() => {
      props.form.setFieldsValue({ pin: props.uniquePIN, rfid: props.form.getFieldValue('rfid') });
    }, [props.uniquePIN]);

    useEffect(() => {
      props.stationUser &&
        props.form.setFieldsValue({
          name: props.stationUser.name,
          pin: props.uniquePIN,
          rfid: RfidHelper.hexToInputFieldFormat(props.rfid || ''),
          externalRefId: props.stationUser.externalRefId
        });
    }, [props.stationUser]);

    const handleChange = () => {
      props.form.setFieldsValue({
        rfid: RfidHelper.changeFormat(props.form.getFieldValue('rfid'))
      });
    };

    const { getFieldDecorator } = props.form;

    const handleSubmit = (e: any) => {
      e.preventDefault();
      props.form.validateFieldsAndScroll(async (err: Error, values: any) => {
        if (!err) {
          const userData: InternalStationUserCreateDto | InternalStationUserUpdateDto = {
            name: values.name,
            stationCredentials: {
              pin: values.pin ? values.pin : undefined,
              rfid: values.rfid ? RfidHelper.rfidToHexPad(values.rfid) : undefined
            },
            externalRefId: values.externalRefId,
            tags
          };

          if (props.stationUserId) {
            props.updateStationUser({
              ...userData,
              id: props.stationUserId
            });
          }

          if (!props.stationUserId) {
            props.createStationUser(userData);
          }
        }
      });
    };

    const getErrorAlertMessage = (validationError: ValidationError) => {
      const res = validationError.descriptions.map(description => {
        const constraint = _.get(description, 'constraints');
        const key = Object.keys(constraint)[0];
        const value: string = (Object.values(constraint)[0] as unknown) as string;
        return <p key={key}>{formatMessage(value)}</p>;
      });

      return (
        <Alert message={validationError.message} description={res} type="error" showIcon style={{ marginBottom: 16 }} />
      );
    };

    return (
      <Modal
        title={`${!props.stationUserId ? 'Create' : 'Update'} User`}
        visible={props.showStationUserModal}
        maskClosable={!props.pending}
        onCancel={props.unloadModal}
        destroyOnClose={true}
        footer={[
          <Button
            key="back"
            onClick={props.unloadModal}
            style={{ display: props.pending === true ? 'none' : 'initial' }}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={props.pending}
            style={{ display: props.isStationUserLoading ? 'none' : 'initial' }}
            disabled={props.readonlyMode}>
            Save
          </Button>
        ]}>
        {props.stationUserId && !props.stationUser ? (
          <Skeleton active />
        ) : (
          <>
            {props.alertComponent}

            <LegacyForm
              {...{
                labelCol: {
                  xs: { span: 24 },
                  sm: { span: 6 }
                },
                wrapperCol: {
                  xs: { span: 24 },
                  sm: { span: 18 }
                }
              }}>
              <LegacyForm.Item label="Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please input name!' }]
                })(<Input disabled={props.readonlyMode} />)}
              </LegacyForm.Item>

              <LegacyForm.Item colon={false} label={<span>RFID:&nbsp;{RfidHelper.rfidFormatTooltip}</span>}>
                {getFieldDecorator('rfid', { rules: [{ validator: validatePinOrRfidExists(props.form) }] })(
                  <Input
                    onBlur={() => props.form.validateFields(['rfid', 'pin'])}
                    addonAfter={<RfidHelper.FormatSelector onChange={handleChange} disabled={props.readonlyMode} />}
                    disabled={props.readonlyMode}
                  />
                )}
              </LegacyForm.Item>

              <LegacyForm.Item label="PIN">
                {getFieldDecorator('pin', {
                  rules: [
                    { validator: validatePinOrRfidExists(props.form) },
                    { pattern: validPinPattern, message: `PIN can only contain numbers.` },
                    { min: minLength, message: `PIN must be at least ${minLength} characters` },
                    { max: maxLength, message: `PIN cannot be longer than ${maxLength} characters` }
                  ]
                })(<Input style={{ width: '150px' }} disabled={props.readonlyMode} />)}

                {props.isUniquePINLoading ? (
                  <LoadingOutlined
                    style={{ fontSize: 18, marginLeft: 10 }}
                    spin
                    data-test-id="station-user-pin-loading"
                  />
                ) : (
                  <Button
                    className="btn-primary"
                    style={{ margin: '0 6px' }}
                    onClick={props.getUniquePIN}
                    data-test-id="station-user-pin-reload-button"
                    disabled={props.readonlyMode}>
                    <ReloadOutlined />
                  </Button>
                )}

                {props.uniquePIN && (
                  <Button
                    className="btn-danger"
                    onClick={props.clearUniquePIN}
                    data-test-id="station-user-pin-delete-button"
                    disabled={props.readonlyMode}>
                    <DeleteFilled />
                  </Button>
                )}
              </LegacyForm.Item>

              <LegacyForm.Item
                colon={false}
                label={
                  <span>
                    External Ref:&nbsp;
                    <Tooltip title="External ref is optional but must be unique">
                      <InfoCircleOutlined className="external-ref-id-tooltip" />
                    </Tooltip>
                  </span>
                }>
                {getFieldDecorator(
                  'externalRefId',
                  {}
                )(<Input data-test-id="station-user-external-ref-input" disabled={props.readonlyMode} />)}
              </LegacyForm.Item>

              <div style={{ marginBottom: 24 }}>
                <Tags
                  tags={props.stationUser?.tags || []}
                  setTagsOnParent={setTags}
                  horizontal
                  disabled={props.readonlyMode}
                />
              </div>

              {props.stationUserId && (
                <LegacyForm.Item label="Assignments">
                  <div style={{ lineHeight: '38px' }}>
                    {props.bayAssignments === undefined ? (
                      <LoadingOutlined spin />
                    ) : props.bayAssignments.length === 0 ? (
                      <span>None</span>
                    ) : (
                      <>
                        <a onClick={() => setBayAssignedExpanded(!bayAssignedExpanded)}>
                          <span>
                            {props.bayAssignments.length} bay{props.bayAssignments.length === 1 ? '' : 's'}
                          </span>
                          {bayAssignedExpanded ? (
                            <CaretUpOutlined style={{ marginLeft: 2 }} />
                          ) : (
                            <CaretDownOutlined style={{ marginLeft: 2 }} />
                          )}
                        </a>
                        {bayAssignedExpanded && (
                          <div
                            className="bay-assigned-items"
                            style={{
                              lineHeight: 'initial',
                              maxHeight: 100,
                              overflow: 'auto'
                            }}>
                            {props.bayAssignments.map(info => (
                              <div key={info.bayId}>
                                <Link
                                  to={`/auth/${accountId}/dashboard/stations/${info.stationId}/bays/${info.bayId}/overview`}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  {info.stationName} - Bay {info.bayNumber}
                                </Link>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </LegacyForm.Item>
              )}

              {props.error && getErrorAlertMessage(props.error)}
            </LegacyForm>
          </>
        )}
      </Modal>
    );
  })
);
