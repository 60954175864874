import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as ActionTypes from '../../store/actionTypes';
import { Dashboard } from '../Dashboard';
import { Account } from '../Account';
import { Profile } from '../Profile';
import { StationUsers } from '../Users';
import { UserGroups } from '../UserGroups';
import { EventLogs } from '../EventLogs';
import { Reports } from '../Reports';
import { Link, matchPath, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Logo } from '../Logo';
import { Button, Dropdown, Layout, Menu, Modal, Popconfirm, Tooltip } from 'antd';
import {
  ApiOutlined,
  DashboardOutlined,
  DownOutlined,
  FileOutlined,
  FileTextOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';
import { RootState } from '../../store/rootReducer';
import { history } from '../../store/store';
import { getCurrentUserContext } from '../../api/account';
import { AdminUserContextDto, AdminUserContextDtoTypeEnum } from '@pclocs/platform-sdk';
import { AuthActionTypes } from '../../store/auth/types';
import { StationSetup } from '../StationSetup';
import { CommonActionTypes } from '../../store/common/types';
import { Integrations } from '../Integrations';
import _ from 'lodash';
import { accountActions, AccountActionTypes } from '../../store/account/reducer';
import { subscriptionMessage } from '../../constants/validations';
import { FeatureId, useFeatureAvailability } from '../../helpers/feature-availability-hooks';
import { useJobStatusNotificationHook } from '../../helpers/job-status-notification-hook';
import { useSessionExpiryHook } from './session-expiry-hook';
import { websocketActions, WebsocketActionTypes } from '../../store/websocket/reducer';
import { Changelogs } from '../Changelogs';
import { AdminClaimEnum, useHasClaim } from '../../helpers/claims';
import { getAuthTokens, getTokenFromHash, isExternalUser, parseJwtToken } from '../../helpers/auth-token-helper';
import { currentUserPoolUser } from '../../api/amplify';
import { LogoLoader } from '../LogoLoader';
import { getBrandSupportLink } from '../../helpers/brand-helper';
import { setGlassUserToken } from '../../helpers/local-storage-helper';

const { Content, Sider } = Layout;

const mapStateToProps = (state: RootState) => {
  return {
    selectedKey: state.common.sideMenuSelectedKey,
    currentUser: state.auth.currentUser,
    websocketConnected: state.websocket.isConnected,
    accountContext: state.auth.accountContext,
    availableFeatures: state.account.availableFeatures,
    unauthorizedModal: state.common.unauthorizedModal
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<CommonActionTypes | AuthActionTypes | AccountActionTypes | WebsocketActionTypes>
) => ({
  logout: (redirect: boolean) => dispatch({ type: ActionTypes.LOGOUT, payload: { redirect } }),
  setContext: (currentUser: AdminUserContextDto) =>
    dispatch({ type: ActionTypes.SET_CONTEXT, payload: { currentUser } }),
  setAccountContext: (accountId: string | undefined) =>
    dispatch({ type: ActionTypes.SET_ACCOUNT_CONTEXT, payload: { accountId } }),
  setCognitoMfaContext: (mfaConfiguredForAdmin: boolean) =>
    dispatch({ type: ActionTypes.SET_COGNITO_MFA_CONTEXT, payload: { mfaConfiguredForAdmin } }),
  showSelectAccountModal: () =>
    dispatch({ type: ActionTypes.TOGGLE_SELECT_ACCOUNT_MODAL, payload: { showSelectAccountModal: true } }),
  connectWebsocket: () => dispatch(websocketActions.connect()),
  getSubscriptionInfo: () => dispatch(accountActions.getSubscriptionInfo()),
  getAvailableFeatures: () => dispatch(accountActions.getFeatures()),
  showUnauthorizedSsoAdminModal: (title: string, content: string) =>
    dispatch({ type: ActionTypes.SHOW_UNAUTHORIZED_MODAL, payload: { title, content } }),
  getAdminClaims: (accountId: string) => dispatch(accountActions.getAdminClaims(accountId))
});

export const unauthPath = () =>
  matchPath(history.location.pathname, {
    path: [
      '/register',
      '/register/verify',
      '/login',
      '/login/forgot-password',
      '/login/reset-password',
      '/login/sso-sign-in',
      '/login/auth',
      '/terms-and-conditions',
      '/cancel-suspension'
    ],
    exact: true,
    strict: true
  });

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const AuthenticatedArea = (props: Props) => {
  const { path } = useRouteMatch();
  const { accountId } = useParams<{ accountId: string }>();
  const [showChangelogs, setShowChangelogs] = useState<boolean>(false);

  const currentAccount = _.find(props.currentUser?.accounts, ['id', props.accountContext]);
  const hasMultipleAccounts = props.currentUser && props.currentUser.accounts.length > 1;
  const [hasFeature] = useFeatureAvailability();

  const [hasAccountSettingsClaim] = useHasClaim({ type: AdminClaimEnum.AccountSettings });

  useJobStatusNotificationHook();
  const [isSessionExpired, pauseCheckSessionExpiryFnc] = useSessionExpiryHook();

  useEffect(() => {
    // Redirect to login page if currentUser does not exist.
    const getCurrentUser = async () => {
      const trySetUserContext = async () => {
        try {
          props.setContext(await getCurrentUserContext());
        } catch {
          props.logout(false);
        }
      };

      try {
        const currentTime = Date.now();
        const hashToken = getTokenFromHash(history.location.hash);
        const payload = parseJwtToken(hashToken);

        if (payload && currentTime < payload.exp * 1000) {
          window.history.replaceState('', '', `/auth/${accountId}/dashboard`);
          setGlassUserToken(hashToken);
          await trySetUserContext();
        } else if (!props.currentUser) {
          const currentAuthTokens = await getAuthTokens();
          if (!currentAuthTokens) {
            throw Error('No active session.');
          }
          await trySetUserContext();

          if (currentAuthTokens.type === 'cognito') {
            const cognitoUser = await currentUserPoolUser();
            props.setCognitoMfaContext(cognitoUser.preferredMFA === 'SOFTWARE_TOKEN_MFA');
          }
        }
      } catch {
        if (!unauthPath()) history.replace('/login');
      }
    };
    getCurrentUser();
  }, [history.location]);

  useEffect(() => {
    // redirect to login when the current user doesn't have an account id allowed in his list
    if (props.currentUser && !_.find(props.currentUser.accounts, ['id', props.accountContext])) {
      history.replace('/login');
    }
    if (props.currentUser) {
      props.getAvailableFeatures();
      props.getSubscriptionInfo();
      props.getAdminClaims(accountId);
    }
  }, [props.currentUser]);

  useEffect(() => {
    const checkSsoAdminAccess = async () => {
      if (await isExternalUser()) {
        props.showUnauthorizedSsoAdminModal(
          'Unsupported account',
          'Account does not support Single Sign On. You will now be logged out.'
        );
      }
    };
    if (props.availableFeatures && !hasFeature(FeatureId.SSOIDENTITYPROVIDERS)) {
      checkSsoAdminAccess();
    }
  }, [props.availableFeatures]);

  // set the account context if one exists in the URL...
  useEffect(() => {
    props.setAccountContext(accountId);
  }, [accountId]);

  // Connect the websocket...
  useEffect(() => {
    !props.websocketConnected && props.connectWebsocket();
  }, []);

  // if isSessionExpired is set, currentUser should be null; Show session-expiry modal instead of the flashing dots
  if (!props.currentUser && !isSessionExpired && !props.unauthorizedModal) {
    return <LogoLoader />;
  } else {
    return (
      <Layout>
        {props.currentUser?.id && (
          <Changelogs userId={props.currentUser.id} visible={showChangelogs} showChangelogs={setShowChangelogs} />
        )}
        <Modal
          title="Session Expired"
          visible={isSessionExpired && !props.unauthorizedModal}
          closable={false}
          maskClosable={false}
          data-test-id="session-expired-modal"
          footer={[
            <Button key="submit" type="primary" onClick={() => props.logout(true)}>
              Login
            </Button>
          ]}>
          <div>Your session has expired. Please login again to continue.</div>
        </Modal>
        <div className="header">
          <Logo />
          <div id="right-menu-wrapper">
            {hasMultipleAccounts && (
              <span onClick={props.showSelectAccountModal} className={'link header-link'}>
                {currentAccount?.name}
              </span>
            )}
            {!hasMultipleAccounts && currentAccount?.name}
            {accountId && (
              <Tooltip title="Account Preferences" mouseEnterDelay={0.6}>
                <Link to={`/auth/${accountId}/account`} className="header-link">
                  <SettingOutlined style={{ fontSize: 22 }} />
                </Link>
              </Tooltip>
            )}
            <Tooltip title="Profile" mouseEnterDelay={0.6}>
              <Link to={`/auth/${accountId}/profile`} className="header-link">
                <UserOutlined style={{ fontSize: 22 }} />
              </Link>
            </Tooltip>
            <Dropdown
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  <Menu.Item key="whats-new" onClick={() => setShowChangelogs(true)}>
                    What&apos;s new
                  </Menu.Item>
                  <Menu.Item key="documentation">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${window.location.protocol}//docs.${window.location.hostname}`}>
                      Documentation
                    </a>
                  </Menu.Item>
                  <Menu.Item key="help-and-support">
                    <a href={getBrandSupportLink()} target="_blank" rel="noopener noreferrer">
                      Help & Support
                    </a>
                  </Menu.Item>
                </Menu>
              }>
              <span className="header-link" style={{ cursor: 'pointer' }}>
                <QuestionCircleOutlined style={{ fontSize: 22 }} />
                <DownOutlined style={{ fontSize: 18, verticalAlign: '-0.05em' }} />
              </span>
            </Dropdown>
            <Tooltip title="Logout" mouseEnterDelay={0.6} placement="bottomRight">
              <Popconfirm
                placement="leftTop"
                title="Are you sure you want to log out?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  pauseCheckSessionExpiryFnc();
                  props.logout(props.currentUser?.type !== AdminUserContextDtoTypeEnum.External);
                }}>
                <LogoutOutlined className="header-link" style={{ fontSize: 22, marginLeft: 20 }} />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
        <Layout>
          <Sider
            width={200}
            style={{
              position: 'fixed',
              zIndex: 1,
              top: 70,
              overflow: 'auto',
              height: '100vh',
              left: 0
            }}>
            <Menu
              className="side-menu"
              theme="dark"
              mode="inline"
              selectedKeys={[props.selectedKey]}
              style={{ height: '100%', borderRight: 0 }}>
              <Menu.Item id="dashboard" key="dashboard">
                <Link to={`/auth/${accountId}/dashboard`}>
                  <DashboardOutlined />
                  Dashboard
                </Link>
              </Menu.Item>
              <Menu.Item
                id="eventlogs"
                key="event-logs"
                disabled={!hasFeature(FeatureId.EVENTLOGS)}
                title={!hasFeature(FeatureId.EVENTLOGS) ? subscriptionMessage : undefined}>
                <Link to={`/auth/${accountId}/event-logs`}>
                  <FileTextOutlined />
                  Event Logs
                </Link>
              </Menu.Item>
              <Menu.Item
                id="reports"
                key="reports"
                disabled={!hasFeature(FeatureId.REPORTS)}
                title={!hasFeature(FeatureId.REPORTS) ? subscriptionMessage : undefined}>
                <Link to={`/auth/${accountId}/reports`}>
                  <FileOutlined />
                  Reports
                </Link>
              </Menu.Item>
              <Menu.Item
                id="users"
                key="users"
                disabled={!hasFeature(FeatureId.MANAGEDSTATIONS)}
                title={!hasFeature(FeatureId.MANAGEDSTATIONS) ? subscriptionMessage : undefined}>
                <Link to={`/auth/${accountId}/users`}>
                  <UserOutlined />
                  Users
                </Link>
              </Menu.Item>
              <Menu.Item
                id="usergroups"
                key="user-groups"
                disabled={!hasFeature(FeatureId.MANAGEDSTATIONS)}
                title={!hasFeature(FeatureId.MANAGEDSTATIONS) ? subscriptionMessage : undefined}>
                <Link to={`/auth/${accountId}/user-groups`}>
                  <TeamOutlined />
                  User Groups
                </Link>
              </Menu.Item>
              {hasAccountSettingsClaim && (
                <Menu.Item id="integrations" key="integrations">
                  <Link to={`/auth/${accountId}/integrations`}>
                    <ApiOutlined />
                    Integrations
                  </Link>
                </Menu.Item>
              )}
            </Menu>
          </Sider>
          <Layout>
            <Content className="main">
              <Switch>
                <Route path={`${path}/profile`} component={Profile} />
                <Route path={`${path}/dashboard`} component={Dashboard} />
                <Route path={`${path}/integrations`} component={Integrations} />
                <Route path={`${path}/setup`} component={StationSetup} />
                <Route path={`${path}/account`} component={Account} />
                <Route path={`${path}/users`} component={StationUsers} />
                <Route path={`${path}/user-groups`} component={UserGroups} />
                <Route path={`${path}/event-logs`} component={EventLogs} />
                <Route path={`${path}/reports`} component={Reports} />
                <Redirect from={'/'} to={`/auth/${accountId}/dashboard`} />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
};
export const Authenticated = connector(AuthenticatedArea);
