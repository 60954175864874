import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { useEffect, useReducer, useRef, useState } from 'react';

interface PageableSlice<
  Item extends unknown = unknown,
  Filters extends Record<string, unknown> = Record<string, unknown>
> {
  items: Item[];
  filters: Filters;
  loading: boolean;
  after?: string;
  limit?: number;
  fetchError?: string;
  requestParameters?: { after?: string; filters: Filters; limit: number };
}

type PageableResponse<Item extends unknown> =
  | {
      items: Item[];
      after?: string;
    }
  | {
      error: string;
    };

export const createInitialState = <Item extends unknown, Filters extends Record<string, unknown>>(
  initialFilters: Filters
): PageableSlice<Item, Filters> => {
  return {
    filters: initialFilters,
    items: [] as Item[],
    loading: false
  };
};

export const createPageableSlice = <Item extends unknown, Filters extends Record<string, unknown>>(
  initialFilters: Filters
) => {
  return createSlice({
    name: 'pageableslice',
    initialState: createInitialState<Item, Filters>(initialFilters),
    reducers: {
      setFilters: (state, action: PayloadAction<Partial<Filters>>) => {
        const newFilters = { ...state.filters, ...action.payload };
        return {
          ...state,
          items: [],
          after: undefined,
          loading: true,
          filters: newFilters,
          fetchError: undefined,
          requestParameters: { after: undefined, filters: newFilters, limit: state.limit }
        };
      },
      clearItems: state => {
        return {
          ...createInitialState<Item, Filters>(state.filters as Filters),
          limit: state.limit
        };
      },
      loadItems: (state, action: PayloadAction<{ limit?: number }>) => {
        const limit = action.payload?.limit ?? state.limit ?? 100;
        return {
          ...state,
          limit,
          loading: true,
          fetchError: undefined,
          requestParameters: { after: state.after, filters: state.filters, limit }
        };
      },
      setResponse: (state, action: PayloadAction<PageableResponse<Item>>) => {
        if (!state.loading) {
          return state;
        }
        return {
          ...state,
          loading: false,
          ...('error' in action.payload
            ? {
                fetchError: action.payload.error
              }
            : {
                items: [...state.items, ...action.payload.items] as Draft<Item[]>,
                after: action.payload.after
              })
        };
      }
    }
  });
};

/**
 * Handles progressively loading async items for things like an InfinityList.
 *
 * The caller gets requests via the `requestParameters` state and sends back responses or errors
 * via the `setResponse` function provided.
 *
 * `setFilters` will apply new filters and clear items
 * `clearItems` will clear the list and paging information
 * `loadItems` will request the next page be loaded
 */
export const usePageableReducer = <Item extends unknown, Filters extends Record<string, unknown>>(
  initialFilters: Filters
) => {
  const slice = createPageableSlice<Item, Filters>(initialFilters);
  const [state, dispatch] = useReducer(slice.reducer, createInitialState<Item, Filters>(initialFilters));
  return {
    items: state.items,
    filters: state.filters,
    loading: state.loading,
    fetchError: state.fetchError,
    hasMoreItems: state.after != null,
    requestParameters: state.requestParameters,
    setResponse: (response: PageableResponse<Item>) => dispatch(slice.actions.setResponse(response)),
    clearItems: () => dispatch(slice.actions.clearItems()),
    setFilters: (filters: Partial<Filters>) => dispatch(slice.actions.setFilters(filters)),
    loadItems: (limit?: number) => dispatch(slice.actions.loadItems({ limit }))
  };
};

export type Pager<
  Item extends unknown = unknown,
  Filters extends Record<string, unknown> = Record<string, unknown>
> = Omit<ReturnType<typeof usePageableReducer>, 'items' | 'setResponse' | 'setFilters' | 'filters'> & {
  items: Item[];
  filters: Filters;
  setResponse: (response: PageableResponse<Item>) => void;
  setFilters: (filters: Partial<Filters>) => void;
};

/**
 * Handles paging items where the returned page sizes are not consistent (eg client side filters).
 * Load item requests will be fired until the required number of items are found.
 */
export const useFixedSizePageableReducer = <P extends Omit<Pager, 'setResponse' | 'requestParameters' | 'filters'>>(
  pageSize: number,
  pager: P
) => {
  const [requestedPages, setRequestedPages] = useState(0);
  const itemsRequired = pageSize * requestedPages;

  const shouldLoadMoreItems =
    pager.items.length < itemsRequired && pager.hasMoreItems && !pager.fetchError && !pager.loading;
  useEffect(() => {
    if (shouldLoadMoreItems) {
      const numMissingItems = itemsRequired - pager.items.length;
      pager.loadItems(Math.max(numMissingItems, pageSize));
    }
  }, [pager.items, shouldLoadMoreItems]);

  return {
    ...pager,
    loading: pager.loading || shouldLoadMoreItems,
    setFilters: (...args: Parameters<Pager['setFilters']>) => {
      setRequestedPages(1);
      pager.setFilters(args[0]);
      pager.loadItems(pageSize);
    },
    clearItems: () => {
      setRequestedPages(0);
      return pager.clearItems();
    },
    loadItems: (limit: number = pageSize) => {
      const newNumPages = requestedPages + Math.ceil(limit / pageSize);
      setRequestedPages(newNumPages);
      const numMissingItems = newNumPages * pageSize - pager.items.length;
      return pager.loadItems(numMissingItems);
    }
  };
};

/**
 * A simple interface to fetch and return a number of items from an existing pager.
 */
export const usePickFromPager = <P extends Pick<Pager, 'loading' | 'items' | 'hasMoreItems' | 'loadItems'>>(
  pager: P
): [P['items'], boolean, (numItems: number) => void] => {
  const limit = useRef<number>(0);
  const [active, setActive] = useState<boolean>(false);
  const [results, setResults] = useState<unknown[] | undefined>(undefined);

  useEffect(() => {
    if (active && !pager.loading) {
      setResults(pager.items.slice(0, limit.current));
      setActive(false);
    }
  }, [pager.items, active]);

  return [
    results,
    pager.loading && active,
    (numItems: number) => {
      limit.current = numItems;
      setResults(undefined);
      setActive(true);
      if (pager.items.length < numItems && pager.hasMoreItems) {
        pager.loadItems(numItems - pager.items.length);
      }
    }
  ];
};
