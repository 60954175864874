import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { Button, Skeleton, Modal, Tooltip, Alert, Tabs } from 'antd';
import { PlusSquareFilled, MinusSquareFilled, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { RootState } from '../../../store/rootReducer';
import { connect, ConnectedProps } from 'react-redux';
import * as ActionTypes from '../../../store/actionTypes';
import { ItemType, CommonActionTypes, ItemRequest } from '../../../store/common/types';
import * as _ from 'lodash';
import { useParams, Link } from 'react-router-dom';
import { OverrideButton } from '../../Buttons/Override';
import { essentialsTierOrAbove } from '../../../helpers/subscription-helper';
import { StationUserActionTypes } from '../../../store/users/types';
import { UserFixedList } from '../../ManageUsersCommon/user-fixed-list';
import { ManageAuthorizedModal } from './manage-authorized-modal';
import { AuthorizedUpdateDto } from '@pclocs/platform-sdk';
import { addUnknownItems } from './add-unknown-items';
import { findGroupNode, useGroupHierarchy } from '../../../helpers/group-hierarchy';
import { ManageUnauthorizedModal } from './manage-unauthorized-modal';

const mapStateToProps = (state: RootState) => ({
  accountId: state.auth.accountContext,
  ..._.pick(state.common, ['globalTags', 'authorizedEntities', 'isManageUsersLoading', 'derivedFrom']),
  tableUsers: state.common.tableUsers,
  tableUserGroups: state.common.tableUserGroups,
  subscriptionInfo: state.account.subscriptionInfo
});

const mapDispatchToProps = (dispatch: Dispatch<StationUserActionTypes | CommonActionTypes>) => ({
  onUnload: () => dispatch({ type: ActionTypes.UNLOAD_MANAGE_USERS_TABLE }),
  fetchAuthorized: (itemType: ItemType, payload: ItemRequest) =>
    dispatch({
      type:
        itemType === ItemType.USER_GROUPS
          ? ActionTypes.FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE
          : ActionTypes.FETCH_AUTH_USERS_IN_MANAGE_TABLE,
      payload
    }),
  fetchGlobalTags: () => dispatch({ type: ActionTypes.FETCH_GLOBAL_TAGS })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  fetchUsers: (id: string, payload?: { after?: string; pageSize: number }) => void;
  updateAuthorized: {
    update: (id: string, authorizedEntities: AuthorizedUpdateDto['authorizedEntities']) => void;
    remove: (id: string, authorizedEntities?: AuthorizedUpdateDto['authorizedEntities']) => void;
  };
  maxItemsPerUpdateForManageUsers: number;
};

export const ManageAuthorized = connector((props: Props) => {
  const [authorizedItemType, setAuthorizedItemType] = useState<string>(ItemType.USERS);
  const [showModal, setShowModal] = useState<'authorize' | 'unauthorize'>();
  const { bayId, stationId, groupId } = useParams<{
    bayId: string;
    stationId: string;
    groupId: string;
  }>();
  const contextId: string = bayId || stationId || groupId || '';

  const authorizedUsers = props.authorizedEntities?.stationUsers || [];
  const authorizedUserGroups = props.authorizedEntities?.stationUserGroups || [];

  const isOverridden: boolean = props.derivedFrom === contextId;
  const groupHierarchy = useGroupHierarchy();
  const canInherit = groupHierarchy && !findGroupNode(groupHierarchy, contextId)?.isRoot;

  const path: string =
    props.accountId && props.derivedFrom
      ? `/auth/${props.accountId}/dashboard/${_.startsWith(props.derivedFrom, 'S-') ? 'stations' : 'groups'}`
      : '';
  const correctSubscription: boolean = essentialsTierOrAbove(props.subscriptionInfo);

  useEffect(() => {
    props.fetchGlobalTags();
    if (!props.authorizedEntities) {
      props.fetchUsers(contextId);
    }
    return () => {
      props.onUnload();
    };
  }, []);

  const confirmClearAllUsers = () => {
    Modal.confirm({
      title: 'Warning',
      content: `This action will clear all authorized users against this ${(bayId && `bay`) ||
        (stationId && `station`) ||
        (groupId && `group`)}, continue?`,
      onOk() {
        props.updateAuthorized.remove(contextId);
      }
    });
  };

  const globalTags: string[] = props.globalTags || [];

  const tableUsers = addUnknownItems(props.tableUsers, id => ({ id, name: `(Unknown) ${id}` }));
  const tableUserGroups = addUnknownItems(props.tableUserGroups, id => ({
    id,
    name: `(Unknown) ${id}`
  }));

  const isLoading: boolean = !showModal && !!props.isManageUsersLoading;
  const unfetchedUserIds = useMemo(
    () =>
      authorizedUsers.filter(
        id => !props.tableUsers.unknownIds.includes(id) && !props.tableUsers.items.some(item => item.id === id)
      ),
    [authorizedUsers]
  );

  useEffect(() => {
    if (unfetchedUserIds.length) {
      props.fetchAuthorized(ItemType.USERS, { ids: unfetchedUserIds });
    }
  }, [unfetchedUserIds]);

  const unfetchedUserGroupIds = useMemo(
    () =>
      authorizedUserGroups.filter(
        id =>
          !props.tableUserGroups.unknownIds.includes(id) && !props.tableUserGroups.items.some(item => item.id === id)
      ),
    [authorizedUserGroups]
  );

  useEffect(() => {
    if (unfetchedUserGroupIds.length) {
      props.fetchAuthorized(ItemType.USER_GROUPS, { ids: unfetchedUserGroupIds });
    }
  }, [unfetchedUserGroupIds]);

  const closeModal = () => {
    setShowModal(undefined);
  };

  return !props.subscriptionInfo ? (
    <Skeleton active />
  ) : !correctSubscription ? (
    <Alert type="error" message="Features disabled due to subscription level." />
  ) : (
    <>
      {showModal === 'authorize' && (
        <ManageAuthorizedModal
          contextId={contextId}
          authorizedUserIds={authorizedUsers}
          authorizedUserGroupIds={authorizedUserGroups}
          update={props.updateAuthorized.update}
          globalTags={props.globalTags}
          maxItemsPerUpdateForManageUsers={props.maxItemsPerUpdateForManageUsers}
          closeModal={closeModal}
        />
      )}
      {showModal === 'unauthorize' && (
        <ManageUnauthorizedModal
          contextId={contextId}
          authorizedUserIds={authorizedUsers}
          authorizedUserGroupIds={authorizedUserGroups}
          update={props.updateAuthorized.update}
          globalTags={props.globalTags}
          maxItemsPerUpdateForManageUsers={props.maxItemsPerUpdateForManageUsers}
          closeModal={closeModal}
        />
      )}

      {canInherit && (
        <div className="inherit-actions">
          <div>
            {!isOverridden && props.derivedFrom && (
              <Tooltip
                placement="left"
                title="Inheriting users from this source."
                mouseEnterDelay={0.6}
                data-test-id="num-inherited-users-tooltip">
                <Link to={`${path}/${props.derivedFrom}/users`} onClick={props.onUnload}>
                  <UserOutlined />
                  <span
                    style={{
                      marginLeft: 5,
                      marginRight: 10,
                      paddingRight: 10,
                      borderRight: '1px solid black'
                    }}>
                    {authorizedUsers.length}
                  </span>
                  <TeamOutlined />
                  <span style={{ marginLeft: 5 }}>{authorizedUserGroups.length}</span>
                </Link>
              </Tooltip>
            )}
          </div>
          <div>
            <OverrideButton
              pending={props.isManageUsersLoading}
              overridden={isOverridden}
              onClick={
                isOverridden
                  ? confirmClearAllUsers
                  : () =>
                      props.updateAuthorized.update(contextId, {
                        stationUsers: [],
                        stationUserGroups: [],
                        otherClaims: []
                      })
              }
            />
          </div>
        </div>
      )}

      <div className="authorised-items-actions">
        <Button
          size="small"
          onClick={() => setShowModal('authorize')}
          className="btn-primary"
          disabled={!isOverridden || isLoading || tableUsers.isLoading || tableUserGroups.isLoading}>
          <PlusSquareFilled /> Authorize users
        </Button>
        <Button
          size="small"
          onClick={() => setShowModal('unauthorize')}
          className="btn-danger"
          disabled={!isOverridden || isLoading || tableUsers.isLoading || tableUserGroups.isLoading}>
          <MinusSquareFilled /> Unauthorize users
        </Button>
      </div>

      <Tabs
        size="small"
        className="authorised-items-tab-section"
        animated={false}
        tabBarGutter={30}
        defaultActiveKey={ItemType.USERS}
        activeKey={authorizedItemType}
        onChange={setAuthorizedItemType}>
        <Tabs.TabPane
          key={ItemType.USERS}
          tab={
            <div data-test-id="authorised-users-tab-heading">
              <UserOutlined />
              Users{!isLoading && ` (${authorizedUsers.length})`}
            </div>
          }>
          <UserFixedList
            items={tableUsers.items}
            globalTags={globalTags}
            loading={isLoading || tableUsers.isLoading}
            tagsFilterable={true}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={ItemType.USER_GROUPS}
          tab={
            <div data-test-id="authorised-user-groups-tab-heading">
              <TeamOutlined />
              User groups{!isLoading && ` (${authorizedUserGroups.length})`}
            </div>
          }>
          <UserFixedList items={tableUserGroups.items} loading={isLoading || tableUserGroups.isLoading} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
});
