import React, { CSSProperties } from 'react';
import { Button, Popconfirm } from 'antd';

export const NotificationButton = (props: {
  id?: string;
  icon: JSX.Element;
  text: string;
  promptContent: string;
  pending: boolean;
  onOk: () => void;
  btnClass?: string;
  btnStyle?: CSSProperties;
  size?: 'small' | 'middle' | 'large';
  disabled?: boolean;
}) => {
  return (
    <div style={{ ...props.btnStyle }} className="notification-btn">
      <Popconfirm
        placement="right"
        title={props.promptContent}
        okText="Yes"
        cancelText="No"
        disabled={props.disabled}
        onConfirm={() => props.onOk()}>
        <Button
          id={props.id}
          type="default"
          className={props.btnClass || 'btn-primary'}
          loading={props.pending}
          icon={props.icon}
          disabled={props.disabled}
          size={props.size || 'middle'}>
          {props.text}
        </Button>
      </Popconfirm>
    </div>
  );
};
