import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Empty, Input, Select, Spin, Tag } from 'antd';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import * as _ from 'lodash';

type SelectableListProps = {
  selectable: true;
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  selectDisabled: boolean;
  unselectDisabled: boolean;
  maxItemsPerUpdateForManageUsers: number;
};

type UserListItem = {
  id: string;
  name: string;
  tags?: string[];
};

type FilterableTagProps = {
  tagsFilterable: true;
  globalTags: string[];
};

type Props = {
  loading?: boolean;
  items: UserListItem[];
} & (SelectableListProps | { selectable?: false }) &
  (FilterableTagProps | { tagsFilterable?: false });

function debouncedFilter<T>(filterFunc: Dispatch<SetStateAction<T>>) {
  return _.throttle(filterFunc, 500);
}

export const UserFixedList = (props: Props) => {
  const [nameFilter, setNameFilter] = useState<string>('');
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);
  const userEntities = props.items.filter(user => {
    return (
      (!nameFilter.length || user.name.toLocaleLowerCase().includes(nameFilter.toLocaleLowerCase())) &&
      (!tagsFilter.length || tagsFilter.filter(v => user.tags?.includes(v)).length === tagsFilter.length)
    );
  });

  return (
    <>
      <div className="authorised-items-search-fields">
        <Input
          className="item-search-input"
          placeholder="Filter by name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => debouncedFilter<string>(setNameFilter)(e.target.value)}
        />
        {props.tagsFilterable && (
          <Select
            mode="multiple"
            className="item-search-tags item-search-input"
            placeholder="Filter by tags"
            filterOption={(input, option) => option?.value?.toLowerCase().includes(input.toLowerCase())}
            onChange={(v: string[]) => debouncedFilter<string[]>(setTagsFilter)(v)}
            notFoundContent={null}>
            {props.globalTags.map((item: string, key: number) => (
              <Select.Option key={key} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>
      {props.selectable && (
        <div className="authorised-items-actions">
          <Button
            size="small"
            onClick={() => {
              props.setSelectedIds(userEntities.map(v => v.id).slice(0, props.maxItemsPerUpdateForManageUsers));
            }}
            disabled={props.selectDisabled}>
            <CheckCircleFilled />
            Select all filtered
          </Button>
          <Button
            size="small"
            onClick={() => {
              props.setSelectedIds([]);
            }}
            disabled={props.unselectDisabled}>
            <CloseCircleFilled />
            Deselect all
          </Button>
        </div>
      )}

      <div
        className="authorised-items-list"
        style={{
          height: userEntities.length > 0 ? userEntities.length * 35 + 5 : 170,
          maxHeight: 'calc(100vh - 630px)'
        }}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <FixedSizeList
                className="fixed-size-list"
                height={height}
                width={width}
                itemCount={userEntities.length > 0 ? userEntities.length : 1}
                itemSize={35}>
                {({ index, style }) => {
                  if (userEntities.length === 0 || props.loading) {
                    return (
                      <div className="empty-list" style={{ border: 0, height }}>
                        {props.loading ? <Spin /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                      </div>
                    );
                  } else {
                    const user = userEntities[index];
                    const isSelected = props.selectable && props.selectedIds.indexOf(user.id) !== -1;
                    return (
                      <div className="list-item" style={style}>
                        <div className="list-row user-list-row" data-test-id={`authorised-item-${user.id}`}>
                          <div
                            title={user.name}
                            className="user-detail"
                            style={{
                              flex: `0 0 ${!props.tagsFilterable || !user.tags ? '100%' : '60%'}`
                            }}>
                            {isSelected && <CheckCircleFilled />}
                            {!props.selectable ? (
                              user.name
                            ) : (
                              <Button
                                type="link"
                                title={user.name}
                                className="toggle-item-btn"
                                onClick={() => {
                                  if (isSelected) {
                                    props.setSelectedIds(props.selectedIds.filter(v => v !== user.id));
                                  } else {
                                    props.setSelectedIds([...props.selectedIds, user.id]);
                                  }
                                }}>
                                {user.name}
                              </Button>
                            )}
                          </div>
                          {props.tagsFilterable && user.tags && (
                            <div
                              title={user.tags.join(',')}
                              className="user-detail user-tags"
                              style={{ marginRight: 0 }}>
                              {user.tags.map((tag: string) => (
                                <Tag closable={false} key={tag}>
                                  {tag}
                                </Tag>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                }}
              </FixedSizeList>
            );
          }}
        </AutoSizer>
      </div>
    </>
  );
};
