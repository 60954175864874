import { takeEvery, takeLatest, put, all, call, select, delay, takeLeading } from 'redux-saga/effects';
import * as ActionTypes from '../actionTypes';
import {
  FetchGlobalTagsSuccessAction,
  UpdateAuthorisedUsersSuccessAction,
  UpdateAuthErrorAction,
  ShowNotificationModalAction,
  FetchAuthorisedItemsInManageAction,
  FetchAuthorisedItemsInManageSuccessAction,
  FetchAuthorisedItemsInManageErrorAction,
  FetchAuthorizedUsersAction,
  FetchAuthorisedUsersSuccessAction,
  UpdateBackgroundJobs,
  UpdateAuthorizedAction,
  SdkCallAction,
  SdkCallSuccessAction,
  SdkCallErrorAction,
  BackgroundJob,
  UpdateTriggeredBackgroundJobs,
  TriggeredBackgroundJob,
  SetGroupHierarchyAction,
  GroupHierarchy,
  FetchGroupHierarchyAction
} from './types';
import { createApi } from '../../api/sdk';
import {
  TagsApi,
  StationApi,
  BayApi,
  GroupApi,
  StationUserGroupApi,
  CommonApi,
  StationUserApi,
  WebsocketBackgroundJobStatusRequestDtoTypeEnum
} from '@pclocs/platform-sdk';
import { RootState } from '../rootReducer';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import {
  getSessionTriggeredBackgroundJobs,
  setSessionTriggeredBackgroundJobs
} from '../../helpers/session-storage-helper';
import _ from 'lodash';
import { websocketActions, WebsocketIncomingMessageAction } from '../websocket/reducer';

function* refreshWhenAppUpdated() {
  let prevEtag: string | undefined;
  while (process.env.NODE_ENV !== 'test') {
    const result = yield call(axios.head, `${window.location.origin}/index.html`);
    if (prevEtag && prevEtag !== result.headers.etag) {
      window.location.reload();
    }
    prevEtag = result.headers.etag;
    yield delay(20000);
  }
}

export function* fetchGlobalTags() {
  yield takeEvery(ActionTypes.FETCH_GLOBAL_TAGS, function*() {
    const api = createApi<TagsApi>('TagsApi');
    try {
      const response: ApiReturnType<TagsApi['findAll']> = yield call(api.findAll);
      yield put<FetchGlobalTagsSuccessAction>({
        type: ActionTypes.FETCH_GLOBAL_TAGS_SUCCESS,
        payload: {
          data: response.data.tags.sort()
        }
      });
    } catch (e) {
      console.error(e);
    }
  });
}

export function* fetchAuthUsersInManageTable() {
  yield takeLatest(ActionTypes.FETCH_AUTH_USERS_IN_MANAGE_TABLE, function*(action: FetchAuthorisedItemsInManageAction) {
    try {
      const api = createApi<StationUserApi>('StationUserApi');
      const response: ApiReturnType<typeof api.findByIds> = yield call(api.findByIds, {
        userIds: action.payload.ids
      });
      yield put<FetchAuthorisedItemsInManageSuccessAction>({
        type: ActionTypes.FETCH_AUTH_USERS_IN_MANAGE_TABLE_SUCCESS,
        payload: {
          data: response.data,
          idsNotInResponse: action.payload.ids.filter(id => !response.data.some(item => item.id === id))
        }
      });
    } catch (e) {
      console.error(e);
      yield put<FetchAuthorisedItemsInManageErrorAction>({
        type: ActionTypes.FETCH_AUTH_USERS_IN_MANAGE_TABLE_ERROR,
        payload: {
          fetchError: 'Failed to fetch items'
        }
      });
    }
  });
}

export function* fetchAuthUserGroupsInManageTable() {
  yield takeLatest(ActionTypes.FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE, function*(
    action: FetchAuthorisedItemsInManageAction
  ) {
    try {
      const api = createApi<StationUserGroupApi>('StationUserGroupApi');
      const response: ApiReturnType<typeof api.findByIds> = yield call(api.findByIds, {
        ids: action.payload.ids
      });
      yield put<FetchAuthorisedItemsInManageSuccessAction>({
        type: ActionTypes.FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE_SUCCESS,
        payload: {
          data: response.data,
          idsNotInResponse: action.payload.ids.filter(id => !response.data.some(item => item.id === id))
        }
      });
    } catch (e) {
      console.error(e);
      yield put<FetchAuthorisedItemsInManageErrorAction>({
        type: ActionTypes.FETCH_AUTH_USER_GROUPS_IN_MANAGE_TABLE_ERROR,
        payload: {
          fetchError: 'Failed to fetch items'
        }
      });
    }
  });
}

export function* fetchUsersInStation() {
  yield takeLatest(ActionTypes.FETCH_AUTHORIZED_IN_STATION, function*(action: FetchAuthorizedUsersAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      const response: ApiReturnType<typeof api.getAuthorized> = yield call(api.getAuthorized, action.payload.id);
      yield put<
        FetchAuthorisedUsersSuccessAction
      >({ type: ActionTypes.FETCH_AUTHORIZED_IN_STATION_SUCCESS, payload: { data: response.data } });
    } catch (e) {
      yield put({
        type: ActionTypes.FETCH_STATION_FAILED,
        payload: {
          id: action.payload.id,
          statusCode: e.response?.status
        }
      });
      console.error(e);
    }
  });
}

export function* updateUsersInStation() {
  yield takeLatest(ActionTypes.UPDATE_AUTHORIZED_IN_STATION, function*(action: UpdateAuthorizedAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      yield call(api.updateAuthorized, action.payload.id, { authorizedEntities: action.payload.authorizedEntities });
      yield put<UpdateAuthorisedUsersSuccessAction>({ type: ActionTypes.UPDATE_AUTHORIZED_IN_STATION_SUCCESS });
      yield put<
        FetchAuthorizedUsersAction
      >({ type: ActionTypes.FETCH_AUTHORIZED_IN_STATION, payload: { id: action.payload.id } });
    } catch (e) {
      console.error(e);

      const errorMsg: string =
        e.response.data.statusCode === 400 && e.response.data.message[0].constraints?.arrayMaxSize
          ? 'A maximum of 1000 users and user groups can be authorised at a time'
          : 'Error updating user authorisation';

      yield put<UpdateAuthErrorAction>({ type: ActionTypes.UPDATE_MANAGE_AUTHORIZED_ERROR });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: errorMsg
        }
      });
    }
  });
}

export function* removeAllUsersInStation() {
  yield takeLatest(ActionTypes.REMOVE_ALL_AUTHORIZED_IN_STATION, function*(action: UpdateAuthorizedAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      yield call(api.deleteAuthorized, action.payload.id);
      yield put<
        FetchAuthorizedUsersAction
      >({ type: ActionTypes.FETCH_AUTHORIZED_IN_STATION, payload: { id: action.payload.id } });
    } catch (e) {
      console.error(e);
    }
  });
}

export function* fetchUsersInBay() {
  yield takeLatest(ActionTypes.FETCH_AUTHORIZED_IN_BAY, function*(action: FetchAuthorizedUsersAction) {
    try {
      const api = createApi<BayApi>('BayApi');
      const response: ApiReturnType<typeof api.getAuthorized> = yield call(api.getAuthorized, action.payload.id);
      yield put<
        FetchAuthorisedUsersSuccessAction
      >({ type: ActionTypes.FETCH_AUTHORIZED_IN_BAY_SUCCESS, payload: { data: response.data } });
    } catch (e) {
      yield put({
        type: ActionTypes.FETCH_AUTHORIZED_IN_BAY_FAILED,
        payload: {
          id: action.payload.id,
          statusCode: e.response?.status
        }
      });
      console.error(e);
    }
  });
}

export function* updateUsersInBay() {
  yield takeLatest(ActionTypes.UPDATE_AUTHORIZED_IN_BAY, function*(action: UpdateAuthorizedAction) {
    try {
      const api = createApi<BayApi>('BayApi');
      yield call(api.updateAuthorized, action.payload.id, { authorizedEntities: action.payload.authorizedEntities });
      yield put<UpdateAuthorisedUsersSuccessAction>({ type: ActionTypes.UPDATE_AUTHORIZED_IN_BAY_SUCCESS });
      yield put<
        FetchAuthorizedUsersAction
      >({ type: ActionTypes.FETCH_AUTHORIZED_IN_BAY, payload: { id: action.payload.id } });
    } catch (e) {
      console.error(e);
      console.error(e.response.data);
      const errorMsg: string =
        e.response.data.statusCode === 400 && e.response.data.message[0].constraints?.arrayMaxSize
          ? 'A maximum of 1000 users and user groups can be authorised at a time'
          : 'Error updating user authorisation';

      yield put<UpdateAuthErrorAction>({ type: ActionTypes.UPDATE_MANAGE_AUTHORIZED_ERROR });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: errorMsg
        }
      });
    }
  });
}

export function* removeAllUsersInBay() {
  yield takeLatest(ActionTypes.REMOVE_ALL_AUTHORIZED_IN_BAY, function*(action: UpdateAuthorizedAction) {
    try {
      const api = createApi<BayApi>('BayApi');
      yield call(api.deleteAuthorized, action.payload.id);
      yield put<
        FetchAuthorizedUsersAction
      >({ type: ActionTypes.FETCH_AUTHORIZED_IN_BAY, payload: { id: action.payload.id } });
    } catch (e) {
      console.error(e);
    }
  });
}

export function* fetchUsersInGroup() {
  yield takeLatest(ActionTypes.FETCH_AUTHORIZED_IN_GROUP, function*(action: FetchAuthorizedUsersAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<typeof api.getAuthorized> = yield call(api.getAuthorized, action.payload.id);
      yield put<
        FetchAuthorisedUsersSuccessAction
      >({ type: ActionTypes.FETCH_AUTHORIZED_IN_GROUP_SUCCESS, payload: { data: response.data } });
    } catch (e) {
      yield put({
        type: ActionTypes.FETCH_GROUP_FAILED,
        payload: {
          id: action.payload.id,
          statusCode: e.response?.status
        }
      });
      console.error(e);
    }
  });
}

export function* updateUsersInGroup() {
  yield takeLatest(ActionTypes.UPDATE_AUTHORIZED_IN_GROUP, function*(action: UpdateAuthorizedAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      yield call(api.updateAuthorized, action.payload.id, { authorizedEntities: action.payload.authorizedEntities });
      yield put<UpdateAuthorisedUsersSuccessAction>({ type: ActionTypes.UPDATE_AUTHORIZED_IN_GROUP_SUCCESS });
      yield put<
        FetchAuthorizedUsersAction
      >({ type: ActionTypes.FETCH_AUTHORIZED_IN_GROUP, payload: { id: action.payload.id } });
    } catch (e) {
      console.error(e);
      const errorMsg: string =
        e.response.data.statusCode === 400 && e.response.data.message[0].constraints?.arrayMaxSize
          ? 'A maximum of 1000 users and user groups can be authorised at a time'
          : 'Error updating user authorisation';

      yield put<UpdateAuthErrorAction>({ type: ActionTypes.UPDATE_MANAGE_AUTHORIZED_ERROR });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: errorMsg
        }
      });
    }
  });
}

export function* removeAllUsersInGroup() {
  yield takeLatest(ActionTypes.REMOVE_ALL_AUTHORIZED_IN_GROUP, function*(action: UpdateAuthorizedAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      yield call(api.deleteAuthorized, action.payload.id);
      yield put<
        FetchAuthorizedUsersAction
      >({ type: ActionTypes.FETCH_AUTHORIZED_IN_GROUP, payload: { id: action.payload.id } });
    } catch (e) {
      console.error(e);
    }
  });
}

function* watchBackgroundJobs() {
  yield takeEvery(ActionTypes.WATCH_BACKGROUND_JOBS, function*() {
    try {
      const numWatchers: number = yield select((state: RootState) => state.common.backgroundJobWatchers);
      if (numWatchers === 1) {
        const api = createApi<CommonApi>('CommonApi');
        const res: ApiReturnType<typeof api.getBackgroundJobStatus> = yield call(api.getBackgroundJobStatus);
        yield put<UpdateBackgroundJobs>({
          type: ActionTypes.UPDATE_BACKGROUND_JOBS,
          payload: { jobs: res.data }
        });
        yield put(
          websocketActions.subscribe({ type: WebsocketBackgroundJobStatusRequestDtoTypeEnum.BackgroundJobStatus })
        );
      }
    } catch (e) {
      console.error(e);
    }
  });
}

function* unwatchBackgroundJobs() {
  yield takeEvery(ActionTypes.UNWATCH_BACKGROUND_JOBS, function*() {
    try {
      const numWatchers: number = yield select((state: RootState) => state.common.backgroundJobWatchers);
      if (numWatchers === 0) {
        yield put(
          websocketActions.unsubscribe({ type: WebsocketBackgroundJobStatusRequestDtoTypeEnum.BackgroundJobStatus })
        );
        yield put<UpdateBackgroundJobs>({ type: ActionTypes.UPDATE_BACKGROUND_JOBS, payload: { jobs: undefined } });
      }
    } catch (e) {
      console.error(e);
    }
  });
}

function* getTriggeredBackgroundJobs() {
  yield takeLatest(ActionTypes.GET_TRIGGERED_BACKGROUND_JOBS, function*() {
    try {
      const triggeredBackgroundJobs: TriggeredBackgroundJob[] = getSessionTriggeredBackgroundJobs();
      if (triggeredBackgroundJobs.length > 0) {
        let backgroundJobs: BackgroundJob[] = yield select(state => state.common.backgroundJobs);
        if (backgroundJobs === undefined) {
          const api = createApi<CommonApi>('CommonApi');
          const res: ApiReturnType<typeof api.getBackgroundJobStatus> = yield call(api.getBackgroundJobStatus);
          backgroundJobs = res.data;
        }

        // get from session storage again to handle race conditions if API returns after value changes
        const currentTriggeredBackgroundJobs: TriggeredBackgroundJob[] = getSessionTriggeredBackgroundJobs();

        const existingJobs = _.intersectionBy(currentTriggeredBackgroundJobs, backgroundJobs, 'id');
        yield put<UpdateTriggeredBackgroundJobs>({
          type: ActionTypes.UPDATE_TRIGGERED_BACKGROUND_JOBS,
          payload: { jobs: existingJobs }
        });
      }
    } catch (e) {
      console.error(e);
    }
  });
}

function* updateTriggeredBackgroundJobs() {
  yield takeLatest(
    [
      ActionTypes.ADD_TRIGGERED_BACKGROUND_JOB,
      ActionTypes.REMOVE_TRIGGERED_BACKGROUND_JOB,
      ActionTypes.UPDATE_TRIGGERED_BACKGROUND_JOBS
    ],
    function*() {
      const triggeredBackgroundJobs: TriggeredBackgroundJob[] = yield select(
        (state: RootState) => state.common.triggeredBackgroundJobs
      );
      setSessionTriggeredBackgroundJobs(triggeredBackgroundJobs);
    }
  );
}

function* applyBackgroundJobWebsocketUpdates() {
  yield takeEvery(ActionTypes.WEBSOCKET_INCOMING_MESSAGE, function*(action: WebsocketIncomingMessageAction) {
    if (action.payload.type === 'background-job-status') {
      yield put<UpdateBackgroundJobs>({
        type: ActionTypes.UPDATE_BACKGROUND_JOBS,
        payload: { jobs: [action.payload.data] }
      });
    }
  });
}

function* sdkCall() {
  yield takeEvery(ActionTypes.SDK_CALL_ACTION, function*(action: SdkCallAction) {
    try {
      const api: any = createApi(action.payload.api as any);
      const response: AxiosResponse<unknown> = yield call(api[action.payload.function], ...action.payload.parameters);
      yield put<SdkCallSuccessAction>({
        type: ActionTypes.SDK_CALL_ACTION_SUCCESS,
        payload: {
          requestId: action.payload.requestId,
          status: response.status,
          data: response.data
        }
      });
    } catch (e) {
      yield put<SdkCallErrorAction>({
        type: ActionTypes.SDK_CALL_ACTION_ERROR,
        payload: e?.response
          ? {
              requestId: action.payload.requestId,
              status: e.response.status,
              error: e,
              data: e.response.data
            }
          : {
              requestId: action.payload.requestId,
              error: e
            }
      });
    }
  });
}

function* fetchGroupHierarchy() {
  yield takeLeading([ActionTypes.FETCH_GROUP_HIERARCHY], function*(action: FetchGroupHierarchyAction) {
    try {
      const tree: GroupHierarchy = action.payload?.forceUpdate
        ? undefined
        : yield select((state: RootState) => state.common.groupHierarchy);
      if (tree !== undefined) {
        return;
      }
      const api = createApi<GroupApi>('GroupApi');
      const res: ApiReturnType<typeof api.groupTree> = yield call(api.groupTree);
      yield put<SetGroupHierarchyAction>({
        type: ActionTypes.SET_GROUP_HIERARCHY,
        payload: res.data
      });
    } catch (e) {
      console.error('Failed to fetch the group hierarchy tree.');
    }
  });
}

export function* commonSaga() {
  yield all([
    refreshWhenAppUpdated(),
    fetchGlobalTags(),
    fetchAuthUsersInManageTable(),
    fetchAuthUserGroupsInManageTable(),
    fetchUsersInStation(),
    updateUsersInStation(),
    removeAllUsersInStation(),
    fetchUsersInBay(),
    updateUsersInBay(),
    removeAllUsersInBay(),
    fetchUsersInGroup(),
    updateUsersInGroup(),
    removeAllUsersInGroup(),
    watchBackgroundJobs(),
    unwatchBackgroundJobs(),
    getTriggeredBackgroundJobs(),
    updateTriggeredBackgroundJobs(),
    applyBackgroundJobWebsocketUpdates(),
    sdkCall(),
    fetchGroupHierarchy()
  ]);
}
