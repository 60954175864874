import React from 'react';
import { Button, Input, Spin } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { SafetyOutlined } from '@ant-design/icons';
import { getErrorAlertMessage, Props } from '../login-form';

export const RequestMfaTotpForm = (properties: { props: Props }) => {
  const props = properties.props;

  return (
    <>
      <div className="desc">
        <LegacyForm.Item>
          {props.form.getFieldDecorator('mfaTotpConfirmationToken', {
            rules: [{ required: true, message: 'Please input your One-time password!' }]
          })(<Input prefix={<SafetyOutlined />} placeholder="One-time password" maxLength={6} />)}
        </LegacyForm.Item>
      </div>

      {props.error && getErrorAlertMessage(props)}

      <LegacyForm.Item>
        <Spin spinning={props.pending === true}>
          <Button block type="primary" htmlType="submit" id="mfa-confirm-btn">
            Confirm
          </Button>
        </Spin>
      </LegacyForm.Item>
    </>
  );
};
