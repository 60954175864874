import React, { useEffect, useState, useRef, ReactNode, Dispatch } from 'react';
import { Tag, Select, Tooltip, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { CommonActionTypes } from '../../store/common/types';
import * as ActionTypes from '../../store/actionTypes';

type TagProps = {
  tags?: string[];
  updateTags?: (tags: string[]) => void;
  setTagsOnParent?: (tags: string[] | undefined) => void;
  horizontal?: boolean;
  disabled?: boolean;
};

const mapStateToProps = (state: RootState) => ({ globalTags: state.common.globalTags });

const mapDispatchToProps = (dispatch: Dispatch<CommonActionTypes>) => ({
  getGlobalTags: () => dispatch({ type: ActionTypes.FETCH_GLOBAL_TAGS })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & TagProps;

export const Tags = connector((props: Props) => {
  const [localTags, setLocalTags] = useState<string[] | undefined>(props.tags ? props.tags : undefined);
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const horizontal = props.horizontal !== undefined ? props.horizontal : false;

  useEffect(() => {
    if (props.tags && props.setTagsOnParent) {
      props.setTagsOnParent(localTags);
    }
    if (localTags !== undefined && localTags !== props.tags && props.updateTags) {
      props.updateTags(localTags);
    }
    return () => {
      props.setTagsOnParent && props.setTagsOnParent([]);
    };
  }, [localTags]);

  useEffect(() => {
    if (inputVisible && inputRef) {
      props.getGlobalTags();
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const showInput = () => {
    setInputVisible(true);
  };

  const handleRemove = (removedTag: string) => {
    const filteredTags = localTags && localTags.filter(tag => tag !== removedTag);
    setLocalTags(filteredTags);
  };

  const dropdownRender = (node: React.ReactElement) => {
    if (!node?.props?.searchValue) {
      return node;
    }
    // customise the menu object to display new tags at bottom of list with (New tag) flag
    let sortedMenu;
    if (props.globalTags?.includes(node.props.searchValue)) {
      sortedMenu = node.props.flattenOptions;
    } else {
      let alteredInput: Record<string, unknown> = node.props.flattenOptions[0] ?? {};
      alteredInput = {
        ...alteredInput,
        key: node.props.searchValue,
        data: {
          key: node.props.searchValue,
          children: node.props.searchValue + ' (New tag)'
        }
      };
      const headless = node.props.flattenOptions.slice(1);
      const firstMatch = headless.filter((v: { key: string }) => v.key.startsWith(node.props.searchValue));
      const noMatch = headless.filter((v: { key: string }) => !v.key.startsWith(node.props.searchValue));
      sortedMenu = firstMatch.concat([...noMatch, alteredInput]);
    }
    return { ...node, props: { ...node.props, flattenOptions: sortedMenu } };
  };

  const contents: ReactNode = (
    <>
      {inputVisible && (
        <Select
          ref={inputRef}
          defaultValue={localTags}
          mode="tags"
          style={{ width: '100%' }}
          onBlur={() => setInputVisible(false)}
          onChange={(e: React.SetStateAction<string[] | undefined>) => setLocalTags(e)}
          filterOption={(input, option) => option?.value?.toLowerCase().includes(input.toLowerCase())}
          dropdownRender={dropdownRender}
          autoClearSearchValue={true}>
          {props.globalTags &&
            props.globalTags.map(option => (
              <Select.Option value={option} key={option}>
                {option}
              </Select.Option>
            ))}
        </Select>
      )}
      {!inputVisible && (
        <>
          {localTags &&
            localTags.map(tag => {
              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag key={tag} closable={!props.disabled} onClose={() => handleRemove(tag)}>
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
          {!props.disabled && (
            <Tag
              onClick={showInput}
              style={{ background: '#fff', borderStyle: 'dashed' }}
              data-test-id="new-tag-button">
              <PlusOutlined /> New Tag
            </Tag>
          )}
        </>
      )}
    </>
  );

  if (horizontal) {
    return (
      <Row>
        <Col xs={24} sm={6} style={{ textAlign: 'right', paddingRight: 10, fontSize: '1rem' }}>
          Tags:
        </Col>
        <Col xs={24} sm={18} style={{ lineHeight: 2 }}>
          {contents}
        </Col>
      </Row>
    );
  } else {
    return <>{contents}</>;
  }
});
