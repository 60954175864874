import React from 'react';

export const AdminIcon = ({ width = 31, height = 23 }: { width?: number; height?: number }): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 23"
    fill="none"
    aria-labelledby="admin-icon-title"
    aria-describedby="admin-icon-desc"
    role="img">
    <title id="admin-icon-title">Admin</title>
    <desc id="admin-icon-desc">Combination of User and Setting icons</desc>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 15C25 16.6569 23.6568 18 22 18C20.3431 18 19 16.6569 19 15C19 13.3431 20.3431 12 22 12C23.6568 12 25 
        13.3431 25 15ZM24 15C24 16.1046 23.1046 17 22 17C20.8954 17 20 16.1046 20 15C20 13.8954 20.8954 13 22 
        13C23.1046 13 24 13.8954 24 15Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.579 13.5706C13.889 13.4451 14.1883 13.301 14.4752 13.1398L15.5147 13.9799C15.4563 14.3157 15.428 
        14.6586 15.428 14.9997C15.428 15.3425 15.4563 15.6854 15.5147 16.0194L14.2641 17.0302C14.1709 17.1057 14.1042 
        17.2063 14.0729 17.3186C14.0415 17.4309 14.064 17.596 14.1056 17.7052C14.4471 18.6053 14.9526 19.4357 15.6091 
        20.1679C15.6884 20.2562 15.8281 20.3572 15.9463 20.3875C16.0645 20.4177 16.1896 20.4134 16.3051 20.375L17.85 
        19.8554C18.4122 20.2929 19.0403 20.6393 19.7175 20.8804L20.0156 22.4055C20.0379 22.5198 20.0964 22.6248 20.1834 
        22.7068C20.2703 22.7888 20.4324 22.8527 20.5532 22.8734C21.545 23.0422 22.5606 23.0422 23.5525 22.8734C23.6732 
        22.8527 23.8353 22.7888 23.9223 22.7068C24.0092 22.6248 24.0677 22.5198 24.0901 22.4055L24.3862 20.8876C25.0688 
        20.6453 25.7043 20.2978 26.2669 19.8589L27.8005 20.375C27.916 20.4137 28.1995 20.5 28.4966 20.1679C29.153 
        19.4339 29.6586 18.6053 30 17.7052C30.0849 17.4855 30.0321 17.1891 29.8415 17.0337L28.606 16.0337C28.6645 
        15.6944 28.6946 15.3479 28.6946 15.0014C28.6946 14.655 28.6645 14.3085 28.606 13.9692L29.8415 12.9692C29.9347 
        12.8936 30.1527 12.6982 30 12.2941C29.6598 11.3941 29.1505 10.5598 28.4966 9.83145C28.199 9.5 27.9161 9.58592 
        27.8005 9.62429L26.2669 10.1404C25.701 9.70108 25.0691 9.35463 24.3862 9.11175L24.0901 7.59379C24.0677 7.47957 
        23.9109 7.18738 23.5525 7.1259C22.5697 6.95803 21.536 6.95803 20.5532 7.1259C20.1985 7.18648 20.0379 7.47957 
        20.0156 7.59379L19.7175 9.1189C19.0401 9.36182 18.4093 9.70809 17.85 10.144L17.4686 10.0157C17.908 9.12059 
        18.1528 8.12538 18.1528 7.07692C18.1528 3.16845 14.7502 0 10.5528 0C6.35546 0 2.95282 3.16845 2.95282 
        7.07692C2.95282 9.98387 4.83506 12.4814 7.52658 13.5706C3.23629 14.782 0.0527954 18.5033 0.0527954 
        22.9999H2.0528C2.0528 18.7326 5.79002 15.1538 10.5528 15.1538C11.81 15.1538 12.9958 15.4032 14.0602 
        15.8487C14.0602 15.2592 14.1164 14.3348 14.2374 13.7791C14.0211 13.703 13.8016 13.6334 13.579 13.5706ZM16.1528 
        7.07692C16.1528 9.7489 13.7823 12.1538 10.5528 12.1538C7.32331 12.1538 4.95282 9.7489 4.95282 7.07692C4.95282 
        4.40494 7.32331 2 10.5528 2C13.7823 2 16.1528 4.40494 16.1528 7.07692ZM16.9804 13.4763L15.605 12.3639C16.0706 
        11.9875 16.4141 11.6186 16.7701 11.1482L18.1217 11.603L18.714 11.1405C19.161 10.7922 19.6609 10.5172 20.1985 
        10.3243L20.9135 10.069L21.2511 8.33313C21.7812 8.27598 22.3226 8.27598 22.8545 8.33313L23.1922 10.0654L23.9109 
        10.3208C24.4522 10.5136 24.9559 10.7887 25.4067 11.1387L25.9991 11.5994L27.7496 11.0119C28.0703 11.4208 28.3381 
        11.8619 28.5532 12.3263L27.1441 13.4674L27.2667 14.18C27.3138 14.4496 27.3384 14.7264 27.3384 15.0032C27.3384 
        15.28 27.3138 15.5568 27.2667 15.8265L27.1422 16.5426L28.5513 17.6838C28.3377 18.1497 28.068 18.5907 27.7477 
        18.9982L25.9972 18.4106L25.4049 18.8714C24.954 19.2214 24.4522 19.4964 23.909 19.6893L23.1903 19.9446L22.8526 
        21.6769C22.3199 21.7341 21.782 21.7341 21.2492 21.6769L20.9116 19.9411L20.1985 19.6821C19.6609 19.4893 19.161 
        19.2142 18.714 18.866L18.1217 18.4035L16.3598 18.9964C16.0391 18.5874 15.7713 18.1463 15.5562 17.682L16.9804 
        16.5301L16.8578 15.8158C16.8125 15.5497 16.788 15.2747 16.788 15.0032C16.788 14.73 16.8107 14.4568 16.8578 
        14.1907L16.9804 13.4763Z"
      fill="currentColor"
    />
  </svg>
);
