import { StationUserState, StationUserActionTypes, CsvImportState, ExportState } from './types';
import * as ActionTypes from '../actionTypes';
import _ from 'lodash';
import { internalUserToStationUser } from '../../helpers/internal-user-to-station-user';

const defaultState: StationUserState = {
  stationUsers: [],
  showStationUserModal: false,
  showStationUserCsvModal: false,
  showStationUserUpdateCsvModal: false,
  pending: false,
  isStationUserLoading: false,
  isUniquePINLoading: false,
  preview: false,
  csvImportState: CsvImportState.IDLE,
  stationUser: undefined,
  isStationUserListLoading: false,
  filterSearchInProgress: false,
  createStationUserSuccess: false,
  showStationUserExportModal: false,
  exportState: ExportState.IDLE,
  stagedUsers: [],
  stationUsersFilter: '',
  stationUsersTagsFilter: []
};

export const stationUser = (
  state: StationUserState = defaultState,
  action: StationUserActionTypes
): StationUserState => {
  switch (action.type) {
    case ActionTypes.FIND_STATION_USERS:
      return {
        ...state,
        ...((!state.stationUsers.length || state.filterSearchInProgress) && {
          isStationUserListLoading: true,
          stagedUsers: []
        }),
        fetchError: undefined,
        createStationUserSuccess: false
      };
    case ActionTypes.FIND_STATION_USERS_SUCCESS:
      return {
        ...state,
        stationUsers: !!state.stationUsersPagingData?.after
          ? [...state.stationUsers, ...action.payload.items]
          : action.payload.items,
        isStationUserListLoading: false,
        stationUsersPagingData: {
          hasFailedOrigins: state.stationUsersPagingData?.hasFailedOrigins || action.payload.failedOrigins.length > 0,
          hasMoreNonPaginatable:
            action.payload.hasMoreNonPaginatable || state.stationUsersPagingData?.hasMoreNonPaginatable,
          after: action.payload.after
        },
        filterSearchInProgress: false
      };
    case ActionTypes.FIND_STATION_USERS_ERROR:
      return {
        ...state,
        isStationUserListLoading: false,
        fetchError: action.payload.fetchError,
        filterSearchInProgress: false
      };
    case ActionTypes.CLEAR_STATION_USERS:
      return {
        ...state,
        stationUsers: [],
        isStationUserListLoading: false,
        stationUsersPagingData: undefined
      };
    case ActionTypes.INTERNAL_STATION_USER_LOAD_MODAL:
      return {
        ...state,
        pending: false,
        showStationUserModal: true,
        stationUserId: action.payload?.stationUserId
      };
    case ActionTypes.INTERNAL_STATION_USER_UNLOAD_MODAL:
      return {
        ...state,
        showStationUserModal: false,
        stationUserId: undefined,
        uniquePIN: undefined,
        stationUser: undefined,
        isStationUserLoading: false,
        pending: false,
        messages: undefined,
        error: undefined
      };
    case ActionTypes.GET_STATION_USER:
      return {
        ...state,
        stationUserId: action.payload.stationUserId,
        isStationUserLoading: true
      };
    case ActionTypes.CLEAR_STATION_USER:
      return {
        ...state,
        stationUserId: undefined,
        stationUser: undefined,
        uniquePIN: undefined,
        rfid: undefined
      };
    case ActionTypes.GET_STATION_USER_SUCCESS:
      return {
        ...state,
        stationUser: action.payload.data,
        uniquePIN: action.payload.data.stationCredentials?.pin || '',
        rfid: action.payload.data.stationCredentials?.rfid || '',
        isStationUserLoading: false
      };
    case ActionTypes.GET_STATION_USER_ERROR:
      return {
        ...state,
        isStationUserLoading: false,
        showStationUserModal: false,
        messages: [action.payload.message]
      };
    case ActionTypes.DELETE_STATION_USER:
      return {
        ...state,
        isStationUserListLoading: true
      };
    case ActionTypes.DELETE_STATION_USER_SUCCESS:
      return {
        ...state,
        stationUsers: _.reject(state.stationUsers, { id: action.payload.id }),
        stagedUsers: _.reject(state.stagedUsers, { id: action.payload.id }),
        isStationUserListLoading: false
      };
    case ActionTypes.DELETE_STATION_USER_ERROR:
      return {
        ...state,
        isStationUserListLoading: false
      };
    case ActionTypes.FILTER_STATION_USERS_BY_PROPERTY:
      return {
        ...state,
        filterSearchInProgress: true,
        stationUsersFilter: action.payload.filter,
        stationUsersTagsFilter: action.payload.tagsFilter,
        stationUsersPagingData: undefined
      };
    case ActionTypes.GET_UNIQUE_PIN_STATION_USER:
      return {
        ...state,
        isUniquePINLoading: true,
        uniquePIN: undefined
      };
    case ActionTypes.GET_UNIQUE_PIN_STATION_USER_SUCCESS:
      return {
        ...state,
        isUniquePINLoading: false,
        uniquePIN: action.payload.pin
      };
    case ActionTypes.CLEAR_UNIQUE_PIN_STATION_USER:
      return {
        ...state,
        isUniquePINLoading: false,
        uniquePIN: undefined
      };
    case ActionTypes.GET_UNIQUE_PIN_STATION_USER_ERROR:
      return {
        ...state,
        isUniquePINLoading: false,
        uniquePIN: undefined,
        messages: [action.payload.message]
      };
    case ActionTypes.SET_STAGED_STATION_USERS:
      return {
        ...state,
        stagedUsers: action.payload.stagedUsers
      };
    case ActionTypes.UPDATE_STATION_USER:
    case ActionTypes.CREATE_STATION_USER:
      return {
        ...state,
        pending: true,
        messages: undefined,
        errorMessage: undefined
      };
    case ActionTypes.CREATE_STATION_USER_SUCCESS:
      return {
        ...state,
        createStationUserSuccess: true
      };

    case ActionTypes.UPDATE_STATION_USER_SUCCESS:
      return {
        ...state,
        stationUsers: state.stationUsers.map(user =>
          action.payload.data.id === user.id ? internalUserToStationUser(action.payload.data) : user
        )
      };
    case ActionTypes.UPDATE_STATION_USER_ERROR:
    case ActionTypes.CREATE_STATION_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    case ActionTypes.PUSH_CSV_FILE_TO_S3:
      return {
        ...state,
        csvFile: action.payload.file,
        csvImportState: CsvImportState.UPLOADING,
        errorMessage: undefined
      };
    case ActionTypes.PUSH_CSV_FILE_TO_S3_SUCCESS:
      return {
        ...state,
        csvImportState: CsvImportState.IDLE,
        fileReference: action.payload.fileReference,
        errorMessage: undefined
      };
    case ActionTypes.PUSH_CSV_FILE_TO_S3_ERROR:
      return {
        ...state,
        csvImportState: CsvImportState.ERROR,
        errorMessage: action.payload.message
      };
    case ActionTypes.TRIGGER_API_CSV_IMPORT:
      return {
        ...state,
        csvImportState: CsvImportState.IMPORTING,
        fileReference: action.payload.fileReference
      };
    case ActionTypes.TRIGGER_API_CSV_IMPORT_SUCCESS:
    case ActionTypes.TRIGGER_API_CSV_UPDATE_SUCCESS:
      return {
        ...state,
        csvFile: undefined,
        fileReference: undefined,
        csvImportState: CsvImportState.DONE
      };
    case ActionTypes.STATION_USER_LOAD_CSV_MODAL:
      return {
        ...state,
        csvFile: undefined,
        fileReference: undefined,
        csvImportState: CsvImportState.IDLE,
        showStationUserCsvModal: true,
        errorMessage: undefined
      };
    case ActionTypes.STATION_USER_UNLOAD_CSV_MODAL:
      return {
        ...state,
        showStationUserCsvModal: false
      };
    case ActionTypes.TRIGGER_API_CSV_UPDATE:
      return {
        ...state,
        csvImportState: CsvImportState.UPDATING,
        fileReference: action.payload.fileReference
      };
    case ActionTypes.STATION_USER_LOAD_UPDATE_CSV_MODAL:
      return {
        ...state,
        csvFile: undefined,
        fileReference: undefined,
        csvImportState: CsvImportState.IDLE,
        showStationUserUpdateCsvModal: true,
        errorMessage: undefined
      };
    case ActionTypes.STATION_USER_UNLOAD_UPDATE_CSV_MODAL:
      return {
        ...state,
        showStationUserUpdateCsvModal: false
      };
    case ActionTypes.TRIGGER_API_USER_EXPORT:
      return {
        ...state,
        exportState: ExportState.REQUESTING
      };
    case ActionTypes.TRIGGER_API_USER_EXPORT_SUCCESS:
      return {
        ...state,
        exportState: ExportState.REQUESTED
      };
    case ActionTypes.STATION_USER_LOAD_EXPORT_MODAL:
      return {
        ...state,
        exportState: ExportState.IDLE,
        showStationUserExportModal: true
      };
    case ActionTypes.STATION_USER_UNLOAD_EXPORT_MODAL:
      return {
        ...state,
        showStationUserExportModal: false
      };

    case ActionTypes.REMOVE_BAY_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        stationUser: undefined
      };
    case ActionTypes.USERS_PAGE_UNLOADED: {
      return {
        ...state,
        stationUsersFilter: undefined,
        stationUsersTagsFilter: undefined,
        stationUsers: [],
        isStationUserListLoading: false,
        stationUsersPagingData: undefined
      };
    }
    case ActionTypes.STATION_USER_FIND_BAY_ASSIGNMENTS: {
      return {
        ...state,
        bayAssignments: undefined
      };
    }
    case ActionTypes.STATION_USER_FIND_BAY_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        bayAssignments: action.payload.bays
      };
    }
    default:
      return state;
  }
};
