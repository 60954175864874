import React, { useEffect } from 'react';
import { Button, Input, Select, Tag } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { InfinityList } from '../InfinityList';
import { Pager, usePickFromPager } from '../../helpers/pageable-reducer';
import _ from 'lodash';

export interface CompatibleUser {
  id: string;
  name: string;
  tags?: string[];
}

interface SelectableListProps {
  selectable: true;
  selectedIds: string[];
  onSelect: (selectedIds: string[]) => void;
  maxSelectableItems: number;
  selectDisabled: boolean;
  unselectDisabled: boolean;
}

interface FilterableTagProps {
  tagsFilterable: true;
  globalTags: string[];
}

type Props = {
  pageSize: number;
  pager: Omit<Pager<CompatibleUser, { name?: string; tags?: string[] }>, 'requestParameters' | 'setResponse'>;
} & (SelectableListProps | { selectable?: false }) &
  (FilterableTagProps | { tagsFilterable?: false });

export const UserInfinityList = (props: Props) => {
  const [pickedItems, pickLoading, pickItemsFromPager] = !props.selectable ? [] : usePickFromPager(props.pager);
  useEffect(() => {
    if (props.selectable && pickedItems !== undefined) {
      props.onSelect(_.uniq([...props.selectedIds, ...pickedItems.map(v => v.id)]).slice(0, props.maxSelectableItems));
    }
  }, [props.selectable, pickedItems]);

  const setFilters = _.throttle(props.pager.setFilters, 500);

  return (
    <div className="user-list-section">
      <div className="user-list-search-fields">
        <Input
          defaultValue={props.pager.filters.name ?? ''}
          className="item-search-input"
          placeholder="Filter by name"
          onChange={e => setFilters({ ...props.pager.filters, name: e.target.value || undefined })}
        />
        {props.tagsFilterable && (
          <Select
            mode="multiple"
            className="item-search-input"
            placeholder="Filter by tags"
            defaultValue={props.pager.filters.tags ?? []}
            filterOption={(input, option) => option?.value?.toLowerCase().includes(input.toLowerCase())}
            onChange={(v: string[]) => setFilters({ ...props.pager.filters, tags: v.length ? v : undefined })}
            notFoundContent={null}>
            {props.globalTags.map((item: string, key: number) => (
              <Select.Option key={key} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>

      {props.selectable && (
        <div className="user-list-actions">
          <Button
            size="small"
            onClick={() => pickItemsFromPager(props.maxSelectableItems)}
            loading={pickLoading}
            disabled={pickLoading || props.selectDisabled}>
            {!pickLoading && <CheckCircleFilled />}
            Select all filtered
          </Button>
          <Button size="small" onClick={() => props.onSelect([])} disabled={pickLoading || props.unselectDisabled}>
            <CloseCircleFilled />
            Deselect all
          </Button>
        </div>
      )}

      <div className="user-list-items">
        <InfinityList
          {...props.pager}
          loadItems={() => props.pager.loadItems(props.pageSize)}
          pageSize={props.pageSize}
          render={user => {
            const isSelected = props.selectable && props.selectedIds.includes(user.id);
            return (
              <div className="list-row user-list-row">
                <div
                  title={user.name}
                  className="user-detail"
                  style={{
                    flex: `0 0 ${!props.tagsFilterable || !user.tags ? '100%' : '60%'}`
                  }}>
                  {props.selectable ? (
                    <Button
                      type="link"
                      title={user.name}
                      onClick={() => {
                        props.onSelect(
                          isSelected ? props.selectedIds.filter(v => v !== user.id) : props.selectedIds.concat(user.id)
                        );
                      }}>
                      {isSelected && <CheckCircleFilled />}
                      {user.name}
                    </Button>
                  ) : (
                    <>{user.name}</>
                  )}
                </div>
                {props.tagsFilterable && user.tags && (
                  <div title={user.tags.join(',')} className="user-detail user-tags">
                    {user.tags.map((tag: string) => (
                      <Tag closable={false} key={tag}>
                        {tag}
                      </Tag>
                    ))}
                  </div>
                )}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};
