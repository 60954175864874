import React, { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Alert, Skeleton, Tooltip, Tree } from 'antd';
import { CloudDownloadOutlined, DatabaseOutlined, ReloadOutlined, DisconnectOutlined } from '@ant-design/icons';
import * as ActionTypes from '../../../store/actionTypes';
import { RootState } from '../../../store/rootReducer';
import { DashboardActionTypes } from '../../../store/dashboard/types';
import { TreeStationGroupNodeDto } from '@pclocs/platform-sdk';
import { WebsocketActionTypes } from '../../../store/websocket/reducer';
import { HierarchyTree, filterStationGroupNode, useLiveHierarchy } from './use-live-hierarchy-hook';
import { createTreeNodes, findGroupNode, groupHierarchyToArray } from '../../../helpers/group-hierarchy';
import { firmwareValidAndGt } from '../../../helpers/station';
import { useTrackElementHeight } from '../../../helpers/mutation-observer-hooks';
import { EventDataNode } from 'antd/lib/tree';
import _ from 'lodash';
import { getCollapsedGroupNodeIds, setCollapsedGroupNodeIds } from '../../../helpers/local-storage-helper';
import { useParams } from 'react-router';

const mapStateToProps = (state: RootState) => ({
  latestFirmwareVersion: state.dashboard.firmwareList?.[0]?.version,
  currentUserId: state.auth.currentUser?.id
});

const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes | WebsocketActionTypes>) => ({
  getAvailableFirmwareList: () => dispatch({ type: ActionTypes.GET_AVAILABLE_FIRMWARE_LIST })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  defaultSelected?: string;
  onSelect: (itemType: 'station' | 'group', itemId: string) => void;
  searchTerm: string;
};

const createStationNode = _.memoize(
  (
    id: string,
    name: string,
    firmwareUpdateAvailable: boolean,
    hasPendingConfigChanges: boolean,
    connected: boolean
  ) => {
    return {
      'data-type': 'station',
      key: id,
      isLeaf: true,
      switcherIcon: <DatabaseOutlined />,
      title: (
        <>
          <span style={{ float: 'right' }}>
            {firmwareUpdateAvailable && (
              <Tooltip title="Firmware update is available." placement="topLeft">
                <CloudDownloadOutlined style={{ padding: '0 3px' }} />
              </Tooltip>
            )}
            {hasPendingConfigChanges && (
              <Tooltip title="Pending config changes..." placement="topLeft">
                <ReloadOutlined spin style={{ padding: '0 3px' }} />
              </Tooltip>
            )}
            {!connected && (
              <Tooltip title="Station not connected" placement="topLeft">
                <DisconnectOutlined style={{ margin: '0 3px' }} />
              </Tooltip>
            )}
          </span>
          {name ?? id}
        </>
      )
    };
  },
  (...args) => args.join(',')
);

const renderTree = (tree: Record<string, TreeStationGroupNodeDto>, latestFirmwareVersion: string) =>
  createTreeNodes(tree, g => ({
    'data-type': 'group',
    children: Object.values(g.stations)
      .sort((a, b) => a.name?.localeCompare(b.name) || a.id.localeCompare(b.id))
      .map(station => {
        return createStationNode(
          station.id,
          station.name,
          firmwareValidAndGt(latestFirmwareVersion, station.firmwareVersion),
          station.hasPendingChanges,
          station.connected
        );
      })
  }));

const filterTree = (tree: HierarchyTree, searchTerm: string) => {
  return _.mapValues(tree, node => {
    return { ...node, stations: {}, groups: {}, ...filterStationGroupNode(node, searchTerm) };
  });
};

export const Hierarchy = connector((props: Props) => {
  const GROUP_NODES_EXPAND_LIMIT = 30;

  useEffect(() => {
    props.getAvailableFirmwareList();
  }, []);

  const { accountId } = useParams<{ accountId: string }>();
  const [collapsedNodes, setCollapsedNodes] = useState<string[]>([]);

  const updateCollapsedNodes = (nodeIds: string[]) => {
    const groupNodeIds = nodeIds.filter(id => id.startsWith('G-'));
    setCollapsedGroupNodeIds(props.currentUserId, accountId, groupNodeIds);
    setCollapsedNodes(groupNodeIds);
  };

  const { tree, fetchError } = useLiveHierarchy();
  const filteredTree = props.searchTerm.length ? filterTree(tree, props.searchTerm.toLowerCase().trim()) : tree;

  useEffect(() => {
    if (tree) {
      const collapsedGroupNodes = getCollapsedGroupNodeIds(props.currentUserId, accountId);
      if (collapsedGroupNodes === null) {
        const groupNodesWithManyStations = groupHierarchyToArray(tree)
          .slice(1)
          .filter(v => {
            const node = findGroupNode(tree, v.id);
            const totalSubNodes = Object.keys(node.groups).length + Object.keys(node.stations).length;
            return totalSubNodes > GROUP_NODES_EXPAND_LIMIT;
          })
          .map(node => node.id);
        updateCollapsedNodes(groupNodesWithManyStations);
      } else {
        updateCollapsedNodes(collapsedGroupNodes);
      }
    }
  }, [tree]);

  const expandedGroupNodes = useMemo(
    () =>
      _.difference(
        groupHierarchyToArray(tree ?? {})
          .map(node => node.id)
          .flat(),
        collapsedNodes
      ),
    [tree, collapsedNodes]
  );

  const hierarchyTreeContainer = useRef<HTMLDivElement>(null);
  const treeContainerHeight = useTrackElementHeight(hierarchyTreeContainer);

  const onSelect = useCallback(
    (_selectedKeys, event) => {
      const itemId = event.node.key as string;
      const itemType = _.get(event.node, 'data-type') as string;
      (itemType === 'station' || itemType === 'group') && props.onSelect(itemType, itemId);
    },
    [props.onSelect]
  );

  return (
    <div className="hierarchy-tree-container" ref={hierarchyTreeContainer}>
      {!tree ? (
        fetchError ? (
          <Alert type="error" message={fetchError} />
        ) : (
          <Skeleton active />
        )
      ) : (
        <Tree
          showLine
          defaultExpandAll
          expandedKeys={expandedGroupNodes}
          height={treeContainerHeight}
          onSelect={onSelect}
          onExpand={(_expandedKeys, { expanded, node }: { expanded: boolean; node: EventDataNode }) => {
            const updatedNodes = expanded
              ? collapsedNodes.filter(v => v !== node.key)
              : [...collapsedNodes, node.key as string];
            updateCollapsedNodes(updatedNodes);
          }}
          autoExpandParent={false}
          blockNode
          selectedKeys={props.defaultSelected ? [props.defaultSelected] : undefined}
          treeData={renderTree(filteredTree, props.latestFirmwareVersion)}
        />
      )}
    </div>
  );
});
