import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, DatePicker, Radio, Select, Skeleton } from 'antd';
import {
  DatabaseOutlined,
  ExportOutlined,
  FileSearchOutlined,
  FolderOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio';
import { RootState } from '../../store/rootReducer';
import * as ActionTypes from '../../store/actionTypes';
import { DashboardActionTypes } from '../../store/dashboard/types';
import { ReportsActionTypes, ReportsPayload } from '../../store/reports/types';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { history } from '../../store/store';
import { ALL_REPORTS_LIST, ReportKey, TABLE_REPORT_LIST } from '../../constants/reports';
import { ExportReportModal } from './export-report-modal';
import { disabledCustomerReportDates } from '../../helpers/customer-reports-helper';
import { PopularUsageTimesReport } from './popular-usage-times-report';
import { TableReport } from './table-report';
import { FeatureId, useFeatureAvailability } from '../../helpers/feature-availability-hooks';
import { groupHierarchyToArray, useGroupHierarchy } from '../../helpers/group-hierarchy';
import { GroupHierarchy } from '../../store/common/types';
import { useSdkCallOnChanges } from '../../helpers/sdk-hooks';

const { MonthPicker, RangePicker } = DatePicker;
const PAGE_SIZE: number = 200;

const mapStateToProps = (state: RootState) => state.reports;

const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes | ReportsActionTypes>) => ({
  onLoad: () => dispatch({ type: ActionTypes.REPORTS_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ActionTypes.REPORTS_PAGE_UNLOADED }),
  generateReport: (reportsPayload: ReportsPayload) => {
    dispatch({
      type: ActionTypes.GENERATE_REPORT,
      payload: { ...reportsPayload, pageSize: PAGE_SIZE }
    });
  },
  fetchNextReportPage: (reportsPayload: ReportsPayload) => {
    dispatch({
      type: ActionTypes.FETCH_NEXT_REPORT_PAGE,
      payload: { ...reportsPayload, pageSize: PAGE_SIZE }
    });
  },
  exportReport: (payload: ReportsPayload) => dispatch({ type: ActionTypes.EXPORT_REPORT, payload }),
  filterReportsByProperty: (payload: ReportsPayload) =>
    dispatch({ type: ActionTypes.FILTER_REPORTS_BY_PROPERTY, payload }),
  hideExportReportModal: () => dispatch({ type: ActionTypes.HIDE_EXPORT_REPORT_MODAL })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const Reports = connector((props: Props) => {
  const [dateRangePickerType, setDateRangePickerType] = useState<'month' | 'custom'>('custom');
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState<boolean>(false);
  const [dateRangeFilter, setDateRangeFilter] = useState<[Moment, Moment]>([
    moment(props.startTimeFilter),
    moment(props.endTimeFilter)
  ]);
  const [hasFeature, isFeatureAvailabilityLoading] = useFeatureAvailability();
  const hasReportsFeature = hasFeature(FeatureId.REPORTS);

  const { accountId } = useParams<{ accountId: string }>();

  useEffect(() => {
    if (!isFeatureAvailabilityLoading && !hasReportsFeature) {
      history.replace(`/auth/${accountId}/dashboard`);
    }
  }, [isFeatureAvailabilityLoading, hasReportsFeature]);

  const groupHierarchy = useGroupHierarchy();
  const [, stationNameMap] = useSdkCallOnChanges('StationApi', 'getStationNames');

  useEffect(() => {
    props.onLoad();
    return () => {
      props.onUnload();
    };
  }, []);

  useEffect(() => {
    props.filterReportsByProperty({
      startTimeFilter: props.startTimeFilter,
      endTimeFilter: props.endTimeFilter,
      reportKeyFilter: props.reportKeyFilter,
      groupOrStationFilter: groupHierarchy && Object.keys(groupHierarchy)[0]
    });
  }, [groupHierarchy]);

  const filterReports = (
    reportKeyFilter: ReportKey,
    groupOrStationFilter?: string,
    startDate?: Moment,
    endDate?: Moment
  ) => {
    /* istanbul ignore else */
    if (startDate && endDate) {
      props.filterReportsByProperty({
        startTimeFilter: moment(startDate).toISOString(),
        endTimeFilter: moment(endDate).toISOString(),
        reportKeyFilter: reportKeyFilter,
        ...(groupOrStationFilter && { groupOrStationFilter })
      });
    }
  };

  const renderDateRangeTypePicker = (): JSX.Element => (
    <div className="reports-date-range-picker-footer">
      <span>Select dates by:&nbsp;</span>
      <Radio.Group
        buttonStyle="solid"
        value={dateRangePickerType}
        onChange={(e: RadioChangeEvent) => {
          setDateRangePickerType(e.target.value);
          setIsDateRangePickerOpen(true);
        }}>
        <Radio.Button value="month">Month</Radio.Button>
        <Radio.Button value="custom">Custom</Radio.Button>
      </Radio.Group>
    </div>
  );

  const isStationFilterSupported = props.reportKeyFilter !== 'current-station-connectivity';

  const generateHierarchySelect = (tree: GroupHierarchy) => {
    const groups = groupHierarchyToArray(tree);
    const stations = _.flatten(groups.map(g => g.stations));
    return (
      <Select
        showSearch={true}
        value={props.groupOrStationFilter}
        placeholder="Select node or station"
        disabled={props.isReportsGenerating || props.requestingExportJob}
        filterOption={(input, option) => {
          return option.children
            ?.toString()
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        onChange={(val: string) => {
          filterReports(props.reportKeyFilter, val, dateRangeFilter[0], dateRangeFilter[1]);
        }}
        data-test-id="group-or-station-select-input">
        <Select.OptGroup label="Nodes">
          {groups.map(group => (
            <Select.Option key={group.id} value={group.id} data-test-id={`group-${group.id}`}>
              <FolderOutlined />
              &nbsp;{group.name}
            </Select.Option>
          ))}
        </Select.OptGroup>
        {isStationFilterSupported && (
          <Select.OptGroup label="Stations">
            {stations.map((id: string) => (
              <Select.Option key={id} value={id} data-test-id={`station-${id}`}>
                <DatabaseOutlined />
                &nbsp;{stationNameMap?.[id] ?? id}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}
      </Select>
    );
  };

  const reportProperties: ReportsPayload = _.pick(props, [
    'startTimeFilter',
    'endTimeFilter',
    'reportKeyFilter',
    'groupOrStationFilter',
    'nextPage',
    'executionId'
  ]);
  const buttonsDisabled: boolean =
    !props.reportKeyFilter || !props.groupOrStationFilter || props.isReportsGenerating || props.requestingExportJob;

  function canReportBeExported(reportKey: ReportKey): boolean {
    return ALL_REPORTS_LIST.some(v => v.reportKey === reportKey && v.canExport);
  }

  function isTableReport() {
    return TABLE_REPORT_LIST.some(v => v.reportKey === props.reportKeyFilter);
  }

  const showDateRangeFilter = !['current-status', 'current-user-reservations', 'current-station-connectivity'].includes(
    props.reportKeyFilter
  );

  return (
    <div className="reports-section">
      <ExportReportModal visible={props.exportReportModalShown} unloadModal={props.hideExportReportModal} />
      <h4>Reports</h4>
      <div className="section-container">
        <Skeleton loading={!groupHierarchy} active>
          <div className="reports-filter-actions">
            <div className="filter-action report-type-filter">
              <Select
                value={props.reportKeyFilter}
                placeholder="Select report type"
                disabled={props.isReportsGenerating || props.requestingExportJob}
                onChange={(val: ReportKey) => {
                  filterReports(val, props.groupOrStationFilter, dateRangeFilter[0], dateRangeFilter[1]);
                }}
                data-test-id="report-type-select-input">
                {ALL_REPORTS_LIST.map((val, key) => (
                  <Select.Option value={val.reportKey} key={key}>
                    {val.reportName}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="filter-action group-station-filter">
              {groupHierarchy && generateHierarchySelect(groupHierarchy)}
            </div>
            {showDateRangeFilter && (
              <div className="filter-action date-range-filter">
                <MonthPicker
                  className="month-picker"
                  allowClear={false}
                  open={isDateRangePickerOpen && dateRangePickerType === 'month'}
                  onOpenChange={setIsDateRangePickerOpen}
                  format="MMM YYYY"
                  value={moment(props.startTimeFilter)}
                  disabled={props.isReportsGenerating || props.requestingExportJob}
                  onChange={(value: Moment | null) => {
                    /* istanbul ignore else */
                    if (value) {
                      const startDate: Moment = moment(value)
                        .startOf('month')
                        .startOf('day');
                      const endDate: Moment = moment(value)
                        .endOf('month')
                        .endOf('day');
                      setDateRangeFilter([startDate, endDate]);
                      filterReports(props.reportKeyFilter, props.groupOrStationFilter, startDate, endDate);
                    }
                  }}
                  renderExtraFooter={() => renderDateRangeTypePicker()}
                />
                <RangePicker
                  allowClear={false}
                  open={isDateRangePickerOpen && dateRangePickerType === 'custom'}
                  onOpenChange={setIsDateRangePickerOpen}
                  separator="to"
                  format="MMM D YYYY"
                  value={[moment(props.startTimeFilter), moment(props.endTimeFilter)]}
                  disabled={props.isReportsGenerating || props.requestingExportJob}
                  onChange={(values: [Moment, Moment]) => {
                    const startDate: Moment = moment(values[0]).startOf('day');
                    const endDate: Moment = moment(values[1]).endOf('day');
                    setDateRangeFilter([startDate, endDate]);
                    filterReports(props.reportKeyFilter, props.groupOrStationFilter, startDate, endDate);
                  }}
                  renderExtraFooter={() => renderDateRangeTypePicker()}
                  disabledDate={disabledCustomerReportDates}
                />
              </div>
            )}
          </div>
          <div className="reports-button-actions">
            <Button
              className="btn-primary"
              onClick={() => props.generateReport(reportProperties)}
              disabled={buttonsDisabled}>
              {props.isReportsGenerating ? <LoadingOutlined /> : <FileSearchOutlined />}
              Generate report
            </Button>
            {canReportBeExported(props.reportKeyFilter) && (
              <Button
                className="btn-primary"
                onClick={() => props.exportReport(reportProperties)}
                disabled={buttonsDisabled}>
                {props.requestingExportJob ? <LoadingOutlined /> : <ExportOutlined />}
                Export to CSV
              </Button>
            )}
          </div>
          {isTableReport() && (
            <TableReport
              pageSize={PAGE_SIZE}
              reportProperties={reportProperties}
              accountId={accountId}
              fetchError={props.fetchError}
              isReportsGenerating={props.isReportsGenerating}
              isPageLoading={props.isPageLoading}
              nextPage={props.nextPage}
              reportData={props.reportData}
              columns={TABLE_REPORT_LIST.find(val => val.reportKey === props.reportKeyFilter)?.columns}
              fetchNextReportPage={props.fetchNextReportPage}
            />
          )}
          {props.reportKeyFilter === 'popular-usage-times' && (
            <PopularUsageTimesReport
              isReportsGenerating={props.isReportsGenerating}
              reportData={props.reportData}
              reportKeyFilter={props.reportKeyFilter}
            />
          )}
        </Skeleton>
      </div>
    </div>
  );
});
