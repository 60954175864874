import React from 'react';
import { Result, Skeleton } from 'antd';
import { AdminClaim } from '../../api/claims-types';
import { useHasClaim } from '../../helpers/claims';

interface Props {
  requiredClaim: AdminClaim;
  entityName: string;
  children: React.ReactNode;
}

export const IfHasClaim = (props: Props) => {
  const [claimFound, isClaimsPending] = useHasClaim(props.requiredClaim);
  return (
    <>
      {isClaimsPending ? (
        <Skeleton active />
      ) : claimFound ? (
        props.children
      ) : (
        <Result status="warning" title={`You do not have access to this ${props.entityName}.`} />
      )}
    </>
  );
};
