import { takeEvery, call, put, all, takeLatest, select } from 'redux-saga/effects';
import * as ActionTypes from '../actionTypes';
import {
  UpdateStationStatusAction,
  FetchStationAction,
  FetchStationSuccessAction,
  UpdateStationNameAction,
  UpdateStationNameSuccessAction,
  UpdateStationNameErrorAction,
  ChangeStationAccessModeAction,
  ToggleStationAccessModePendingAction,
  ToggleStationAccessModeOverrideAction,
  ToggleStationAccessModeOverrideSuccessAction,
  ToggleStationAccessModeOverridePendingAction,
  ChangeStationAccessModeSuccessAction,
  FetchGroupAction,
  FetchGroupSuccessAction,
  ToggleGroupAccessModeOverrideAction,
  ToggleGroupAccessModeOverridePendingAction,
  ToggleGroupAccessModeOverrideSuccessAction,
  ToggleGroupAccessModePendingAction,
  ChangeGroupAccessModeAction,
  ChangeGroupAccessModeSuccessAction,
  ChangeGroupBayOfflineWhenAccessedAction,
  ChangeGroupBayOfflineWhenAccessedSuccessAction,
  ToggleGroupBayOfflineWhenAccessedOverrideAction,
  ToggleGroupBayOfflineWhenAccessedOverrideSuccessAction,
  ChangeStationBayOfflineWhenAccessedAction,
  ChangeStationBayOfflineWhenAccessedSuccessAction,
  ToggleStationBayOfflineWhenAccessedOverrideAction,
  ToggleStationBayOfflineWhenAccessedOverrideSuccessAction,
  UpdateBayNameSuccessAction,
  UpdateBayNameErrorAction,
  ChangeGroupTimezoneAction,
  ChangeGroupTimezoneSuccessAction,
  ToggleGroupTimezoneOverrideSuccessAction,
  UpdateNetworkSettingsAction,
  UpdateNetworkSettingsErrorAction,
  UpdateNetworkSettingsSuccessAction,
  ToggleGroupTimezoneOverrideAction,
  FetchGroupOverviewAction,
  FetchGroupOverviewSuccessAction,
  UpdateGroupNameSuccessAction,
  UpdateGroupNameAction,
  UpdateGroupNameErrorAction,
  RestartStationAction,
  RestartStationErrorAction,
  RestartStationSuccessAction,
  FactoryResetStationAction,
  FactoryResetStationSuccessAction,
  ToggleBayOfflineAction,
  ToggleBayOfflineSuccessAction,
  UnlockBaySuccessAction,
  UnlockBayAction,
  ChangeBayBayOfflineWhenAccessedAction,
  ChangeBayBayOfflineWhenAccessedSuccessAction,
  ToggleBayBayOfflineWhenAccessedOverrideAction,
  ToggleBayBayOfflineWhenAccessedOverrideSuccessAction,
  UpdateCurfewAction,
  UpdateCurfewSuccessAction,
  UpdateCurfewErrorAction,
  ToggleStationLockdownAction,
  ToggleStationLockdownSuccessAction,
  ToggleStationLockdownErrorAction,
  RemoveBayAssignmentAction,
  SetBayAssignmentAction,
  BayAssignmentSuccessAction,
  BayAssignmentErrorAction,
  GetAvailableFirmwareListSuccessAction,
  UpdateFirmwareAction,
  UpdateFirmwareSuccessAction,
  UpdateFirmwareErrorAction,
  CreateSubGroupAction,
  CreateSubGroupSuccessAction,
  CreateSubGroupErrorAction,
  DeleteSubGroupAction,
  DeleteSubGroupSuccessAction,
  DeleteSubGroupErrorAction,
  ToggleGroupAutomaticFirmwareUpdateAction,
  ToggleGroupAutomaticFirmwareUpdateSuccessAction,
  ToggleGroupAutomaticFirmwareUpdateOverrideAction,
  ToggleGroupAutomaticFirmwareUpdateOverrideSuccessAction,
  UpdateGroupTagsAction,
  UpdateGroupTagsSuccessAction,
  UpdateStationTagsAction,
  UpdateStationTagsSuccessAction,
  UpdateBayNameAction,
  UpdateBayTagsAction,
  UpdateBayTagsSuccessAction
} from './types';
import { createApi } from '../../api/sdk';
import { StationApi, GroupApi, BayApi, GroupConfigUpdateDto, StationConfigUpdateDto } from '@pclocs/platform-sdk';
import {
  FetchGroupHierarchyAction,
  RedirectAction,
  ShowNotificationMessageAction,
  ShowNotificationModalAction
} from '../common/types';
import _ from 'lodash';
import { replaceSecretsInNetworkSettings } from '../../helpers/secrets-helper';
import { WebsocketIncomingMessageAction } from '../websocket/reducer';

export function* applyStationStatusUpdates() {
  yield takeEvery(ActionTypes.WEBSOCKET_INCOMING_MESSAGE, function*(action: WebsocketIncomingMessageAction) {
    const payload = action.payload;
    switch (payload.type) {
      case 'station':
        yield put<UpdateStationStatusAction>({
          type: ActionTypes.UPDATE_STATION_STATUS,
          payload: {
            id: payload.id,
            data: payload.data as UpdateStationStatusAction['payload']['data']
          }
        });
        break;
    }
  });
}

export function* fetchGroup() {
  yield takeLatest(ActionTypes.FETCH_GROUP, function*(action: FetchGroupAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<GroupApi['findOne']> = yield call(api.findOne, action.payload.id);
      yield put<FetchGroupSuccessAction>({
        type: ActionTypes.FETCH_GROUP_SUCCESS,
        payload: {
          id: action.payload.id,
          data: response.data
        }
      });
      return response;
    } catch (e) {
      yield put({
        type: ActionTypes.FETCH_GROUP_FAILED,
        payload: {
          id: action.payload.id,
          statusCode: e.response?.status
        }
      });
      console.error(e);
    }
  });
}

export function* fetchGroupOverview() {
  yield takeLatest(ActionTypes.FETCH_GROUP_OVERVIEW, function*(action: FetchGroupOverviewAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<GroupApi['getGroupOverview']> = yield call(api.getGroupOverview, action.payload.id);
      yield put<FetchGroupOverviewSuccessAction>({
        type: ActionTypes.FETCH_GROUP_OVERVIEW_SUCCESS,
        payload: {
          data: response.data
        }
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  });
}

export function* updateGroupName() {
  yield takeLatest(ActionTypes.UPDATE_GROUP_NAME, function*(action: UpdateGroupNameAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<typeof api.updateOne> = yield call(api.updateOne, action.payload.id, {
        name: action.payload.value
      });
      yield put<FetchGroupHierarchyAction>({
        type: ActionTypes.FETCH_GROUP_HIERARCHY,
        payload: { forceUpdate: true }
      });
      yield put<UpdateGroupNameSuccessAction>({
        type: ActionTypes.UPDATE_GROUP_NAME_SUCCESS,
        payload: {
          id: action.payload.id,
          group: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<UpdateGroupNameErrorAction>({ type: ActionTypes.UPDATE_GROUP_NAME_ERROR });
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'error', content: 'Updating name failed.' }
      });
    }
  });
}

export function* updateGroupTags() {
  yield takeLatest(ActionTypes.UPDATE_GROUP_TAGS, function*(action: UpdateGroupTagsAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<typeof api.updateOne> = yield call(api.updateOne, action.payload.id, {
        tags: action.payload.value
      });
      yield put<UpdateGroupTagsSuccessAction>({
        type: ActionTypes.UPDATE_GROUP_TAGS_SUCCESS,
        payload: {
          id: action.payload.id,
          group: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'error', content: 'Updating tags failed.' }
      });
    }
  });
}

export function* fetchStation() {
  yield takeLatest(ActionTypes.FETCH_STATION, function*(action: FetchStationAction) {
    try {
      yield put({ type: ActionTypes.CLEAR_STATION });
      const api = createApi<StationApi>('StationApi');
      const response: ApiReturnType<StationApi['findOne']> = yield call(api.findOne, action.payload.id);
      // Manually type this response for now
      yield put<FetchStationSuccessAction>({
        type: ActionTypes.FETCH_STATION_SUCCESS,
        payload: {
          id: action.payload.id,
          data: response.data
        }
      });
      return response;
    } catch (e) {
      yield put({
        type: ActionTypes.FETCH_STATION_FAILED,
        payload: {
          id: action.payload.id,
          statusCode: e.response?.status
        }
      });
      console.error(e);
    }
  });
}

export function* updateStationName() {
  yield takeLatest(ActionTypes.UPDATE_STATION_NAME, function*(action: UpdateStationNameAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      const response: ApiReturnType<typeof api.updateOne> = yield call(api.updateOne, action.payload.id, {
        name: action.payload.value
      });
      yield put<UpdateStationNameSuccessAction>({
        type: ActionTypes.UPDATE_STATION_NAME_SUCCESS,
        payload: {
          id: action.payload.id,
          station: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<UpdateStationNameErrorAction>({ type: ActionTypes.UPDATE_STATION_NAME_ERROR });
    }
  });
}

export function* updateStationTags() {
  yield takeLatest(ActionTypes.UPDATE_STATION_TAGS, function*(action: UpdateStationTagsAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      const response: ApiReturnType<typeof api.updateOne> = yield call(api.updateOne, action.payload.id, {
        tags: action.payload.value
      });
      yield put<UpdateStationTagsSuccessAction>({
        type: ActionTypes.UPDATE_STATION_TAGS_SUCCESS,
        payload: {
          id: action.payload.id,
          station: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'error', content: 'Updating tags failed.' }
      });
    }
  });
}

export function* updateBayName() {
  yield takeLatest(ActionTypes.UPDATE_BAY_NAME, function*(action: UpdateBayNameAction) {
    try {
      const api = createApi<BayApi>('BayApi');
      const response: ApiReturnType<typeof api.updateOne> = yield call(api.updateOne, action.payload.id, {
        name: action.payload.value
      });
      yield put<UpdateBayNameSuccessAction>({
        type: ActionTypes.UPDATE_BAY_NAME_SUCCESS,
        payload: {
          id: action.payload.id,
          bay: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<UpdateBayNameErrorAction>({ type: ActionTypes.UPDATE_BAY_NAME_ERROR });
    }
  });
}

export function* updateBayTags() {
  yield takeLatest(ActionTypes.UPDATE_BAY_TAGS, function*(action: UpdateBayTagsAction) {
    try {
      const api = createApi<BayApi>('BayApi');
      const response: ApiReturnType<typeof api.updateOne> = yield call(api.updateOne, action.payload.id, {
        tags: action.payload.value
      });
      yield put<UpdateBayTagsSuccessAction>({
        type: ActionTypes.UPDATE_BAY_TAGS_SUCCESS,
        payload: {
          id: action.payload.id,
          bay: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'error', content: 'Updating tags failed.' }
      });
    }
  });
}

export function* changeStationAccessMode() {
  yield takeLatest(ActionTypes.CHANGE_STATION_ACCESS_MODE, function*(action: ChangeStationAccessModeAction) {
    try {
      // turn pending spinners on
      yield put<ToggleStationAccessModePendingAction>({
        type: ActionTypes.TOGGLE_STATION_ACCESS_MODE_PENDING,
        payload: { value: true }
      });
      const api = createApi<StationApi>('StationApi');
      const response = yield call(api.updateConfig, action.payload.id, {
        managed: action.payload.managed
      });
      yield put<ChangeStationAccessModeSuccessAction>({
        type: ActionTypes.CHANGE_STATION_ACCESS_MODE_SUCCESS,
        payload: {
          managed: response.data.config.managed
        }
      });
    } catch (e) {
      console.error(e);
      // turn pending spinners off
      yield put<ToggleStationAccessModePendingAction>({
        type: ActionTypes.TOGGLE_STATION_ACCESS_MODE_PENDING,
        payload: { value: false }
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: `Error changing access mode`
        }
      });
    }
  });
}

export function* toggleStationAccessModeOverride() {
  yield takeLatest(ActionTypes.TOGGLE_STATION_ACCESS_MODE_OVERRIDE, function*(
    action: ToggleStationAccessModeOverrideAction
  ) {
    try {
      // turn pending spinners on
      yield put<ToggleStationAccessModeOverridePendingAction>({
        type: ActionTypes.TOGGLE_STATION_ACCESS_MODE_OVERRIDE_PENDING,
        payload: { value: true }
      });
      const api = createApi<StationApi>('StationApi');
      const response = yield call(api.updateConfig, action.payload.id, {
        managed: action.payload.value
      });
      yield put<ToggleStationAccessModeOverrideSuccessAction>({
        type: ActionTypes.TOGGLE_STATION_ACCESS_MODE_OVERRIDE_SUCCESS,
        payload: {
          configManaged: response.data.config.managed,
          derivedManaged: response.data.derivedConfig.managed
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ToggleStationAccessModeOverridePendingAction>({
        type: ActionTypes.TOGGLE_STATION_ACCESS_MODE_OVERRIDE_PENDING,
        payload: { value: false }
      });
    }
  });
}

export function* changeGroupAccessMode() {
  yield takeLatest(ActionTypes.CHANGE_GROUP_ACCESS_MODE, function*(action: ChangeGroupAccessModeAction) {
    try {
      // turn pending spinners on
      yield put<ToggleGroupAccessModePendingAction>({
        type: ActionTypes.TOGGLE_GROUP_ACCESS_MODE_PENDING,
        payload: { value: true }
      });
      const api = createApi<GroupApi>('GroupApi');
      const response = yield call(api.updateConfig, action.payload.id, {
        managed: action.payload.managed
      });
      yield put<ChangeGroupAccessModeSuccessAction>({
        type: ActionTypes.CHANGE_GROUP_ACCESS_MODE_SUCCESS,
        payload: {
          managed: response.data.config.managed
        }
      });
    } catch (e) {
      console.error(e);
      // turn pending spinners off
      yield put<ToggleGroupAccessModePendingAction>({
        type: ActionTypes.TOGGLE_GROUP_ACCESS_MODE_PENDING,
        payload: { value: false }
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: `Error changing access mode`
        }
      });
    }
  });
}

export function* toggleGroupAccessModeOverride() {
  yield takeLatest(ActionTypes.TOGGLE_GROUP_ACCESS_MODE_OVERRIDE, function*(
    action: ToggleGroupAccessModeOverrideAction
  ) {
    try {
      // turn pending spinners on
      yield put<ToggleGroupAccessModeOverridePendingAction>({
        type: ActionTypes.TOGGLE_GROUP_ACCESS_MODE_OVERRIDE_PENDING,
        payload: { value: true }
      });
      const api = createApi<GroupApi>('GroupApi');
      const response = yield call(api.updateConfig, action.payload.id, {
        managed: action.payload.value
      });
      yield put<ToggleGroupAccessModeOverrideSuccessAction>({
        type: ActionTypes.TOGGLE_GROUP_ACCESS_MODE_OVERRIDE_SUCCESS,
        payload: {
          configManaged: response.data.config.managed,
          derivedManaged: response.data.derivedConfig.managed
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ToggleGroupAccessModeOverridePendingAction>({
        type: ActionTypes.TOGGLE_GROUP_ACCESS_MODE_OVERRIDE_PENDING,
        payload: { value: false }
      });
    }
  });
}

export function* changeGroupBayOfflineWhenAccessed() {
  yield takeLatest(ActionTypes.CHANGE_GROUP_BAY_OFFLINE_WHEN_ACCESSED, function*(
    action: ChangeGroupBayOfflineWhenAccessedAction
  ) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<typeof api.updateConfig> = yield call(api.updateConfig, action.payload.id, {
        takeBayOfflineWhenAccessed: action.payload.value
      });
      yield put<ChangeGroupBayOfflineWhenAccessedSuccessAction>({
        type: ActionTypes.CHANGE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS,
        payload: {
          group: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: `Error changing bay offline when accessed setting`
        }
      });
    }
  });
}

export function* toggleGroupBayOfflineWhenAccessedOverride() {
  yield takeLatest(ActionTypes.TOGGLE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE, function*(
    action: ToggleGroupBayOfflineWhenAccessedOverrideAction
  ) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<typeof api.updateConfig> = yield call(api.updateConfig, action.payload.id, {
        takeBayOfflineWhenAccessed: action.payload.value
      });
      yield put<ToggleGroupBayOfflineWhenAccessedOverrideSuccessAction>({
        type: ActionTypes.TOGGLE_GROUP_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS,
        payload: {
          group: response.data
        }
      });
    } catch (e) {
      console.error(e);
    }
  });
}

export function* changeStationBayOfflineWhenAccessed() {
  yield takeLatest(ActionTypes.CHANGE_STATION_BAY_OFFLINE_WHEN_ACCESSED, function*(
    action: ChangeStationBayOfflineWhenAccessedAction
  ) {
    try {
      const api = createApi<StationApi>('StationApi');
      const response: ApiReturnType<typeof api.updateConfig> = yield call(api.updateConfig, action.payload.id, {
        takeBayOfflineWhenAccessed: action.payload.value
      });
      yield put<ChangeStationBayOfflineWhenAccessedSuccessAction>({
        type: ActionTypes.CHANGE_STATION_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS,
        payload: {
          station: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: `Error changing bay offline when accessed setting`
        }
      });
    }
  });
}

export function* changeStationBayOfflineWhenAccessedOverride() {
  yield takeLatest(ActionTypes.TOGGLE_STATION_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE, function*(
    action: ToggleStationBayOfflineWhenAccessedOverrideAction
  ) {
    try {
      const api = createApi<StationApi>('StationApi');
      const response: ApiReturnType<typeof api.updateConfig> = yield call(api.updateConfig, action.payload.id, {
        takeBayOfflineWhenAccessed: action.payload.value
      });
      yield put<ToggleStationBayOfflineWhenAccessedOverrideSuccessAction>({
        type: ActionTypes.TOGGLE_STATION_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS,
        payload: {
          station: response.data
        }
      });
    } catch (e) {
      console.error(e);
    }
  });
}

export function* changeBayBayOfflineWhenAccessed() {
  yield takeLatest(ActionTypes.CHANGE_BAY_BAY_OFFLINE_WHEN_ACCESSED, function*(
    action: ChangeBayBayOfflineWhenAccessedAction
  ) {
    try {
      const api = createApi<BayApi>('BayApi');
      const response: ApiReturnType<typeof api.updateConfig> = yield call(api.updateConfig, action.payload.id, {
        takeBayOfflineWhenAccessed: action.payload.value
      });
      yield put<ChangeBayBayOfflineWhenAccessedSuccessAction>({
        type: ActionTypes.CHANGE_BAY_BAY_OFFLINE_WHEN_ACCESSED_SUCCESS,
        payload: {
          bay: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: `Error changing bay offline when accessed setting`
        }
      });
    }
  });
}

export function* toggleBayBayOfflineWhenAccessedOverride() {
  yield takeLatest(ActionTypes.TOGGLE_BAY_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE, function*(
    action: ToggleBayBayOfflineWhenAccessedOverrideAction
  ) {
    try {
      const api = createApi<BayApi>('BayApi');
      const response: ApiReturnType<typeof api.updateConfig> = yield call(api.updateConfig, action.payload.id, {
        takeBayOfflineWhenAccessed: action.payload.value
      });
      yield put<ToggleBayBayOfflineWhenAccessedOverrideSuccessAction>({
        type: ActionTypes.TOGGLE_BAY_BAY_OFFLINE_WHEN_ACCESSED_OVERRIDE_SUCCESS,
        payload: {
          bay: response.data
        }
      });
    } catch (e) {
      console.error(e);
    }
  });
}

export function* updateNetworkSettings() {
  yield takeLatest(ActionTypes.UPDATE_NETWORK_SETTINGS, function*(action: UpdateNetworkSettingsAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      const networkSettings = yield call(replaceSecretsInNetworkSettings, action.payload.networkSettings);
      const response = yield call(api.updateConfig, action.payload.id, { networkSettings });
      yield put<UpdateNetworkSettingsSuccessAction>({
        type: ActionTypes.UPDATE_NETWORK_SETTINGS_SUCCESS,
        payload: {
          configNetworkSettings: response.data.config.networkSettings,
          derivedNetworkSettings: response.data.derivedConfig.networkSettings
        }
      });
    } catch (e) {
      console.error(e);
      yield put<UpdateNetworkSettingsErrorAction>({
        type: ActionTypes.UPDATE_NETWORK_SETTINGS_ERROR
      });
    }
  });
}

export function* updateGroupNetworkSettings() {
  yield takeLatest(ActionTypes.UPDATE_GROUP_NETWORK_SETTINGS, function*(action: UpdateNetworkSettingsAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const networkSettings = yield call(replaceSecretsInNetworkSettings, action.payload.networkSettings);
      const response = yield call(api.updateConfig, action.payload.id, { networkSettings });
      yield put<UpdateNetworkSettingsSuccessAction>({
        type: ActionTypes.UPDATE_GROUP_NETWORK_SETTINGS_SUCCESS,
        payload: {
          configNetworkSettings: response.data.config.networkSettings,
          derivedNetworkSettings: response.data.derivedConfig.networkSettings
        }
      });
    } catch (e) {
      console.error(e);
      yield put<UpdateNetworkSettingsErrorAction>({
        type: ActionTypes.UPDATE_NETWORK_SETTINGS_ERROR
      });
    }
  });
}

export function* changeGroupTimezone() {
  yield takeLatest(ActionTypes.CHANGE_GROUP_TIMEZONE, function*(action: ChangeGroupTimezoneAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<typeof api.updateConfig> = yield call(api.updateConfig, action.payload.id, {
        timezone: action.payload.value
      });
      yield put<ChangeGroupTimezoneSuccessAction>({
        type: ActionTypes.CHANGE_GROUP_TIMEZONE_SUCCESS,
        payload: {
          group: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: `Error changing the group timezone`
        }
      });
    }
  });
}

export function* toggleGroupTimezoneOverride() {
  yield takeLatest(ActionTypes.TOGGLE_GROUP_TIMEZONE_OVERRIDE, function*(action: ToggleGroupTimezoneOverrideAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<typeof api.updateConfig> = yield call(api.updateConfig, action.payload.id, {
        timezone: action.payload.value
      });
      yield put<ToggleGroupTimezoneOverrideSuccessAction>({
        type: ActionTypes.TOGGLE_GROUP_TIMEZONE_OVERRIDE_SUCCESS,
        payload: {
          group: response.data
        }
      });
    } catch (e) {
      console.error(e);
    }
  });
}

export function* restartStation() {
  yield takeLatest(ActionTypes.RESTART_STATION, function*(action: RestartStationAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      yield call(api.action, action.payload.id, {
        restart: true
      });

      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: 'Restart has been initiated on the station.' }
      });
      yield put<RestartStationSuccessAction>({
        type: ActionTypes.RESTART_STATION_SUCCESS
      });
    } catch (e) {
      console.error(e.toString());
      yield put<RestartStationErrorAction>({ type: ActionTypes.RESTART_STATION_ERROR });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Restart station could not be initiated.'
        }
      });
    }
  });
}

export function* toggleBayOffline() {
  yield takeLatest(ActionTypes.TOGGLE_BAY_OFFLINE, function*(action: ToggleBayOfflineAction) {
    try {
      const api = createApi<BayApi>('BayApi');
      yield call(api.action, action.payload.id, {
        toggleOffline: action.payload.offline
      });
      yield put<ToggleBayOfflineSuccessAction>({
        type: ActionTypes.TOGGLE_BAY_OFFLINE_SUCCESS,
        payload: { id: action.payload.id }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Failed to set bay offline.'
        }
      });
    }
  });
}

export function* unlockBay() {
  yield takeLatest(ActionTypes.UNLOCK_BAY, function*(action: UnlockBayAction) {
    try {
      const api = createApi<BayApi>('BayApi');
      yield call(api.action, action.payload.id, {
        unlock: true
      });
      yield put<UnlockBaySuccessAction>({
        type: ActionTypes.UNLOCK_BAY_SUCCESS,
        payload: { id: action.payload.id }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Failed to unlock the bay.'
        }
      });
    }
  });
}

export function* updateCurfew() {
  yield takeLatest(ActionTypes.UPDATE_CURFEW, function*(action: UpdateCurfewAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      const response = yield call(
        api.updateConfig,
        action.payload.id,
        action.payload.curfew === null
          ? (({ curfew: null } as unknown) as StationConfigUpdateDto)
          : {
              curfew: {
                enabled: action.payload.curfew.enabled || false,
                schedule: _.flatten(_.values(action.payload.curfew.schedule))
              }
            }
      );
      yield put<UpdateCurfewSuccessAction>({
        type: ActionTypes.UPDATE_CURFEW_SUCCESS,
        payload: { curfew: response.data.config.curfew, derivedCurfew: response.data.derivedConfig.curfew }
      });
    } catch (e) {
      console.error(e);
      yield put<UpdateCurfewErrorAction>({
        type: ActionTypes.UPDATE_CURFEW_ERROR
      });
    }
  });
}

export function* updateGroupCurfew() {
  yield takeLatest(ActionTypes.UPDATE_GROUP_CURFEW, function*(action: UpdateCurfewAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response = yield call(
        api.updateConfig,
        action.payload.id,
        action.payload.curfew === null
          ? (({ curfew: null } as unknown) as GroupConfigUpdateDto)
          : {
              curfew: {
                enabled: action.payload.curfew.enabled || false,
                schedule: _.flatten(_.values(action.payload.curfew.schedule))
              }
            }
      );
      yield put<UpdateCurfewSuccessAction>({
        type: ActionTypes.UPDATE_GROUP_CURFEW_SUCCESS,
        payload: { curfew: response.data.config.curfew, derivedCurfew: response.data.derivedConfig.curfew }
      });
    } catch (e) {
      console.error(e);
      yield put<UpdateCurfewErrorAction>({
        type: ActionTypes.UPDATE_CURFEW_ERROR
      });
    }
  });
}

export function* factoryResetStation() {
  yield takeLatest(ActionTypes.FACTORY_RESET_STATION, function*(action: FactoryResetStationAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      const accountId = yield select(state => state.auth.accountContext);
      yield call(api.action, action.payload.id, {
        factoryReset: true
      });
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: 'Factory reset has been initiated on the station.' }
      });
      yield put<FactoryResetStationSuccessAction>({
        type: ActionTypes.FACTORY_RESET_STATION_SUCCESS
      });
      yield put<FetchGroupHierarchyAction>({
        type: ActionTypes.FETCH_GROUP_HIERARCHY,
        payload: { forceUpdate: true }
      });
      yield put<RedirectAction>({
        type: ActionTypes.REDIRECT,
        redirectTo: `/auth/${accountId}/dashboard`
      });
    } catch (e) {
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Factory reset could not be initiated.'
        }
      });
    }
  });
}

export function* removeBayAssignment() {
  yield takeLatest(ActionTypes.REMOVE_BAY_ASSIGNMENT, function*(action: RemoveBayAssignmentAction) {
    try {
      const api = createApi<BayApi>('BayApi');
      yield call(api.action, action.payload.bayId, {
        setBayAssignment: { credentials: false }
      });
      yield put<BayAssignmentSuccessAction>({
        type: ActionTypes.REMOVE_BAY_ASSIGNMENT_SUCCESS,
        payload: { bayId: action.payload.bayId }
      });
    } catch (e) {
      console.error(e);
      yield put<BayAssignmentErrorAction>({
        type: ActionTypes.REMOVE_BAY_ASSIGNMENT_ERROR
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Could not remove bay assignment.'
        }
      });
    }
  });
}

export function* setBayAssignment() {
  yield takeLatest(ActionTypes.SET_BAY_ASSIGNMENT, function*(action: SetBayAssignmentAction) {
    try {
      const api = createApi<BayApi>('BayApi');
      yield call(api.action, action.payload.bayId, {
        setBayAssignment: { credentials: action.payload.credentials }
      });
      yield put<BayAssignmentSuccessAction>({
        type: ActionTypes.SET_BAY_ASSIGNMENT_SUCCESS,
        payload: { bayId: action.payload.bayId }
      });
    } catch (e) {
      console.error(e);
      yield put<BayAssignmentErrorAction>({
        type: ActionTypes.SET_BAY_ASSIGNMENT_ERROR
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Could not set bay assignment.'
        }
      });
    }
  });
}

export function* toggleStationLockdown() {
  yield takeLatest(ActionTypes.TOGGLE_STATION_LOCKDOWN, function*(action: ToggleStationLockdownAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      yield call(api.action, action.payload.id, {
        lockdown: action.payload.lockdown
      });
      yield put<ToggleStationLockdownSuccessAction>({
        type: ActionTypes.TOGGLE_STATION_LOCKDOWN_SUCCESS
      });
    } catch (e) {
      yield put<ToggleStationLockdownErrorAction>({ type: ActionTypes.TOGGLE_STATION_LOCKDOWN_ERROR });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Failed to set station lockdown.'
        }
      });
    }
  });
}

export function* createSubGroup() {
  yield takeLatest(ActionTypes.CREATE_SUB_GROUP, function*(action: CreateSubGroupAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      yield call(api.createSubGroup, action.payload.id);
      yield put<FetchGroupHierarchyAction>({
        type: ActionTypes.FETCH_GROUP_HIERARCHY,
        payload: { forceUpdate: true }
      });
      yield put<CreateSubGroupSuccessAction>({
        type: ActionTypes.CREATE_SUB_GROUP_SUCCESS
      });
    } catch (e) {
      yield put<CreateSubGroupErrorAction>({ type: ActionTypes.CREATE_SUB_GROUP_ERROR });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Could not create sub-group in this group.'
        }
      });
    }
  });
}

export function* deleteSubGroup() {
  yield takeLatest(ActionTypes.DELETE_SUB_GROUP, function*(action: DeleteSubGroupAction) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const accountId = yield select(state => state.auth.accountContext);
      yield call(api.deleteGroup, action.payload.id);
      yield put<FetchGroupHierarchyAction>({
        type: ActionTypes.FETCH_GROUP_HIERARCHY,
        payload: { forceUpdate: true }
      });
      yield put<DeleteSubGroupSuccessAction>({
        type: ActionTypes.DELETE_SUB_GROUP_SUCCESS
      });
      yield put<RedirectAction>({
        type: ActionTypes.REDIRECT,
        redirectTo: `/auth/${accountId}/dashboard`
      });
    } catch (e) {
      yield put<DeleteSubGroupErrorAction>({ type: ActionTypes.DELETE_SUB_GROUP_ERROR });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: e.response.data.message[0].constraints.forbidden || 'Could not delete this group.'
        }
      });
    }
  });
}

export function* getAvailableFirmwareList() {
  yield takeLatest(ActionTypes.GET_AVAILABLE_FIRMWARE_LIST, function*() {
    try {
      const api = createApi<StationApi>('StationApi');
      const response: ApiReturnType<StationApi['getFirmwareUpdateList']> = yield call(api.getFirmwareUpdateList);
      yield put<GetAvailableFirmwareListSuccessAction>({
        type: ActionTypes.GET_AVAILABLE_FIRMWARE_LIST_SUCCESS,
        payload: {
          firmwareList: response.data
        }
      });
    } catch (e) {
      console.error(e);
    }
  });
}

export function* updateFirmware() {
  yield takeLatest(ActionTypes.UPDATE_FIRMWARE, function*(action: UpdateFirmwareAction) {
    try {
      const api = createApi<StationApi>('StationApi');
      yield call(api.updateToLatestFirmware, action.payload.id);
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: 'Firmware update has been initiated on the station.' }
      });
      yield put<UpdateFirmwareSuccessAction>({
        type: ActionTypes.UPDATE_FIRMWARE_SUCCESS
      });
    } catch (e) {
      console.error(e);
      yield put<UpdateFirmwareErrorAction>({ type: ActionTypes.UPDATE_FIRMWARE_ERROR });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Failed to trigger firmware update on the station.'
        }
      });
    }
  });
}

export function* toggleGroupAutomaticFirmwareUpdate() {
  yield takeLatest(ActionTypes.TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE, function*(
    action: ToggleGroupAutomaticFirmwareUpdateAction
  ) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<typeof api.updateConfig> = yield call(api.updateConfig, action.payload.id, {
        firmwareUpdateSchedule: action.payload.firmwareUpdateSchedule
      });
      yield put<ToggleGroupAutomaticFirmwareUpdateSuccessAction>({
        type: ActionTypes.TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_SUCCESS,
        payload: {
          group: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: `Error changing automatic firmware update setting`
        }
      });
    }
  });
}

export function* toggleGroupAutomaticFirmwareUpdateOverride() {
  yield takeLatest(ActionTypes.TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE, function*(
    action: ToggleGroupAutomaticFirmwareUpdateOverrideAction
  ) {
    try {
      const api = createApi<GroupApi>('GroupApi');
      const response: ApiReturnType<typeof api.updateConfig> = yield call(api.updateConfig, action.payload.id, ({
        firmwareUpdateSchedule: action.payload.firmwareUpdateSchedule
      } as unknown) as GroupConfigUpdateDto);
      yield put<ToggleGroupAutomaticFirmwareUpdateOverrideSuccessAction>({
        type: ActionTypes.TOGGLE_GROUP_AUTOMATIC_FIRMWARE_UPDATE_OVERRIDE_SUCCESS,
        payload: {
          group: response.data
        }
      });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: `Error changing automatic firmware update override setting`
        }
      });
    }
  });
}

export function* dashboardSaga() {
  yield all([
    applyStationStatusUpdates(),
    fetchGroup(),
    fetchGroupOverview(),
    updateGroupName(),
    updateGroupTags(),
    fetchStation(),
    updateStationName(),
    updateStationTags(),
    updateBayName(),
    updateBayTags(),
    changeStationAccessMode(),
    toggleStationAccessModeOverride(),
    changeGroupAccessMode(),
    toggleGroupAccessModeOverride(),
    changeGroupBayOfflineWhenAccessed(),
    toggleGroupBayOfflineWhenAccessedOverride(),
    changeStationBayOfflineWhenAccessed(),
    changeStationBayOfflineWhenAccessedOverride(),
    changeBayBayOfflineWhenAccessed(),
    toggleBayBayOfflineWhenAccessedOverride(),
    changeGroupTimezone(),
    toggleGroupTimezoneOverride(),
    updateNetworkSettings(),
    updateGroupNetworkSettings(),
    restartStation(),
    factoryResetStation(),
    toggleBayOffline(),
    unlockBay(),
    updateCurfew(),
    updateGroupCurfew(),
    removeBayAssignment(),
    setBayAssignment(),
    toggleStationLockdown(),
    createSubGroup(),
    deleteSubGroup(),
    getAvailableFirmwareList(),
    updateFirmware(),
    toggleGroupAutomaticFirmwareUpdate(),
    toggleGroupAutomaticFirmwareUpdateOverride()
  ]);
}
