import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DatePicker, Radio, Select } from 'antd';
import Icon, {
  ApiOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio';
import { RootState } from '../../store/rootReducer';
import * as ActionTypes from '../../store/actionTypes';
import { EventLogsActionTypes } from '../../store/event-logs/types';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import { history } from '../../store/store';
import { EventLogDto, EventLogDtoUserTypeEnum, EventLogsQueryDtoUserTypesEnum } from '@pclocs/platform-sdk';
import { AdminIcon } from '../../assets/icons/admin';
import { SelectValue } from 'antd/lib/select';
import { StructuredDescription } from './EventDescription';
import { FeatureId, useFeatureAvailability } from '../../helpers/feature-availability-hooks';
import { InfinityList } from '../InfinityList';

const { MonthPicker, RangePicker } = DatePicker;

const PAGE_SIZE = 200;

const mapStateToProps = (state: RootState) => ({
  ..._.pick(state.eventLogs, [
    'startDateFilter',
    'endDateFilter',
    'userTypeFilters',
    'searchFilters',
    'eventLogs',
    'isEventLogsLoading',
    'nextToken',
    'fetchError'
  ])
});

type UserTypeKey = keyof typeof EventLogsQueryDtoUserTypesEnum;

const mapDispatchToProps = (dispatch: Dispatch<EventLogsActionTypes>) => ({
  onLoad: () => dispatch({ type: ActionTypes.EVENT_LOGS_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ActionTypes.EVENT_LOGS_PAGE_UNLOADED }),
  findEventLogs: () => dispatch({ type: ActionTypes.FIND_EVENT_LOGS, payload: { pageSize: PAGE_SIZE } }),
  filter: (searchFilters: string[], userType: UserTypeKey | 'all', startDate?: Moment, endDate?: Moment) => {
    dispatch({
      type: ActionTypes.FILTER_EVENT_LOGS_BY_PROPERTY,
      payload: {
        startDateFilter: moment(startDate).toISOString(),
        endDateFilter: moment(endDate).toISOString(),
        userTypeFilters: userType === 'all' ? undefined : [EventLogsQueryDtoUserTypesEnum[userType]],
        ...(searchFilters?.length && { searchFilters })
      }
    });
    dispatch({ type: ActionTypes.FIND_EVENT_LOGS, payload: { pageSize: PAGE_SIZE } });
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const EventLogs = connector((props: Props) => {
  const [selectedUserType, setSelectedUserType] = useState<UserTypeKey | 'all'>('all');
  const [dateRangePickerType, setDateRangePickerType] = useState<'month' | 'days'>('days');
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState<boolean>(false);
  const [dateRangeFilter, setDateRangeFilter] = useState<[Moment, Moment]>([
    moment(props.startDateFilter),
    moment(props.endDateFilter)
  ]);
  const [searchInputs, setSearchInputs] = useState<string[]>([]);
  const [hasFeature, isFeatureAvailabilityLoading] = useFeatureAvailability();

  const { accountId } = useParams<{ accountId: string }>();

  useEffect(() => {
    props.onLoad();
    props.findEventLogs();
    return () => props.onUnload();
  }, []);

  if (!isFeatureAvailabilityLoading && !hasFeature(FeatureId.EVENTLOGS)) {
    history.replace(`/auth/${accountId}/dashboard`);
  }

  const getUserIcon = (userType: EventLogDtoUserTypeEnum): JSX.Element => {
    const iconStyle = { width: 20, height: 16 };
    switch (userType) {
      case EventLogDtoUserTypeEnum.ADMIN:
        return <Icon component={() => <AdminIcon width={20} height={16} />} />;
      case EventLogDtoUserTypeEnum.APPCLIENT:
        return <ApiOutlined title={_.startCase(userType.toLowerCase())} style={iconStyle} />;
      case EventLogDtoUserTypeEnum.SYSTEM:
        return <SettingOutlined title={_.capitalize(userType)} style={iconStyle} />;
      case EventLogDtoUserTypeEnum.USER:
        return <UserOutlined title={_.capitalize(userType)} style={iconStyle} />;
    }
  };

  const renderDateRangeTypePicker = (): JSX.Element => (
    <div className="event-logs-date-range-picker-footer">
      <span>Select dates by:&nbsp;</span>
      <Radio.Group
        buttonStyle="solid"
        value={dateRangePickerType}
        onChange={e => {
          setDateRangePickerType(e.target.value);
          setIsDateRangePickerOpen(true);
        }}>
        <Radio.Button value="month">Month</Radio.Button>
        <Radio.Button value="days">Days</Radio.Button>
      </Radio.Group>
    </div>
  );

  return (
    <div className="event-logs-section">
      <h4>Event Logs</h4>
      <div className="section-container">
        <div className="event-logs-filter-actions">
          <div className="filter-action user-type-filter">
            <Radio.Group
              buttonStyle="solid"
              value={selectedUserType}
              onChange={(e: RadioChangeEvent) => {
                setSelectedUserType(e.target.value);
                props.filter(searchInputs, e.target.value, dateRangeFilter[0], dateRangeFilter[1]);
              }}>
              <Radio.Button value="all">All</Radio.Button>
              {Object.keys(EventLogDtoUserTypeEnum).map((value: string, key: number) => {
                const userType = value as UserTypeKey;
                return (
                  <Radio.Button key={key} value={userType}>
                    {_.startCase(EventLogDtoUserTypeEnum[userType].toLowerCase())}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </div>
          <div className="filter-action date-range-filter">
            <MonthPicker
              className="month-picker"
              allowClear={false}
              open={isDateRangePickerOpen && dateRangePickerType === 'month'}
              onOpenChange={setIsDateRangePickerOpen}
              format="MMM YYYY"
              value={dateRangeFilter[0]}
              onChange={(value: Moment | null) => {
                /* istanbul ignore else */
                if (value) {
                  const startDate: Moment = moment(value)
                    .startOf('month')
                    .startOf('day');
                  const endDate: Moment = moment(value)
                    .endOf('month')
                    .endOf('day');
                  setDateRangeFilter([startDate, endDate]);
                  props.filter(searchInputs, selectedUserType, startDate, endDate);
                }
              }}
              renderExtraFooter={() => renderDateRangeTypePicker()}
            />
            <RangePicker
              allowClear={false}
              open={isDateRangePickerOpen && dateRangePickerType === 'days'}
              onOpenChange={setIsDateRangePickerOpen}
              separator="to"
              format="MMM D YYYY"
              value={dateRangeFilter}
              onChange={(values: [Moment, Moment]) => {
                const startDate: Moment = moment(values[0]).startOf('day');
                const endDate: Moment = moment(values[1]).endOf('day');
                setDateRangeFilter([startDate, endDate]);
                props.filter(searchInputs, selectedUserType, startDate, endDate);
              }}
              renderExtraFooter={() => renderDateRangeTypePicker()}
            />
          </div>
          <div className="filter-action search-filter">
            <Select
              mode="tags"
              placeholder="Search event logs"
              value={searchInputs}
              allowClear
              showArrow
              filterOption={false}
              onChange={(e: SelectValue) => {
                const value: string[] = e as string[];
                setSearchInputs(value);
                props.filter(value, selectedUserType, dateRangeFilter[0], dateRangeFilter[1]);
              }}
              suffixIcon={<SearchOutlined style={{ fontSize: '16px' }} />}
              clearIcon={<CloseCircleOutlined style={{ fontSize: '18px' }} />}
              notFoundContent={null}
              style={{ width: '100%' }}
            />
          </div>
        </div>

        <div className="content-section">
          <div className="event-logs-list-header">
            <h6 className="event-logs-list-header-item">Event</h6>
            <h6 className="event-logs-list-header-item">Date (UTC{moment().format('Z')})</h6>
          </div>
          <div className="content-section-inner event-logs-list-content">
            <InfinityList
              pageSize={PAGE_SIZE}
              items={props.eventLogs}
              loading={props.isEventLogsLoading}
              hasMoreItems={!!props.nextToken}
              fetchError={props.fetchError}
              loadItems={() => props.findEventLogs()}
              render={(eventLog: EventLogDto) => {
                return (
                  <div className="event-log-list-row list-row" data-test-id={`event-log-${eventLog.timestamp}`}>
                    <div className="event-log-detail">
                      {getUserIcon(eventLog.userType)}
                      <span title={eventLog.description}>
                        <StructuredDescription accountId={accountId} description={eventLog.structuredDescription} />
                      </span>
                    </div>
                    <div className="event-log-detail">{moment(eventLog.timestamp).format('MMM D YYYY, h:mma')}</div>
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
