import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Radio, Skeleton } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { RootState } from '../../../store/rootReducer';
import * as ActionTypes from '../../../store/actionTypes';
import { ShowNotificationMessageAction } from '../../../store/common/types';
import { history } from '../../../store/store';
import { useParams } from 'react-router';
import { OidcIdentityProviderResponseDto, SamlIdentityProviderResponseDto } from '@pclocs/platform-sdk';
import { FeatureId, useFeatureAvailability } from '../../../helpers/feature-availability-hooks';
import { useLatestSdkCall } from '../../../helpers/sdk-hooks';
import { OidcIdentityProviderForm } from './oidc-form';
import { SamlIdentityProviderForm } from './saml-form';

const mapStateToProps = (state: RootState) => ({
  subscriptionInfo: state.account.subscriptionInfo
});
const mapDispatchToProps = (dispatch: Dispatch<ShowNotificationMessageAction>) => ({
  showNotification: (type: 'success' | 'error', content: string): void =>
    dispatch({ type: ActionTypes.SHOW_NOTIFICATION_MESSAGE, payload: { type, content } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const IdentityProvidersForm = connector((props: Props) => {
  const { accountId, providerName } = useParams<{ accountId: string; providerName: string }>();
  const [providerType, setProviderType] = useState<string>();
  const [identityProvider, setIdentityProvider] = useState<
    OidcIdentityProviderResponseDto | SamlIdentityProviderResponseDto
  >();
  const [hasFeature] = useFeatureAvailability();
  const hasSsoFeature = hasFeature(FeatureId.SSOIDENTITYPROVIDERS);

  const [fetchIdentityProvider, fetchingIdentityProvider] = useLatestSdkCall(
    'IdentityProviderApi',
    'getIdentityProvider',
    resp => setIdentityProvider(resp.data),
    () => props.showNotification('error', 'Could not get SSO provider.')
  );

  useEffect(() => {
    if (props.subscriptionInfo && !hasSsoFeature) {
      !providerName && history.replace(`/auth/${accountId}/dashboard`);
    }
  }, [props.subscriptionInfo]);

  useEffect(() => {
    providerName && fetchIdentityProvider(providerName);
  }, []);

  useEffect(() => {
    setProviderType(identityProvider?.providerType ?? 'OIDC');
  }, [identityProvider]);

  return providerName && !identityProvider ? (
    <Skeleton active paragraph={{ rows: 5 }} />
  ) : (
    <div className="content-section">
      <div className="custom-form-input">
        <label>Provider Type</label>
        {identityProvider ? (
          <span>{identityProvider.providerType}</span>
        ) : (
          <Radio.Group
            buttonStyle="solid"
            value={providerType}
            onChange={(e: RadioChangeEvent) => setProviderType(e.target.value)}
            disabled={!hasSsoFeature}>
            <Radio.Button key="OIDC" value="OIDC">
              OIDC
            </Radio.Button>
            <Radio.Button key="SAML" value="SAML">
              SAML
            </Radio.Button>
          </Radio.Group>
        )}
      </div>
      {providerType === 'OIDC' && (
        <OidcIdentityProviderForm
          identityProvider={identityProvider as OidcIdentityProviderResponseDto}
          fetching={fetchingIdentityProvider}
          readonly={!hasSsoFeature}
        />
      )}
      {providerType === 'SAML' && (
        <SamlIdentityProviderForm
          identityProvider={identityProvider as SamlIdentityProviderResponseDto}
          fetching={fetchingIdentityProvider}
          readonly={!hasSsoFeature}
        />
      )}
    </div>
  );
});
