import { useSelector } from 'react-redux';
import { AdminClaim } from '../api/claims-types';
import { RootState } from '../store/rootReducer';

export { AdminClaimEnum, AdminClaimWithIdEnum } from '../api/claims-types';

export function useHasClaim(requestedClaim: { type: AdminClaim['type']; id?: string }): [boolean, boolean] {
  const { isPending, claims } = useSelector((state: RootState) => ({
    isPending: state.account.isAdminClaimsPending,
    claims: state.account.adminClaims
  }));
  return [
    !!claims?.find(
      c => c.type === requestedClaim.type && (requestedClaim.id == null || ('id' in c && requestedClaim.id === c.id))
    ),
    isPending
  ];
}
