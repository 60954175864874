import React, { useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Input, Select, Button, Spin, Row, Col, Alert } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { PlusOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { formItemLayout, tailFormItemLayout, regions } from './form-helper';
import * as ActionTypes from '../../store/actionTypes';
import { RootState } from '../../store/rootReducer';
import { RegisterActionTypes } from '../../store/register/types';
import { AccountCreateDto } from '@pclocs/platform-sdk';
import { Link } from 'react-router-dom';

const mapStateToProps = (state: RootState) => ({ ...state.register });
const mapDispatchToProps = (dispatch: Dispatch<RegisterActionTypes>) => ({
  onLoad: () => dispatch({ type: ActionTypes.REGISTER_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ActionTypes.REGISTER_PAGE_UNLOADED }),
  createAccount: (data: AccountCreateDto) => dispatch({ type: ActionTypes.CREATE_ACCOUNT, payload: { data: data } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & FormComponentProps;

export const RegisterForm = connector(
  LegacyForm.create({ name: 'register' })((props: Props) => {
    useEffect(() => {
      props.onLoad();

      return () => {
        props.onUnload();
      };
    }, []);

    const { getFieldDecorator } = props.form;
    const InputGroup = Input.Group;

    const getErrorAlertMessage = (props: any) => {
      const alert = <Alert message={props.error.message} type="error" showIcon />;

      return <LegacyForm.Item {...tailFormItemLayout}>{alert}</LegacyForm.Item>;
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();
      props.form.validateFieldsAndScroll(async (err: Error, values: any) => {
        if (!err) {
          const account: AccountCreateDto = {
            name: values.companyName,
            phone: `+${values.areaCode}${values.phone}`,
            region: values.region,
            email: values.email || '',
            password: values.password
          };
          props.createAccount(account);
        }
      });
    };

    const sectionCols = { xs: { span: 7, offset: 0 }, md: { span: 8, offset: 0 } };
    const phoneError = props.form.getFieldError('phone');
    const areaCodeError = props.form.getFieldError('areaCode');

    return (
      <div>
        {props.status === 'accountHasBeenCreated' ? (
          <Col>
            <Col span={12} offset={6}>
              <div style={{ textAlign: 'center' }}>
                <div className="success-message-title">Success!</div>
                <div className="success-message-subtitle">You have successfully registered a new account.</div>
                <hr />
                <h4>Account Name: {props.account?.name}</h4>
                <p>Account Code: {props.account?.accountCode}</p>
                <hr />
                <Link to="/login" className="ant-btn btn-primary" style={{ marginTop: 30 }}>
                  Go to login page
                </Link>
              </div>
            </Col>
          </Col>
        ) : (
          <LegacyForm id="registerForm" {...formItemLayout} onSubmit={handleSubmit}>
            <Row>
              <Col {...sectionCols} className="form-section-label">
                Company Details
              </Col>
            </Row>
            <hr className="form-section-line" />

            <LegacyForm.Item label="Company Name">
              {getFieldDecorator('companyName', {
                rules: [{ required: true, message: 'Please input your company name!' }]
              })(<Input />)}
            </LegacyForm.Item>

            <LegacyForm.Item
              label="Phone Number"
              validateStatus={phoneError || areaCodeError ? 'error' : ''}
              help={(phoneError || areaCodeError) && 'Please input your company phone number and area code!'}>
              <InputGroup compact>
                {getFieldDecorator('areaCode', { rules: [{ required: true }] })(
                  <Input style={{ width: '80px' }} prefix={<PlusOutlined />} />
                )}
                {getFieldDecorator('phone', { rules: [{ required: true }] })(
                  <Input style={{ width: 'calc(100% - 80px)' }} />
                )}
              </InputGroup>
            </LegacyForm.Item>

            <LegacyForm.Item label="Region">
              {getFieldDecorator('region', { rules: [{ required: true, message: 'Please input your region!' }] })(
                <Select defaultActiveFirstOption={false}>
                  {regions.map(region => (
                    <Select.Option key={region.id} value={region.name}>
                      {region.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </LegacyForm.Item>

            <LegacyForm.Item label="Owner e-mail" hasFeedback>
              {getFieldDecorator('email', {
                rules: [
                  { type: 'email', message: 'Not a valid email address!' },
                  { required: false, message: 'Please input your email!' }
                ],
                validateTrigger: 'onBlur'
              })(<Input />)}
            </LegacyForm.Item>

            <LegacyForm.Item label="Password">
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your password!' }]
              })(<Input.Password />)}
            </LegacyForm.Item>

            {props.error && getErrorAlertMessage(props)}

            <LegacyForm.Item {...tailFormItemLayout}>
              <Spin spinning={props.pending === true}>
                <Button id="register_submit" block type="primary" htmlType="submit">
                  Create account
                </Button>
              </Spin>
            </LegacyForm.Item>
          </LegacyForm>
        )}{' '}
      </div>
    );
  })
);
