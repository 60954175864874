import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Tabs, Alert } from 'antd';
import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { RootState } from '../../../store/rootReducer';
import { ItemType } from '../../../store/common/types';
import _ from 'lodash';
import { AuthorizedUpdateDto, StationUserGroupResponseDto, StationUserResponseDto } from '@pclocs/platform-sdk';
import { UserFixedList } from '../../ManageUsersCommon/user-fixed-list';

type Props = {
  contextId: string;
  authorizedUserIds: string[];
  authorizedUserGroupIds: string[];
  update: (id: string, authorizedEntities?: AuthorizedUpdateDto['authorizedEntities']) => void;
  maxItemsPerUpdateForManageUsers: number;
  globalTags: string[] | undefined;
  closeModal: () => void;
};

export const ManageUnauthorizedModal = (props: Props) => {
  const [authorisedItemType, setAuthorisedItemType] = useState<string>(ItemType.USERS);
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);
  const [selectedUserGroupsIds, setSelectedUserGroupsIds] = useState<string[]>([]);
  const { authorizedUserIds, authorizedUserGroupIds } = props;

  const { isManageUsersLoading, tableUsers, tableUserGroups, authorizedEntities } = useSelector((state: RootState) => {
    return { ..._.pick(state.common, ['isManageUsersLoading', 'tableUsers', 'tableUserGroups', 'authorizedEntities']) };
  });

  const completeUsers: StationUserResponseDto[] = useMemo(
    () => [
      ...tableUsers.items,
      ...tableUsers.unknownIds.map(id => {
        return { id, name: 'Unknown user', type: 'unknown' };
      })
    ],
    [tableUsers.unknownIds, tableUsers.items]
  );
  const completeGroups: StationUserGroupResponseDto[] = useMemo(
    () => [
      ...tableUserGroups.items,
      ...tableUserGroups.unknownIds.map(id => {
        return { id, name: 'Unknown group', type: 'unknown' };
      })
    ],
    [tableUserGroups.unknownIds, tableUserGroups.items]
  );

  const slicedUsers = useMemo(() => selectedUsersIds.slice(0, 3).map(v => completeUsers.find(user => user.id === v)), [
    selectedUsersIds
  ]);
  const slicedUserGroups = useMemo(
    () => selectedUserGroupsIds.slice(0, 3).map(v => completeGroups.find(group => group.id === v)),
    [selectedUserGroupsIds]
  );

  const updateLimitExceeded = useMemo(
    () => selectedUsersIds.length + selectedUserGroupsIds.length > props.maxItemsPerUpdateForManageUsers,
    [selectedUsersIds, selectedUserGroupsIds]
  );

  const closeModal = () => {
    props.closeModal();
    setAuthorisedItemType(ItemType.USERS);
  };

  useEffect(() => {
    if (authorizedEntities === undefined && !isManageUsersLoading) {
      closeModal();
    }
  }, [isManageUsersLoading, authorizedEntities]);

  const updateUsers = () => {
    props.update(props.contextId, {
      stationUsers: props.authorizedUserIds.filter(v => !selectedUsersIds.includes(v)),
      otherClaims: [],
      stationUserGroups: props.authorizedUserGroupIds.filter(v => !selectedUserGroupsIds.includes(v))
    });
  };

  return (
    <Modal
      title="Unauthorize users"
      className="authorised-items-modal"
      maskClosable={!isManageUsersLoading}
      visible={true}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal} className="btn-secondary">
          Cancel
        </Button>,
        <Button
          key="submit"
          onClick={updateUsers}
          className="btn-danger"
          loading={isManageUsersLoading}
          disabled={
            isManageUsersLoading || (!selectedUsersIds.length && !selectedUserGroupsIds.length) || updateLimitExceeded
          }
          data-test-id="authorise-items-submit-btn">
          Unauthorize selected
        </Button>
      ]}
      destroyOnClose={true}
      width={720}>
      <>
        <Tabs
          size="small"
          className="authorised-items-tab-section"
          animated={false}
          tabBarGutter={30}
          defaultActiveKey={ItemType.USERS}
          activeKey={authorisedItemType}
          onChange={setAuthorisedItemType}>
          <Tabs.TabPane
            key={ItemType.USERS}
            tab={
              <div data-test-id="authorised-users-tab-heading">
                <UserOutlined />
                Users{` (${authorizedUserIds.length})`}
              </div>
            }>
            <UserFixedList
              tagsFilterable={true}
              selectable={true}
              globalTags={props.globalTags ?? []}
              selectedIds={selectedUsersIds}
              maxItemsPerUpdateForManageUsers={props.maxItemsPerUpdateForManageUsers}
              setSelectedIds={setSelectedUsersIds}
              selectDisabled={isManageUsersLoading || updateLimitExceeded}
              unselectDisabled={isManageUsersLoading || !selectedUsersIds.length}
              items={completeUsers}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            key={ItemType.USER_GROUPS}
            tab={
              <div data-test-id="authorised-user-groups-tab-heading">
                <TeamOutlined />
                User groups{` (${authorizedUserGroupIds.length})`}
              </div>
            }>
            <UserFixedList
              selectable={true}
              selectedIds={selectedUserGroupsIds}
              setSelectedIds={setSelectedUserGroupsIds}
              maxItemsPerUpdateForManageUsers={props.maxItemsPerUpdateForManageUsers}
              selectDisabled={isManageUsersLoading || updateLimitExceeded}
              unselectDisabled={isManageUsersLoading || !selectedUserGroupsIds.length}
              items={completeGroups}
            />
          </Tabs.TabPane>
        </Tabs>
        <div style={{ margin: '15px 0' }}>
          <div className="authorised-items-selected-section">
            <h6>Selected:</h6>
            {!!selectedUsersIds.length && (
              <div className="selected-items">
                <>
                  <UserOutlined />
                  &nbsp;
                  <span>
                    {slicedUsers.map(v => v.name).join(', ')}
                    {selectedUsersIds.length > 3 && <> and {selectedUsersIds.length - 3} others</>}
                  </span>
                </>
              </div>
            )}
            {!!selectedUserGroupsIds.length && (
              <div className="selected-items">
                <>
                  <TeamOutlined />
                  &nbsp;
                  <span>
                    {slicedUserGroups.map(v => v.name).join(', ')}
                    {selectedUserGroupsIds.length > 3 && <> and {selectedUserGroupsIds.length - 3} others</>}
                  </span>
                </>
              </div>
            )}
            {updateLimitExceeded && (
              <Alert
                style={{ marginTop: 5, marginBottom: 5 }}
                message={`A maximum of ${props.maxItemsPerUpdateForManageUsers} users and groups can be unauthorized at a time.`}
                type="error"
              />
            )}
          </div>
        </div>
      </>
    </Modal>
  );
};
