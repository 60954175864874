import _ from 'lodash';

const CHANGELOG_TAG_KEY = 'changelog-tag';
const COLLAPSED_GROUP_NODES_KEY = 'collapsed-group-nodes';

export const getChangelogTag = (userId: string): number => {
  const userChangelogTagKey = `${CHANGELOG_TAG_KEY}-${userId}`;
  const val = parseInt(localStorage.getItem(userChangelogTagKey));
  return _.isInteger(val) ? val : -1;
};

export const setChangelogTag = (userId: string, versionTag: number): void => {
  const userChangelogTagKey = `${CHANGELOG_TAG_KEY}-${userId}`;
  localStorage.setItem(userChangelogTagKey, versionTag.toString());
};

export const getCollapsedGroupNodeIds = (userId: string, accountId: string): string[] | null => {
  const collapsedGroupNodesKey = constructCollapsedGroupNodesKey(userId, accountId);
  try {
    const nodeIds = JSON.parse(localStorage.getItem(collapsedGroupNodesKey));
    if (Array.isArray(nodeIds)) {
      return nodeIds;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const setCollapsedGroupNodeIds = (userId: string, accountId: string, nodeIds: string[]): void => {
  const collapsedGroupNodesKey = constructCollapsedGroupNodesKey(userId, accountId);
  localStorage.setItem(collapsedGroupNodesKey, JSON.stringify(nodeIds));
};

const constructCollapsedGroupNodesKey = (userId: string, accountId: string) => {
  return `${COLLAPSED_GROUP_NODES_KEY}-${userId}-${accountId}`;
};

const GLASS_USER_TOKEN_KEY = 'cloud-support-token';

export const getGlassUserToken = (): string => {
  return localStorage.getItem(GLASS_USER_TOKEN_KEY);
};

export const setGlassUserToken = (token: string): void => {
  localStorage.setItem(GLASS_USER_TOKEN_KEY, token);
};

export const clearGlassUserToken = (): void => {
  localStorage.removeItem(GLASS_USER_TOKEN_KEY);
};
