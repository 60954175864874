import {
  TreeStationGroupNodeDto,
  TreeStationNodeDto,
  WebsocketHierarchyRequestDtoTypeEnum,
  WebsocketHierarchyResponseGroupDataItemTypeEnum,
  WebsocketHierarchyResponseStationDataItemTypeEnum
} from '@pclocs/platform-sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { websocketActions } from '../../../store/websocket/reducer';
import { exhaustiveDiscrimination } from '../../../typescript-utils';
import { useLatestSdkCall } from '../../../helpers/sdk-hooks';
import { findGroupNode, groupHierarchyToArray } from '../../../helpers/group-hierarchy';

export type HierarchyTree = { [rootGroupId: string]: TreeStationGroupNodeDto };

interface HierarchyState {
  tree?: HierarchyTree;
  fetchError?: string;
}

const { reducer, actions } = createSlice({
  name: 'hierarchy',
  initialState: {} as HierarchyState,
  reducers: {
    setTree: (state, action: PayloadAction<HierarchyTree>) => {
      state.tree = action.payload;
      state.fetchError = undefined;
    },
    updateGroupName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const groupNode = findGroupNode(state.tree, action.payload.id);
      if (groupNode) {
        Object.assign(groupNode, { name: action.payload.name });
      }
    },
    updateStation: (
      state,
      action: PayloadAction<{
        id: string;
        update: Partial<Omit<TreeStationNodeDto, 'id'>>;
      }>
    ) => {
      const station = groupHierarchyToArray(state.tree).find(g => g.stations[action.payload.id])?.stations?.[
        action.payload.id
      ];
      if (station) {
        Object.assign(station, {
          ...action.payload.update
        });
      }
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.fetchError = action.payload;
    }
  }
});

export const useLiveHierarchy = (): HierarchyState => {
  const dispatchRedux = useDispatch();
  const streamMessage = useSelector((state: RootState) => state.websocket.streamMessage);
  const [state, dispatch] = useReducer(reducer, {});

  const [getTree] = useLatestSdkCall(
    'GroupApi',
    'tree',
    res => dispatch(actions.setTree(res.data)),
    () => dispatch(actions.setFetchError('Error when fetching data.'))
  );

  useEffect(() => {
    getTree();
    dispatchRedux(websocketActions.subscribe({ type: WebsocketHierarchyRequestDtoTypeEnum.Hierarchy }));

    return () => {
      dispatchRedux(websocketActions.unsubscribe({ type: WebsocketHierarchyRequestDtoTypeEnum.Hierarchy }));
      dispatch(actions.setTree(undefined));
    };
  }, []);

  const refreshTree = useCallback(
    _.debounce(
      () => {
        getTree();
        dispatchRedux(websocketActions.resubscribe({ type: WebsocketHierarchyRequestDtoTypeEnum.Hierarchy }));
      },
      500,
      { trailing: true }
    ),
    []
  );

  useEffect(() => {
    if (streamMessage?.type === 'hierarchy') {
      switch (streamMessage.data.itemType) {
        case WebsocketHierarchyResponseStationDataItemTypeEnum.Station:
          dispatch(actions.updateStation({ id: streamMessage.id, update: streamMessage.data.node }));
          break;
        case WebsocketHierarchyResponseGroupDataItemTypeEnum.Group:
          const groupUpdate = streamMessage.data.node;
          dispatch(actions.updateGroupName({ id: streamMessage.id, name: groupUpdate.name }));
          if (groupUpdate.groupsChanged || groupUpdate.stationsChanged) {
            refreshTree();
          }
          break;
        default:
          exhaustiveDiscrimination(streamMessage.data, () =>
            console.log(`Unknown hierarchy update item type ${streamMessage.data.itemType}.`)
          );
      }
    }
  }, [streamMessage]);

  return state;
};

export const filterStationGroupNode = (node: TreeStationGroupNodeDto, searchTerm: string): TreeStationGroupNodeDto => {
  if (node.name.toLowerCase().match(searchTerm)) {
    return node;
  }
  const filteredGroups = _(node.groups)
    .mapValues(group => filterStationGroupNode(group, searchTerm))
    .pickBy(v => v !== undefined)
    .value();

  const filteredStations = _.pickBy(node.stations, station => {
    return (station.name ?? station.id).toLowerCase().match(searchTerm);
  });
  if (!_.isEmpty(filteredGroups) || !_.isEmpty(filteredStations)) {
    return {
      ...node,
      groups: filteredGroups,
      stations: filteredStations
    };
  }

  return undefined;
};
