import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../actionTypes';
import { CreateAccountAction, CreateAccountErrorAction, CreateAccountSuccessAction } from './types';
import { create } from '../../api/account';
import { AccountBasicResponseDto } from '@pclocs/platform-sdk';

function* createAccountAction() {
  yield takeLatest(ActionTypes.CREATE_ACCOUNT, function*(action: CreateAccountAction) {
    try {
      const response: AccountBasicResponseDto = yield call(create, action.payload.data);
      yield put<CreateAccountSuccessAction>({
        type: ActionTypes.CREATE_ACCOUNT_SUCCESS,
        payload: { account: response }
      });
    } catch (e) {
      console.error(e);
      yield put<CreateAccountErrorAction>({
        type: ActionTypes.CREATE_ACCOUNT_ERROR,
        payload: { error: e.response.data }
      });
    }
  });
}

export function* registerSaga() {
  yield all([createAccountAction()]);
}
