import { AdminRoleResponseDto, AdminUserResponseDto } from '@pclocs/platform-sdk';
import { Button, Modal, Table } from 'antd';
import React from 'react';
import { ColumnProps } from 'antd/lib/table';

type Props = {
  adminRole: AdminRoleResponseDto;
  roleMembers: AdminUserResponseDto[];
  modalLoading: boolean;
  onCancel: () => void;
};

export const RoleMembersModal = (props: Props) => {
  const footer = [
    <Button key="back" type="primary" onClick={props.onCancel}>
      Close
    </Button>
  ];

  const tableColumns: ColumnProps<AdminUserResponseDto>[] = [
    {
      key: 'name',
      ellipsis: true,
      render: (data: AdminUserResponseDto) => (
        <div
          key={data.id}
          className="admin-name"
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
          title={data.name}>
          {data.name}
        </div>
      )
    },
    {
      key: 'email',
      ellipsis: true,
      render: (data: AdminUserResponseDto) => (
        <div
          className="admin-email"
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
          title={data.email}>
          {data.email}
        </div>
      )
    }
  ];

  return (
    <Modal
      title={'Admins with role "' + props.adminRole.name + '"'}
      visible={true}
      footer={footer}
      onCancel={props.onCancel}>
      <Table
        rowKey="id"
        showHeader={false}
        columns={tableColumns}
        dataSource={props.roleMembers}
        size="small"
        pagination={false}
        className="no-hover"
        scroll={{ y: 500 }}
      />
    </Modal>
  );
};
