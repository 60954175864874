import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../actionTypes';
import { createApi } from '../../api/sdk';
import {
  ClearStationUsersAction,
  CreateStationUserAction,
  CreateStationUserSuccessAction,
  DeleteStationUserAction,
  DeleteStationUserSuccessAction,
  DeleteStationUserErrorAction,
  FindStationUserAction,
  FindStationUsersAction,
  FindStationUsersErrorAction,
  FindStationUsersSuccessAction,
  GetUniquePinStationUserErrorAction,
  GetUniquePinStationUserSuccessAction,
  PushCsvFileToS3Action,
  PushCsvFileToS3ErrorAction,
  PushCsvFileToS3SuccessAction,
  StationUserFindBayAssignments,
  StationUserFindBayAssignmentsSuccess,
  StationUserUnloadExportModalAction,
  TriggerApiCsvImportAction,
  TriggerApiCsvImportSuccessAction,
  TriggerApiCsvUpdateAction,
  TriggerApiCsvUpdateSuccessAction,
  TriggerApiUserExportAction,
  TriggerApiUserExportSuccessAction,
  UpdateStationUserAction,
  UpdateStationUserErrorAction,
  UpdateStationUserSuccessAction,
  StationUserModalUnloadAction as InternalStationUserModalUnloadAction,
  CreateStationUserErrorAction
} from './types';
import {
  AdminUserContextDto,
  StationUserApi,
  StationUserInternalApi,
  InternalStationUserCsvExportDtoRfidFormatEnum,
  InternalStationUserCsvImportDtoRfidFormatEnum,
  InternalStationUserCsvUpdateDtoRfidFormatEnum
} from '@pclocs/platform-sdk';
import { ShowNotificationMessageAction, ShowNotificationModalAction, AddTriggeredBackgroundJob } from '../common/types';
import { getRfidFormat } from '../../helpers/rfid-converter-helper';
import { performFileUpload } from '../../helpers/file-upload';

function* findStationUsers() {
  yield takeLatest(ActionTypes.FIND_STATION_USERS, function*(action: FindStationUsersAction) {
    try {
      const api = createApi<StationUserApi>('StationUserApi');
      const response: ApiReturnType<typeof api.findSome> = yield call(
        api.findSome,
        action.payload?.after,
        action.payload?.pageSize || 200, // safe default limit if not provided
        action.payload?.filter,
        action.payload?.tagsFilter
      );

      yield put<FindStationUsersSuccessAction>({
        type: ActionTypes.FIND_STATION_USERS_SUCCESS,
        payload: response.data
      });
    } catch (e) {
      console.error(e);
      yield put<FindStationUsersErrorAction>({
        type: ActionTypes.FIND_STATION_USERS_ERROR,
        payload: {
          fetchError: 'Failed to fetch items'
        }
      });
    }
  });
}

function* getStationUser() {
  yield takeLatest(ActionTypes.GET_STATION_USER, function*(action: FindStationUserAction) {
    try {
      const api = createApi<StationUserInternalApi>('StationUserInternalApi');
      const response: ApiReturnType<typeof api.findOne> = yield call(api.findOne, action.payload.stationUserId);
      yield put({ type: ActionTypes.GET_STATION_USER_SUCCESS, payload: { data: response.data } });
    } catch (err) {
      console.error(err.response);
      yield put({
        type: ActionTypes.GET_STATION_USER_ERROR,
        payload: err.response.data.message
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: `User could not be retrieved`
        }
      });
    }
  });
}

function* findStationUser() {
  // Similar to getStationUser above, but does not throw any errors to the UI
  yield takeLatest(ActionTypes.FIND_STATION_USER, function*(action: FindStationUserAction) {
    try {
      const api = createApi<StationUserInternalApi>('StationUserInternalApi');
      const response: ApiReturnType<typeof api.findOne> = yield call(api.findOne, action.payload.stationUserId);
      yield put({ type: ActionTypes.GET_STATION_USER_SUCCESS, payload: { data: response.data } });
    } catch (err) {
      console.error(err.reponse);
    }
  });
}

function* deleteStationUser() {
  yield takeEvery(ActionTypes.DELETE_STATION_USER, function*(action: DeleteStationUserAction) {
    const user = action.payload;
    try {
      const api = createApi<StationUserInternalApi>('StationUserInternalApi');
      const response: ApiReturnType<typeof api.deleteOne> = yield call(api.deleteOne, user.id);
      if (response.status !== 204) {
        console.error('Failed to delete user.');
        return;
      }
      yield put<DeleteStationUserSuccessAction>({
        type: ActionTypes.DELETE_STATION_USER_SUCCESS,
        payload: { id: user.id }
      });
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: `User ${user.name} deleted.` }
      });
    } catch (err) {
      console.error(err.response);
      yield put<DeleteStationUserErrorAction>({ type: ActionTypes.DELETE_STATION_USER_ERROR });

      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: `User ${user.name} could not be deleted`
        }
      });
    }
  });
}

function* createStationUser() {
  yield takeEvery(ActionTypes.CREATE_STATION_USER, function*(action: CreateStationUserAction) {
    try {
      const aaf = createApi<StationUserInternalApi>('StationUserInternalApi');
      yield call(aaf.createOne, action.payload.data);
      yield put<ClearStationUsersAction>({ type: ActionTypes.CLEAR_STATION_USERS });
      yield put<CreateStationUserSuccessAction>({ type: ActionTypes.CREATE_STATION_USER_SUCCESS });
      yield put<InternalStationUserModalUnloadAction>({ type: ActionTypes.INTERNAL_STATION_USER_UNLOAD_MODAL });
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: 'User created' }
      });
    } catch (err) {
      console.error(err.response);
      yield put<CreateStationUserErrorAction>({
        type: ActionTypes.CREATE_STATION_USER_ERROR,
        payload: {
          error: {
            message: `User could not be created`,
            descriptions: err.response.data.message
          }
        }
      });
    }
  });
}

function* updateStationUser() {
  yield takeEvery(ActionTypes.UPDATE_STATION_USER, function*(action: UpdateStationUserAction) {
    try {
      const aaf = createApi<StationUserInternalApi>('StationUserInternalApi');
      yield call(aaf.updateOne, action.payload.data.id, action.payload.data);
      yield put<UpdateStationUserSuccessAction>({
        type: ActionTypes.UPDATE_STATION_USER_SUCCESS,
        payload: { data: action.payload.data }
      });
      yield put<InternalStationUserModalUnloadAction>({ type: ActionTypes.INTERNAL_STATION_USER_UNLOAD_MODAL });
      yield put<ShowNotificationMessageAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
        payload: { type: 'success', content: 'User updated' }
      });
    } catch (err) {
      console.error(err.response);
      yield put<UpdateStationUserErrorAction>({
        type: ActionTypes.UPDATE_STATION_USER_ERROR,
        payload: {
          error: {
            message: `User ${action.payload.data.name} could not be updated`,
            descriptions: err.response.data.message
          }
        }
      });
    }
  });
}

function* getUniquePin() {
  yield takeLatest(ActionTypes.GET_UNIQUE_PIN_STATION_USER, function*() {
    try {
      const api = createApi<StationUserApi>('StationUserApi');
      const response: ApiReturnType<typeof api.getUniquePin> = yield call(api.getUniquePin);
      yield put<
        GetUniquePinStationUserSuccessAction
      >({ type: ActionTypes.GET_UNIQUE_PIN_STATION_USER_SUCCESS, payload: { pin: response.data.pin } });
    } catch (e) {
      console.error(e);
      yield put<
        GetUniquePinStationUserErrorAction
      >({ type: ActionTypes.GET_UNIQUE_PIN_STATION_USER_ERROR, payload: { message: 'Unique PIN could not be generated' } });
    }
  });
}

function* pushCsvFileToS3() {
  yield takeLatest(ActionTypes.PUSH_CSV_FILE_TO_S3, function*(action: PushCsvFileToS3Action) {
    try {
      if (action.payload.file.name.substr(-4) !== '.csv') {
        throw new Error(`Please select a valid CSV file`);
      }

      const fileReference: string = yield call(performFileUpload, action.payload.file, 'text/csv');

      yield put<PushCsvFileToS3SuccessAction>({
        type: ActionTypes.PUSH_CSV_FILE_TO_S3_SUCCESS,
        payload: { fileReference }
      });
    } catch (e) {
      yield put<PushCsvFileToS3ErrorAction>({
        type: ActionTypes.PUSH_CSV_FILE_TO_S3_ERROR,
        payload: { message: e.message || 'Error uploading CSV file.' }
      });
    }
  });
}

function* triggerApiCsvImport() {
  yield takeLatest(ActionTypes.TRIGGER_API_CSV_IMPORT, function*(action: TriggerApiCsvImportAction) {
    try {
      const api = createApi<StationUserInternalApi>('StationUserInternalApi');
      const response: ApiReturnType<typeof api.importCsv> = yield call(api.importCsv, {
        fileReference: action.payload.fileReference,
        deleteAllUsers: action.payload.deleteAllUsers,
        rfidFormat:
          getRfidFormat() === 'decimal'
            ? InternalStationUserCsvImportDtoRfidFormatEnum.Decimal
            : InternalStationUserCsvImportDtoRfidFormatEnum.Hex
      });

      const currentUser: AdminUserContextDto = yield select(state => state.auth.currentUser);
      if (currentUser?.email) {
        yield put<TriggerApiCsvImportSuccessAction>({ type: ActionTypes.TRIGGER_API_CSV_IMPORT_SUCCESS });
      } else {
        yield put<AddTriggeredBackgroundJob>({
          type: ActionTypes.ADD_TRIGGERED_BACKGROUND_JOB,
          payload: {
            job: {
              id: response.data.backgroundJobStatusId,
              jobName: 'StationUsersImportJob'
            }
          }
        });
        yield put<InternalStationUserModalUnloadAction>({ type: ActionTypes.STATION_USER_UNLOAD_CSV_MODAL });
      }
    } catch (e) {
      yield put<InternalStationUserModalUnloadAction>({
        type: ActionTypes.STATION_USER_UNLOAD_CSV_MODAL
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Error importing CSV file.'
        }
      });
    }

    yield put<ClearStationUsersAction>({ type: ActionTypes.CLEAR_STATION_USERS });
    yield put<CreateStationUserSuccessAction>({ type: ActionTypes.CREATE_STATION_USER_SUCCESS });
  });
}

function* findBayAssignments() {
  yield takeLatest(ActionTypes.STATION_USER_FIND_BAY_ASSIGNMENTS, function*(action: StationUserFindBayAssignments) {
    try {
      const api = createApi<StationUserInternalApi>('StationUserInternalApi');
      const response: ApiReturnType<typeof api.findBayAssignments> = yield call(
        api.findBayAssignments,
        action.payload.id
      );
      yield put<StationUserFindBayAssignmentsSuccess>({
        type: ActionTypes.STATION_USER_FIND_BAY_ASSIGNMENTS_SUCCESS,
        payload: {
          bays: response.data
        }
      });
    } catch (e) {
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Failed to get bay assignments.'
        }
      });
    }
  });
}

function* triggerApiUserExport() {
  yield takeLatest(ActionTypes.TRIGGER_API_USER_EXPORT, function*(action: TriggerApiUserExportAction) {
    const { searchText, tagFilter } = action.payload;
    try {
      const api = createApi<StationUserInternalApi>('StationUserInternalApi');
      const response: ApiReturnType<typeof api.exportCsv> = yield call(api.exportCsv, {
        search: searchText?.length ? searchText : undefined,
        tags: tagFilter?.length ? tagFilter : undefined,
        rfidFormat:
          getRfidFormat() === 'decimal'
            ? InternalStationUserCsvExportDtoRfidFormatEnum.Decimal
            : InternalStationUserCsvExportDtoRfidFormatEnum.Hex
      });

      const currentUser: AdminUserContextDto = yield select(state => state.auth.currentUser);
      if (currentUser?.email) {
        yield put<TriggerApiUserExportSuccessAction>({ type: ActionTypes.TRIGGER_API_USER_EXPORT_SUCCESS });
      } else {
        yield put<AddTriggeredBackgroundJob>({
          type: ActionTypes.ADD_TRIGGERED_BACKGROUND_JOB,
          payload: {
            job: {
              id: response.data.backgroundJobStatusId,
              jobName: 'StationUsersExportJob'
            }
          }
        });
        yield put<StationUserUnloadExportModalAction>({ type: ActionTypes.STATION_USER_UNLOAD_EXPORT_MODAL });
      }
    } catch (e) {
      yield put<StationUserUnloadExportModalAction>({
        type: ActionTypes.STATION_USER_UNLOAD_EXPORT_MODAL
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Error exporting CSV file.'
        }
      });
    }
  });
}

function* triggerApiCsvUpdate() {
  yield takeLatest(ActionTypes.TRIGGER_API_CSV_UPDATE, function*(action: TriggerApiCsvUpdateAction) {
    try {
      const api = createApi<StationUserInternalApi>('StationUserInternalApi');
      const response: ApiReturnType<typeof api.updateCsv> = yield call(api.updateCsv, {
        fileReference: action.payload.fileReference,
        rfidFormat:
          getRfidFormat() === 'decimal'
            ? InternalStationUserCsvUpdateDtoRfidFormatEnum.Decimal
            : InternalStationUserCsvUpdateDtoRfidFormatEnum.Hex
      });

      const currentUser: AdminUserContextDto = yield select(state => state.auth.currentUser);
      if (currentUser?.email) {
        yield put<TriggerApiCsvUpdateSuccessAction>({ type: ActionTypes.TRIGGER_API_CSV_UPDATE_SUCCESS });
      } else {
        yield put<AddTriggeredBackgroundJob>({
          type: ActionTypes.ADD_TRIGGERED_BACKGROUND_JOB,
          payload: {
            job: {
              id: response.data.backgroundJobStatusId,
              jobName: 'StationUsersUpdateJob'
            }
          }
        });
        yield put<InternalStationUserModalUnloadAction>({ type: ActionTypes.STATION_USER_UNLOAD_UPDATE_CSV_MODAL });
      }
    } catch (e) {
      yield put<InternalStationUserModalUnloadAction>({
        type: ActionTypes.STATION_USER_UNLOAD_UPDATE_CSV_MODAL
      });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: {
          type: 'error',
          title: 'Error',
          content: 'Error updating CSV file.'
        }
      });
    }

    yield put<ClearStationUsersAction>({ type: ActionTypes.CLEAR_STATION_USERS });
    yield put<CreateStationUserSuccessAction>({ type: ActionTypes.CREATE_STATION_USER_SUCCESS });
  });
}

export function* stationUserSaga() {
  yield all([
    findStationUsers(),
    findStationUser(),
    getStationUser(),
    deleteStationUser(),
    updateStationUser(),
    createStationUser(),
    getUniquePin(),
    pushCsvFileToS3(),
    triggerApiCsvImport(),
    findBayAssignments(),
    triggerApiUserExport(),
    triggerApiCsvUpdate()
  ]);
}
