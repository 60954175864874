import { TriggeredBackgroundJob } from '../store/common/types';
import _ from 'lodash';

const TRIGGERED_BACKGROUND_JOBS_KEY = 'jobs';

export const getSessionTriggeredBackgroundJobs = (): TriggeredBackgroundJob[] => {
  try {
    const jobs = JSON.parse(sessionStorage.getItem(TRIGGERED_BACKGROUND_JOBS_KEY));
    if (
      Array.isArray(jobs) &&
      jobs.every((job): job is TriggeredBackgroundJob => _.isObject(job) && 'id' in job && 'jobName' in job)
    ) {
      return jobs;
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const setSessionTriggeredBackgroundJobs = (jobs: TriggeredBackgroundJob[]): void => {
  sessionStorage.setItem(TRIGGERED_BACKGROUND_JOBS_KEY, JSON.stringify(jobs));
};
