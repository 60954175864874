import * as ActionTypes from '../actionTypes';
import { IntegrationsState, IntegrationsActionTypes } from './types';

const defaultState: IntegrationsState = {
  appClients: undefined,
  newAppClientFormShown: false,
  pending: false
};

export const integrations = (
  state: IntegrationsState = defaultState,
  action: IntegrationsActionTypes
): IntegrationsState => {
  switch (action.type) {
    case ActionTypes.FETCH_APP_CLIENTS:
    case ActionTypes.CREATE_APP_CLIENT:
    case ActionTypes.UPDATE_APP_CLIENT:
    case ActionTypes.DELETE_APP_CLIENT: {
      return { ...state, pending: true };
    }
    case ActionTypes.FETCH_APP_CLIENTS_SUCCESS: {
      return {
        ...state,
        appClients: action.payload.appClients,
        newAppClientFormShown: false,
        pending: false
      };
    }
    case ActionTypes.TOGGLE_NEW_APP_CLIENT_FORM: {
      return {
        ...state,
        newAppClientFormShown: !state.newAppClientFormShown
      };
    }
    case ActionTypes.APP_CLIENT_ERROR: {
      return {
        ...state,
        pending: false
      };
    }
    case ActionTypes.FETCH_WEBHOOKS: {
      return {
        ...state,
        pending: true,
        messages: undefined
      };
    }
    case ActionTypes.FETCH_WEBHOOKS_SUCCESS: {
      return {
        ...state,
        pending: false,
        webhooks: action.payload.webhooks
      };
    }
    case ActionTypes.DELETE_WEBHOOK:
      return {
        ...state,
        pending: true
      };
    case ActionTypes.WEBHOOK_ERROR:
      return {
        ...state,
        pending: false,
        messages: action.payload.messages
      };
    default:
      return state;
  }
};
