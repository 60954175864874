import React from 'react';
import { Input, Form } from 'antd';

import { CollapsibleFormSection } from '../../../helpers/collapsible-form-section';

type Attribute = {
  fieldName: string;
  label: string;
  defaultValue: string;
};

type AttributesMapProps = {
  readonly?: boolean;
  attributes: Attribute[];
};

export const ATTRIBUTES_LDAP: Attribute[] = [
  { fieldName: 'userName', label: "User's Name", defaultValue: 'cn' },
  { fieldName: 'pin', label: 'PIN', defaultValue: 'pclPin' },
  { fieldName: 'rfid', label: 'RFID', defaultValue: 'pclRfid' }
];
export const ATTRIBUTES_GOOGLE_WORKSPACE: Attribute[] = [
  { fieldName: 'pin', label: 'PIN', defaultValue: 'pclPin' },
  { fieldName: 'rfid', label: 'RFID', defaultValue: 'pclRfid' }
];

export const AttributesMap: React.FunctionComponent<AttributesMapProps> = props => {
  const { readonly, attributes } = props;
  const renderFormField = (field: string, label: string, initialValue: string) => (
    <Form.Item
      label={label}
      key={field}
      name={['attributes', field]}
      initialValue={initialValue}
      rules={[{ required: true, message: `An attribute for ${label} is required.` }]}>
      <Input disabled={readonly} />
    </Form.Item>
  );

  return (
    <CollapsibleFormSection title="Attribute Mappings" wrapperCol={{ sm: { offset: 6, span: 18 }, xxl: { span: 12 } }}>
      {attributes && attributes.map(a => renderFormField(a.fieldName, a.label, a.defaultValue))}
    </CollapsibleFormSection>
  );
};
