import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { Alert, Button, Input, Modal, Table } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import '@ant-design/compatible/assets/index.css';
import * as ActionTypes from '../../store/actionTypes';
import { connect, ConnectedProps } from 'react-redux';
import {
  InternalStationUserGroupCreateDto,
  InternalStationUserGroupFailedItemDto,
  StationUserGroupResponseDto,
  ValidationExceptionDto
} from '@pclocs/platform-sdk';
import _ from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import { useLatestSdkCall } from '../../helpers/sdk-hooks';
import { ShowNotificationMessageAction } from '../../store/common/types';
import { joinErrorMessages } from '../../api/sdk';

type ValidationError = {
  title: string;
  description?: string;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<ShowNotificationMessageAction>) => ({
  showSuccessNotification: (content: string) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload: { type: 'success', content }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux &
  FormComponentProps & {
    userGroup?: StationUserGroupResponseDto;
    reloadUserGroups: () => void;
    modalOpen: boolean;
    onClose: () => void;
  };

export const UserGroupsModal = connector(
  LegacyForm.create<Props>({ name: 'UserGroups' })((props: Props) => {
    const textInput = useRef(null) as React.MutableRefObject<any>;
    const [error, setError] = useState<InternalStationUserGroupFailedItemDto[] | ValidationError | undefined>();

    const [createUserGroups, creatingUserGroups] = useLatestSdkCall(
      'StationUserGroupInternalApi',
      'createMany',
      ({ data }) => {
        if (data.length > 0) {
          setError(data);
        } else {
          props.showSuccessNotification('User group created.');
          props.onClose();
          props.reloadUserGroups();
        }
      },
      () => setError({ title: 'User groups could not be created.' })
    );

    const [updateUserGroup, updatingUserGroup] = useLatestSdkCall(
      'StationUserGroupInternalApi',
      'updateOne',
      () => {
        props.showSuccessNotification('User group updated.');
        props.onClose();
        props.reloadUserGroups();
      },
      res => {
        const title = 'User group could not be updated.';
        if ('status' in res && res.status === 400) {
          setError({
            title,
            description: _.capitalize(joinErrorMessages(res.data as ValidationExceptionDto)[0])
          });
        } else {
          setError({ title: 'User group could not be updated.' });
        }
      }
    );

    useEffect(() => {
      !props.modalOpen && setError(undefined);
      props.userGroup ? props.form.setFieldsValue({ name: props.userGroup.name }) : props.form.resetFields();
      if (textInput.current) {
        textInput.current.focus();
      }
    }, [props.modalOpen, props.userGroup]);

    const { getFieldDecorator } = props.form;

    const handleSubmit = (e: any) => {
      e.preventDefault();
      setError(undefined);

      props.form.validateFieldsAndScroll(async (err: Error, values: any) => {
        if (!err) {
          if (props.userGroup) {
            updateUserGroup(props.userGroup.id, { name: values.name });
          } else {
            const userGroups = values.name
              .split(',')
              .reduce((groups: InternalStationUserGroupCreateDto[], v: string) => {
                const name: string = _.trim(v.replace(/\s+/g, ' '), ' ');
                name.length && groups.push({ name });
                return groups;
              }, []);
            createUserGroups(userGroups);
          }
        }
      });
    };

    const validateGroupsSubmit = (_rule: any, value: string, callback: { (arg0: string): void; (): void }) => {
      if (!value) {
        callback('Please input group name(s)!');
      } else if ((value.match(/,/g) || []).length >= 1000) {
        callback('You can only add up to 1000 groups!');
      } else {
        callback();
      }
    };

    const ValidationErrorBlock = () => {
      if (Array.isArray(error) && error.length > 0) {
        return (
          <>
            <Alert
              message="Validation errors occurred. Please confirm group names and submit it again."
              type="error"
              showIcon
              style={{ marginBottom: 16 }}
            />
            <Table
              className="no-hover"
              pagination={false}
              scroll={{ y: 150 }}
              style={{ minHeight: 150 }}
              showHeader={false}
              size="small"
              dataSource={error.map((e: InternalStationUserGroupFailedItemDto, i: number) => {
                return { key: i, ...e };
              })}
              columns={[
                { title: 'Group Name', dataIndex: 'groupName', key: 'groupName' },
                { title: 'Error Message', dataIndex: 'message', key: 'message' }
              ]}
            />
          </>
        );
      } else if (error) {
        const { title, description } = error as ValidationError;
        return <Alert message={title} description={description} type="error" showIcon style={{ marginBottom: 16 }} />;
      }
      return <></>;
    };

    const pending = creatingUserGroups || updatingUserGroup;

    return (
      <Modal
        title={!props.userGroup ? 'Create User Groups' : 'Update User Group'}
        visible={props.modalOpen}
        maskClosable={!pending}
        onCancel={props.onClose}
        width={600}
        footer={[
          <Button key="back" onClick={props.onClose} style={{ display: pending ? 'none' : 'initial' }}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit} loading={pending}>
            Save
          </Button>
        ]}>
        <LegacyForm
          {...{
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 }
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 }
            }
          }}>
          {props.userGroup ? (
            <LegacyForm.Item label="Group Name:">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please input the group name!' }]
              })(<Input autoFocus ref={textInput} />)}
            </LegacyForm.Item>
          ) : (
            <>
              <Alert
                message="Add up to 1000 groups by comma separating the group names"
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
              <LegacyForm.Item label="Group Names:">
                {getFieldDecorator('name', {
                  rules: [{ validator: validateGroupsSubmit }]
                })(<TextArea autoFocus ref={textInput} rows={4} placeholder="Group One,Group Two,Group Three" />)}
              </LegacyForm.Item>
            </>
          )}
          <ValidationErrorBlock />
        </LegacyForm>
      </Modal>
    );
  })
);
