import { Button, Result, Skeleton } from 'antd';
import React, { ReactElement } from 'react';

interface Props {
  loading: boolean;
  error?: string | boolean;
  children: ReactElement | ReactElement[];
}

export const InitialDataLoader = (props: Props) => {
  return (
    <>
      {props.loading ? (
        <Skeleton active />
      ) : props.error ? (
        <Result
          status="warning"
          title={typeof props.error === 'string' ? props.error : 'Could not load the required data.'}
          extra={
            <Button type="primary" onClick={() => window.location.reload()}>
              Refresh Page
            </Button>
          }
        />
      ) : (
        props.children
      )}
    </>
  );
};
