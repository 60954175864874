import {
  InternalStationUserResponseDto,
  StationUserResponseInternalDto,
  StationUserResponseInternalDtoTypeEnum
} from '@pclocs/platform-sdk';

export function internalUserToStationUser(u: InternalStationUserResponseDto): StationUserResponseInternalDto {
  return {
    id: u.id,
    type: StationUserResponseInternalDtoTypeEnum.Internal,
    name: u.name,
    stationCredentials: u.stationCredentials,
    tags: u.tags ?? []
  };
}
