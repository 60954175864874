import { MutableRefObject, useEffect, useState } from 'react';

export const useTrackElementHeight = (ref: MutableRefObject<HTMLElement>) => {
  const [elementHeight, setElementHeight] = useState<number>();

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((mutationRecords: ResizeObserverEntry[]) => {
        const record = mutationRecords[0];
        if (record.target instanceof HTMLElement) {
          setElementHeight(record.target.offsetHeight);
        }
      });
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref.current]);

  return elementHeight;
};
