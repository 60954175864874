import React from 'react';
import { Upload, Button } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { isValidCertificateContent, isValidPrivateKeyContent } from '../../helpers/form-helper';

type Props = {
  /**
   * Values coming in/out of this class deal with text values of the files.
   */
  value?: string;
  onChange?: (val?: string) => void;
  disabled?: boolean;
};

export const validateCertificate = (_rule: unknown, value: string, callback: (error?: string) => void) => {
  callback(
    value === undefined || isValidCertificateContent(value)
      ? undefined
      : 'A valid X.509 certificate in PEM format is required.'
  );
};

export const validatePrivateKey = (_rule: unknown, value: string, callback: (error?: string) => void) => {
  callback(
    value === undefined || isValidPrivateKeyContent(value)
      ? undefined
      : 'A valid X.509 private key in PEM format is required.'
  );
};

export class CertificateFormField extends React.PureComponent {
  constructor(public readonly props: Props) {
    super(props);
  }

  protected onFileUpload(file: File): void {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      if (this.props.onChange && reader.result !== null) {
        this.props.onChange(reader.result.toString());
      }
    };
  }

  render() {
    return (
      <>
        <Upload
          accept=".txt, .crt, .pem, .ppk, .key"
          showUploadList={false}
          customRequest={options => this.onFileUpload(options.file as File)}
          disabled={this.props.disabled}>
          {this.props.value ? (
            <Button
              disabled={this.props.disabled}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.onChange?.(undefined);
              }}>
              <DeleteOutlined /> Remove file
            </Button>
          ) : (
            <Button disabled={this.props.disabled}>
              <UploadOutlined /> Upload file
            </Button>
          )}
        </Upload>
      </>
    );
  }
}
