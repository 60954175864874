const formItemLayout = {
  labelCol: {
    xs: { span: 22, offset: 1 },
    sm: { span: 7, offset: 0 },
    md: { span: 8, offset: 0 }
  },
  wrapperCol: {
    xs: { span: 22, offset: 1 },
    sm: { span: 10, offset: 0 },
    md: { span: 8, offset: 0 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 22, offset: 1 },
    sm: { span: 10, offset: 7 },
    md: { span: 8, offset: 8 }
  }
};

export { formItemLayout, tailFormItemLayout };

export const regions = [
  { id: 'ANZ', name: 'Australia and New Zealand' },
  { id: 'EU', name: 'Europe' },
  { id: 'UK', name: 'United Kingdom' },
  { id: 'USA', name: 'U.S and Canada' }
];
