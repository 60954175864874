import { ReportsActionTypes, ReportsState } from './types';
import * as ActionTypes from '../actionTypes';
import moment from 'moment';

const defaultState: ReportsState = {
  reportData: [],
  isReportsGenerating: false,
  isPageLoading: false,
  requestingExportJob: false,
  exportReportModalShown: false,
  startTimeFilter: moment()
    .subtract(6, 'd')
    .startOf('day')
    .toISOString(),
  endTimeFilter: moment()
    .endOf('day')
    .toISOString(),
  reportKeyFilter: 'current-status'
};

export const reports = (state: ReportsState = defaultState, action: ReportsActionTypes): ReportsState => {
  switch (action.type) {
    case ActionTypes.REPORTS_PAGE_LOADED:
      return {
        ...state,
        ...defaultState
      };
    case ActionTypes.REPORTS_PAGE_UNLOADED:
      return {
        ...state,
        ...defaultState,
        reportKeyFilter: 'current-status',
        groupOrStationFilter: undefined
      };
    case ActionTypes.GENERATE_REPORT:
      return {
        ...state,
        reportData: [],
        isPageLoading: false,
        isReportsGenerating: true,
        fetchError: undefined
      };
    case ActionTypes.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        reportData: state.reportData.concat(action.payload.data),
        isReportsGenerating: false,
        isPageLoading: false,
        nextPage: action.payload.nextPage,
        executionId: action.payload.executionId
      };
    case ActionTypes.FETCH_NEXT_REPORT_PAGE:
      return {
        ...state,
        fetchError: undefined,
        isPageLoading: true
      };
    case ActionTypes.GENERATE_REPORT_ERROR:
      return {
        ...state,
        isReportsGenerating: false,
        fetchError: action.payload.fetchError
      };
    case ActionTypes.EXPORT_REPORT:
      return {
        ...state,
        requestingExportJob: true
      };
    case ActionTypes.EXPORT_REPORT_SUCCESS:
      return {
        ...state,
        requestingExportJob: false,
        exportReportModalShown: true
      };
    case ActionTypes.EXPORT_REPORT_ERROR:
      return {
        ...state,
        requestingExportJob: false
      };
    case ActionTypes.HIDE_EXPORT_REPORT_MODAL:
      return {
        ...state,
        requestingExportJob: false,
        exportReportModalShown: false
      };
    case ActionTypes.FILTER_REPORTS_BY_PROPERTY:
      return {
        ...state,
        startTimeFilter: action.payload.startTimeFilter,
        endTimeFilter: action.payload.endTimeFilter,
        reportKeyFilter: action.payload.reportKeyFilter,
        groupOrStationFilter: action.payload.groupOrStationFilter,
        reportData: [],
        nextPage: undefined,
        executionId: undefined
      };
    default:
      return state;
  }
};
