import React, { useEffect } from 'react';
import {
  LdapUserDirectoryConfigCreateDto,
  LdapUserDirectoryConfigUpdateDto,
  LdapUserDirectoryConfigResponseDto
} from '@pclocs/platform-sdk';
import { Divider, Form, Modal } from 'antd';
import { LdapUserProviderConfig } from './ldap-user-provider-config';
import { ShowNotificationMessageAction } from '../../../store/common/types';
import { FooterButtons } from './footer-buttons';
import { useEverySdkCall } from '../../../helpers/sdk-hooks';
import { LdapTestResults } from './ldap-test-results';

type FormProperties = {
  currentId: string | undefined;
  showNotification: (payload: ShowNotificationMessageAction['payload']) => void;
  setCurrentId: React.Dispatch<React.SetStateAction<string>>;
  initialConfig: LdapUserDirectoryConfigResponseDto | undefined;
  hasExternalDirectoriesFeature: boolean;
};

export const LdapUserDirectoryForm: React.FunctionComponent<FormProperties> = ({
  currentId,
  showNotification,
  setCurrentId,
  initialConfig,
  hasExternalDirectoriesFeature
}) => {
  const [form] = Form.useForm<LdapUserDirectoryConfigCreateDto>();
  useEffect(() => {
    if (!!initialConfig) {
      form.setFieldsValue((initialConfig as unknown) as LdapUserDirectoryConfigCreateDto);
    }
  }, [initialConfig]);

  const [createLdapUserProviderConfig, createLdapLoading] = useEverySdkCall(
    'ExternalUserDirectoryApi',
    'createLdapDirectoryConfig',
    v => {
      setCurrentId(v.data.id);
      showNotification({ type: 'success', content: 'Configuration saved.' });
    },
    () => showNotification({ type: 'error', content: 'Could not save the configuration.' })
  );

  const [updateLdapUserProviderConfig, updateLdapLoading] = useEverySdkCall(
    'ExternalUserDirectoryApi',
    'updateLdapDirectoryConfig',
    () => showNotification({ type: 'success', content: 'Configuration saved.' }),
    () => showNotification({ type: 'error', content: 'Could not save the configuration.' })
  );

  const [deleteExternalUserProvider, deleteLdapLoading] = useEverySdkCall(
    'ExternalUserDirectoryApi',
    'deleteOne',
    () => {
      setCurrentId(undefined);
      form.resetFields();
      showNotification({ type: 'warning', content: 'Removed the configuration.' });
    },
    () => showNotification({ type: 'error', content: 'Could not remove the configuration.' })
  );

  const [testLdapConnection, isTestingLdapConnection] = useEverySdkCall(
    'ExternalUserDirectoryApi',
    'testLdapDirectoryConfig',
    response => {
      Modal.info({
        title: 'LDAP Connection Test Results',
        content: <LdapTestResults results={response.data} />,
        icon: <></>
      });
    },
    () => showNotification({ type: 'error', content: 'Could not test the connection' })
  );

  const submitting = {
    form: updateLdapLoading || createLdapLoading,
    delete: !!deleteLdapLoading,
    test: !!isTestingLdapConnection
  };

  return (
    <Form
      form={form}
      onFinish={values => {
        if (currentId) {
          updateLdapUserProviderConfig(currentId, (values as unknown) as LdapUserDirectoryConfigUpdateDto);
        } else {
          createLdapUserProviderConfig(values);
        }
      }}
      labelCol={{ xs: 24, sm: 6 }}
      wrapperCol={{ xs: 24, sm: 18, xxl: 12 }}>
      <LdapUserProviderConfig
        isNewRecord={!currentId}
        readonly={!hasExternalDirectoriesFeature}
        clientKey={!!initialConfig ? initialConfig.clientKey : undefined}
      />
      <Divider orientation="left" />
      <FooterButtons
        submitting={submitting}
        readonly={!hasExternalDirectoriesFeature}
        validateFields={() => form.validateFields()}
        currentId={currentId}
        delete={deleteExternalUserProvider}
        testConnection={testLdapConnection}
      />
    </Form>
  );
};
