import React from 'react';
import { LdapTestResponseDto } from '@pclocs/platform-sdk';
import { List } from 'antd';
import { If } from '../../Util';
import { CheckItem, MissingAttributesItem } from './common-test-results-components';

export const LdapTestResults = ({ results }: { results: LdapTestResponseDto }) => {
  const falseyKeys = (obj: Record<string, unknown>) =>
    Object.entries(obj)
      .filter(([_name, isPresent]) => !isPresent)
      .map(([name]) => name);
  const missingUserAttributes = falseyKeys(results.mandatoryUserAttributes as Record<string, boolean>);
  const missingGroupAttributes = falseyKeys(results.mandatoryGroupAttributes as Record<string, boolean>);
  const missingOptionalAttributes = falseyKeys(results.optionalUserAttributes as Record<string, boolean>);

  return (
    <List bordered={true} style={{ marginTop: 20 }}>
      <CheckItem isSuccess={results.connect} title="Connect" />
      <CheckItem isSuccess={results.bind} title="Bind" />
      <CheckItem isSuccess={results.searchBaseDnExists} title="Base DN" />
      <CheckItem
        canFail={results.searchBaseDnExists}
        isSuccess={results.usersFound}
        title="Users"
        warningDescription="No users found. User attributes could not be validated"
      />
      <If condition={results.usersFound && missingUserAttributes.length}>
        <MissingAttributesItem type="User" attributes={missingUserAttributes} />
      </If>
      <If condition={results.usersFound && missingOptionalAttributes.length}>
        <MissingAttributesItem type="Optional" attributes={missingOptionalAttributes} />
      </If>
      <CheckItem
        canFail={results.searchBaseDnExists}
        isSuccess={results.groupsFound}
        title="Groups"
        warningDescription="No groups found. Group attributes could not be validated"
      />
      <If condition={results.groupsFound && missingGroupAttributes.length}>
        <MissingAttributesItem type="Group" attributes={missingGroupAttributes} />
      </If>
    </List>
  );
};
