import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../actionTypes';
import {
  changePassword,
  completeNewPassword,
  confirmMfaTotpToken,
  currentUserPoolUser,
  forgotPassword,
  OAUTH_REDIRECT_SIGN_IN_URI,
  resetPassword,
  setPreferredMFA,
  setupMfaTotp,
  signIn,
  signOut,
  USER_POOL_DOMAIN,
  verifyMfaTotpToken
} from '../../api/amplify';
import { getCurrentUserContext, verifyProvider } from '../../api/account';
import { RedirectAction, ShowNotificationModalAction } from '../common/types';
import {
  AdminOnboardUpdateInfoAction,
  AuthActionTypes,
  ChangePasswordAction,
  ChangePasswordErrorAction,
  CompleteAdminOnboardAction,
  ConfirmMfaTotpTokenAction,
  CreateNewPasswordAction,
  ForgotPasswordAction,
  ForgotPasswordErrorAction,
  GetCurrentUserErrorAction,
  GetMfaSecretCodeErrorAction,
  GetUserContextAction,
  LoginAction,
  LoginErrorAction,
  LogoutAction,
  LogoutErrorAction,
  RemoveMfaForAdminErrorAction,
  RemoveMfaForAdminSuccessAction,
  ResetPasswordAction,
  ResetPasswordErrorAction,
  SetAccountContextAction,
  SsoSignInAction,
  SsoSignInErrorAction,
  UpdateProfileInfoAction,
  UpdateProfileInfoSuccessAction,
  VerifyMfaTotpAction
} from './types';
import {
  AdminApi,
  AdminUserContextDto,
  VerifyIdentityProviderResponseDtoStatusEnum as VerifyStatusEnum
} from '@pclocs/platform-sdk';
import { createApi } from '../../api/sdk';
import { RootState } from '../rootReducer';
import _ from 'lodash';
import { clearGlassUserToken } from '../../helpers/local-storage-helper';

function* login() {
  yield takeLatest(ActionTypes.LOGIN, function*(action: LoginAction) {
    try {
      const user: any = yield call(signIn, action.payload.username, action.payload.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        yield put<AuthActionTypes>({
          type: ActionTypes.NEW_PASSWORD_REQUIRED,
          payload: {
            cognitoUser: user
          }
        });
      } else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        yield put<AuthActionTypes>({
          type: ActionTypes.REQUEST_SOFTWARE_TOKEN_MFA,
          payload: {
            cognitoUser: user
          }
        });
      } else {
        yield put<AuthActionTypes>({
          type: ActionTypes.GET_USER_CONTEXT,
          payload: {
            cognitoUser: user
          }
        });
      }
    } catch (e) {
      console.error(e);
      const errorMessage = e.message.replace(/^PreAuthentication failed with error /, '');
      if (errorMessage === 'Password reset required for restored user.') {
        yield put<ForgotPasswordAction>({
          type: ActionTypes.FORGOT_PASSWORD,
          payload: {
            email: action.payload.username,
            resetPasswordForRestoredAdmin: true
          }
        });
      } else {
        yield put<LoginErrorAction>({
          type: ActionTypes.LOGIN_ERROR,
          payload: {
            email: action.payload.username,
            error: { ...e, message: errorMessage }
          }
        });
      }
    }
  });
}

function* verifyMfaTotp() {
  yield takeLatest(ActionTypes.VERIFY_MFA_TOTP_TOKEN, function*(action: VerifyMfaTotpAction) {
    try {
      yield call(verifyMfaTotpToken, action.payload.cognitoUser, action.payload.mfaTotpVerificationToken);
      yield call(setPreferredMFA, action.payload.cognitoUser, 'TOTP');

      if (action.payload.origin === 'login') {
        yield put<AuthActionTypes>({
          type: ActionTypes.GET_USER_CONTEXT_NO_MFA_CHECK
        });
      } else {
        yield put<AuthActionTypes>({
          type: ActionTypes.VERIFY_MFA_TOTP_TOKEN_PROFILE_CLEANUP
        });
      }
    } catch (e) {
      console.error(e.message);

      if (action.payload.origin === 'profile') {
        yield put<AuthActionTypes>({
          type: ActionTypes.VERIFY_MFA_TOTP_TOKEN_PROFILE_ERROR,
          payload: { error: e }
        });
        yield put<ShowNotificationModalAction>({
          type: ActionTypes.SHOW_NOTIFICATION_MODAL,
          payload: { type: 'error', title: 'Error', content: e.message }
        });
      } else {
        yield put<LoginErrorAction>({
          type: ActionTypes.LOGIN_ERROR,
          payload: { email: action.payload.cognitoUser.getUsername(), error: e }
        });
      }
    }
  });
}

function* confirmMfaTotp() {
  yield takeLatest(ActionTypes.CONFIRM_MFA_TOTP_TOKEN, function*(action: ConfirmMfaTotpTokenAction) {
    try {
      yield call(confirmMfaTotpToken, action.payload.cognitoUser, action.payload.mfaTotpConfirmationToken);
      yield put<AuthActionTypes>({
        type: ActionTypes.GET_USER_CONTEXT_NO_MFA_CHECK
      });
    } catch (e) {
      console.error(e);
      yield put<
        LoginErrorAction
      >({ type: ActionTypes.LOGIN_ERROR, payload: { email: action.payload.cognitoUser.getUsername(), error: e } });
    }
  });
}

function* logout() {
  yield takeEvery(ActionTypes.LOGOUT, function*(action: LogoutAction) {
    try {
      clearGlassUserToken();
      yield call(signOut);
      if (action.payload.redirect) {
        window.location.href = '/login';
      }
    } catch (e) {
      console.error(e);
      yield put<LogoutErrorAction>({ type: ActionTypes.LOGOUT_ERROR, payload: { message: e.message } });
    }
  });
}

function* forgotPasswordAction() {
  // This function is used to send an account recovery code to the user via email
  yield takeLatest(ActionTypes.FORGOT_PASSWORD, function*(action: ForgotPasswordAction) {
    try {
      yield call(forgotPassword, action.payload.email);
      yield put({ type: ActionTypes.FORGOT_PASSWORD_SUCCESS });
      yield put<RedirectAction>({
        type: ActionTypes.REDIRECT,
        redirectTo: '/login/reset-password'
      });
    } catch (e) {
      console.error(e);
      yield put<ForgotPasswordErrorAction>({
        type: ActionTypes.FORGOT_PASSWORD_ERROR,
        payload: { message: e.message }
      });
    }
  });
}

function* resetPasswordAction() {
  // This function takes the account recovery code and uses it to reset the user's password
  yield takeLatest(ActionTypes.RESET_PASSWORD, function*(action: ResetPasswordAction) {
    try {
      yield call(resetPassword, action.payload.email, action.payload.code, action.payload.password);
      yield put({ type: ActionTypes.RESET_PASSWORD_SUCCESS });
    } catch (e) {
      console.error(e);
      yield put<ResetPasswordErrorAction>({
        type: ActionTypes.RESET_PASSWORD_ERROR,
        payload: { message: e.message }
      });
    }
  });
}

function* ssoSignIn() {
  yield takeLatest(ActionTypes.SSO_SIGN_IN, function*(action: SsoSignInAction) {
    try {
      const verifyStatus: string = yield call(verifyProvider, action.payload.providerName.toLowerCase());
      if (verifyStatus === VerifyStatusEnum.Verified) {
        const params = [
          `idp_identifier=${action.payload.providerName.toLowerCase()}`,
          'response_type=code',
          `client_id=${process.env.USER_POOL_CLIENT_ID}`,
          'scope=openid',
          `redirect_uri=${OAUTH_REDIRECT_SIGN_IN_URI}`
        ].join('&');
        const authEndpointUrl = `https://${USER_POOL_DOMAIN}/oauth2/authorize?${params}`;
        window.location.href = authEndpointUrl;
      } else {
        yield put<SsoSignInErrorAction>({
          type: ActionTypes.SSO_SIGN_IN_ERROR,
          payload: {
            message:
              verifyStatus === VerifyStatusEnum.Unsupported
                ? 'SSO provider is not supported'
                : 'SSO provider does not exist'
          }
        });
      }
    } catch (e) {
      console.error(e);
      yield put<SsoSignInErrorAction>({
        type: ActionTypes.SSO_SIGN_IN_ERROR,
        payload: {
          message: 'An unexpected error has occurred. Please try again later.'
        }
      });
    }
  });
}

function* changePasswordAction() {
  yield takeLatest(ActionTypes.CHANGE_PASSWORD, function*(action: ChangePasswordAction) {
    try {
      yield call(changePassword, action.payload.oldPassword, action.payload.newPassword);
      yield put({ type: ActionTypes.CHANGE_PASSWORD_SUCCESS });
    } catch (e) {
      console.error(e);
      yield put<
        ChangePasswordErrorAction
      >({ type: ActionTypes.CHANGE_PASSWORD_ERROR, payload: { message: e.message } });
    }
  });
}

function* createNewPassword() {
  yield takeLatest(ActionTypes.CREATE_NEW_PASSWORD, function*(action: CreateNewPasswordAction) {
    try {
      yield call(completeNewPassword, action.payload.cognitoUser, action.payload.password);
      const username: string = action.payload.cognitoUser.getSignInUserSession().getIdToken().payload?.[
        'cognito:username'
      ];
      yield put<AuthActionTypes>({
        type: ActionTypes.COMPLETE_ADMIN_ONBOARD,
        payload: {
          id: username,
          adminUser: action.payload.adminUser
        }
      });
    } catch (e) {
      console.error(e);
      yield put<LoginErrorAction>({ type: ActionTypes.LOGIN_ERROR, payload: { error: e } });
    }
  });
}

function* completeAdminOnboard() {
  yield takeLatest(ActionTypes.COMPLETE_ADMIN_ONBOARD, function*(action: CompleteAdminOnboardAction) {
    try {
      const userContext: AdminUserContextDto = yield call(getCurrentUserContext);
      yield put<AdminOnboardUpdateInfoAction>({
        type: ActionTypes.ADMIN_ONBOARD_UPDATE_INFO,
        payload: {
          currentUser: userContext
        }
      });

      const defaultAccountId: string | null = localStorage.getItem(`defaultAccountId.${userContext.id}`)
        ? localStorage.getItem(`defaultAccountId.${userContext.id}`)
        : userContext.accounts[0].id;
      yield put<AuthActionTypes>({
        type: ActionTypes.SET_ACCOUNT_CONTEXT,
        payload: {
          accountId: defaultAccountId ? defaultAccountId : ''
        }
      });

      const aaf = createApi<AdminApi>('AdminApi');
      const response: ApiReturnType<typeof aaf.updateOne> = yield call(
        aaf.updateOne,
        action.payload.id,
        action.payload.adminUser
      );
      yield put<
        UpdateProfileInfoSuccessAction
      >({ type: ActionTypes.UPDATE_PROFILE_INFO_SUCCESS, payload: { adminUser: response.data } });

      yield put<RedirectAction>({
        type: ActionTypes.REDIRECT,
        redirectTo: `/auth/${defaultAccountId}/dashboard`
      });
    } catch (e) {
      console.error(e);
      yield put<LoginErrorAction>({ type: ActionTypes.LOGIN_ERROR, payload: { error: e } });
    }
  });
}

function* getUserContext() {
  yield takeLatest(ActionTypes.GET_USER_CONTEXT, function*(action: GetUserContextAction) {
    try {
      const userContext: AdminUserContextDto = yield call(getCurrentUserContext);
      if (userContext.accounts.length === 0) {
        yield put<LoginErrorAction>({
          type: ActionTypes.LOGIN_ERROR,
          payload: { error: { message: 'No active accounts found for user', code: 'EvalError' } }
        });
      } else {
        const localStorageItem: string | null = localStorage.getItem(`defaultAccountId.${userContext.id}`);
        const defaultAccountId: string | null =
          localStorageItem && _.find(userContext.accounts, ['id', localStorageItem])
            ? localStorage.getItem(`defaultAccountId.${userContext.id}`)
            : userContext.accounts[0].id;

        const defaultAccount = _.find(userContext.accounts, ['id', defaultAccountId]);
        if (defaultAccount.mfaRequired) {
          const mfaSecretCode = yield call(setupMfaTotp, action.payload.cognitoUser);
          yield put<AuthActionTypes>({
            type: ActionTypes.SETUP_SOFTWARE_TOKEN_MFA,
            payload: {
              cognitoUser: action.payload.cognitoUser,
              mfaSecretCode
            }
          });
        } else {
          yield put<AuthActionTypes>({
            type: ActionTypes.LOGIN_SUCCESS,
            payload: {
              currentUser: userContext
            }
          });
          yield put<AuthActionTypes>({
            type: ActionTypes.SET_ACCOUNT_CONTEXT,
            payload: {
              accountId: defaultAccountId ? defaultAccountId : ''
            }
          });
          yield put<RedirectAction>({
            type: ActionTypes.REDIRECT,
            redirectTo: `/auth/${defaultAccountId}/dashboard`
          });
        }
      }
    } catch (e) {
      console.error(e);
      yield put<LoginErrorAction>({ type: ActionTypes.LOGIN_ERROR, payload: { error: e } });
    }
  });
}

function* getUserContextWithNoMfaCheck() {
  yield takeLatest(ActionTypes.GET_USER_CONTEXT_NO_MFA_CHECK, function*() {
    try {
      const userContext: AdminUserContextDto = yield call(getCurrentUserContext);
      const localStorageItem: string | null = localStorage.getItem(`defaultAccountId.${userContext.id}`);
      const defaultAccountId: string | null =
        localStorageItem && _.find(userContext.accounts, ['id', localStorageItem])
          ? localStorage.getItem(`defaultAccountId.${userContext.id}`)
          : userContext.accounts[0].id;

      yield put<AuthActionTypes>({
        type: ActionTypes.LOGIN_SUCCESS,
        payload: {
          currentUser: userContext
        }
      });
      yield put<AuthActionTypes>({
        type: ActionTypes.SET_ACCOUNT_CONTEXT,
        payload: {
          accountId: defaultAccountId ? defaultAccountId : ''
        }
      });
      yield put<RedirectAction>({
        type: ActionTypes.REDIRECT,
        redirectTo: `/auth/${defaultAccountId}/dashboard`
      });
    } catch (e) {
      console.error(e);
      yield put<LoginErrorAction>({ type: ActionTypes.LOGIN_ERROR, payload: { error: e } });
    }
  });
}

function* getCurrentUser() {
  yield takeLatest(ActionTypes.GET_CURRENT_USER, function*() {
    try {
      const userContext: AdminUserContextDto = yield call(getCurrentUserContext);

      yield put<AuthActionTypes>({
        type: ActionTypes.GET_CURRENT_USER_SUCCESS,
        payload: {
          currentUser: userContext
        }
      });
    } catch (e) {
      console.error(e);
      yield put<GetCurrentUserErrorAction>({ type: ActionTypes.GET_CURRENT_USER_ERROR, payload: { error: e } });
    }
  });
}

function* getMfaSecretCode() {
  yield takeLatest(ActionTypes.GET_MFA_SECRET_CODE, function*() {
    try {
      const user = yield call(currentUserPoolUser);
      const mfaSecretCode = yield call(setupMfaTotp, user);
      yield put<AuthActionTypes>({
        type: ActionTypes.SETUP_SOFTWARE_TOKEN_MFA,
        payload: {
          cognitoUser: user,
          mfaSecretCode
        }
      });
    } catch (e) {
      console.error(e);
      yield put<GetMfaSecretCodeErrorAction>({ type: ActionTypes.GET_MFA_SECRET_CODE_ERROR, payload: { error: e } });
    }
  });
}

function* updateProfileInfo() {
  yield takeLatest(ActionTypes.UPDATE_PROFILE_INFO, function*(action: UpdateProfileInfoAction) {
    try {
      const aaf = createApi<AdminApi>('AdminApi');
      const response: ApiReturnType<typeof aaf.updateOne> = yield call(
        aaf.updateOne,
        action.payload.id,
        action.payload.adminUser
      );
      yield put<
        UpdateProfileInfoSuccessAction
      >({ type: ActionTypes.UPDATE_PROFILE_INFO_SUCCESS, payload: { adminUser: response.data } });
    } catch (e) {
      console.error(e);
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: e.response.data.message }
      });
    }
  });
}

function* removeMfaForAdmin() {
  yield takeLatest(ActionTypes.REMOVE_MFA_FOR_ADMIN, function*() {
    try {
      const user = yield call(currentUserPoolUser);
      yield call(setPreferredMFA, user, 'NOMFA');
      yield put<RemoveMfaForAdminSuccessAction>({ type: ActionTypes.REMOVE_MFA_FOR_ADMIN_SUCCESS });
    } catch (e) {
      console.error(e);
      yield put<RemoveMfaForAdminErrorAction>({ type: ActionTypes.REMOVE_MFA_FOR_ADMIN_ERROR });
      yield put<ShowNotificationModalAction>({
        type: ActionTypes.SHOW_NOTIFICATION_MODAL,
        payload: { type: 'error', title: 'Error', content: e.message }
      });
    }
  });
}

export function* setDefaultAccountContext() {
  yield takeLatest(ActionTypes.SET_DEFAULT_ACCOUNT_CONTEXT, function*(action: SetAccountContextAction) {
    try {
      const userId: AdminUserContextDto['id'] = yield select((state: RootState) => state.auth.currentUser?.id);
      if (action.payload.accountId && userId) {
        localStorage.setItem(`defaultAccountId.${userId}`, action.payload.accountId);
        window.location.href = `/auth/${action.payload.accountId}/dashboard`;
      }
    } catch (e) {
      console.error(e);
    }
  });
}

export function* authSaga() {
  yield all([
    login(),
    logout(),
    forgotPasswordAction(),
    resetPasswordAction(),
    ssoSignIn(),
    changePasswordAction(),
    getUserContext(),
    getCurrentUser(),
    createNewPassword(),
    completeAdminOnboard(),
    updateProfileInfo(),
    setDefaultAccountContext(),
    verifyMfaTotp(),
    confirmMfaTotp(),
    getUserContextWithNoMfaCheck(),
    removeMfaForAdmin(),
    getMfaSecretCode()
  ]);
}
