export enum BayState {
  'Unreserved',
  'Door open',
  'Assigned',
  'Offline',
  'Breach',
  'Stuck'
}
export enum BayStateClass {
  'bay-status-unreserved',
  'bay-status-open',
  'bay-status-assigned',
  'bay-status-offline',
  'bay-status-breach',
  'bay-status-stuck'
}
