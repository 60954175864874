import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';

export const validatePinOrRfidExists = (form: WrappedFormUtils) => (
  rule: any,
  value: string,
  callback: (error?: string) => void
) => {
  const otherField = rule.field === 'pin' ? 'rfid' : 'pin';
  const otherValue = form.getFieldValue(otherField);
  callback(!value && !otherValue ? 'PIN or RFID is required.' : undefined);
};

export const minLength: number = 4;
export const maxLength: number = 12;
export const validPinPattern: RegExp = new RegExp('^[0-9]*$');
