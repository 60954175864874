import React, { Dispatch } from 'react';
import * as ActionTypes from '../../store/actionTypes';
import { connect, ConnectedProps } from 'react-redux';
import { Alert, Button, Modal } from 'antd';
import { useEverySdkCall } from '../../helpers/sdk-hooks';
import { ShowNotificationMessageAction, ShowNotificationModalAction } from '../../store/common/types';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<ShowNotificationMessageAction | ShowNotificationModalAction>) => ({
  showNotification: (payload: ShowNotificationMessageAction['payload']) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload
    }),
  showNotificationModal: (payload: ShowNotificationModalAction['payload']) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MODAL,
      payload
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  selectedUsers: { id: string; name: string }[];
  onDelete: (deletedIds: string[]) => void;
  onCancel: () => void;
};

export const StationUserDeleteModal = connector((props: Props) => {
  const usersToDisplay = props.selectedUsers.slice(0, 15);

  const [deleteUsers, deleteUsersSubmitting] = useEverySdkCall(
    'StationUserInternalApi',
    'deleteMany',
    res => {
      const failedDeleteUsersMsg: string = res.data.failedIds.length
        ? ` Failed to delete ${res.data.failedIds.length} users.`
        : '';
      props.showNotification({
        type: 'success',
        content: `${props.selectedUsers.length} users have been deleted.${failedDeleteUsersMsg}`
      });
      props.onDelete(props.selectedUsers.map(v => v.id).filter(id => !res.data.failedIds.includes(id)));
    },
    () => {
      props.showNotificationModal({
        type: 'error',
        title: 'Error',
        content: `Users could not be deleted`
      });
    }
  );

  return (
    <Modal
      title="Confirm Delete Users"
      width={600}
      visible={true}
      maskClosable={!deleteUsersSubmitting}
      onCancel={props.onCancel}
      destroyOnClose={true}
      footer={[
        <Button key="back" className="button-secondary" onClick={props.onCancel} disabled={deleteUsersSubmitting}>
          Cancel
        </Button>,
        <Button
          key="submit"
          className="btn-danger"
          onClick={e => {
            e.preventDefault();
            deleteUsers({ ids: props.selectedUsers.map(v => v.id) });
          }}
          loading={deleteUsersSubmitting}
          disabled={deleteUsersSubmitting}>
          Delete
        </Button>
      ]}>
      <div className="station-user-delete-section">
        <p>Are you sure you want to delete the following {props.selectedUsers.length} users?</p>
        <ul className="station-user-delete-list">
          {usersToDisplay.map((user, idx) => (
            <li key={idx} title={user.name}>
              {user.name}
            </li>
          ))}
          {props.selectedUsers.length > usersToDisplay.length && (
            <li className="others">and {props.selectedUsers.length - usersToDisplay.length} others</li>
          )}
        </ul>
        <Alert
          type="warning"
          message="Warning"
          description={
            <ul>
              <li>
                Deleting these users will remove them from any group, station or bay where they may be authorised.
              </li>
              <li>Deleting users who have current bay assignments will NOT remove their assignment.</li>
            </ul>
          }
          showIcon
        />
      </div>
    </Modal>
  );
});
