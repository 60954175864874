import React, { useState } from 'react';
import { Button, Checkbox, Input, Spin } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Validations } from '../../../constants/validations';
import { getErrorAlertMessage, Props } from '../login-form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isBrandPCLocs } from '../../../helpers/brand-helper';

export const CompleteAdminOnboardForm = (properties: { props: Props }) => {
  const props = properties.props;

  const [isTermsAndConditionsChecked, setTermsAndConditions] = useState(false);
  const onCheckTermsAndConditions = (e: CheckboxChangeEvent) => {
    setTermsAndConditions(e.target.checked);
  };

  const compareToFirstPassword = (rule: any, value: any, callback: { (arg0: string): void; (): void }) => {
    const { form } = props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Passwords do not match');
    }
    callback();
  };

  const securePassword = (rule: any, value: any, callback: { (arg0: string): void; (): void }) => {
    if (!value || !value.match(Validations.securePassword.rule)) {
      callback(Validations.securePassword.message);
    }
    callback();
  };

  return (
    <>
      <div>
        <p>To accept your invitation, please enter your details below.</p>

        <LegacyForm.Item>
          {props.form.getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input name!' }]
          })(<Input prefix={<UserOutlined />} placeholder="Name" />)}
        </LegacyForm.Item>

        <LegacyForm.Item hasFeedback>
          {props.form.getFieldDecorator('newPassword', {
            rules: [{ required: true, message: 'Please input your password!' }, { validator: securePassword }]
          })(<Input.Password prefix={<LockOutlined />} placeholder="Password" autoComplete="new-password" />)}
        </LegacyForm.Item>

        <LegacyForm.Item hasFeedback>
          {props.form.getFieldDecorator('confirm', {
            rules: [{ required: true, message: 'Please confirm your password!' }, { validator: compareToFirstPassword }]
          })(<Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" />)}
        </LegacyForm.Item>
      </div>

      {props.error && getErrorAlertMessage(props)}

      <LegacyForm.Item>
        <Checkbox id="register_terms_check" style={{ lineHeight: '20px' }} onChange={onCheckTermsAndConditions}>
          I agree to the{' '}
          <a
            href={
              isBrandPCLocs()
                ? 'https://www.pclocs.com.au/terms-of-service/'
                : 'https://www.lockncharge.com/terms-of-service/'
            }
            target="_blank"
            rel="noopener noreferrer">
            terms and conditions
          </a>
          .
        </Checkbox>

        <Spin spinning={props.pending === true}>
          <Button
            block
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={!isTermsAndConditionsChecked}>
            Save and continue
          </Button>
        </Spin>
      </LegacyForm.Item>
    </>
  );
};
