import React from 'react';
import { Switch, Tooltip } from 'antd';
import { OverrideButton } from '../../Buttons/Override';
import { essentialsTierOrAbove } from '../../../helpers/subscription-helper';
import { SubscriptionResponseDto } from '@pclocs/platform-sdk';
import { subscriptionMessage } from '../../../constants/validations';
import { FirmwareUpdateInProgress } from '../Station/Settings';

type Props = {
  derivedValue: boolean;
  isOverridden: boolean;
  showOverrideButton: boolean;
  changeBayOfflineWhenAccessedPending: boolean;
  changeBayOfflineWhenAccessed: (val: boolean) => void;
  toggleBayOfflineWhenAccessedOverride: (val: boolean | null) => void;
  subscriptionInfo?: SubscriptionResponseDto;
  firmwareUpdateInProgress?: FirmwareUpdateInProgress;
};

export const BayOfflineWhenAccessed = (props: Props) => {
  const isFirmwareUpdateInProgress: boolean = !!props.firmwareUpdateInProgress;
  const handleOverrideClick = (override: boolean) => {
    props.toggleBayOfflineWhenAccessedOverride(override ? props.derivedValue : null);
  };
  const correctSubscription: boolean = essentialsTierOrAbove(props.subscriptionInfo);
  const switchElement: JSX.Element = (
    <div>
      <Switch
        title={!correctSubscription ? subscriptionMessage : undefined}
        className="takeBayOfflineWhenAccessed-btn"
        disabled={
          !correctSubscription ||
          isFirmwareUpdateInProgress ||
          !props.isOverridden ||
          props.changeBayOfflineWhenAccessedPending
        }
        checked={props.derivedValue}
        loading={props.changeBayOfflineWhenAccessedPending}
        onClick={props.changeBayOfflineWhenAccessed}
      />{' '}
      Take bay offline when accessed
    </div>
  );
  const overrideBtn: JSX.Element = (
    <OverrideButton
      overridden={props.isOverridden}
      pending={props.changeBayOfflineWhenAccessedPending}
      onClick={handleOverrideClick}
      disabled={!correctSubscription || isFirmwareUpdateInProgress}
    />
  );

  return (
    <div className="content-split">
      {!correctSubscription ? (
        <Tooltip title={subscriptionMessage} placement="right">
          {switchElement}
        </Tooltip>
      ) : (
        switchElement
      )}
      {props.showOverrideButton &&
        (!correctSubscription ? (
          <Tooltip title={subscriptionMessage} placement="left">
            <span>{overrideBtn}</span>
          </Tooltip>
        ) : (
          overrideBtn
        ))}
    </div>
  );
};
