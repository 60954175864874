import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Logo } from '../Logo';
import { ResetPassword } from './reset-password';
import { ForgotPassword } from './forgot-password';
import { LoginForm } from './login-form';
import { SsoSignIn } from './sso-sign-in';
import { SsoAuth } from './sso-auth';

export const Login = () => {
  return (
    <div className="login">
      <div>
        <Logo />

        <Switch>
          <Route exact path="/login" component={LoginForm} />
          <Route path="/login/forgot-password" component={ForgotPassword} />
          <Route path="/login/reset-password" component={ResetPassword} />
          <Route path="/login/sso-sign-in" component={SsoSignIn} />
          <Route path="/login/auth" component={SsoAuth} />
        </Switch>
      </div>
    </div>
  );
};
