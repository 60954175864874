import React, { Dispatch, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Modal, Button, Tree, Skeleton, Tooltip, Popconfirm, Input } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import { RootState } from '../../../../store/rootReducer';
import { useEverySdkCall } from '../../../../helpers/sdk-hooks';
import {
  useGroupHierarchy,
  groupHierarchyToArray,
  createTreeNodes,
  filterGroupNode
} from '../../../../helpers/group-hierarchy';
import * as ActionTypes from '../../../../store/actionTypes';
import {
  CommonActionTypes,
  ShowNotificationMessageAction,
  ShowNotificationModalAction
} from '../../../../store/common/types';
import { DashboardActionTypes } from '../../../../store/dashboard/types';
import { subscriptionMessage } from '../../../../constants/validations';
import { ButtonProps } from 'antd/lib/button';
import { FeatureId, useFeatureAvailability } from '../../../../helpers/feature-availability-hooks';
import { TreeGroupNodeDto } from '@pclocs/platform-sdk';
import _ from 'lodash';

const mapStateToProps = (state: RootState) => ({
  subscriptionInfo: state.account.subscriptionInfo
});
const mapDispatchToProps = (dispatch: Dispatch<CommonActionTypes | DashboardActionTypes>) => ({
  showNotificationMessage: (payload: ShowNotificationMessageAction['payload']) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload
    }),
  showNotificationModal: (payload: ShowNotificationModalAction['payload']) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MODAL,
      payload
    }),
  refreshPage: (stationId: string) => {
    dispatch({ type: ActionTypes.FETCH_GROUP_HIERARCHY, payload: { forceUpdate: true } });
    dispatch({ type: ActionTypes.FETCH_STATION, payload: { id: stationId } });
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  stationId: string;
  disabled: boolean;
};

export const MoveStation = connector((props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [hasFeature] = useFeatureAvailability();
  const accountAllowedSubgroups = hasFeature(FeatureId.MANAGEDSTATIONS);
  const [searchTerm, setSearchTerm] = useState('');

  const tree = useGroupHierarchy();
  const flatGroups = tree && groupHierarchyToArray(tree);
  const currentGroup = flatGroups?.find(g => g.stations.includes(props.stationId));
  const currentGroupId = currentGroup?.id;
  const visibleRootGroup = flatGroups?.find(g => g.isRoot);

  useEffect(() => {
    setSelectedGroupId(currentGroupId);
  }, [currentGroupId]);

  const [moveStation, moveStationPending] = useEverySdkCall(
    'StationApi',
    'moveToGroup',
    () => {
      setShowModal(false);
      props.showNotificationMessage({ type: 'success', content: 'Station has been moved.' });
      props.refreshPage(props.stationId);
    },
    () => {
      setShowModal(false);
      props.showNotificationModal({ type: 'error', title: 'Error', content: 'Could not move station to the group.' });
    }
  );

  const moveButtonProps: Partial<ButtonProps> = {
    id: 'move-station-btn',
    className: 'btn-primary',
    icon: <DatabaseOutlined />,
    loading: moveStationPending,
    disabled: !!props.disabled,
    children: 'Move Station'
  };

  const filterTree = (tree: Record<string, TreeGroupNodeDto>, searchTerm: string) => {
    return _.mapValues(tree, node => {
      return { ...node, stations: [], groups: {}, ...filterGroupNode(node, searchTerm) };
    });
  };

  const filteredTree = searchTerm?.trim().length > 0 ? filterTree(tree, searchTerm.trim()) : tree;

  return (
    <>
      <div className="tooltip-btn" style={{ marginTop: 20 }}>
        {tree && currentGroup && (
          <>
            {accountAllowedSubgroups ? (
              <Button
                {...moveButtonProps}
                onClick={() => {
                  setSelectedGroupId(currentGroupId);
                  setShowModal(true);
                }}
              />
            ) : !currentGroup.isRoot && visibleRootGroup ? (
              <Popconfirm
                placement="right"
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  moveStation({ stationIds: [props.stationId], groupId: visibleRootGroup.id });
                }}>
                <Button {...moveButtonProps}>Move station to root group</Button>
              </Popconfirm>
            ) : (
              <Tooltip title={subscriptionMessage} placement="right">
                <Button {...moveButtonProps} disabled={true} />
              </Tooltip>
            )}
          </>
        )}
      </div>

      <Modal
        title="Move Station"
        width={480}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        okText="Move"
        cancelButtonProps={{ disabled: moveStationPending }}
        okButtonProps={{ loading: moveStationPending, disabled: currentGroupId === selectedGroupId }}
        onOk={() => moveStation({ stationIds: [props.stationId], groupId: selectedGroupId })}>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
          placeholder="Filter by name"
          style={{ marginBottom: 15 }}
        />
        {tree ? (
          <div style={{ overflow: 'auto' }}>
            <Tree
              showLine
              defaultExpandAll
              height={530}
              onSelect={selectedKeys => setSelectedGroupId(selectedKeys[0] as string)}
              autoExpandParent
              blockNode
              selectedKeys={selectedGroupId ? [selectedGroupId] : undefined}
              treeData={createTreeNodes(filteredTree)}
            />
          </div>
        ) : (
          <Skeleton active />
        )}
      </Modal>
    </>
  );
});
