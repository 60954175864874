import { useEffect, useState } from 'react';

export const useDebouncedHook = (val: string, wait: number = 400) => {
  const [debouncedStr, setDebouncedStr] = useState(val);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedStr(val), wait);
    return () => clearTimeout(handler);
  }, [val, wait]);

  return debouncedStr;
};
