import React from 'react';
import { MfaQrCode } from '../../MFA/mfa-qr-code';
import { MfaForm } from '../../MFA/mfa-form';
import { Props } from '../login-form';

export const SetupMfaTotpForm = (properties: { props: Props }) => {
  const props = properties.props;

  return (
    <>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <h5>Two-Factor Authenticator</h5>
        <p>
          To set up 2FA, scan the QR code with an authenticator app, then enter the one-time password that it generates.
        </p>
      </div>
      <MfaQrCode username={props.cognitoUser.getUsername()} secretCode={props.mfaSecretCode} pending={false} />

      <MfaForm props={props} />
    </>
  );
};
