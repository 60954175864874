import React, { useState, useEffect } from 'react';
import { Select, Popconfirm } from 'antd';
import { OverrideButton } from '../../../Buttons/Override';
import { GroupResponseDto, SubscriptionResponseDto } from '@pclocs/platform-sdk';
import * as moment from 'moment-timezone';
import { essentialsTierOrAbove } from '../../../../helpers/subscription-helper';
import _ from 'lodash';
import { subscriptionMessage } from '../../../../constants/validations';

type Props = {
  group: GroupResponseDto;
  changeTimezonePending: boolean;
  changeTimezone: (val: string) => void;
  toggleTimezoneOverride: (val: string | null) => void;
  subscriptionInfo: SubscriptionResponseDto;
};

let timezoneList: { label: string; value: string }[];
export const getTimezoneList = () => {
  if (timezoneList === undefined) {
    timezoneList = _.chain([
      ..._.flatten<moment.MomentZoneOffset>(moment.tz.countries().map(v => moment.tz.zonesForCountry(v, true))),
      { name: 'UTC', offset: 0 }
    ])
      .uniqBy('name')
      .sort((a, b) => b.offset - a.offset || a.name.localeCompare(b.name))
      .map((tz: moment.MomentZoneOffset) => {
        return {
          value: tz.name,
          label: `(UTC${moment.tz(tz.name).format('Z')}) ${tz.name.replace(/_/g, ' ')}`
        };
      })
      .value();
  }
  return timezoneList;
};

export const Timezone = (props: Props) => {
  const tzNames = getTimezoneList();
  const derivedValue = props.group.derivedConfig.timezone?.toString();
  const [pendingValue, setPendingValue] = useState<string>();
  const correctSubscription: boolean = essentialsTierOrAbove(props.subscriptionInfo);

  useEffect(() => {
    if (props.changeTimezonePending === false) {
      setPendingValue(undefined);
    }
  }, [props.changeTimezonePending]);

  const isOverridden = props.group.config?.timezone !== undefined;

  const handleOverrideClick = (override: boolean) => {
    props.toggleTimezoneOverride(override ? derivedValue : null);
  };

  return (
    <div className="content-split" title={!correctSubscription ? subscriptionMessage : undefined}>
      <div>
        <label>Timezone: </label>
        <Popconfirm
          visible={pendingValue !== undefined && !props.changeTimezonePending && pendingValue !== derivedValue}
          placement="top"
          title="This action will restart all stations. Continue to set the timezone?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => {
            props.changeTimezone(pendingValue as string);
          }}
          onCancel={() => {
            setPendingValue(undefined);
          }}>
          <Select
            showSearch
            style={{ width: 300, marginLeft: 10 }}
            placeholder="Select a timezone"
            loading={props.changeTimezonePending}
            value={pendingValue ? pendingValue : derivedValue}
            disabled={!isOverridden || props.changeTimezonePending || (!correctSubscription && !props.group?.isRoot)}
            onChange={(v: string) => {
              setPendingValue(v);
            }}>
            {tzNames.map(tz => {
              return (
                <Select.Option key={tz.value} value={tz.value}>
                  {tz.label}
                </Select.Option>
              );
            })}
          </Select>
        </Popconfirm>
      </div>
      {!props.group.isRoot && (
        <div title={!correctSubscription ? subscriptionMessage : undefined} style={{ float: 'right' }}>
          <OverrideButton
            disabled={!correctSubscription}
            overridden={isOverridden}
            pending={props.changeTimezonePending}
            onClick={handleOverrideClick}
          />
        </div>
      )}
    </div>
  );
};
