import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { notification } from 'antd';
import React from 'react';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';

export const DegradationStatusBanner = () => {
  const status = useSelector((state: RootState) => state.degradationStatus.status);

  const [notificationMessage, setNotificationMessage] = useState<string | undefined>();
  const [, notificationElement] = notification.useNotification();

  useEffect(() => {
    const key = 'error';

    if (!!status && !(notificationMessage === status)) {
      notification.open({
        message: status,
        style: { backgroundColor: '#FFF4E5' },
        icon: <WarningOutlined style={{ color: '#EF6C00' }} />,
        closeIcon: <CloseOutlined style={{ paddingTop: '7px' }} />,
        placement: 'bottomLeft',
        duration: 0,
        key,
        onClose: () => setNotificationMessage(null)
      });
      setNotificationMessage(status);
    } else if (!status && notificationMessage) {
      notification.close(key);
      setNotificationMessage(null);
    }
  }, [status, setNotificationMessage]);

  return <>{notificationElement}</>;
};
