import React, { Dispatch, useEffect, useState } from 'react';
import { IntegrationsActionTypes } from '../../../store/integrations/types';
import { connect, ConnectedProps } from 'react-redux';
import * as ActionTypes from './../../../store/actionTypes';
import { useSdkCallOnChanges } from '../../../helpers/sdk-hooks';
import { ShowNotificationMessageAction } from '../../../store/common/types';
import {
  FeaturesDtoFeaturesEnum,
  GoogleWorkspaceConfigResponseDtoTypeEnum,
  LdapUserDirectoryConfigResponseDtoTypeEnum,
  NoUserDirectoryConfigResponseDtoTypeEnum
} from '@pclocs/platform-sdk';
import { InitialDataLoader } from './initial-data-loader';
import { useFeatureAvailability } from '../../../helpers/feature-availability-hooks';
import { Alert, Radio, RadioChangeEvent } from 'antd';
import { subscriptionMessage } from '../../../constants/validations';
import { GoogleWorkspaceForm } from './google-workspace-form';
import { LdapUserDirectoryForm } from './ldap-user-directory-form';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch<IntegrationsActionTypes | ShowNotificationMessageAction>) => ({
  showNotification: (payload: ShowNotificationMessageAction['payload']) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const ExternalUsers = connector((props: Props) => {
  const [currentId, setCurrentId] = useState<string | undefined>();
  const [currentType, setCurrentType] = useState<'google' | 'ldap'>('ldap');

  const [initialLoading, initialConfig, initialLoadingError] = useSdkCallOnChanges(
    'ExternalUserDirectoryApi',
    'getCurrentDirectoryConfig'
  );

  const [hasFeature, featureDetectionLoading] = useFeatureAvailability();
  const hasExternalDirectoriesFeature = hasFeature(FeaturesDtoFeaturesEnum.EXTERNALUSERDIRECTORIES);
  const hasGoogleWorkspaceIntegrationFeature = hasFeature(FeaturesDtoFeaturesEnum.GOOGLEWORKSPACEINTEGRATION);

  useEffect(() => {
    if (initialConfig && initialConfig.type !== NoUserDirectoryConfigResponseDtoTypeEnum.None) {
      setCurrentId(initialConfig.id);
      setCurrentType(initialConfig.type);
    }
  }, [initialConfig]);

  const handleTypeChange = (e: RadioChangeEvent) => {
    const targetType = e.target.value as 'google' | 'ldap';
    setCurrentType(targetType);
  };

  const ldapConfig =
    initialConfig?.type === LdapUserDirectoryConfigResponseDtoTypeEnum.Ldap ? initialConfig : undefined;
  const googleConfig =
    initialConfig?.type === GoogleWorkspaceConfigResponseDtoTypeEnum.Google ? initialConfig : undefined;

  return (
    <div className="content-section">
      <div className="content-section-inner">
        <InitialDataLoader loading={initialLoading || featureDetectionLoading} error={!!initialLoadingError}>
          {!hasExternalDirectoriesFeature && (
            <Alert style={{ marginBottom: 16 }} type="error" message={subscriptionMessage} />
          )}
          {hasGoogleWorkspaceIntegrationFeature ? (
            <>
              <div className="custom-form-input">
                <label>Provider / Protocol</label>
                <Radio.Group
                  buttonStyle="solid"
                  value={currentType}
                  onChange={handleTypeChange}
                  disabled={!hasExternalDirectoriesFeature || !!currentId}>
                  <Radio.Button key="google" value="google">
                    Google Workspace
                  </Radio.Button>
                  <Radio.Button key="ldap" value="ldap">
                    LDAP
                  </Radio.Button>
                </Radio.Group>
              </div>
              {currentType === LdapUserDirectoryConfigResponseDtoTypeEnum.Ldap && (
                <LdapUserDirectoryForm
                  currentId={currentId}
                  showNotification={props.showNotification}
                  setCurrentId={setCurrentId}
                  initialConfig={ldapConfig}
                  hasExternalDirectoriesFeature={hasExternalDirectoriesFeature}
                />
              )}
              {currentType === GoogleWorkspaceConfigResponseDtoTypeEnum.Google && (
                <GoogleWorkspaceForm
                  currentId={currentId}
                  showNotification={props.showNotification}
                  setCurrentId={setCurrentId}
                  initialConfig={googleConfig}
                  hasExternalDirectoriesFeature={hasExternalDirectoriesFeature}
                />
              )}
            </>
          ) : (
            <LdapUserDirectoryForm
              currentId={currentId}
              showNotification={props.showNotification}
              setCurrentId={setCurrentId}
              initialConfig={ldapConfig}
              hasExternalDirectoriesFeature={hasExternalDirectoriesFeature}
            />
          )}
        </InitialDataLoader>
      </div>
    </div>
  );
});
