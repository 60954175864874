import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { isSecretRef } from './secrets-helper';

interface Props extends Omit<InputProps, 'onChange'> {
  value?: string;
  onChange?: (v: string | undefined) => void;
}

export const SecretsInput = forwardRef((props: Props, ref) => {
  useImperativeHandle(ref, () => ({}), []);

  const [initialValue, setInitialValue] = useState<string>();
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.value === undefined || isSecretRef(props.value ?? '')) {
      setInitialValue(props.value);
      setInputValue('');
    } else if (!!props.value) {
      setInputValue(props.value);
    }
  }, [props.value]);

  return (
    <Input
      type="password"
      {...props}
      value={inputValue}
      onChange={e => {
        const newValue = e.target.value;
        setInputValue(newValue);
        props.onChange?.(newValue === '' ? initialValue : newValue);
      }}
      placeholder={initialValue ? 'Using saved value' : props.placeholder}
    />
  );
});
