import React, { Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Alert, Modal, Table } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { RootState } from '../../store/rootReducer';
import { AuthActionTypes } from '../../store/auth/types';
import * as ActionTypes from '../../store/actionTypes';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { AccountContextResponseDto } from '@pclocs/platform-sdk';

const mapStateToProps = (state: RootState) => {
  return {
    currentUser: state.auth.currentUser,
    showSelectAccountModal: state.auth.showSelectAccountModal,
    mfaConfiguredForAdmin: state.auth.mfaConfiguredForAdmin
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AuthActionTypes>) => ({
  setDefaultAccountContext: (accountId: string | undefined) =>
    dispatch({ type: ActionTypes.SET_DEFAULT_ACCOUNT_CONTEXT, payload: { accountId } }),
  closeSelectAccountModal: () =>
    dispatch({ type: ActionTypes.TOGGLE_SELECT_ACCOUNT_MODAL, payload: { showSelectAccountModal: false } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const SelectAccount = connector((props: Props) => {
  const selectedAccount: string = localStorage.getItem(`defaultAccountId.${props.currentUser?.id}`) || '';
  const dataSource = props.currentUser?.accounts;
  const [switchAccountProps, setSwitchAccountProps] = React.useState({ showAlert: false, accountName: undefined });

  const tableColumns: ColumnProps<AccountContextResponseDto>[] = [
    {
      key: 'name',
      render: (account: AccountContextResponseDto) => (
        <>
          <div
            key={account.id}
            className="link"
            onClick={() => {
              if (!props.mfaConfiguredForAdmin && account.mfaRequired) {
                setSwitchAccountProps({ showAlert: true, accountName: account.name });
              } else {
                setDefaultAccount(account.id);
              }
            }}>
            {account.name}
          </div>
          {selectedAccount === account.id && (
            <CheckCircleFilled style={{ color: '#39b54a', float: 'right', marginTop: 3 }} />
          )}
        </>
      )
    }
  ];

  const setDefaultAccount = (accountId: string) => {
    props.setDefaultAccountContext(accountId);
    props.closeSelectAccountModal();
  };

  React.useEffect(() => {
    return () => {
      setSwitchAccountProps({ showAlert: false, accountName: undefined });
    };
  }, [props.showSelectAccountModal]);

  const isFirstLogin = !localStorage.getItem(`defaultAccountId.${props.currentUser?.id}`);

  return (
    <Modal
      title="Select Account"
      visible={
        props.showSelectAccountModal === true ||
        (!!props.currentUser?.accounts && isFirstLogin && props.currentUser?.accounts.length > 1)
      }
      onCancel={props.closeSelectAccountModal}
      footer={null}
      width={416}>
      <div>
        {switchAccountProps.showAlert && (
          <Alert
            message={
              <div>
                <strong>{switchAccountProps.accountName}</strong> account requires Two-factor authentication on login,
                please set up 2FA for your profile&nbsp;
                {isFirstLogin ? (
                  <>in another account</>
                ) : (
                  <Link
                    to={`/auth/${localStorage.getItem(`defaultAccountId.${props.currentUser?.id}`)}/profile`}
                    onClick={props.closeSelectAccountModal}>
                    <>here</>
                  </Link>
                )}
                .
              </div>
            }
            type="warning"
            showIcon
            style={{ marginBottom: 15 }}
          />
        )}
        <Table
          className="no-hover"
          pagination={false}
          scroll={{ y: 395 }}
          style={{ minHeight: 395 }}
          rowKey="id"
          showHeader={false}
          columns={tableColumns}
          dataSource={dataSource}
          size="small"
        />
      </div>
    </Modal>
  );
});
