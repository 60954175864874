import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { reducer } from './rootReducer';
import reduxWebsocket from '@giantmachines/redux-websocket';

import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { rootSaga } from './rootSaga';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const reduxWebsocketMiddleware = reduxWebsocket(
  (() => ({
    reconnectOnClose: false,
    reconnectOnError: false
  }))()
);

// Build the middleware for intercepting and dispatching navigation actions
const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(myRouterMiddleware, sagaMiddleware, reduxWebsocketMiddleware);
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(
      myRouterMiddleware,
      sagaMiddleware,
      reduxWebsocketMiddleware,
      createLogger({ collapsed: true })
    );
  }
};

export const testStore = compose(getMiddleware())(createStore);
export const store = createStore(reducer, composeWithDevTools(getMiddleware()));

sagaMiddleware.run(rootSaga);
