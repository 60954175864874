import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import { RootState } from '../../store/rootReducer';
import * as ActionTypes from '../../store/actionTypes';
import { AuthActionTypes } from '../../store/auth/types';
import { getCurrentAuthenticatedUser } from '../../api/amplify';
import { CommonActionTypes } from '../../store/common/types';

const mapStateToProps = (state: RootState) => ({ error: state.auth.error });
const mapDispatchToProps = (dispatch: Dispatch<AuthActionTypes | CommonActionTypes>) => ({
  getUserContext: () => dispatch({ type: ActionTypes.GET_USER_CONTEXT_NO_MFA_CHECK }),
  redirectToLogin: () => dispatch({ type: ActionTypes.REDIRECT, redirectTo: '/login' })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const CHECK_AUTH_USER_MAX_ATTEMPTS = 15;

export const SsoAuth = connector((props: Props) => {
  const checkAuthUserTimer = useRef(null);
  const [numAttempts, setNumAttempts] = useState<number>(1);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      const checkCurrentAuthUser = async () => {
        setNumAttempts(state => state + 1);
        try {
          await getCurrentAuthenticatedUser();
          props.getUserContext();
          clearInterval(checkAuthUserTimer.current);
        } catch (e) {
          console.error(e);
        }
      };
      checkAuthUserTimer.current = setInterval(() => checkCurrentAuthUser(), 1000);
    } else {
      props.redirectToLogin();
    }

    return () => {
      clearInterval(checkAuthUserTimer.current);
    };
  }, []);

  useEffect(() => {
    if (numAttempts > CHECK_AUTH_USER_MAX_ATTEMPTS) {
      clearInterval(checkAuthUserTimer.current);
    }
  }, [numAttempts]);

  return (
    <>
      {!!props.error || numAttempts > CHECK_AUTH_USER_MAX_ATTEMPTS ? (
        <div data-test-id="sso-auth-error">
          <Alert
            message="An error has occurred verifying your SSO credentials."
            description="Please go to the login page and try again."
            type="error"
            showIcon
          />
          <div className="login-login-link">
            <Link to="/login">Go to login page.</Link>
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }} data-test-id="sso-auth-load">
          <Spin spinning />
          <p>Please wait while we verify your SSO credentials.</p>
        </div>
      )}
    </>
  );
});
