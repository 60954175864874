import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { List } from 'antd';

const SuccessIcon = () => <CheckCircleOutlined className="text-success" />;
const FailIcon = () => <CloseCircleOutlined className="text-danger" />;
const WarningIcon = () => <WarningOutlined className="text-warning" />;
export const CheckItem = (props: {
  isSuccess: boolean;
  title: React.ReactNode;
  canFail?: boolean;
  failDescription?: React.ReactNode;
  warningDescription?: React.ReactNode;
}) => {
  let description = undefined;
  if (!props.isSuccess) {
    description = props.canFail ? props.warningDescription : props.failDescription;
  }
  return (
    <List.Item>
      <List.Item.Meta
        title={
          <>
            {props.isSuccess ? <SuccessIcon /> : props.canFail === true ? <WarningIcon /> : <FailIcon />}&nbsp;&nbsp;
            {props.title}
          </>
        }
        description={description}
      />
    </List.Item>
  );
};
export const MissingAttributesItem = ({
  type,
  attributes
}: {
  type: 'User' | 'Group' | 'Optional';
  attributes: string[];
}) => (
  <CheckItem
    canFail={true}
    isSuccess={!attributes.length}
    title={`${type} attributes`}
    warningDescription={
      <span>
        The following attributes cannot be found:
        <br />
        <ul style={{ marginBottom: 5 }}>
          {attributes.map((v, k) => (
            <li key={k}>{v}</li>
          ))}
        </ul>
      </span>
    }
  />
);
