import React, { useEffect, Dispatch, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col, Button, Input, Popconfirm } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { UserGroupsModal } from './user-groups-modal';
import * as ActionTypes from '../../store/actionTypes';
import { useRouteMatch, Route } from 'react-router-dom';
import { history } from '../../store/store';
import { UserGroupUsers } from './users';
import { StationUserGroupResponseDto } from '@pclocs/platform-sdk/dist';
import { InfinityList } from '../InfinityList';
import { useLatestSdkCall, usePageableSdkCall } from '../../helpers/sdk-hooks';
import {
  CommonActionTypes,
  ShowNotificationMessageAction,
  ShowNotificationModalAction
} from '../../store/common/types';
import _ from 'lodash';

const PAGE_SIZE = 200;

const mapStateToProps = () => ({});

const mapDispatchToProps = (
  dispatch: Dispatch<CommonActionTypes | ShowNotificationMessageAction | ShowNotificationModalAction>
) => ({
  onLoad: () => dispatch({ type: ActionTypes.USER_GROUPS_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ActionTypes.USER_GROUPS_PAGE_UNLOADED }),
  redirectTo: (redirectTo: string) => dispatch({ type: ActionTypes.REDIRECT, redirectTo }),
  showNotification: (payload: ShowNotificationMessageAction['payload']) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MESSAGE,
      payload
    }),
  showErrorNotification: (content: string) =>
    dispatch({
      type: ActionTypes.SHOW_NOTIFICATION_MODAL,
      payload: { type: 'error', title: 'Error', content }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const UserGroups = connector((props: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState<StationUserGroupResponseDto>();

  const { path, url: baseUrl } = useRouteMatch();
  const selectedItemInRoute = useRouteMatch<{ userGroupId: string }>(`${baseUrl}/:userGroupId`)?.params?.userGroupId;

  const toggleDeletingUserGroupNotification = (key: string, show: boolean) => {
    props.showNotification({ type: 'loading', key, content: 'Deleting user group.', duration: show ? 0 : undefined });
  };

  const userGroupsPager = usePageableSdkCall(
    'StationUserGroupInternalApi',
    'findSome',
    { name: undefined },
    (fn, filters, limit, after) => fn(undefined, after, limit, filters.name || undefined, ['id', 'name']),
    res => res
  );

  const reloadUserGroups = () => {
    setSelectedUserGroup(undefined);
    props.redirectTo(baseUrl);
    userGroupsPager.clearItems();
    userGroupsPager.loadItems();
  };

  const [deleteUserGroup, deletingUserGroup] = useLatestSdkCall(
    'StationUserGroupInternalApi',
    'deleteOne',
    () => {
      props.showNotification({ type: 'success', key: selectedUserGroup.id, content: 'User group deleted.' });
      reloadUserGroups();
    },
    () => {
      toggleDeletingUserGroupNotification(selectedUserGroup.id, false);
      props.showErrorNotification('Could not delete user group.');
    }
  );

  useEffect(() => {
    props.onLoad();
    userGroupsPager.loadItems(PAGE_SIZE);
    return () => {
      props.onUnload();
    };
  }, []);

  const setFilters = _.debounce(userGroupsPager.setFilters, 500);

  const onSelect = (userGroup: StationUserGroupResponseDto, fn: () => void) => {
    setSelectedUserGroup(userGroup);
    fn();
  };

  return (
    <div>
      <UserGroupsModal
        userGroup={selectedUserGroup}
        reloadUserGroups={reloadUserGroups}
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <Row gutter={32}>
        <Col span={12}>
          <h4>User Groups</h4>
          <div className="user-groups-section">
            <div className="section-container">
              <div className="content-section">
                <div className="controls-section">
                  <div className="filter-section">
                    <Input
                      placeholder="Search by name"
                      onChange={e => setFilters({ name: e.target.value })}
                      disabled={deletingUserGroup}
                    />
                  </div>

                  <Button onClick={() => onSelect(undefined, () => setModalOpen(true))} disabled={deletingUserGroup}>
                    Create User Groups
                  </Button>
                </div>

                <div className="content-section-inner user-groups-list-content">
                  <InfinityList
                    {...userGroupsPager}
                    pageSize={PAGE_SIZE}
                    render={(userGroup: StationUserGroupResponseDto) => {
                      const isSelected = userGroup.id === (selectedUserGroup?.id ?? selectedItemInRoute);
                      return (
                        <div className="user-group-list-row list-row" data-test-id={`user-group-${userGroup.id}`}>
                          <div className={`user-group-detail${isSelected ? ' selected' : ''}`}>
                            <Button
                              type="link"
                              title={userGroup.name}
                              disabled={deletingUserGroup}
                              onClick={() => onSelect(userGroup, () => history.push(`${baseUrl}/${userGroup.id}`))}>
                              {userGroup.name}
                            </Button>
                          </div>
                          <div className="user-group-actions">
                            <Button
                              size="small"
                              className="btn-primary"
                              onClick={() => onSelect(userGroup, () => setModalOpen(true))}
                              disabled={deletingUserGroup}
                              style={{ marginRight: 10 }}>
                              <EditFilled />
                              Edit
                            </Button>
                            <Popconfirm
                              placement="left"
                              title="Delete user group?"
                              okText="Yes"
                              cancelText="No"
                              disabled={deletingUserGroup}
                              onConfirm={() =>
                                onSelect(userGroup, () => {
                                  toggleDeletingUserGroupNotification(userGroup.id, true);
                                  deleteUserGroup(userGroup.id);
                                })
                              }>
                              <Button className="btn-danger" size="small" disabled={deletingUserGroup}>
                                <DeleteFilled />
                                Delete
                              </Button>
                            </Popconfirm>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <Route path={`${path}/:userGroupId`} component={UserGroupUsers} />
        </Col>
      </Row>
    </div>
  );
});
