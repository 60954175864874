import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { message } from 'antd';
import { NotificationMessageOptions } from '../../store/common/types';

const mapStateToProps = (state: RootState) => {
  return {
    notificationMessage: state.common.notificationMessage
  };
};
const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

// Component with similar look to the ANTD Message functions in a component.
// We are limiting options and functionality however to maintain consistency.
// Ref: https://github.com/ant-design/ant-design/blob/master/components/message/index.tsx
export const NotificationMessage = connector((props: Props) => {
  useEffect(() => {
    props.notificationMessage && getMessage(props.notificationMessage);
  }, [props.notificationMessage]);

  const getMessage = (messageProps: NotificationMessageOptions) => {
    switch (messageProps.type) {
      case 'warning':
      case 'success':
      case 'info':
      case 'error':
        message[messageProps.type](messageProps);
        return;
      case 'loading':
        message.loading({ duration: 0, ...messageProps });
        return;
    }
  };

  return <></>;
});
