import { Modal, Button, Alert } from 'antd';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useFixedSizePageableReducer } from '../../helpers/pageable-reducer';
import { usePageableSdkCall } from '../../helpers/sdk-hooks';
import { RootState } from '../../store/rootReducer';
import { CompatibleUser, UserInfinityList } from '../ManageUsersCommon/user-infinity-list';

const UPDATE_LIMITS = 5000;

const mapStateToProps = (state: RootState) => ({
  globalTags: state.common?.globalTags
});
const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  action: 'add' | 'remove';
  userGroupId: string;
  isSubmitting?: boolean;
  onChange: (removedUserIds: string[]) => void;
  onClose: () => void;
};

export const AddRemoveUsersModal = connector((props: Props) => {
  const PAGE_SIZE = 500;
  const isAdding = props.action === 'add';

  const pager = isAdding
    ? useFixedSizePageableReducer(
        PAGE_SIZE,
        usePageableSdkCall(
          'StationUserInternalApi',
          'findSome',
          { name: undefined, tags: undefined },
          (fn, filters, limit, after) =>
            fn(undefined, after, limit, filters.name, filters.tags, ['id', 'name', 'tags', 'groupIds']),
          res => ({ after: res.after, items: res.items.filter(u => !u.groupIds?.includes(props.userGroupId)) })
        )
      )
    : usePageableSdkCall(
        'StationUserGroupInternalApi',
        'findUsers',
        { name: undefined, tags: undefined },
        (fn, filters, limit, after) =>
          fn(props.userGroupId, undefined, after, limit, filters.name, filters.tags, ['id', 'name', 'tags']),
        res => res
      );

  useEffect(() => {
    pager.loadItems(PAGE_SIZE);
  }, []);

  const [allUserMap, setAllUserMap] = useState<Record<string, CompatibleUser>>({});
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  useEffect(() => {
    setAllUserMap({ ...allUserMap, ..._.keyBy(pager.items, 'id') });
  }, [pager.items]);

  const exceedsUpdateLimits = useMemo(() => selectedUserIds.length > UPDATE_LIMITS, [selectedUserIds, UPDATE_LIMITS]);

  return (
    <Modal
      title={`${isAdding ? 'Add' : 'Remove'} Users`}
      maskClosable={false}
      visible={true}
      onCancel={props.onClose}
      footer={[
        <Button key="back" onClick={props.onClose} className="btn-secondary" disabled={props.isSubmitting}>
          Cancel
        </Button>,
        <Button
          key="submit"
          className={isAdding ? 'btn-primary' : 'btn-danger'}
          onClick={() => props.onChange(selectedUserIds)}
          loading={props.isSubmitting}
          disabled={selectedUserIds.length < 1 || exceedsUpdateLimits}>
          {isAdding ? 'Add' : 'Remove'} selected
        </Button>
      ]}
      destroyOnClose={true}
      width={720}>
      <div style={{ height: '50vh', marginBottom: 10 }}>
        <UserInfinityList
          tagsFilterable={true}
          pager={pager}
          globalTags={props.globalTags ?? []}
          pageSize={PAGE_SIZE}
          selectable={true}
          selectedIds={selectedUserIds}
          onSelect={newSelectedIds => setSelectedUserIds(newSelectedIds)}
          maxSelectableItems={UPDATE_LIMITS}
          selectDisabled={exceedsUpdateLimits}
          unselectDisabled={!selectedUserIds.length}
        />
      </div>

      <div>
        <h6>Selected:</h6>
        <p>
          {selectedUserIds
            .slice(0, 3)
            .map(id => allUserMap[id].name)
            .join(', ')}
          {selectedUserIds.length > 3 && <> and {selectedUserIds.length - 3} others</>}
        </p>
        {exceedsUpdateLimits && (
          <Alert
            style={{ marginTop: 5, marginBottom: 5 }}
            message={`A maximum of ${UPDATE_LIMITS} users can be ${isAdding ? 'added' : 'removed'} at a time.`}
            type="error"
          />
        )}
      </div>
    </Modal>
  );
});
