import * as ActionTypes from '../actionTypes';
import { AuthState, AuthActionTypes } from './types';
import { AdminUserContextDtoTypeEnum } from '@pclocs/platform-sdk/dist/api';

const defaultState: AuthState = {
  currentUser: null,
  pending: false,
  pendingProfile: false,
  changePasswordSuccess: false,
  showCompleteAdminOnboardForm: false,
  showProfileForm: false,
  showLoginForm: false,
  showSelectAccountModal: false,
  mfaSecretCode: undefined,
  showRequestMfaTotpTokenForm: false,
  showSetupMfaTotpForm: false,
  mfaConfiguredForAdmin: false,
  pendingRemoveMfa: false,
  pendingMfaSecretCode: false,
  pendingMfaTotpRegistration: false,
  pendingCurrentUser: false
};

export const auth = (state: AuthState = defaultState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case ActionTypes.LOGIN_PAGE_LOADED:
      return {
        ...state,
        pending: false,
        error: undefined,
        showLoginForm: true,
        showRequestMfaTotpTokenForm: false,
        showSetupMfaTotpForm: false
      };
    case ActionTypes.FORGOT_PASSWORD_PAGE_LOADED:
    case ActionTypes.RESET_PASSWORD_PAGE_LOADED:
      return {
        ...state,
        pending: false,
        error: undefined
      };
    case ActionTypes.LOGIN:
      return {
        ...state,
        pending: true
      };
    case ActionTypes.LOGIN_ERROR:
      return {
        ...state,
        error: action.payload.error,
        pending: false,
        pendingMfaTotpRegistration: false
      };
    case ActionTypes.VERIFY_MFA_TOTP_TOKEN_PROFILE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        pendingMfaTotpRegistration: false
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.currentUser,
        pending: false
      };
    case ActionTypes.GET_CURRENT_USER:
      return {
        ...state,
        pendingCurrentUser: true
      };
    case ActionTypes.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.currentUser,
        pendingCurrentUser: false
      };
    case ActionTypes.GET_CURRENT_USER_ERROR:
      return {
        ...state,
        pendingCurrentUser: false
      };
    case ActionTypes.LOGOUT_ERROR:
      return {
        ...state,
        error: action.payload.message
      };
    case ActionTypes.SET_CONTEXT:
      return {
        ...state,
        currentUser: action.payload.currentUser
      };
    case ActionTypes.GET_MFA_SECRET_CODE:
      return {
        ...state,
        pendingMfaSecretCode: true
      };
    case ActionTypes.FORGOT_PASSWORD:
      return {
        ...state,
        pending: true,
        email: action.payload.email,
        resetPasswordForRestoredAdmin: action.payload.resetPasswordForRestoredAdmin
      };
    case ActionTypes.RESET_PASSWORD:
      return {
        ...state,
        pending: true
      };
    case ActionTypes.SSO_SIGN_IN:
      return {
        ...state,
        pending: true,
        error: null
      };
    case ActionTypes.FORGOT_PASSWORD_ERROR:
    case ActionTypes.RESET_PASSWORD_ERROR:
    case ActionTypes.SSO_SIGN_IN_ERROR:
      return {
        ...state,
        error: action.payload.message,
        pending: false
      };
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        status: 'accountHasBeenCreated',
        pending: false
      };
    case ActionTypes.LOGIN_PAGE_UNLOADED:
    case ActionTypes.FORGOT_PASSWORD_PAGE_UNLOADED:
      return {
        ...state,
        status: '',
        pending: false,
        error: null,
        cognitoUser: undefined,
        showCompleteAdminOnboardForm: false,
        showLoginForm: false,
        showRequestMfaTotpTokenForm: false,
        showSetupMfaTotpForm: false,
        mfaTotpConfirmationToken: undefined,
        mfaSecretCode: undefined,
        mfaTotpVerificationToken: undefined,
        pendingMfaTotpRegistration: false
      };
    case ActionTypes.RESET_PASSWORD_PAGE_UNLOADED:
      return {
        ...defaultState,
        status: '',
        error: null,
        resetPasswordForRestoredAdmin: undefined
      };
    case ActionTypes.CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordSuccess: false,
        changePasswordErrorMessage: undefined,
        pending: true
      };
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        changePasswordSuccess: true
      };
    case ActionTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        pending: false,
        changePasswordErrorMessage: action.payload.message
      };
    case ActionTypes.PROFILE_PAGE_LOADED:
      return {
        ...state,
        showSelectAccountModal: false
      };
    case ActionTypes.PROFILE_PAGE_UNLOADED:
      return {
        ...state,
        pending: false,
        changePasswordErrorMessage: undefined,
        changePasswordSuccess: false
      };
    case ActionTypes.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        pending: false,
        error: undefined,
        cognitoUser: action.payload.cognitoUser,
        showLoginForm: false,
        showCompleteAdminOnboardForm: true
      };
    case ActionTypes.SETUP_SOFTWARE_TOKEN_MFA:
      return {
        ...state,
        pending: false,
        error: undefined,
        showLoginForm: false,
        showSetupMfaTotpForm: true,
        showCompleteAdminOnboardForm: false,
        cognitoUser: action.payload.cognitoUser,
        mfaSecretCode: action.payload.mfaSecretCode,
        pendingMfaSecretCode: false
      };
    case ActionTypes.VERIFY_MFA_TOTP_TOKEN:
      return {
        ...state,
        error: undefined,
        pendingMfaTotpRegistration: true,
        cognitoUser: action.payload.cognitoUser,
        mfaTotpVerificationToken: action.payload.mfaTotpVerificationToken
      };
    case ActionTypes.VERIFY_MFA_TOTP_TOKEN_PROFILE_CLEANUP:
      return {
        ...state,
        pendingMfaTotpRegistration: false,
        mfaConfiguredForAdmin: true,
        mfaSecretCode: undefined,
        mfaTotpVerificationToken: undefined
      };
    case ActionTypes.GET_USER_CONTEXT_NO_MFA_CHECK:
      return {
        ...state,
        mfaConfiguredForAdmin: true
      };
    case ActionTypes.REQUEST_SOFTWARE_TOKEN_MFA:
      return {
        ...state,
        pending: false,
        error: undefined,
        showLoginForm: false,
        cognitoUser: action.payload.cognitoUser,
        showRequestMfaTotpTokenForm: true,
        mfaConfiguredForAdmin: true
      };
    case ActionTypes.CONFIRM_MFA_TOTP_TOKEN:
      return {
        ...state,
        pending: true,
        error: undefined,
        mfaTotpConfirmationToken: action.payload.mfaTotpConfirmationToken
      };
    case ActionTypes.REMOVE_MFA_FOR_ADMIN:
      return {
        ...state,
        pendingRemoveMfa: true
      };
    case ActionTypes.REMOVE_MFA_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        pendingRemoveMfa: false,
        mfaConfiguredForAdmin: false
      };
    case ActionTypes.REMOVE_MFA_FOR_ADMIN_ERROR:
      return {
        ...state,
        pendingRemoveMfa: false
      };
    case ActionTypes.COMPLETE_ADMIN_ONBOARD:
      return {
        ...state,
        pending: true,
        error: undefined,
        showCompleteAdminOnboardForm: true
      };
    case ActionTypes.UPDATE_PROFILE_INFO:
      return {
        ...state,
        pendingProfile: true
      };
    case ActionTypes.UPDATE_PROFILE_INFO_SUCCESS:
      const displayText =
        state.currentUser.type === AdminUserContextDtoTypeEnum.Internal
          ? {
              displayText: action.payload.adminUser.name || ''
            }
          : {};
      return {
        ...state,
        currentUser: state.currentUser
          ? {
              ...state.currentUser,
              name: action.payload.adminUser.name || '',
              ...displayText
            }
          : null,
        pendingProfile: false,
        showProfileForm: false
      };
    case ActionTypes.TOGGLE_PROFILE_FORM:
      return {
        ...state,
        showProfileForm: action.payload.showProfileForm
      };
    case ActionTypes.ADMIN_ONBOARD_UPDATE_INFO:
      return {
        ...state,
        currentUser: action.payload.currentUser
      };
    case ActionTypes.SET_DEFAULT_ACCOUNT_CONTEXT:
    case ActionTypes.SET_ACCOUNT_CONTEXT:
      return {
        ...state,
        accountContext: action.payload.accountId
      };
    case ActionTypes.SET_COGNITO_MFA_CONTEXT:
      return {
        ...state,
        mfaConfiguredForAdmin: action.payload.mfaConfiguredForAdmin
      };
    case ActionTypes.TOGGLE_SELECT_ACCOUNT_MODAL:
      return {
        ...state,
        showSelectAccountModal: action.payload.showSelectAccountModal
      };
    default:
      return state;
  }
};
