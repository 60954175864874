import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Modal, Button, Switch, Tooltip } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { ClockCircleOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { RootState } from '../../../store/rootReducer';
import * as ActionTypes from '../../../store/actionTypes';
import { DashboardActionTypes, DayOfTheWeek } from '../../../store/dashboard/types';
import _ from 'lodash';
import { OverrideButton } from '../../Buttons/Override';
import { CurfewDto, CurfewScheduleDto } from '@pclocs/platform-sdk';
import { CurfewSlider } from './curfew-slider';
import { essentialsTierOrAbove } from '../../../helpers/subscription-helper';
import { subscriptionMessage } from '../../../constants/validations';

const mapStateToProps = (state: RootState) => ({
  idStation: state.dashboard.station?.id,
  idGroup: state.dashboard.group?.id,
  isRootGroup: state.dashboard.group?.isRoot,
  ..._.pick(state.dashboard, ['showCurfewModal', 'updateCurfewPending']),
  curfewScheduleCloned: _.cloneDeep(state.dashboard.curfewSchedule),
  subscriptionInfo: state.account.subscriptionInfo,
  firmwareUpdateInProgress: state.dashboard.station?.reported?.firmwareUpdateInProgress
});
const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes>) => ({
  loadModal: (curfewSchedule: any) => dispatch({ type: ActionTypes.LOAD_CURFEW_MODAL, payload: { curfewSchedule } }),
  unloadModal: () => dispatch({ type: ActionTypes.UNLOAD_CURFEW_MODAL }),
  updateCurfew: (id: string, curfew: CurfewDto | null) =>
    dispatch({ type: ActionTypes.UPDATE_CURFEW, payload: { id, curfew } }),
  updateGroupCurfew: (id: string, curfew: CurfewDto | null) =>
    dispatch({ type: ActionTypes.UPDATE_GROUP_CURFEW, payload: { id, curfew } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux &
  FormComponentProps & { curfew: CurfewDto; isOverridden: boolean; type: 'station' | 'group' };

export const Curfew = connector(
  LegacyForm.create<Props>({ name: 'Curfew' })((props: Props) => {
    const isFirmwareUpdateInProgress: boolean = !!props.firmwareUpdateInProgress;
    const [isOverridden, setIsOverridden] = useState(props.isOverridden);

    useEffect(() => {
      const curfew: CurfewDto = props.curfew ? props.curfew : { enabled: false, schedule: [] };
      props.form.setFieldsValue({ enable: curfew.enabled });
      return () => {
        props.form.resetFields();
      };
    }, [props.showCurfewModal]);

    useEffect(() => {
      setIsOverridden(props.isOverridden);
    }, [!props.showCurfewModal]);

    const { getFieldDecorator } = props.form;

    const formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 21 }
      }
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();
      props.form.validateFieldsAndScroll(async (err: Error, values: any) => {
        if (!err) {
          const curfew: any = isOverridden
            ? { enabled: values.enable, schedule: props.curfewScheduleCloned || [] }
            : null;

          props.type === 'station' && props.idStation && props.updateCurfew(props.idStation, curfew);
          props.type === 'group' && props.idGroup && props.updateGroupCurfew(props.idGroup, curfew);
        }
      });
    };

    const handleToggleOverrideCurfew = () => {
      setIsOverridden(!isOverridden);
    };

    const footer = [];

    const days: DayOfTheWeek[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

    footer.push(
      <Button
        key="back"
        onClick={props.unloadModal}
        style={{ display: props.updateCurfewPending ? 'none' : 'initial' }}
        className="ant-btn-secondary">
        Cancel
      </Button>
    );

    footer.push(
      <Button key="submit" loading={props.updateCurfewPending} onClick={handleSubmit} type="primary">
        Save
      </Button>
    );

    const timeStringToSeconds = (time: string) => {
      const hours: number = parseInt(time.replace(':', '.'));
      const minutes: number = _.ceil((parseFloat(time.replace(':', '.')) % 1) * 1.6666666666666667, 2);

      return (hours + minutes) * 3600;
    };

    const correctSubscription: boolean = essentialsTierOrAbove(props.subscriptionInfo);
    const curfewDisabled: boolean = !correctSubscription || isFirmwareUpdateInProgress;
    const curfewButton: JSX.Element = (
      <Button
        className="btn-primary"
        icon={<ClockCircleOutlined />}
        onClick={() => props.loadModal(props.curfewScheduleCloned)}
        disabled={curfewDisabled}>
        Curfew
      </Button>
    );

    return (
      <>
        <div className="tooltip-btn">
          {!correctSubscription ? (
            <Tooltip title={subscriptionMessage} placement="right">
              {curfewButton}
            </Tooltip>
          ) : (
            curfewButton
          )}
        </div>
        <Modal
          title={'Curfew Policy'}
          width={900}
          visible={props.showCurfewModal}
          onCancel={props.unloadModal}
          footer={footer}>
          <div style={{ height: 530, overflow: 'auto' }}>
            {!(props.type === 'group' && props.isRootGroup) && (
              <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 20 }}>
                <OverrideButton
                  disabled={false}
                  overridden={isOverridden}
                  pending={props.updateCurfewPending}
                  onClick={handleToggleOverrideCurfew}
                />
              </div>
            )}

            <LegacyForm {...formLayout}>
              <LegacyForm.Item label="Enabled">
                {getFieldDecorator('enable', { valuePropName: 'checked' })(
                  <Switch disabled={!isOverridden || props.updateCurfewPending} />
                )}
              </LegacyForm.Item>

              {days.map((day: DayOfTheWeek, index: number) => {
                return (
                  <LegacyForm.Item key={index} style={{ marginBottom: 10 }} label={_.startCase(day)}>
                    {props.curfewScheduleCloned?.[day]?.map((scheduleMap: CurfewScheduleDto, i: number) => (
                      <CurfewSlider
                        key={i}
                        index={i}
                        dayOfTheWeek={day}
                        indexOfTheWeek={index + 1}
                        start={timeStringToSeconds(scheduleMap.startTime)}
                        end={timeStringToSeconds(scheduleMap.endTime)}
                        add={props.curfewScheduleCloned && props.curfewScheduleCloned[day].length === i + 1}
                        delete={true}
                        pending={!isOverridden || props.updateCurfewPending}
                      />
                    ))}
                    {(!props.curfewScheduleCloned ||
                      !props.curfewScheduleCloned[day] ||
                      props.curfewScheduleCloned[day].length === 0) && (
                      <CurfewSlider
                        index={0}
                        end={0}
                        start={0}
                        dayOfTheWeek={day}
                        indexOfTheWeek={index + 1}
                        add={true}
                        pending={!isOverridden || props.updateCurfewPending}
                      />
                    )}
                  </LegacyForm.Item>
                );
              })}
            </LegacyForm>
          </div>
        </Modal>
      </>
    );
  })
);
