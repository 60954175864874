import * as ActionTypes from '../actionTypes';
import {
  DEFAULT_PREFIX as WEBSOCKET_PREFIX,
  WEBSOCKET_CLOSED,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_OPEN,
  WEBSOCKET_RECONNECTED
} from '@giantmachines/redux-websocket';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WebsocketDto } from '@pclocs/platform-sdk';

type IdConversion<T> = T extends { itemId: string } ? Omit<T, 'itemId'> & { id: string } : T;
export type WebsocketRequestPayload = IdConversion<WebsocketDto['request']>;
export type WebsocketResponsePayload = IdConversion<WebsocketDto['streamResponse']>;

interface WebsocketState {
  isConnected: boolean;
  subscriptionCounts: Record<string, { request: WebsocketRequestPayload; count: number }>;
  streamMessage?: WebsocketResponsePayload;
}

const initialState: WebsocketState = {
  isConnected: false,
  subscriptionCounts: {}
};

export const subRequestToKey = (payload: WebsocketRequestPayload) =>
  [payload.type, 'id' in payload ? payload.id : ''].join('||');

export const setIncomingMessage = createAction<WebsocketResponsePayload>(ActionTypes.WEBSOCKET_INCOMING_MESSAGE);
/**
 * @deprecated Consider using the "streamMessage" state instead.
 */
export type WebsocketIncomingMessageAction = ReturnType<typeof setIncomingMessage>;

const slice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    connect: state => state,
    subscribe: (state, action: PayloadAction<WebsocketRequestPayload>) => {
      const key = subRequestToKey(action.payload);
      if (state.subscriptionCounts[key]) {
        state.subscriptionCounts[key].count++;
      } else {
        state.subscriptionCounts[key] = { request: action.payload, count: 1 };
      }
    },
    unsubscribe: (state, action: PayloadAction<WebsocketRequestPayload>) => {
      const key = subRequestToKey(action.payload);
      if (state.subscriptionCounts[key]?.count > 1) {
        state.subscriptionCounts[key].count--;
      } else {
        delete state.subscriptionCounts[key];
      }
    },
    resubscribe: (state, _action: PayloadAction<WebsocketRequestPayload>) => {
      return state;
    }
  },
  extraReducers: {
    [`${WEBSOCKET_PREFIX}::${WEBSOCKET_OPEN}`]: state => ({ ...state, isConnected: true }),
    [`${WEBSOCKET_PREFIX}::${WEBSOCKET_RECONNECTED}`]: state => ({ ...state, isConnected: true }),
    [`${WEBSOCKET_PREFIX}::${WEBSOCKET_CLOSED}`]: state => ({ ...state, isConnected: false }),
    [`${WEBSOCKET_PREFIX}::${WEBSOCKET_DISCONNECT}`]: state => ({ ...state, isConnected: false }),
    [ActionTypes.WEBSOCKET_INCOMING_MESSAGE]: (state, action: WebsocketIncomingMessageAction) => ({
      ...state,
      streamMessage: action.payload
    })
  }
});

export const websocketReducer = slice.reducer;
export const websocketActions = slice.actions;
export type WebsocketActionTypes = {
  [P in keyof typeof websocketActions]: ReturnType<typeof websocketActions[P]>;
}[keyof typeof websocketActions];
export type WebsocketActionType<T extends keyof typeof websocketActions> = ReturnType<typeof websocketActions[T]>;
