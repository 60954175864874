import React, { Dispatch, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Input, Radio, Tooltip } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { InfoCircleOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import * as ActionTypes from '../../../store/actionTypes';
import { ShowNotificationMessageAction } from '../../../store/common/types';
import { history } from '../../../store/store';
import { useParams } from 'react-router';
import { getErrorAlertMessage } from '../../../helpers/form-helper';
import {
  OidcIdentityProviderResponseDto,
  OidcIdentityProviderResponseDtoAttributesRequestMethodEnum as ProviderMethods
} from '@pclocs/platform-sdk';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import { USER_POOL_DOMAIN } from '../../../api/amplify';
import { joinErrorMessages } from '../../../api/sdk';
import { isValidationExceptionError, useEverySdkCall, UseSdkCallError } from '../../../helpers/sdk-hooks';
import { PasswordFormItem } from '../../PasswordFormItem';
import { CopyToClipboard } from '../../CopyToClipboard';

const CALLBACK_URL = `https://${USER_POOL_DOMAIN}/oauth2/idpresponse`;

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch<ShowNotificationMessageAction>) => ({
  showNotification: (type: 'success' | 'error', content: string): void =>
    dispatch({ type: ActionTypes.SHOW_NOTIFICATION_MESSAGE, payload: { type, content } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux &
  FormComponentProps & {
    identityProvider?: OidcIdentityProviderResponseDto;
    fetching: boolean;
    readonly: boolean;
  };

export const OidcIdentityProviderForm = connector(
  LegacyForm.create<Props>({ name: 'OidcIdentityProvider' })((props: Props) => {
    const { accountId, providerName } = useParams<{ accountId: string; providerName: string }>();
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const identityProvidersPath = `/auth/${accountId}/integrations/identity-providers`;

    const handleErrorResponse = (error: UseSdkCallError, notificationError: string) => {
      if (isValidationExceptionError(error)) {
        setErrorMessages(joinErrorMessages(error.data));
      } else {
        props.showNotification('error', notificationError);
      }
    };

    const [createIdentityProvider, creatingIdentityProvider] = useEverySdkCall(
      'IdentityProviderApi',
      'createOidcIdentityProvider',
      () => {
        props.showNotification('success', 'OIDC SSO provider has been successfully created.');
        history.push(identityProvidersPath);
      },
      err => handleErrorResponse(err, 'Could not create OIDC SSO provider.')
    );

    const [updateIdentityProvider, updatingIdentityProvider] = useEverySdkCall(
      'IdentityProviderApi',
      'updateOidcIdentityProvider',
      () => {
        props.showNotification('success', 'OIDC SSO provider has been successfully updated.');
        history.push(identityProvidersPath);
      },
      err => handleErrorResponse(err, 'Could not update OIDC SSO provider.')
    );

    useEffect(() => {
      props.form.setFieldsValue({
        clientId: props.identityProvider?.clientId ?? '',
        domain: props.identityProvider?.domain ?? 'https://',
        attributesRequestMethod: props.identityProvider?.attributesRequestMethod ?? 'GET',
        authorizeScopes: props.identityProvider?.authorizeScopes ?? ['openid']
      });
    }, [props.identityProvider]);

    const { getFieldDecorator } = props.form;

    const providerMethods = () => {
      const methods = [];
      for (const methodKey in ProviderMethods) {
        methods.push(
          <Radio.Button key={methodKey} value={methodKey}>
            {methodKey}
          </Radio.Button>
        );
      }
      return methods;
    };

    const SSO_URL = `${window.location.origin}/login/sso-sign-in?provider=${providerName}`;

    const options = [
      {
        label: 'OpenID',
        value: 'openid',
        key: 'openid',
        checked: props.identityProvider?.authorizeScopes?.includes('openid') ?? true,
        disabled: true
      },
      {
        label: 'Email',
        value: 'email',
        key: 'email',
        checked: props.identityProvider?.authorizeScopes?.includes('email') ?? false,
        disabled: props.readonly
      },
      {
        label: 'Profile',
        value: 'profile',
        key: 'profile',
        checked: props.identityProvider?.authorizeScopes?.includes('profile') ?? false,
        disabled: props.readonly
      },
      {
        label: 'Roles',
        value: 'roles',
        key: 'roles',
        checked: props.identityProvider?.authorizeScopes?.includes('roles') ?? false,
        disabled: props.readonly
      }
    ];

    const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      props.form.validateFieldsAndScroll(async (err: Error, values: any) => {
        if (!err) {
          const identityProvider = {
            providerName: values.providerName,
            providerType: 'OIDC',
            clientId: values.clientId,
            domain: values.domain,
            attributesRequestMethod: values.attributesRequestMethod,
            authorizeScopes: values.authorizeScopes
          };
          setErrorMessages([]);
          providerName
            ? updateIdentityProvider(providerName, { ...identityProvider, clientSecret: values.clientSecret })
            : createIdentityProvider({
                ...identityProvider,
                clientId: values.clientId,
                clientSecret: values.clientSecret
              });
        }
      });
    };

    const pending = props.fetching || creatingIdentityProvider || updatingIdentityProvider;

    return (
      <LegacyForm
        className="content-section-inner"
        {...{
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 }
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 }
          }
        }}>
        <LegacyForm.Item label="Provider Name">
          {!props.identityProvider
            ? getFieldDecorator('providerName', {
                rules: [
                  { required: true, message: 'Please input provider name!' },
                  { min: 3, message: 'Provider Name must have at least 3 characters' },
                  { max: 25, message: 'Provider Name must have maximum 25 characters' },
                  {
                    validator: (_rule, value, callback) =>
                      callback(
                        !value || /^[a-zA-Z0-9\-\.]+$/.test(value)
                          ? undefined
                          : 'Provider Name must only contain letters, numbers, hyphens or dots'
                      )
                  }
                ]
              })(<Input disabled={props.readonly} />)
            : props.identityProvider.providerName}
        </LegacyForm.Item>

        <LegacyForm.Item label="Client ID">
          {getFieldDecorator('clientId', {
            rules: [{ required: true, message: 'Please input client ID!' }]
          })(<Input disabled={props.readonly} />)}
        </LegacyForm.Item>

        <LegacyForm.Item
          label={
            <span>
              Client Secret&nbsp;
              {
                <Tooltip title="Some SSO configuration methods require a client secret in order to authenticate successfully. Please review your provider setup and if it is required, enter it here.">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            </span>
          }>
          {props.form.getFieldDecorator('clientSecret', {
            rules: [{ max: 100, message: 'Client secret must have maximum 100 characters' }]
          })(<PasswordFormItem hasPassword={!!props.identityProvider?.hasClientSecret} />)}
        </LegacyForm.Item>

        <LegacyForm.Item label="Domain">
          {getFieldDecorator('domain', {
            rules: [
              { required: true, message: 'Please input provider domain!' },
              {
                validator: (_domain, value, callback) =>
                  callback(
                    !value || value.startsWith('https://') ? undefined : 'Domain must start with protocol https://'
                  )
              }
            ]
          })(<Input placeholder="https://identity-provider.domain.com" disabled={props.readonly} />)}
        </LegacyForm.Item>
        <LegacyForm.Item label="Request Method">
          {getFieldDecorator('attributesRequestMethod', {
            rules: [{ required: true, message: 'Please select a request method!' }]
          })(
            <Radio.Group buttonStyle="solid" disabled={props.readonly}>
              {providerMethods()}
            </Radio.Group>
          )}
        </LegacyForm.Item>

        <LegacyForm.Item label="Authorize Scopes">
          {getFieldDecorator('authorizeScopes', {
            rules: [{ required: true, message: 'Please select an authorize scope!' }]
          })(<CheckboxGroup options={options} disabled={props.readonly} />)}
        </LegacyForm.Item>

        <LegacyForm.Item label="Callback URL">
          <pre>
            <CopyToClipboard data={CALLBACK_URL} title="Copy callback URL to clipboard" />
            &nbsp;{CALLBACK_URL}
          </pre>
        </LegacyForm.Item>

        {providerName && (
          <LegacyForm.Item label="SSO URL">
            <pre style={{ marginBottom: 0 }}>
              <CopyToClipboard data={SSO_URL} title="Copy SSO URL to clipboard" />
              &nbsp;{SSO_URL}
            </pre>
          </LegacyForm.Item>
        )}

        {errorMessages.length > 0 && getErrorAlertMessage(errorMessages)}

        <LegacyForm.Item
          {...{
            wrapperCol: {
              xs: { span: 22, offset: 1 },
              sm: { span: 10, offset: 6 }
            }
          }}>
          <Button
            key="back"
            className="cancel-btn"
            style={{ display: pending ? 'none' : 'initial' }}
            onClick={() => history.push(identityProvidersPath)}>
            Cancel
          </Button>
          <Button
            key="submit"
            className="submit-btn"
            type="primary"
            loading={pending}
            disabled={props.readonly}
            onClick={handleSubmit}>
            Save
          </Button>
        </LegacyForm.Item>
      </LegacyForm>
    );
  })
);
