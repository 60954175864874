import React, { useMemo } from 'react';
import { Input, Select } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import '@ant-design/compatible/assets/index.css';
import { NetworkInterfaceSettingsDto } from '@pclocs/platform-sdk';
import { isValidIpAddress, fromCidrIp } from '../../../helpers/form-helper';
import _ from 'lodash';

type Props = {
  form: WrappedFormUtils<any>;
  type: 'group' | 'station';
  interface: 'wifi' | 'ethernet';
  fieldsDisabled: boolean;
  derivedConfig?: NetworkInterfaceSettingsDto;
  showNetworkModal: boolean;
};

export const InterfaceSettings = (props: Props) => {
  const derivedConfig: NetworkInterfaceSettingsDto | undefined = props.derivedConfig;
  const { getFieldDecorator } = props.form;
  const noDhcp: boolean = props.form.getFieldValue(`${props.interface}.dhcp`) === 'none';
  const forcedIpVersion: 'ipv4' | 'ipv6' | undefined =
    props.form.getFieldValue(`${props.interface}.dhcp`) in ['both', 'none']
      ? undefined
      : props.form.getFieldValue(`${props.interface}.dhcp`);

  const ipAddressInfo = useMemo(
    () => (derivedConfig?.address?.[0] ? fromCidrIp(derivedConfig.address[0]) : undefined),
    [derivedConfig?.address?.[0]]
  );

  return (
    <>
      <LegacyForm.Item label="DHCP">
        {getFieldDecorator(`${props.interface}.dhcp`, {
          initialValue: derivedConfig?.dhcp || 'both',
          rules: [{ required: true }]
        })(
          <Select disabled={props.fieldsDisabled}>
            <Select.Option value="both">DHCP</Select.Option>
            {props.type === 'station' && <Select.Option value="none">Static IP</Select.Option>}
            <Select.Option value="ipv4">DHCP IPv4</Select.Option>
            <Select.Option value="ipv6">DHCP IPv6</Select.Option>
          </Select>
        )}
      </LegacyForm.Item>

      {props.type === 'station' && (
        <LegacyForm.Item label="IP Address">
          {getFieldDecorator(`${props.interface}.address[0].ip`, {
            validateTrigger: 'onBlur',
            initialValue: ipAddressInfo?.ip,
            rules: [
              {
                required: noDhcp,
                message: 'Please input an IP address!'
              },
              {
                validator: (_rule, value, callback) =>
                  callback(
                    _.isEmpty(value) || isValidIpAddress(value, forcedIpVersion) ? undefined : 'Invalid IP address.'
                  )
              }
            ]
          })(<Input disabled={props.fieldsDisabled} />)}
        </LegacyForm.Item>
      )}

      {props.type === 'station' && (
        <LegacyForm.Item label="Netmask">
          {getFieldDecorator(`${props.interface}.address[0].netmask`, {
            validateTrigger: 'onBlur',
            initialValue: ipAddressInfo?.netmask,
            rules: [
              {
                validator: (_rule, value, callback) =>
                  callback(
                    _.isEmpty(value) || isValidIpAddress(value, forcedIpVersion) ? undefined : 'Invalid netmask.'
                  )
              }
            ]
          })(<Input disabled={props.fieldsDisabled} />)}
        </LegacyForm.Item>
      )}

      <LegacyForm.Item label="Gateway">
        {getFieldDecorator(`${props.interface}.gateway[0]`, {
          validateTrigger: 'onBlur',
          initialValue: derivedConfig?.gateway?.[0],
          rules: [
            {
              required: noDhcp,
              message: 'A gateway IP is required.'
            },
            {
              validator: (_rule, value, callback) =>
                callback(
                  _.isEmpty(value) || isValidIpAddress(value, forcedIpVersion) ? undefined : 'Invalid IP address.'
                )
            }
          ]
        })(<Input disabled={props.fieldsDisabled} />)}
      </LegacyForm.Item>

      <LegacyForm.Item label="DNS 1">
        {getFieldDecorator(`${props.interface}.dns[0]`, {
          validateTrigger: 'onBlur',
          initialValue: derivedConfig?.dns?.[0],
          rules: [
            {
              required: noDhcp,
              message: 'A DNS IP is required.'
            },
            {
              validator: (_rule, value, callback) =>
                callback(
                  _.isEmpty(value) || isValidIpAddress(value, forcedIpVersion) ? undefined : 'Invalid DNS address.'
                )
            }
          ]
        })(<Input disabled={props.fieldsDisabled} />)}
      </LegacyForm.Item>

      <LegacyForm.Item label="DNS 2">
        {getFieldDecorator(`${props.interface}.dns[1]`, {
          validateTrigger: 'onBlur',
          initialValue: derivedConfig?.dns?.[1],
          rules: [
            {
              validator: (_rule, value, callback) =>
                callback(
                  _.isEmpty(value) || isValidIpAddress(value, forcedIpVersion) ? undefined : 'Invalid DNS address.'
                )
            }
          ]
        })(<Input disabled={props.fieldsDisabled} />)}
      </LegacyForm.Item>
    </>
  );
};
