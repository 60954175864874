import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table, Button, Popconfirm, Tag, Alert } from 'antd';
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { history } from '../../../store/store';
import * as ActionTypes from '../../../store/actionTypes';
import { RootState } from '../../../store/rootReducer';
import _ from 'lodash';
import { IntegrationsActionTypes } from '../../../store/integrations/types';
import { WebhookResponseDto } from '@pclocs/platform-sdk';
import { subscriptionMessage } from '../../../constants/validations';
import { FeatureId, useFeatureAvailability } from '../../../helpers/feature-availability-hooks';

const mapStateToProps = (state: RootState) => _.pick(state.integrations, ['webhooks', 'pending']);

const mapDispatchToProps = (dispatch: Dispatch<IntegrationsActionTypes>) => ({
  fetchWebhooks: () => dispatch({ type: ActionTypes.FETCH_WEBHOOKS }),
  deleteWebhook: (id: string) => dispatch({ type: ActionTypes.DELETE_WEBHOOK, payload: { id } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const Webhooks = connector((props: Props) => {
  const [hasFeature] = useFeatureAvailability();
  const featureEnabled = hasFeature(FeatureId.WEBHOOKS);
  const { accountId } = useParams<{ accountId: string }>();
  const webhookPath = `/auth/${accountId}/integrations/webhooks`;

  useEffect(() => {
    props.fetchWebhooks();
  }, []);

  const editWebhook = (id: string) => {
    history.push(`${webhookPath}/${id}`);
  };

  const tableColumns: ColumnProps<WebhookResponseDto>[] = [
    {
      key: 'name',
      title: 'Name',
      width: '25%',
      render: (webhook: WebhookResponseDto) => (
        <div className="link" onClick={() => editWebhook(webhook.id)}>
          {webhook.name}
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'active',
      width: '10%',
      render: (active: string) =>
        active ? (
          <Tag className="ant-tag-success" key="active">
            Active
          </Tag>
        ) : (
          <Tag className="ant-tag-error" key="1">
            Inactive
          </Tag>
        )
    },
    {
      title: 'URL',
      key: 'url',
      width: '65%',
      render: (webhook: WebhookResponseDto) => (
        <div className="link" onClick={() => editWebhook(webhook.id)}>
          {webhook.url}
        </div>
      )
    },
    {
      align: 'right',
      width: 40,
      fixed: 'right',
      render: (webhook: WebhookResponseDto) => (
        <div>
          <Popconfirm
            onConfirm={() => props.deleteWebhook(webhook.id)}
            placement="left"
            title="Delete webhook?"
            okText="Yes"
            cancelText="No">
            <Button ghost>
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <div className="content-section">
      <div className="content-section-inner">
        {!featureEnabled && <Alert style={{ marginBottom: 16 }} type="error" message={subscriptionMessage} />}

        <Button
          onClick={() => history.push(`${webhookPath}/new`)}
          className="btn-primary"
          style={{ marginBottom: 16 }}
          disabled={!featureEnabled}>
          <PlusCircleFilled />
          New Webhook
        </Button>

        <Table
          className="no-hover"
          pagination={false}
          rowKey="id"
          showHeader={true}
          columns={tableColumns}
          dataSource={props.webhooks}
          loading={props.pending}
          size="small"
        />
      </div>
    </div>
  );
});
