import * as sdk from '@pclocs/platform-sdk';
import {
  AccountBasicResponseDto,
  AccountCreateDto,
  AccountSuspensionRequestDto,
  AdminApi,
  AdminUserContextDto
} from '@pclocs/platform-sdk';
import { createApi } from './sdk';
import axios, { AxiosResponse } from 'axios';

export async function create(data: AccountCreateDto): Promise<AccountBasicResponseDto> {
  const res = await sdk.AccountApiFactory({}, process.env.API_ENDPOINT, axios.create()).createAccount(data);
  return res.data;
}

export async function cancelAccountSuspension(id: string, data: AccountSuspensionRequestDto): Promise<AxiosResponse> {
  const res = await sdk
    .AccountApiFactory({}, process.env.API_ENDPOINT, axios.create())
    .cancelAccountSuspension(id, data);
  return res;
}

export async function verifyProvider(providerName: string): Promise<string> {
  const res = await sdk
    .IdentityProviderApiFactory({}, process.env.API_ENDPOINT, axios.create())
    .verifyProvider(providerName);
  return res.data.status;
}

export async function getCurrentUserContext(): Promise<AdminUserContextDto> {
  const api = createApi<AdminApi>('AdminApi', {});
  const res = await api.context();
  return res.data;
}
