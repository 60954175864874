import React, { useState } from 'react';
import { Button, Col, Popconfirm, Row, Skeleton, Tooltip } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { InfoCircleOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { MfaForm } from '../MFA/mfa-form';
import { MfaQrCode } from '../MFA/mfa-qr-code';
import _ from 'lodash';
import { Props } from './index';

export const ProfileMfa = (properties: {
  props: Pick<
    Props,
    | 'form'
    | 'removeMfaForAdmin'
    | 'cognitoUser'
    | 'verifyMfaTotp'
    | 'currentUser'
    | 'mfaConfiguredForAdmin'
    | 'pendingRemoveMfa'
    | 'getMfaSecretCode'
    | 'pendingMfaSecretCode'
    | 'mfaSecretCode'
    | 'pendingMfaTotpRegistration'
  >;
}) => {
  const props = properties.props;

  const [showSetupMfaForm, setShowSetupMfaForm] = useState(false);

  const verifyMfaTotp = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields(['mfaTotpVerificationToken'], (err: Error, values: any) => {
      if (!err && props.cognitoUser) {
        props.verifyMfaTotp(props.cognitoUser, values.mfaTotpVerificationToken);
      }
    });
  };

  const mfaEnabledOnAtLeastOneAccount = _.some(props.currentUser?.accounts, { mfaRequired: true });

  return (
    <>
      <Row>
        <Col span={11} offset={1}>
          <div style={{ marginBottom: 10 }}>
            <h5>Two-Factor Authentication (2FA)</h5>
            {!props.mfaConfiguredForAdmin && !showSetupMfaForm && (
              <div style={{ marginTop: 15 }}>
                <p>Increase your account&apos;s security by enabling Two-Factor Authentication (2FA)</p>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    props.getMfaSecretCode(props.cognitoUser);
                    setShowSetupMfaForm(true);
                  }}
                  id="enable-mfa-btn">
                  Enable two-factor authentication
                </Button>
              </div>
            )}
            {props.mfaConfiguredForAdmin && (
              <div style={{ marginTop: 15 }}>
                <Popconfirm
                  placement="right"
                  title="Are you sure you want to disable 2FA? This will invalidate your registered authenticator applications"
                  cancelText="No"
                  okText="Yes"
                  onConfirm={() => {
                    props.removeMfaForAdmin();
                  }}
                  disabled={mfaEnabledOnAtLeastOneAccount}>
                  <Button
                    id="remove-mfa-btn"
                    className={mfaEnabledOnAtLeastOneAccount ? '' : 'btn-danger'}
                    loading={props.pendingRemoveMfa}
                    disabled={mfaEnabledOnAtLeastOneAccount}>
                    Disable two-factor authentication
                  </Button>
                </Popconfirm>
                {mfaEnabledOnAtLeastOneAccount && (
                  <Tooltip title="2FA can not be disabled as it is required at the account level.">
                    <InfoCircleOutlined style={{ marginLeft: 10 }} />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
      {!props.mfaConfiguredForAdmin &&
        showSetupMfaForm &&
        (props.pendingMfaSecretCode ? (
          <Skeleton />
        ) : (
          props.mfaSecretCode && (
            <Row style={{ marginTop: 15 }}>
              <Col span={11} offset={1}>
                <p>
                  To set up 2FA, scan the QR code with an authenticator app, then enter the one-time password that it
                  generates.
                </p>
                <LegacyForm onSubmit={verifyMfaTotp}>
                  <MfaForm props={props} />
                </LegacyForm>
              </Col>
              <Col span={11} offset={1}>
                <MfaQrCode
                  username={props.cognitoUser.getUsername()}
                  secretCode={props.mfaSecretCode}
                  pending={props.pendingMfaSecretCode}
                />
              </Col>
            </Row>
          )
        ))}
    </>
  );
};
