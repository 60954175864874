import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Bay } from './Bay';
import { Station } from './Station';
import { Group } from './Group';
import { Button, Col, Input, Row } from 'antd';
import * as ActionTypes from '../../store/actionTypes';
import { history } from '../../store/store';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RootState } from '../../store/rootReducer';
import { DashboardActionTypes } from '../../store/dashboard/types';
import { Hierarchy } from './Hierarchy';
import { CommonActionTypes } from '../../store/common/types';
import { useSdkCallOnChanges } from '../../helpers/sdk-hooks';
import { useDebouncedHook } from '../../helpers/use-debounced-hook';

const mapStateToProps = (state: RootState) => ({
  accountId: state.auth.accountContext
});

const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes | CommonActionTypes>) => ({
  onLoad: () => dispatch({ type: ActionTypes.DASHBOARD_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ActionTypes.DASHBOARD_PAGE_UNLOADED }),
  setupNewStations: (accountId: string | undefined) =>
    dispatch({ type: ActionTypes.REDIRECT, redirectTo: `/auth/${accountId}/setup` })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const Dashboard = connector((props: Props) => {
  const [, ungroupedStations] = useSdkCallOnChanges('StationApi', 'findUngrouped');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    props.onLoad();
    return () => {
      props.onUnload();
    };
  }, []);

  const { path } = useRouteMatch();
  const baseUrl = useRouteMatch().url;
  const selectedItemInRoute: string = useRouteMatch<{ selectedId: string }>(`${baseUrl}/(stations|groups)/:selectedId`)
    ?.params?.selectedId;

  const debouncedSearchTerm = useDebouncedHook(searchTerm);

  const onSelect = useCallback(
    (itemType: 'station' | 'group', itemId: string) => {
      history.push(`${baseUrl}/${itemType}s/${itemId}/overview`);
    },
    [baseUrl]
  );

  return (
    <div>
      <h4>Dashboard</h4>
      <Row gutter={32}>
        <Col span={12}>
          <div className="dashboard-section" id="station-tree-section">
            <div className="dashboard-section-title">
              <div className="content-split">
                Connected Stations
                {!!ungroupedStations?.length && (
                  <Button size="small" className="btn-primary" onClick={() => props.setupNewStations(props.accountId)}>
                    New stations found
                  </Button>
                )}
              </div>
            </div>
            <div className="dashboard-section-inner hierarchy-tree-section">
              <div className="hierarchy-tree-section-inner">
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  placeholder="Filter by name"
                />
                <Hierarchy onSelect={onSelect} defaultSelected={selectedItemInRoute} searchTerm={debouncedSearchTerm} />
              </div>
            </div>
          </div>
        </Col>
        <Switch>
          <Route path={`${path}/groups/:groupId`} component={Group} />
          <Route path={`${path}/stations/:stationId/bays/:bayId`} component={Bay} />
          <Route path={`${path}/stations/:stationId`} component={Station} />
        </Switch>
      </Row>
    </div>
  );
});
