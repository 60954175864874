import React, { Dispatch } from 'react';
import { Skeleton, Row, Col, Tooltip, Alert } from 'antd';
import { CloudOutlined, KeyOutlined, WarningFilled } from '@ant-design/icons';
import { RootState } from '../../../store/rootReducer';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import { DashboardActionTypes } from '../../../store/dashboard/types';
import * as ActionTypes from '../../../store/actionTypes';
import { history } from '../../../store/store';
import { BayStatusIndicator } from '../../BayStatusIndicator';
import { EditableLabel } from '../../EditableLabel';
import { adminTierOrAbove } from '../../../helpers/subscription-helper';
import { Tags } from '../../Tags';

const mapStateToProps = (state: RootState) => ({
  accountId: state.auth.accountContext,
  ..._.pick(state.dashboard, ['station', 'bays', 'changeNamePending', 'stationFetchFailed']),
  subscriptionInfo: state.account.subscriptionInfo
});

const mapDispatchToProps = (dispatch: Dispatch<DashboardActionTypes>) => ({
  updateStationName: (id: string, value: string) =>
    dispatch({ type: ActionTypes.UPDATE_STATION_NAME, payload: { id, value } }),
  updateStationTags: (id: string, value: string[]) =>
    dispatch({ type: ActionTypes.UPDATE_STATION_TAGS, payload: { id, value } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const StationOverview = connector((props: Props) => {
  const { station } = props;
  const correctSubscription: boolean = adminTierOrAbove(props.subscriptionInfo);
  const isHeadless = !!station?.derivedConfig?.isHeadless;

  return (
    <div>
      {station === undefined || !props.subscriptionInfo ? (
        <Skeleton active />
      ) : !correctSubscription ? (
        <Alert type="error" message="Features disabled due to subscription level." />
      ) : (
        <>
          {isHeadless && (
            <Alert
              message="This locker is controlled by it's external interface."
              type="info"
              showIcon
              style={{ marginTop: 10, marginBottom: 20 }}
            />
          )}
          <Row style={{ marginBottom: 15 }}>
            <Col span={12}>
              <EditableLabel
                defaultValue={station.name || station.id}
                loading={!!props.changeNamePending}
                onSubmit={props.updateStationName.bind(null, station.id)}
              />
            </Col>
          </Row>

          <div style={{ marginBottom: 20 }}>
            <Tags tags={station.tags} updateTags={props.updateStationTags.bind(null, station.id)} />
          </div>

          {!isHeadless && (
            <div style={{ marginBottom: 15, fontSize: 14 }}>
              {station.derivedConfig.managed ? (
                <>
                  <CloudOutlined /> Managed
                </>
              ) : (
                <>
                  <KeyOutlined /> Public
                </>
              )}
            </div>
          )}

          <div className="bay-container" style={{ fontSize: 14 }}>
            {_.values(props.bays).map((bay: any, idx: number) => {
              const status = props.bays?.[bay.id]?.reported || {};
              return (
                <Row justify="space-between" key={bay.id} gutter={[10, 5]} data-test-id="bay-item">
                  <Col span={1}>
                    <BayStatusIndicator status={status} size={10} />
                  </Col>
                  <Col span={1}>
                    <b>{idx + 1}.</b>
                  </Col>
                  <Col span={21}>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        history.push(
                          `/auth/${props.accountId}/dashboard/stations/${station.id}/bays/${bay.id}/overview`
                        )
                      }
                      data-test-id="bay-item-link">
                      {bay.name}
                    </span>
                  </Col>
                  <Col span={1}>
                    {!status.connected && (
                      <Tooltip title="Bay is not connected." placement="left">
                        <WarningFilled className="text-warning" />
                      </Tooltip>
                    )}
                    {status.connected && status.bayState === 4 && (
                      <Tooltip title="Bay has been breached." placement="left">
                        <WarningFilled className="text-warning" />
                      </Tooltip>
                    )}
                    {status.connected && status.bayState === 5 && (
                      <Tooltip title="Bay is stuck." placement="left">
                        <WarningFilled className="text-warning" />
                      </Tooltip>
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>

          <div style={{ marginTop: 30, marginBottom: 15 }}>
            <strong>ID:</strong>&nbsp;{station.id}
          </div>
        </>
      )}
    </div>
  );
});
