import { EventLogDto, StructuredDescriptionPartDto, StructuredDescriptionPartDtoTypeEnum } from '@pclocs/platform-sdk';
import React from 'react';
import { Link } from 'react-router-dom';
import { FeatureId, useFeatureAvailability } from '../../helpers/feature-availability-hooks';
import { AdminClaimEnum, useHasClaim } from '../../helpers/claims';

const stationIdFromBayId = (id: string) =>
  id
    .split('_')
    .slice(0, -1)
    .join('_');

export function StructuredDescriptionPart(props: StructuredDescriptionPartDto & { accountId: string }) {
  const [hasFeature] = useFeatureAvailability();
  const [hasAccountSettingsAccess] = useHasClaim({ type: AdminClaimEnum.AccountSettings });

  const link = (subUrl: string) => (
    <Link to={`/auth/${props.accountId}/${subUrl}`} target="_blank" rel="noopener noreferrer">
      {props.text}
    </Link>
  );
  switch (props.type) {
    case StructuredDescriptionPartDtoTypeEnum.Station:
      return link(`dashboard/stations/${props.id}/overview`);
    case StructuredDescriptionPartDtoTypeEnum.Group:
      return link(`dashboard/groups/${props.id}/overview`);
    case StructuredDescriptionPartDtoTypeEnum.Bay:
      return link(`dashboard/stations/${stationIdFromBayId(props.id)}/bays/${props.id}/overview`);
    case StructuredDescriptionPartDtoTypeEnum.UserGroup:
      return link(`user-groups/${props.id}`);
    case StructuredDescriptionPartDtoTypeEnum.Webhook:
      return hasFeature(FeatureId.WEBHOOKS) && hasAccountSettingsAccess ? (
        link(`integrations/webhooks/${props.id}`)
      ) : (
        <>{props.text}</>
      );
    case StructuredDescriptionPartDtoTypeEnum.IdentityProvider:
      return hasFeature(FeatureId.SSOIDENTITYPROVIDERS) && hasAccountSettingsAccess ? (
        link(`integrations/identity-providers/${props.id}`)
      ) : (
        <>{props.text}</>
      );
    default:
      return <>{props.text}</>;
  }
}

export function StructuredDescription({
  description,
  accountId
}: {
  accountId: string;
  description: NonNullable<EventLogDto['structuredDescription']>;
}): JSX.Element {
  return (
    <>
      {description.map((part, i) =>
        typeof part === 'object' ? (
          <StructuredDescriptionPart key={`${i}_${part.text}`} {...part} accountId={accountId} />
        ) : (
          part
        )
      )}
    </>
  );
}
