import React from 'react';
import copy from 'copy-to-clipboard';
import { message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

type Props = {
  data: string;
  title: string;
};

export const CopyToClipboard = (props: Props) => {
  return (
    <CopyOutlined
      onClick={() => {
        copy(props.data);
        message.success({
          content: 'Copied to clipboard',
          duration: 2
        });
      }}
      title={props.title}
      className="copy-to-clipboard"
    />
  );
};
