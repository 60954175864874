import React, { Dispatch } from 'react';
import { Button, Modal, Result, Tag, Descriptions, Alert } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import '@ant-design/compatible/assets/index.css';
import * as ActionTypes from '../../store/actionTypes';
import { ExportState, StationUserActionTypes } from '../../store/users/types';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import * as RfidHelper from '../../helpers/rfid-converter-helper';

const mapStateToProps = (state: RootState) => ({
  ...state.stationUser
});

const mapDispatchToProps = (dispatch: Dispatch<StationUserActionTypes>) => ({
  unloadModal: () => dispatch({ type: ActionTypes.STATION_USER_UNLOAD_EXPORT_MODAL }),
  triggerExport: (searchText: string | undefined, tagFilter: string[] | undefined) =>
    dispatch({ type: ActionTypes.TRIGGER_API_USER_EXPORT, payload: { searchText, tagFilter } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & FormComponentProps;

export const StationUserExportModal = connector(
  LegacyForm.create({ name: 'StationUserExport' })((props: Props) => {
    const handleSubmit = (e: React.MouseEvent) => {
      e.preventDefault();
      props.triggerExport(
        props.stationUsersFilter || undefined,
        props.stationUsersTagsFilter?.length ? props.stationUsersTagsFilter : undefined
      );
    };

    return (
      <Modal
        title="Export Users"
        visible={props.showStationUserExportModal}
        maskClosable={props.exportState === ExportState.IDLE || props.exportState === ExportState.REQUESTED}
        closable={props.exportState === ExportState.IDLE || props.exportState === ExportState.REQUESTED}
        onCancel={props.unloadModal}
        data-test-id="station-user-export-modal"
        width={420}
        footer={
          props.exportState === ExportState.REQUESTED
            ? [
                <Button key="back" onClick={props.unloadModal} data-test-id="station-user-btn-user-export-ok">
                  OK
                </Button>
              ]
            : [
                <Button
                  key="back"
                  onClick={props.unloadModal}
                  data-test-id="station-user-btn-user-export-cancel"
                  style={{ display: props.exportState !== ExportState.IDLE ? 'none' : 'initial' }}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={props.exportState === ExportState.REQUESTING}
                  onClick={handleSubmit}
                  data-test-id="station-user-btn-user-export-submit">
                  Export
                </Button>
              ]
        }>
        {props.exportState === ExportState.REQUESTED ? (
          <Result
            status="info"
            title="Export in progress"
            subTitle="We've started to export your user list. This process can take a while so we'll send you an email once it's complete."
          />
        ) : (
          <>
            <Alert
              message="The list of users exported can be reduced by first applying a Search or Tags filter. If no filters are applied then all users will be exported."
              type="info"
              style={{ marginBottom: 16 }}
              showIcon
            />

            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Search">
                {props.stationUsersFilter?.length ? props.stationUsersFilter : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Tags">
                {props.stationUsersTagsFilter?.length
                  ? props.stationUsersTagsFilter?.map(tag => <Tag key={tag}>{tag}</Tag>)
                  : '-'}
              </Descriptions.Item>
            </Descriptions>

            <hr />

            <div>
              <span>RFID format:&nbsp;{RfidHelper.rfidFormatTooltip}&nbsp;&nbsp;</span>
              <RfidHelper.FormatSelector onChange={() => ({})} />
            </div>
          </>
        )}
      </Modal>
    );
  })
);
