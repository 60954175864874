export const isBrandPCLocs = () => {
  const rootDomain: string[] = window.location.hostname.split('.');
  return rootDomain[rootDomain.length - 2] !== 'lockncharge';
};

export const getBrandName = () => {
  return isBrandPCLocs() ? 'PC Locs' : 'LocknCharge';
};

export const getBrandSupportLink = () => {
  return isBrandPCLocs()
    ? 'https://www.pclocs.com.au/fuyl-tower-support-library'
    : 'https://www.lockncharge.com/fuyl-tower-video-resources';
};
