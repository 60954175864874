import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { Button } from 'antd';
import { getBrandName } from '../../helpers/brand-helper';
import { CopyToClipboard } from '../CopyToClipboard';

export const MfaQrCode = (props: { username: string; secretCode: string; pending: boolean }) => {
  const [codeShown, setCodeShown] = useState(false);

  return (
    <>
      <QRCode
        value={`otpauth://totp/AWSCognito:${props.username}?secret=${props.secretCode}&issuer=${getBrandName()}`}
        style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
        id="mfa-qr-code"
      />
      <div style={{ marginBottom: 40, textAlign: 'center' }}>
        <p>Can&apos;t scan the QR code?</p>
        {!codeShown && (
          <Button
            type="ghost"
            htmlType="button"
            className="login-form-button"
            onClick={() => setCodeShown(!codeShown)}
            id="mfa-reveal-code">
            Reveal code as text
          </Button>
        )}
        {codeShown && (
          <>
            {props.secretCode}&nbsp;
            <CopyToClipboard data={props.secretCode} title="Copy code to clipboard" />
          </>
        )}
      </div>
    </>
  );
};
