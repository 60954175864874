import React, { useState, useEffect } from 'react';
import { InfinityList, InfinityListProps } from '.';

interface Props<T extends unknown> extends InfinityListProps<T> {
  refHash: string;
}

export const LegacyInfinityList = <T extends any>(props: Props<T>) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [refChangeLoading, setRefChangeLoading] = useState<boolean>(false);

  function hideSpinner() {
    setFetchLoading(false);
    setRefChangeLoading(false);
  }

  useEffect(() => {
    setFetchLoading(true);
    setRefChangeLoading(true);
    props.loadItems();
  }, [props.refHash]);

  useEffect(() => {
    if (props.items.length > 0 || props.refHash === '"{}"') {
      hideSpinner();
    }
  }, [JSON.stringify(props.items), props.fetchError]);

  useEffect(() => {
    const noMatchingResults = props.loading === false && props.items.length === 0;
    if (noMatchingResults) {
      hideSpinner();
    }
  }, [props.loading]);

  return (
    <InfinityList
      pageSize={props.pageSize}
      loading={refChangeLoading || fetchLoading || props.loading}
      items={refChangeLoading ? [] : props.items}
      fetchError={props.fetchError}
      hasMoreItems={props.hasMoreItems}
      loadItems={() => {
        setFetchLoading(true);
        props.loadItems();
      }}
      render={props.render}
    />
  );
};
