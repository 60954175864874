import React, { Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as ActionTypes from '../../store/actionTypes';
import { RootState } from '../../store/rootReducer';
import { Button, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { AuthActionTypes } from '../../store/auth/types';

const mapStateToProps = (state: RootState) => ({
  unauthorizedModal: state.common.unauthorizedModal
});

const mapDispatchToProps = (dispatch: Dispatch<AuthActionTypes>) => ({
  logout: () => dispatch({ type: ActionTypes.LOGOUT, payload: { redirect: false } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export const UnauthorizedModal = connector((props: Props) => {
  const ANT_CONFIRM_MODAL_CLASS = 'ant-modal-confirm';

  if (props.unauthorizedModal === null) {
    return <></>;
  }

  const { title, content } = props.unauthorizedModal;

  return (
    <Modal
      visible={true}
      closable={false}
      maskClosable={false}
      footer={null}
      className={`${ANT_CONFIRM_MODAL_CLASS} ${ANT_CONFIRM_MODAL_CLASS}-warning`}
      width={416}>
      <div className={`${ANT_CONFIRM_MODAL_CLASS}-body-wrapper`}>
        <div className={`${ANT_CONFIRM_MODAL_CLASS}-body`}>
          <WarningOutlined />
          <span className={`${ANT_CONFIRM_MODAL_CLASS}-title`}>{title}</span>
          <div className={`${ANT_CONFIRM_MODAL_CLASS}-content`}>{content}</div>
        </div>
        <div className={`${ANT_CONFIRM_MODAL_CLASS}-btns`}>
          <Button type="primary" onClick={props.logout} autoFocus={true}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
});
