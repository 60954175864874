import { Form as LegacyForm } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import React from 'react';
import { EditableContext } from '.';

const EditableRow = ({ form, ...props }: any) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

export const EditableFormRow = LegacyForm.create()(EditableRow);
