import * as ActionTypes from '../actionTypes';
import {
  StationUserBayAssignmentDto,
  InternalStationUserResponseDto,
  StationUserResponseDto,
  StationUserFindSomeResponseDto
} from '@pclocs/platform-sdk';
import { InternalStationUserCreateDto, InternalStationUserUpdateDto } from '@pclocs/platform-sdk/dist';

export enum CsvImportState {
  IDLE = 'IDLE',
  UPLOADING = 'UPLOADING',
  IMPORTING = 'IMPORTING',
  UPDATING = 'UPDATING',
  DONE = 'DONE',
  ERROR = 'ERROR'
}

export enum ExportState {
  IDLE = 'IDLE',
  REQUESTING = 'REQUESTING',
  REQUESTED = 'REQUESTED'
}

export interface ValidationError {
  message: string;
  descriptions: { constraints: string[] }[];
}
export interface StationUserState {
  showStationUserModal: boolean;
  showStationUserCsvModal: boolean;
  showStationUserUpdateCsvModal: boolean;
  stationUsers: StationUserResponseDto[];
  stationUsersPagingData?: {
    after?: StationUserFindSomeResponseDto['after'];
    hasMoreNonPaginatable: StationUserFindSomeResponseDto['hasMoreNonPaginatable'];
    hasFailedOrigins: boolean;
  };
  stationUserId?: string;
  stationUser?: InternalStationUserResponseDto;
  pending: boolean;
  isStationUserLoading: boolean;
  filterSearchInProgress: boolean;
  stationUsersFilter?: string;
  stationUsersTagsFilter?: string[];
  stationUsersModalFilter?: { name?: string; tags?: string[] };
  messages?: string[];
  uniquePIN?: string;
  rfid?: string;
  isUniquePINLoading: boolean;
  preview: boolean;
  csvImportState: CsvImportState;
  errorMessage?: string;
  csvFile?: File;
  fileReference?: string;
  error?: ValidationError;
  isStationUserListLoading: boolean;
  fetchError?: string;
  createStationUserSuccess: boolean;
  bayAssignments?: StationUserBayAssignmentDto[];
  showStationUserExportModal: boolean;
  exportState: ExportState;
  stagedUsers: { id: string; name: string }[];
}

export interface PageLoadedAction {
  type: typeof ActionTypes.USERS_PAGE_LOADED;
}

export interface PageUnloadedAction {
  type: typeof ActionTypes.USERS_PAGE_UNLOADED;
}

export interface StationUserModalLoadAction {
  type: typeof ActionTypes.INTERNAL_STATION_USER_LOAD_MODAL | typeof ActionTypes.STATION_USER_LOAD_CSV_MODAL;
  payload?: {
    stationUserId?: string;
  };
}

export interface StationUserModalUnloadAction {
  type:
    | typeof ActionTypes.INTERNAL_STATION_USER_UNLOAD_MODAL
    | typeof ActionTypes.STATION_USER_UNLOAD_CSV_MODAL
    | typeof ActionTypes.STATION_USER_UNLOAD_DELETE_MODAL
    | typeof ActionTypes.STATION_USER_UNLOAD_UPDATE_CSV_MODAL;
}

export interface FindStationUsersAction {
  type: typeof ActionTypes.FIND_STATION_USERS;
  payload?: {
    after?: string;
    pageSize?: number;
    filter?: string;
    tagsFilter?: string[];
  };
}

export interface FindStationUsersSuccessAction {
  type: typeof ActionTypes.FIND_STATION_USERS_SUCCESS;
  payload: StationUserFindSomeResponseDto;
}

export interface FindStationUsersErrorAction {
  type: typeof ActionTypes.FIND_STATION_USERS_ERROR;
  payload: {
    fetchError: string;
  };
}

export interface ClearStationUsersAction {
  type: typeof ActionTypes.CLEAR_STATION_USERS;
}

export interface FindStationUserAction {
  type: typeof ActionTypes.GET_STATION_USER | typeof ActionTypes.FIND_STATION_USER;
  payload: {
    stationUserId: string;
  };
}

export interface FindStationUserSuccessAction {
  type: typeof ActionTypes.GET_STATION_USER_SUCCESS;
  payload: {
    data: InternalStationUserResponseDto;
  };
}

export interface FindStationUserErrorAction {
  type: typeof ActionTypes.GET_STATION_USER_ERROR;
  payload: {
    message: string;
  };
}

export interface DeleteStationUserAction {
  type: typeof ActionTypes.DELETE_STATION_USER;
  payload: {
    id: string;
    name: string;
  };
}

export interface DeleteStationUserSuccessAction {
  type: typeof ActionTypes.DELETE_STATION_USER_SUCCESS;
  payload: {
    id: string;
  };
}

export interface DeleteStationUserErrorAction {
  type: typeof ActionTypes.DELETE_STATION_USER_ERROR;
}

export interface FilterStationUsersByPropertyAction {
  type: typeof ActionTypes.FILTER_STATION_USERS_BY_PROPERTY;
  payload: {
    filter?: string;
    tagsFilter?: string[];
  };
}

export interface GetUniquePinStationUserAction {
  type: typeof ActionTypes.GET_UNIQUE_PIN_STATION_USER;
}

export interface ClearUniquePinStationUserAction {
  type: typeof ActionTypes.CLEAR_UNIQUE_PIN_STATION_USER;
}

export interface GetUniquePinStationUserSuccessAction {
  type: typeof ActionTypes.GET_UNIQUE_PIN_STATION_USER_SUCCESS;
  payload: {
    pin: string;
  };
}

export interface GetUniquePinStationUserErrorAction {
  type: typeof ActionTypes.GET_UNIQUE_PIN_STATION_USER_ERROR;
  payload: {
    message: string;
  };
}

export interface UpdateStationUserAction {
  type: typeof ActionTypes.UPDATE_STATION_USER;
  payload: {
    data: InternalStationUserUpdateDto;
  };
}

export interface UpdateStationUserSuccessAction {
  type: typeof ActionTypes.UPDATE_STATION_USER_SUCCESS;
  payload: {
    data: InternalStationUserUpdateDto;
  };
}

export interface UpdateStationUserErrorAction {
  type: typeof ActionTypes.UPDATE_STATION_USER_ERROR;
  payload: {
    error: ValidationError;
  };
}

export interface CreateStationUserAction {
  type: typeof ActionTypes.CREATE_STATION_USER;
  payload: {
    data: InternalStationUserCreateDto;
  };
}

export interface CreateStationUserSuccessAction {
  type: typeof ActionTypes.CREATE_STATION_USER_SUCCESS;
}

export interface CreateStationUserErrorAction {
  type: typeof ActionTypes.CREATE_STATION_USER_ERROR;
  payload: {
    error: ValidationError;
  };
}

export interface SetStagedStationUsersAction {
  type: typeof ActionTypes.SET_STAGED_STATION_USERS;
  payload: {
    stagedUsers: { id: string; name: string }[];
  };
}

export interface PushCsvFileToS3Action {
  type: typeof ActionTypes.PUSH_CSV_FILE_TO_S3;
  payload: {
    file: File;
  };
}

export interface PushCsvFileToS3SuccessAction {
  type: typeof ActionTypes.PUSH_CSV_FILE_TO_S3_SUCCESS;
  payload: {
    fileReference: string;
  };
}

export interface PushCsvFileToS3ErrorAction {
  type: typeof ActionTypes.PUSH_CSV_FILE_TO_S3_ERROR;
  payload: {
    message?: string;
  };
}

export interface TriggerApiCsvImportAction {
  type: typeof ActionTypes.TRIGGER_API_CSV_IMPORT;
  payload: {
    fileReference: string;
    deleteAllUsers: boolean;
  };
}

export interface TriggerApiUserExportSuccessAction {
  type: typeof ActionTypes.TRIGGER_API_USER_EXPORT_SUCCESS;
}

export interface StationUserLoadExportModalAction {
  type: typeof ActionTypes.STATION_USER_LOAD_EXPORT_MODAL;
}

export interface StationUserUnloadExportModalAction {
  type: typeof ActionTypes.STATION_USER_UNLOAD_EXPORT_MODAL;
}

export interface TriggerApiUserExportAction {
  type: typeof ActionTypes.TRIGGER_API_USER_EXPORT;
  payload: {
    searchText: string | undefined;
    tagFilter: string[] | undefined;
  };
}

export interface TriggerApiCsvImportSuccessAction {
  type: typeof ActionTypes.TRIGGER_API_CSV_IMPORT_SUCCESS;
}

export interface TriggerApiCsvUpdateAction {
  type: typeof ActionTypes.TRIGGER_API_CSV_UPDATE;
  payload: {
    fileReference: string;
  };
}

export interface TriggerApiCsvUpdateSuccessAction {
  type: typeof ActionTypes.TRIGGER_API_CSV_UPDATE_SUCCESS;
}

export interface StationUserLoadUpdateCsvModalAction {
  type: typeof ActionTypes.STATION_USER_LOAD_UPDATE_CSV_MODAL;
}

export interface RemoveBayAssignmentSuccessAction {
  type: typeof ActionTypes.REMOVE_BAY_ASSIGNMENT_SUCCESS;
}

export interface ClearStationUserAction {
  type: typeof ActionTypes.CLEAR_STATION_USER;
}

export interface StationUserFindBayAssignments {
  type: typeof ActionTypes.STATION_USER_FIND_BAY_ASSIGNMENTS;
  payload: {
    id: string;
  };
}

export interface StationUserFindBayAssignmentsSuccess {
  type: typeof ActionTypes.STATION_USER_FIND_BAY_ASSIGNMENTS_SUCCESS;
  payload: {
    bays: StationUserBayAssignmentDto[];
  };
}

export type StationUserActionTypes =
  | PageLoadedAction
  | PageUnloadedAction
  | FindStationUsersAction
  | FindStationUsersSuccessAction
  | FindStationUsersErrorAction
  | ClearStationUsersAction
  | StationUserModalLoadAction
  | StationUserModalUnloadAction
  | FindStationUserAction
  | FindStationUserSuccessAction
  | FindStationUserErrorAction
  | DeleteStationUserAction
  | DeleteStationUserSuccessAction
  | DeleteStationUserErrorAction
  | SetStagedStationUsersAction
  | FilterStationUsersByPropertyAction
  | GetUniquePinStationUserAction
  | ClearUniquePinStationUserAction
  | GetUniquePinStationUserSuccessAction
  | GetUniquePinStationUserErrorAction
  | UpdateStationUserAction
  | UpdateStationUserSuccessAction
  | UpdateStationUserErrorAction
  | CreateStationUserAction
  | CreateStationUserSuccessAction
  | CreateStationUserErrorAction
  | PushCsvFileToS3Action
  | PushCsvFileToS3SuccessAction
  | PushCsvFileToS3ErrorAction
  | TriggerApiCsvImportAction
  | TriggerApiCsvImportSuccessAction
  | TriggerApiUserExportAction
  | TriggerApiUserExportSuccessAction
  | StationUserLoadExportModalAction
  | StationUserUnloadExportModalAction
  | TriggerApiCsvUpdateAction
  | TriggerApiCsvUpdateSuccessAction
  | StationUserLoadUpdateCsvModalAction
  | RemoveBayAssignmentSuccessAction
  | ClearStationUserAction
  | StationUserFindBayAssignments
  | StationUserFindBayAssignmentsSuccess;
