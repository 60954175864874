import React from 'react';
import { Input, Button } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { LoadingOutlined, PlusCircleFilled } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';

type Props = FormComponentProps & {
  newAppClientFormShown: boolean;
  createAppClient: (payload: { clientSecret: string; name: string }) => void;
  toggleNewAppClientForm: () => void;
  pending: boolean;
  featureEnabled: boolean;
};

export const NewAppClientForm = LegacyForm.create<Props>({ name: 'AppClient' })((props: Props) => {
  const { getFieldDecorator } = props.form;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      if (!err) {
        props.createAppClient({ clientSecret: values.clientSecret, name: values.name });
      }
    });
  };

  return (
    <LegacyForm layout="inline" onSubmit={handleSubmit}>
      <LegacyForm.Item>
        <Button
          className="btn-primary"
          disabled={!props.featureEnabled || props.newAppClientFormShown || props.pending}
          onClick={props.toggleNewAppClientForm}>
          <PlusCircleFilled />
          New App Client
        </Button>
      </LegacyForm.Item>
      {props.featureEnabled && props.newAppClientFormShown && (
        <>
          <LegacyForm.Item>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'A name is required.' }]
            })(<Input type="text" placeholder="Name" style={{ width: 400, lineHeight: 2 }} disabled={props.pending} />)}
          </LegacyForm.Item>
          <LegacyForm.Item>
            {getFieldDecorator('clientSecret', {
              rules: [{ required: true, message: 'A client secret is required.' }]
            })(
              <Input.Password
                type="password"
                placeholder="Client secret"
                style={{ width: 400, lineHeight: 2 }}
                disabled={props.pending}
              />
            )}
          </LegacyForm.Item>
          <LegacyForm.Item>
            <Button style={{ marginRight: 10 }} onClick={props.toggleNewAppClientForm} disabled={props.pending}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" disabled={props.pending}>
              {props.pending ? <LoadingOutlined /> : 'Save'}
            </Button>
          </LegacyForm.Item>
        </>
      )}
    </LegacyForm>
  );
});
