import React from 'react';
import { Modal, Input, Button } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import '@ant-design/compatible/assets/index.css';
import { minLength, maxLength, validatePinOrRfidExists, validPinPattern } from '../../../helpers/credentials-helper';
import * as RfidHelper from '../../../helpers/rfid-converter-helper';

type Props = {
  visible: boolean;
  handleSave: (bayId: string, credentials: { pin?: string; rfid?: string }) => void;
  toggleModalShown: (showModal: boolean) => void;
  bayId: string;
  pending: boolean;
} & FormComponentProps;

export const AssignCredentials = LegacyForm.create<Props>({ name: 'credentials_form' })((props: Props) => {
  const { getFieldDecorator } = props.form;

  const handleRFIDFormatChange = () => {
    props.form.setFieldsValue({
      rfid: RfidHelper.changeFormat(props.form.getFieldValue('rfid'))
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err: Error, values: any) => {
      if (!err) {
        props.handleSave(props.bayId, {
          pin: values.pin,
          rfid: values.rfid ? RfidHelper.rfidToHexPad(values.rfid) : undefined
        });
      }
    });
  };

  return (
    <Modal
      title="Assign Credentials to Bay"
      visible={props.visible}
      destroyOnClose={true}
      onCancel={props.toggleModalShown.bind(null, false)}
      footer={[
        <Button key="back" onClick={props.toggleModalShown.bind(null, false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={props.pending}
          data-test-id="station-user-btn-submit">
          Assign
        </Button>
      ]}>
      <p>Directly set a bay assignment with specified credentials.</p>
      <LegacyForm
        {...{
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 }
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 }
          }
        }}>
        <LegacyForm.Item colon={false} label={<span>RFID:&nbsp;{RfidHelper.rfidFormatTooltip}</span>}>
          {getFieldDecorator('rfid', {
            rules: [{ validator: validatePinOrRfidExists(props.form) }],
            validateTrigger: 'onBlur'
          })(<Input addonAfter={<RfidHelper.FormatSelector onChange={handleRFIDFormatChange} />} />)}
        </LegacyForm.Item>
        <LegacyForm.Item label="PIN">
          {getFieldDecorator('pin', {
            rules: [
              { validator: validatePinOrRfidExists(props.form) },
              { pattern: validPinPattern, message: `PIN can only contain numbers.` },
              { min: minLength, message: `PIN must be at least ${minLength} characters.` },
              { max: maxLength, message: `PIN cannot be longer than ${maxLength} characters.` }
            ],
            validateTrigger: 'onBlur'
          })(<Input />)}
        </LegacyForm.Item>
      </LegacyForm>
    </Modal>
  );
});
