import React from 'react';
import { Alert } from 'antd';

export const UsersBulkActionInProgress = () => {
  return (
    <Alert
      showIcon
      message="Adding or editing users is temporarily disabled"
      description="A bulk action is in progress. Once completed, adding or editing users will become available again."
      type="info"
      style={{ marginBottom: 20 }}
    />
  );
};
