import React from 'react';
import LogoIcon from '../../assets/images/pc-locs-icon.png';

export const LogoLoader = () => {
  return (
    <div className="loader">
      <img src={LogoIcon} alt="Logo icon" width="54" />
      <div className="dot-flashing" />
    </div>
  );
};
