import React, { Dispatch, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Alert } from 'antd';
import { Form as LegacyForm } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import '@ant-design/compatible/assets/index.css';
import * as ActionTypes from '../../store/actionTypes';
import { RootState } from '../../store/rootReducer';
import { AuthActionTypes } from '../../store/auth/types';
import { RegisterActionTypes } from '../../store/register/types';
import { CognitoUser } from '@aws-amplify/auth';
import { AdminUserUpdateDto } from '@pclocs/platform-sdk';
import { CompleteAdminOnboardForm } from './login-forms/complete-admin-onboard-form';
import { SetupMfaTotpForm } from './login-forms/setup-mfa-totp-form';
import { RequestMfaTotpForm } from './login-forms/request-mfa-totp-form';
import { DefaultLoginForm } from './login-forms/default-login-form';

const mapStateToProps = (state: RootState) => ({ ...state.auth });
const mapDispatchToProps = (dispatch: Dispatch<AuthActionTypes | RegisterActionTypes>) => ({
  onLoad: () => dispatch({ type: ActionTypes.LOGIN_PAGE_LOADED }),
  onUnload: () => dispatch({ type: ActionTypes.LOGIN_PAGE_UNLOADED }),
  login: (username: string, password: string) => dispatch({ type: ActionTypes.LOGIN, payload: { username, password } }),
  verifyMfaTotp: (cognitoUser: CognitoUser, mfaTotpVerificationToken: string) =>
    dispatch({
      type: ActionTypes.VERIFY_MFA_TOTP_TOKEN,
      payload: { cognitoUser, mfaTotpVerificationToken, origin: 'login' }
    }),
  confirmMfaTotp: (cognitoUser: CognitoUser, mfaTotpConfirmationToken: string) =>
    dispatch({ type: ActionTypes.CONFIRM_MFA_TOTP_TOKEN, payload: { cognitoUser, mfaTotpConfirmationToken } }),
  createNewPassword: (cognitoUser: CognitoUser, password: string, adminUser: AdminUserUpdateDto) =>
    dispatch({ type: ActionTypes.CREATE_NEW_PASSWORD, payload: { cognitoUser, password, adminUser } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & FormComponentProps;

export const getErrorAlertMessage = (props: Pick<Props, 'error'>) => {
  return (
    <LegacyForm.Item style={{ marginBottom: 16 }}>
      <Alert message={props.error.message} type="error" showIcon />
    </LegacyForm.Item>
  );
};

export const LoginForm = connector(
  LegacyForm.create({ name: 'login' })((props: Props) => {
    const params = new URLSearchParams(window.location.search);
    const error_description = params.get('error_description');

    useEffect(() => {
      props.onLoad();

      return () => {
        props.onUnload();
      };
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      props.form.validateFields((err: Error, values: any) => {
        if (!err) {
          props.login(values.username, values.password);
        }
      });
    };

    const verifyMfaTotp = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      props.form.validateFields((err: Error, values: any) => {
        if (!err && props.cognitoUser) {
          props.verifyMfaTotp(props.cognitoUser, values.mfaTotpVerificationToken);
        }
      });
    };

    const confirmMfaTotp = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      props.form.validateFields((err: Error, values: any) => {
        if (!err && props.cognitoUser) {
          props.confirmMfaTotp(props.cognitoUser, values.mfaTotpConfirmationToken);
        }
      });
    };

    const handleUpdatePassword = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      props.form.validateFields((err: Error, values: any) => {
        if (!err) {
          const adminUser: AdminUserUpdateDto = {
            name: values.name
          };
          props.cognitoUser && props.createNewPassword(props.cognitoUser, values.newPassword, adminUser);
        }
      });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      if (!props.showCompleteAdminOnboardForm && !props.showSetupMfaTotpForm && !props.showRequestMfaTotpTokenForm) {
        handleSubmit(e);
      } else if (props.showSetupMfaTotpForm) {
        verifyMfaTotp(e);
      } else if (props.showRequestMfaTotpTokenForm) {
        confirmMfaTotp(e);
      } else if (props.showCompleteAdminOnboardForm) {
        handleUpdatePassword(e);
      }
    };

    return (
      <LegacyForm onSubmit={onSubmit} className="login-form">
        {error_description && props.showLoginForm && (
          <Alert
            showIcon
            message=""
            description="There was a problem logging in with single sign-on. Please contact your SSO administrator."
            type="warning"
            style={{ marginTop: 5, marginBottom: 20 }}
          />
        )}
        {props.showCompleteAdminOnboardForm && <CompleteAdminOnboardForm props={props} />}
        {props.showSetupMfaTotpForm && <SetupMfaTotpForm props={props} />}
        {props.showRequestMfaTotpTokenForm && <RequestMfaTotpForm props={props} />}
        {props.showLoginForm && <DefaultLoginForm props={props} />}
      </LegacyForm>
    );
  })
);
