import {
  AccountBasicResponseDto,
  AdminUserCreateDto,
  AdminUserResponseDto,
  AdminUserWithCredentialsResponseDto,
  AdminUserUpdateDto,
  FeaturesDto,
  FeaturesDtoFeaturesEnum,
  SubscriptionResponseDto,
  AdminRoleResponseDto,
  AdminClaimsResponseDto,
  AdminRoleResponseDtoTypeEnum
} from '@pclocs/platform-sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

function sortAdminRoles<T extends AdminRoleResponseDto>(roles: T[]): T[] {
  return roles.sort((a, b) => {
    if (a.type !== b.type) {
      const typeOrder = [AdminRoleResponseDtoTypeEnum.Canned, AdminRoleResponseDtoTypeEnum.Custom];
      return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
    }
    return a.name.localeCompare(b.name);
  });
}

export interface AccountState {
  showPortalAdminModal: boolean;
  portalAdmins?: AdminUserResponseDto[];
  portalAdminsFilter?: string;
  adminUser?: AdminUserResponseDto | AdminUserWithCredentialsResponseDto;
  createAdminUser?: AdminUserCreateDto;
  updateAdminUser?: AdminUserUpdateDto;
  uniquePIN?: string;
  rfid?: string;
  messages?: string[];
  adminUserId?: string;
  pending: boolean;
  isUniquePINLoading: boolean;
  isPortalAdminLoading: boolean;
  accountInfo?: AccountBasicResponseDto;
  subscriptionInfo?: SubscriptionResponseDto;
  isSubscriptionInfoPending: boolean;
  isAvailableFeaturesPending: boolean;
  availableFeatures: FeaturesDtoFeaturesEnum[] | null;
  adminRoles?: AdminRoleResponseDto[];
  isAdminRolesLoading: boolean;
  cancelAccountSuspensionPayload?: { accountId: string; token: string };
  isCancelAccountSuspensionLoading: boolean;
  cancelAccountSuspensionMessages?: string[];
  isAdminClaimsPending: boolean;
  adminClaims?: AdminClaimsResponseDto['claims'];
}

const initialState: AccountState = {
  showPortalAdminModal: false,
  pending: false,
  isUniquePINLoading: false,
  isPortalAdminLoading: false,
  subscriptionInfo: undefined,
  isSubscriptionInfoPending: false,
  isAvailableFeaturesPending: true,
  availableFeatures: null,
  isAdminRolesLoading: false,
  isCancelAccountSuspensionLoading: false,
  isAdminClaimsPending: false
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    accountPageLoaded: () => undefined,
    accountPageUnloaded: state => ({
      ...initialState,
      isAvailableFeaturesPending: state.isAvailableFeaturesPending,
      availableFeatures: state.availableFeatures,
      subscriptionInfo: state.subscriptionInfo,
      adminClaims: state.adminClaims
    }),

    portalAdminLoadModal: (state, action: PayloadAction<{ adminUserId: string }>) => {
      state.showPortalAdminModal = true;
      state.adminUserId = action.payload.adminUserId;
    },
    portalAdminUnloadModal: state => ({
      ...state,
      showPortalAdminModal: false,
      adminUserId: undefined,
      uniquePIN: undefined,
      adminUser: undefined,
      pending: false,
      messages: undefined
    }),
    findPortalAdmins: state => {
      state.isPortalAdminLoading = true;
    },
    deletePortalAdmin: (state, _action: PayloadAction<{ user: AdminUserResponseDto }>) => {
      state.isPortalAdminLoading = true;
    },
    deletePortalAdminSuccess: state => {
      state.isPortalAdminLoading = false;
    },
    deletePortalAdminError: state => {
      state.isPortalAdminLoading = false;
    },
    findPortalAdminsSuccess: (state, action: PayloadAction<{ data: AccountState['portalAdmins'] }>) => ({
      ...state,
      isPortalAdminLoading: false,
      portalAdmins: action.payload.data
    }),
    findPortalAdminsError: (state, _action: PayloadAction<string>) => {
      state.portalAdmins = undefined;
    },
    filterPortalAdmins: (state, action: PayloadAction<{ value: string }>) => {
      state.portalAdminsFilter = action.payload.value;
    },
    findPortalAdmin: (state, action: PayloadAction<{ adminUserId: string }>) => {
      state.adminUserId = action.payload.adminUserId;
    },
    findPortalAdminSuccess: (
      state,
      action: PayloadAction<{ data: AdminUserResponseDto | AdminUserWithCredentialsResponseDto }>
    ) => {
      state.adminUser = action.payload.data;
      state.uniquePIN = 'stationPin' in action.payload.data ? action.payload.data.stationPin : undefined;
      state.rfid = 'stationRfid' in action.payload.data ? action.payload.data.stationRfid : undefined;
    },
    createPortalAdmin: (state, action: PayloadAction<{ data: AdminUserCreateDto }>) => {
      state.pending = true;
      state.messages = undefined;
      state.createAdminUser = action.payload.data;
    },
    updatePortalAdmin: (state, action: PayloadAction<{ data: AdminUserUpdateDto; adminUserId: string }>) => {
      state.pending = true;
      state.messages = undefined;
      state.updateAdminUser = action.payload.data;
    },
    updatePortalAdminError: (state, action: PayloadAction<{ messages: string[] }>) => {
      state.pending = false;
      state.messages = action.payload.messages;
    },
    createPortalAdminError: (state, action: PayloadAction<{ messages: string[] }>) => {
      state.pending = false;
      state.messages = action.payload.messages;
    },

    getUniquePin: state => {
      state.isUniquePINLoading = true;
      state.uniquePIN = undefined;
    },
    getUniquePinSuccess: (state, action: PayloadAction<{ pin: string }>) => {
      state.isUniquePINLoading = false;
      state.uniquePIN = action.payload.pin;
    },
    clearUniquePin: state => {
      state.isUniquePINLoading = false;
      state.uniquePIN = '';
    },
    getUniquePinError: (state, _action: PayloadAction<{ message: string }>) => {
      state.isUniquePINLoading = false;
      state.uniquePIN = '';
    },

    getAccountInfo: state => state,
    getAccountInfoSuccess: (state, action: PayloadAction<{ data: AccountState['accountInfo'] }>) => {
      state.accountInfo = action.payload.data;
    },
    getAccountInfoError: state => state,

    getSubscriptionInfo: state => {
      state.isSubscriptionInfoPending = true;
    },
    getSubscriptionInfoSuccess: (state, action: PayloadAction<{ data: AccountState['subscriptionInfo'] }>) => {
      state.isSubscriptionInfoPending = false;
      state.subscriptionInfo = action.payload.data;
    },
    getSubscriptionInfoError: state => {
      state.isSubscriptionInfoPending = false;
    },

    getFeatures: state => {
      state.isAvailableFeaturesPending = true;
    },
    getFeaturesSuccess: (state, action: PayloadAction<{ data: FeaturesDto }>) => {
      state.isAvailableFeaturesPending = false;
      state.availableFeatures = action.payload.data.features;
    },
    getFeaturesError: state => {
      state.isAvailableFeaturesPending = false;
    },

    findAdminRoles: state => {
      state.isAdminRolesLoading = true;
    },
    findAdminRolesSuccess: (state, action: PayloadAction<{ data: AccountState['adminRoles'] }>) => ({
      ...state,
      isAdminRolesLoading: false,
      adminRoles: sortAdminRoles(action.payload.data)
    }),
    findAdminRolesError: (state, _action: PayloadAction<string>) => {
      state.isAdminRolesLoading = false;
      state.adminRoles = undefined;
    },
    cancelAccountSuspension: (state, action: PayloadAction<{ accountId: string; token: string }>) => {
      state.isCancelAccountSuspensionLoading = true;
      state.cancelAccountSuspensionPayload = action.payload;
      state.cancelAccountSuspensionMessages = undefined;
    },
    cancelAccountSuspensionSuccess: state => {
      state.isCancelAccountSuspensionLoading = false;
    },
    cancelAccountSuspensionError: (state, action: PayloadAction<{ messages: string[] }>) => {
      state.isCancelAccountSuspensionLoading = false;
      state.cancelAccountSuspensionMessages = action.payload.messages;
    },
    getAdminClaims: (state, _action: PayloadAction<string>) => {
      state.isAdminClaimsPending = true;
    },
    getAdminClaimsSuccess: (state, action: PayloadAction<{ data: AdminClaimsResponseDto }>) => {
      state.isAdminClaimsPending = false;
      state.adminClaims = action.payload.data.claims;
    },
    getAdminClaimsError: state => {
      state.isAdminClaimsPending = false;
    }
  }
});

export const accountReducer = slice.reducer;
export const accountActions = slice.actions;
export type AccountActionTypes = {
  [P in keyof typeof accountActions]: ReturnType<typeof accountActions[P]>;
}[keyof typeof accountActions];
