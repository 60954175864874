import { CommonApi } from '@pclocs/platform-sdk';
import { createApi } from '../api/sdk';
import axios from 'axios';
import _ from 'lodash';

/**
 * Uploads a file directly from the client to the file server (S3).
 * Returns the reference to send to the platform.
 * @param file
 * @param mimeType
 */
export const performFileUpload = async (file: File, mimeType?: string) => {
  const api = createApi<CommonApi>('CommonApi');
  const uploadDetailsRes = await api.getFileUploadUrl(file.name);
  const uploadDetails = uploadDetailsRes.data;

  const formData = new FormData();
  formData.append('key', uploadDetails.key);
  for (const [key, val] of Object.entries(uploadDetails.fields)) {
    formData.append(key, val);
  }

  // NOTE: 'file' must be the last element in the form or the request will fail
  formData.append('Content-Type', mimeType || file.type);
  formData.append('file', file);

  if (file.size > 10 * 1024 * 1024) {
    throw new Error(`CSV file size cannot exceed 10MB.`);
  }

  const uploadRes = await axios.post(uploadDetails.url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  if (!_.inRange(uploadRes.status, 200, 300)) {
    throw new Error(`Failed to upload the file to the server. ${uploadRes.statusText}`);
  }
  return uploadDetails.ref;
};
